import * as React from 'react';
import MapWrapper from "./map/MapWrapper";
import SnackbarWrapper from "./commonComponents/SnackbarWrapper";
import UrlHandler from "./permalink/UrlHandler";
import {CssBaseline, Grid} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import Theme from "./theme/Theme";
import TabContentControl from "./bar/TabContentControl";
import AppBar from "./bar/AppBar";
import SearchServiceRetriever from "./search/SearchServiceRetriever";

class App extends React.Component<any, any>{
    public render(): JSX.Element{
        return(
            <ThemeProvider theme={Theme} >
                <CssBaseline />
                <div>
                    <AppBar />
                    <Grid container direction="row">
                        <Grid item md={4}>
                            <TabContentControl />
                        </Grid>
                        <Grid item md={8}>
                            <MapWrapper/>
                        </Grid>
                    </Grid>

                    <SnackbarWrapper/>
                    <UrlHandler />
                    <SearchServiceRetriever />
                </div>
            </ThemeProvider>
        )
    }
}

export default App;
