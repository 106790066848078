import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from "@material-ui/core";
import CreatableSelect from 'react-select/creatable';
import {TypeUtils} from "../../../utils/TypeUtils";

type ShieldNameProps = {
    initialName: string,
    onChange: (newName: string) => void
}

type ShieldNameState = {
    prefix: string,
    numeric: string,
    directional: string,
    modifier: string
}

class ShieldName extends React.Component<ShieldNameProps, ShieldNameState>{
    public constructor(props) {
        super(props);

        let nameParts: Array<string> = [];
        if(this.props.initialName)
            nameParts = this.props.initialName.split('|');
        console.log(nameParts);
        if(nameParts.length === 5)
            this.state = {
                prefix: nameParts[0],
                numeric: nameParts[1],
                directional: nameParts[2],
                modifier: nameParts[3]
            };
        else
            this.state = {
                prefix: "",
                numeric: "",
                directional: "",
                modifier: ""
            }
    }

    public handleSave = () => {
        let streetName: string = this.state.prefix + "|" + this.state.numeric + "|" + this.state.directional + "|" + this.state.modifier + "|";
        this.props.onChange(streetName);
    };

    public handleClose = () => {
        console.log("ShieldName handleClose");
        if(!this.props.initialName || (this.props.initialName && this.props.initialName.split("|").length !== 5))
            this.props.onChange("||||");
        else
            this.props.onChange(this.props.initialName);
    };

    public onNameChange = () => {
        let prefix: string = (document.getElementById("namePrefixId") as HTMLInputElement).value;
        let numeric: string = (document.getElementById("nameNumericId") as HTMLInputElement).value;
        let modifier: string = (document.getElementById("nameModifierId") as HTMLInputElement).value;

        this.setState({
            prefix: prefix,
            numeric: numeric,
            modifier: modifier
        })
    };

    public handleDirectionChange = (newValue: any) => {
        if(newValue)
            this.setState({
                directional: newValue.value
            });
        else
            this.setState({
                directional: ""
            });
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let directions: Array<{label: string, value: string}> = TypeUtils.StreetDirection.map(dir => {return {"label": dir, "value": dir}});
        let currentDirection: {label: string, value: string} = {label: this.state.directional, value: this.state.directional};

        return(
            <div>
                <Dialog open={true} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item md={3}>
                                        Prefix
                                    </Grid>
                                    <Grid item md={3}>
                                        Numeric
                                    </Grid>
                                    <Grid item md={3}>
                                        Directional
                                    </Grid>
                                    <Grid item md={3}>
                                        Modifier
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item md={3}>
                                        <TextField id="namePrefixId" type="text" size="small" onChange={this.onNameChange} value={this.state.prefix}/>
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField id="nameNumericId" type="text" size="small" onChange={this.onNameChange} value={this.state.numeric}/>
                                    </Grid>
                                    <Grid item md={3}>
                                        <CreatableSelect isClearable options={directions} onChange={this.handleDirectionChange} value={currentDirection}/>
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField id="nameModifierId" type="text" size="small" onChange={this.onNameChange} value={this.state.modifier}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} variant="outlined" color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSave} variant="outlined" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default ShieldName