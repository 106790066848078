import * as React from 'react';
import {Entity} from "../../model/Entity";
import {setEntityCreation} from "../../store/actions/searchEntities";
import {connect} from "react-redux";
import {EntityExtension} from "../../model/EntityExtension";
import {Button} from "@material-ui/core";
import {RequestUtils} from "../../utils/RequestUtils";
import {CloneEntityRequest} from "../../model/request/edit/CloneEntityRequest";
import GlobalCallbacks from "../../utils/GlobalCallbacks";

type CloneEntityProps = {
    entity: EntityExtension,
    setEntityCreation: (entityId: string, entity: Entity) => void
}

class CloneEntity extends React.Component<CloneEntityProps, any>{
    public cloneEntity = () => {
        let data: CloneEntityRequest = {EntityID: this.props.entity.entityId};
        RequestUtils.sendRequestWithConfigString("/api/entity/cloneEntity/post/", 'POST', data)
            .then(result => result.json())
            .then(
                data => {
                    window.console.log('SearchForm, finished, entityId ->', data);
                    if(data && data.SingleEntity) {
                        this.props.setEntityCreation(data.SingleEntity.EntityID, data.SingleEntity);
                    }
                    else{
                        GlobalCallbacks.displaySnackbarMessage("Clone failed, try again!!");
                    }
                })
    };

    public render(){
        return(
            <div>
                <Button fullWidth={true} onClick={this.cloneEntity} variant="contained" size={"small"}>Clone entity</Button>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setEntityCreation: (entityId: string, entity: Entity) => dispatch(setEntityCreation(entityId, entity))
    }
};

export default connect(null, mapDispatchToProps)(CloneEntity)