import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from "@material-ui/core";
import DropdownList from "../../../commonComponents/DropdownList";
import CreatableSelect from 'react-select/creatable';
import {TypeUtils} from "../../../utils/TypeUtils";

type StreetNameProps = {
    initialName: string,
    onChange: (newName: string) => void
}

type StreetNameState = {
    body: string,
    type: string,
    prefix: string,
    suffix: string,
    isPreceded: boolean,
    isAttached: boolean
}

class StreetName extends React.Component<StreetNameProps, StreetNameState>{
    public constructor(props) {
        super(props);

        let nameParts: Array<string> = [];
        if(this.props.initialName)
            nameParts = this.props.initialName.split('|');
        console.log(nameParts);
        if(nameParts.length === 7)
            this.state = {
                body: nameParts[0],
                type: nameParts[1],
                prefix: nameParts[2],
                suffix: nameParts[3],
                isPreceded: nameParts[4] === "1",
                isAttached: nameParts[5] === "1"
            };
        else
            this.state = {
                body: "",
                type: "",
                prefix: "",
                suffix: "",
                isPreceded: false,
                isAttached: false
            }
    }

    public handleSave = () => {
        let streetName: string = this.state.body + "|" + this.state.type + "|" + this.state.prefix + "|" + this.state.suffix + "|" + (this.state.isPreceded ? "1" : "0") + "|" + (this.state.isAttached ? "1" : "0") + "|";
        this.props.onChange(streetName);
    };

    public handleClose = () => {
        console.log("StreetName handleClose");
        if(!this.props.initialName || (this.props.initialName && this.props.initialName.split("|").length !== 7))
            this.props.onChange("||||0|0|");
        else
            this.props.onChange(this.props.initialName);
    };

    public onNameChange = () => {
        let body: string = (document.getElementById("nameBodyId") as HTMLInputElement).value;

        this.setState({
            body: body
        })
    };

    public onPrecededChange = (selectedOption) => {
        this.setState({
            isPreceded: selectedOption === "Yes"
        })
    };

    public onAttachedChange = (selectedOption) => {
        this.setState({
            isAttached: selectedOption === "Yes"
        })
    };

    public handleDirPrefChange = (newValue: any) => {
        if(newValue)
            this.setState({
                prefix: newValue.value
            });
        else
            this.setState({
                prefix: ""
            });
    };

    public handleDirSufChange = (newValue: any) => {
        if(newValue)
            this.setState({
                suffix: newValue.value
            });
        else
            this.setState({
                suffix: ""
            });
    };

    public handleTypeChange = (newValue: any) => {
        if(newValue)
            this.setState({
                type: newValue.value
            });
        else
            this.setState({
                type: ""
            });
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log(this.props.initialName);
        let directions: Array<{label: string, value: string}> = TypeUtils.StreetDirection.map(dir => {return {"label": dir, "value": dir}});
        let streetTypes: Array<{label: string, value: string}> = TypeUtils.StreetTypes.map(dir => {return {"label": dir, "value": dir}});
        let currDirectionPrefix: {label: string, value: string} = {label: this.state.prefix, value: this.state.prefix};
        let currDirectionSuffix: {label: string, value: string} = {label: this.state.suffix, value: this.state.suffix};
        let currStreetType: {label: string, value: string} = {label: this.state.type, value: this.state.type};

        return(
            <div>
                <Dialog open={true} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item md={4}>
                                        Street Body
                                    </Grid>
                                    <Grid item md={4}>
                                        Street Type
                                    </Grid>
                                    <Grid item md={4}>
                                        Directional Prefix
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item md={4}>
                                        <TextField id="nameBodyId" type="text" size="small" onChange={this.onNameChange} value={this.state.body}/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <CreatableSelect isClearable options={streetTypes} onChange={this.handleTypeChange} value={currStreetType}/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <CreatableSelect isClearable options={directions} onChange={this.handleDirPrefChange} value={currDirectionPrefix}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item md={4}>
                                        Directional Suffix
                                    </Grid>
                                    <Grid item md={4}>
                                        Type precedes body?
                                    </Grid>
                                    <Grid item md={4}>
                                        Type is attached to body?
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item md={4}>
                                        <CreatableSelect isClearable options={directions} onChange={this.handleDirSufChange} value={currDirectionSuffix}/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <DropdownList options={["Yes", "No"]} onChange={this.onPrecededChange} initialValue={this.state.isPreceded ? "Yes" : "No"}/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <DropdownList options={["Yes", "No"]} onChange={this.onAttachedChange} initialValue={this.state.isAttached ? "Yes" : "No"}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} variant="outlined" color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSave} variant="outlined" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default StreetName