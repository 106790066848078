import {OntologyFieldNames} from "../OntologyFieldNames";
import {OntologyUtils} from "../OntologyUtils";
import {Ontology} from "../../model/Ontology";

export class OntologyTypesWrapper {
    private properties: {[keyName: string]: Set<string>} = {};
    private relates: {[keyName: string]: Set<string>} = {};

    public constructor(ontology: Ontology | null, ontologyFields: OntologyFieldNames){
        if(ontology){
            this.init(ontologyFields, ontology[ontologyFields.type]);
        }
    }

    public getPropertiesOfType(type: string | null){
        if(type && type in this.properties)
            return Array.from(this.properties[type]).sort();
        else
            return [];
    }

    public getRelatesOfType(type: string): Array<string>{
        if(type && type in this.relates)
            return Array.from(this.relates[type]).sort();
        else
            return [];
    }

    private init(ontologyFields: OntologyFieldNames, inheritanceObjects: any){
        let objects: Array<any> = OntologyUtils.ensureIsArray(inheritanceObjects);

        let descendantsMap: {[ancestorId: string]: Array<string>} = OntologyUtils.getDescendantsMap(ontologyFields.idField, ontologyFields.parentIdField, objects);
        let idToNameMap: {[objectId: string]: string} = {};

        objects.forEach(
            object => {
                let title: string = object['@KeyName'];
                let objectId: string = object[ontologyFields.idField];

                idToNameMap[objectId] = title;

                this.properties[title] = new Set<string>();
                this.relates[title] = new Set<string>();

                if(ontologyFields.properties in object){
                    let properties: Array<any> = OntologyUtils.ensureIsArray(object[ontologyFields.properties]);

                    properties.forEach(property => {
                        let propertyName: string = property['@KeyName'];
                        this.properties[title].add(propertyName);
                    });
                }

                if(ontologyFields.relateProp in object){
                    let relates: Array<any> = OntologyUtils.ensureIsArray(object[ontologyFields.relateProp]);

                    relates.forEach(relate => {
                        let relateName: string = relate['@KeyName'];
                        this.relates[title].add(relateName);
                    })
                }
            }
        );

        let nameDescendantsMap: {[keyName: string]: Array<string>} = OntologyUtils.changeIdsToNames(descendantsMap, idToNameMap);
        OntologyUtils.completeNameToPropertiesMap(nameDescendantsMap, this.properties);
        OntologyUtils.completeNameToPropertiesMap(nameDescendantsMap, this.relates);
    }
}