import * as React from 'react';
import StreamLoader from "./StreamLoader";
import {RequestUtils} from "../utils/RequestUtils";
import { connect } from 'react-redux';
import { resetSearchStore } from '../store/actions/searchEntities';
import {changeActiveTab} from "../store/actions/changeMapState";
import {setErrorMessage, setExpandedNodes, updateStreamInformation} from "../store/actions/changeStreamState";
import '../App.css';
import GlobalCallbacks from '../utils/GlobalCallbacks';
import {UrlUtils} from "../permalink/UrlUtils";
import {StreamUtils} from "../utils/StreamUtils";
import {Button, Divider, LinearProgress} from "@material-ui/core";
import StreamsDisplay from "./StreamsDisplay";
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../App.css';
import {StreamInfo} from "./StreamInfo";
import {Alert} from "@material-ui/lab";

type StreamChooserProps = {
    streamList: {[streamId: string]: StreamInfo},
    currentStreamId: string,
    showSpinner: boolean,
    cosmosPath: string | null,

    expandedNodes: Array<string>,
    errorMessage: string,
    setExpandedNodes: (nodeIds: Array<string>) => void,
    resetSearchStore: any,
    changeActiveTab: any,
    updateStreamInformation: (streamInformation: {}) => any,
    setErrorMessage: (errorMessage: string) => void
}

class StreamChooser extends React.Component<StreamChooserProps, any>{
    private displayList: any;

    public updateStreamList = () => {
        let data = {"ID": -1};
        RequestUtils.sendRequest("/api/entity/getDropDown/post/", 'POST', data)
            .then(result => result.json())
            .then(
                data => {
                    if(data){
                        this.props.updateStreamInformation({
                            streamList: data
                        })
                    }
                    else{
                        GlobalCallbacks.displaySnackbarMessage("Stream list update failed!");
                    }
                }
            )
            .catch((/*error*/) => {
                this.props.setErrorMessage("Error: Streams are not available!");
            })
    };

    public componentDidMount(){
        if(this.displayList !== undefined)
            this.displayList.setHighlighted(this.props.currentStreamId);
    }

    public componentDidUpdate(){
        if(this.displayList !== undefined)
            this.displayList.setHighlighted(this.props.currentStreamId);
    }

    public handleStreamClick = (streamId: string) => {
        this.props.updateStreamInformation({
            showSpinner: true
        });

        window.console.log('*** Stream click ' + streamId + ' ***');
        this.props.resetSearchStore();

        let configString: string = this.props.streamList[streamId].configString;
        sessionStorage.setItem("configString", configString);

        RequestUtils.sendRequestWithConfigString("/api/entity/setSolrCore/post/", 'POST').then(() => {
            StreamUtils.setOntology(streamId, this.props.updateStreamInformation, this.props.changeActiveTab);
            UrlUtils.changeParameter("id", streamId);
        });
    };

    public onNodeToggle = (event: Object, nodeIds: Array<string>) => {
        this.props.setExpandedNodes(nodeIds);
    };

    public render() : JSX.Element{
        return(
            <div>
                <div>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon/>}
                        defaultExpandIcon={<ChevronRightIcon/>}
                        onNodeToggle={this.onNodeToggle}
                        expanded={this.props.expandedNodes}
                    >
                        <StreamsDisplay streams={Object.values(this.props.streamList)} pathIndex={0}
                                        handleStreamClick={this.handleStreamClick}
                                        currentStreamId={this.props.currentStreamId}/>
                    </TreeView>
                    {
                        this.props.errorMessage !== "" &&
                        <Alert severity="error">{this.props.errorMessage}</Alert>
                    }
                </div>
                <div>
                    <Divider variant="fullWidth" />
                    {
                        this.props.showSpinner &&
                        <div>
                            <LinearProgress color={"secondary"}/>
                        </div>
                    }
                    {
                        this.props.currentStreamId !== "" && this.props.cosmosPath !== null && this.props.cosmosPath !== "" &&
                        <div>
                            <Button href={this.props.cosmosPath} target="_blank" rel="noopener noreferrer" id="copyCosmosLinkButton" variant="contained" fullWidth={true}>
                                Current stream's Cosmos path
                            </Button>
                        </div>
                    }
                    <StreamLoader updateStreamList={this.updateStreamList}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        streamList: state.streamState.streamList,
        currentStreamId: state.streamState.currentStreamId,
        showSpinner: state.streamState.showSpinner,
        cosmosPath: state.streamState.cosmosPath,
        expandedNodes: state.streamState.expandedNodes,
        errorMessage: state.streamState.errorMessage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetSearchStore: () => dispatch(resetSearchStore()),
        changeActiveTab: (key: string) => dispatch(changeActiveTab(key)),
        updateStreamInformation: (streamInformation: {}) => dispatch(updateStreamInformation(streamInformation)),
        setExpandedNodes: (nodeIds: Array<string>) => dispatch(setExpandedNodes(nodeIds)),
        setErrorMessage: (errorMessage: string) => dispatch(setErrorMessage(errorMessage))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(StreamChooser)