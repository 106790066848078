export interface Property {
    ID: string | null;
    PropertyID: string | null;
    ParentPropertyID: string | null;
    PropertyKeyName: string | null;
    Value: string | null;
    SourceInfo: string | null;
    Confidence: any | null;
    Values: Array<Property> | null;
    Subproperties: Array<Property> | null;
    Name: string | null
}

export function createProperty(key: string): Property {
    return {
        ID: null,
        PropertyID: null,
        ParentPropertyID: null,
        PropertyKeyName: key,
        Value: null,
        SourceInfo: null,
        Confidence: null,
        Values: null,
        Subproperties: null,
        Name: null
    };
}