import {Property} from "./Property";
import {Guid} from "guid-typescript";

export interface Relate{
    RelateTypeKeyName: string;
    RelateID: string;
    FromID: string;
    ToID: string;
    EditedBy: string;
    ToName: string | null;
    SourceInfo: string;
    Confidence: any;
    Properties: Array<Property>
}

export function createRelate(type: string, fromId: string, toId: string){
    let newRelate: Relate = {
        RelateTypeKeyName: type,
        RelateID: Guid.create().toString(),
        FromID: fromId,
        ToID: toId,
        EditedBy: "",
        ToName: null,
        SourceInfo: "",
        Confidence: null,
        Properties: []
    };

    return newRelate;
}