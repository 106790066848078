import * as React from 'react';
import {TreeItem} from "@material-ui/lab";
import {ModelUtils} from "../utils/ModelUtils";
import {Theme, Typography, withTheme} from "@material-ui/core";
import {setExpandedNodes} from "../store/actions/changeStreamState";
import {connect} from "react-redux";
import {StreamInfo} from "./StreamInfo";

type StreamsDisplayProps = {
    streams: Array<StreamInfo>,
    pathIndex: number,
    handleStreamClick: (streamId: string) => void,
    currentStreamId: string,
    theme: Theme
}

class StreamsDisplay extends React.Component<StreamsDisplayProps, any>{
    private streamGroups: {[groupName: string]: Array<StreamInfo>} = {};
    private streamsWithoutGroup: Array<StreamInfo> = [];

    private formatStreamData = () => {
        this.streamGroups = {};
        this.streamsWithoutGroup = [];

        this.props.streams.forEach((info: StreamInfo) => {
            if(this.props.pathIndex < info.streamPath.length - 1){
                let groupName: string = info.streamPath[this.props.pathIndex];

                if(!this.streamGroups.hasOwnProperty(groupName)){
                    this.streamGroups[groupName] = [];
                }

                this.streamGroups[groupName].push(info);
            }
            else{
                this.streamsWithoutGroup.push(info);
            }
        })
    };

    private createStreamGroups = () => {
        let displayGroups: JSX.Element[] = [];
        let nextPathIndex = this.props.pathIndex + 1;

        Object.keys(this.streamGroups).forEach(name =>{
            let streamPath: Array<string> = ModelUtils.deepCopy(this.streamGroups[name][0].streamPath);
            let itemId: string = streamPath.slice(0, this.props.pathIndex + 1).join('/');

            displayGroups.push(
                <TreeItem nodeId={itemId} label={<Typography variant={"h6"}>{name}</Typography>} key={itemId}>
                    <StreamsDisplay streams={this.streamGroups[name]} pathIndex={nextPathIndex} handleStreamClick={this.props.handleStreamClick} key={itemId} currentStreamId={this.props.currentStreamId} theme={this.props.theme}/>
                </TreeItem>
            );
        });

        this.streamsWithoutGroup.forEach(streamInfo => {
            let label: JSX.Element;
            let id: string = streamInfo.id;
            let streamName: string = streamInfo.streamPath[this.props.pathIndex];

            if(id === this.props.currentStreamId){
                label=<Typography variant={"h6"} style={{color: this.props.theme.palette.secondary.main}}>{streamName}</Typography>;
            }
            else{
                label=<Typography variant={"h6"}>{streamName}</Typography>;
            }

            displayGroups.push(
                <TreeItem nodeId={id} label={label} onClick={() => this.props.handleStreamClick(id)} key={id}/>
            )
        });

        return displayGroups;
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        this.formatStreamData();

        return(
            <div>
                {this.createStreamGroups()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        expandedNodes: state.streamState.expandedNodes
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setExpandedNodes: (nodeIds: Array<string>) => dispatch(setExpandedNodes(nodeIds))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTheme(StreamsDisplay))