import * as React from 'react';
import { connect } from 'react-redux';

type WktHighlightChangeListenerProps = {
    selectedWktId: string | null,
    setHighlight: (wktId: string | null) => void
}

class WktHighlightChangeListener extends React.Component<WktHighlightChangeListenerProps, any>{
    public componentDidMount(){
        this.props.setHighlight(this.props.selectedWktId);
    }

    public componentDidUpdate(prevProps: WktHighlightChangeListenerProps){
        this.props.setHighlight(this.props.selectedWktId);
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedWktId: state.mapState.selectedWktId
    }
};

export default connect(mapStateToProps, null, null, {forwardRef:true})(WktHighlightChangeListener)