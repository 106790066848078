import * as React from 'react';
import { connect } from 'react-redux';
import { LatLngBounds } from 'leaflet';

type ListenerProps = {
    mapBounds: LatLngBounds;
    fitBounds: any
}

class MapBoundsChangeListener extends React.Component <ListenerProps, any>{
    public componentDidUpdate(){
        this.props.fitBounds(this.props.mapBounds);
    }
    
    public render(){
        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        mapBounds: state.mapState.mapBounds
	}
};

export default connect(mapStateToProps, null, null, {forwardRef:true})(MapBoundsChangeListener)