import * as React from 'react';
import {GeometryUtils} from "../../utils/GeometryUtils";
import Shape from "./Shape";

type ShapeWrapperProps = {
    geometry: string,
    onClick: any
}

type ShapeWrapperState = {
    highlighted: boolean
}

class ShapeWrapper extends React.Component<ShapeWrapperProps, ShapeWrapperState>{
    public constructor(props){
        super(props);

        this.state = {
            highlighted: false
        }
    }

    public setHighlight(highlighted: boolean){
        if(this.state.highlighted !== highlighted)
            this.setState({
                highlighted: highlighted
            })
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let color: string = this.state.highlighted ? GeometryUtils.HighlightedPrimitiveColor : GeometryUtils.WktColor;

        return <Shape geometry={this.props.geometry} onClick={this.props.onClick} color={color} />
    }
}

export {ShapeWrapper}