import * as React from 'react';
import {Box} from "@material-ui/core";

class TabContent extends React.Component<any, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            this.props.value === this.props.selected &&
            <Box m={1}>
                {this.props.children}
            </Box>
        );
    }
}

export {TabContent}