import * as React from 'react'
import EntityComponent from "./EntityComponent";
import {RequestUtils} from "../../utils/RequestUtils";
import {Entity} from "../../model/Entity";
import { connect } from 'react-redux';
import { MapEntity } from '../../model/MapEntity';
import { EntityExtension } from '../../model/EntityExtension';
import { ModelUtils } from '../../utils/ModelUtils';
import { LinkedHashMap } from '../../utils/LinkedHashMap';
import { setSearchProgress } from '../../store/actions/searchEntities';
import HighlightChangeListener from '../listeners/HighlightChangeListener';
import KeepOnMapChangeListener from '../listeners/KeepOnMapChangeListener';
import ShowMarkersChangeListener from '../listeners/ShowMarkersChangeListener';
import EditingChangeListener from '../listeners/EditingChangeListener';
import EntityToUpdateListener from '../listeners/EntityToUpdateListener';
import { Primitive } from '../../model/Primitive';
import { GeometryUtils } from '../../utils/GeometryUtils';
import {Guid} from "guid-typescript";
import GlobalCallbacks from '../../utils/GlobalCallbacks';
import {registerLoadedEntity} from "../../store/actions/changeLinkedHashMap";
import {Direction} from "../../entityInformation/entityDetails/primitives/Direction";
 
type EntitiesGroupProps = {
    basicEntities: any,
    setSearchProgress: any,
    multipleEntityView: boolean,
    centerMap: any,
    fitBounds: any,
    registerLoadedEntity: (id: string, item: any, reduced: boolean, enforce: boolean) => void
}

class EntitiesGroup extends React.Component<EntitiesGroupProps, any>{
    private entityMap: any = {};
    private shouldFetch: boolean = false;
    private previousHighlighted: string | null = null;
    private highlightedEntity: string | null = null;
    private isEdit: boolean = false;
    private editableEntity: string | null = null;
    private highlightedPrimitive: string | null = null;
    private showMarkers: boolean = true;
    private shouldHighlight: boolean = false;
    private shouldCenter: boolean = false;
    private entitiesKeptOnMap: Set<string> = new Set();

    public constructor(props){
        super(props);

        this.setHighlight = this.setHighlight.bind(this);
        this.setKeptEntities = this.setKeptEntities.bind(this);
    }

    public componentDidMount(): void {
        GlobalCallbacks.setMoveLayer(this.moveLayer);

        if(Object.keys(this.props.basicEntities).length > 0) {
            this.fetchEntityInformation();
        }
    }

    public componentWillMount(): void {
        GlobalCallbacks.resetMoveLayer();
    }

    public moveLayer = (entityId: string, primitiveId: string, direction: Direction) => {
        this.entityMap[entityId].moveLayer(primitiveId, direction);
    };

    private centerMap(highlightedEntity: EntityExtension, highlightedPrimitive: string | null){
        let primitive: Primitive | null = null;

        if(highlightedPrimitive !== null){
            for(let i = 0; i < highlightedEntity.primitives.length; i++){
                if(highlightedEntity.primitives[i].PrimitiveID === highlightedPrimitive){
                    primitive = highlightedEntity.primitives[i];
                    break;
                }
            }

            if(primitive !== null/* && primitive.PrimitiveTypeKeyName !== 'Point'*/){
                this.props.fitBounds(GeometryUtils.getBoundsFromWkt(primitive.ShapeWKT));
            }
            /*else{
                this.props.centerMap(highlightedEntity.lat, highlightedEntity.long, highlightedEntity.zoom);
            }*/
        }
        else
            this.props.centerMap(highlightedEntity.lat, highlightedEntity.long, highlightedEntity.zoom);
    }

    public setKeptEntities(entitiesKeptOnMap: Set<string>){
        this.entitiesKeptOnMap = entitiesKeptOnMap;
        this.setState({});
    }

    public setHighlight(highlightedEntity: string | null, highlightedPrimitive: string | null){
        if(this.highlightedEntity !== highlightedEntity || this.highlightedPrimitive !== highlightedPrimitive){
            this.shouldCenter = true;
        }

        if(!this.props.multipleEntityView && this.highlightedEntity !== highlightedEntity && highlightedEntity !== null){
            this.highlightedEntity = highlightedEntity;
            this.highlightedPrimitive = highlightedPrimitive;

            if(this.previousHighlighted !== null && this.previousHighlighted !== highlightedEntity && this.entityMap[this.previousHighlighted] !== undefined && this.entityMap[this.previousHighlighted] !== null){
                this.entityMap[this.previousHighlighted].resetHighlight(highlightedPrimitive);
            }

            this.shouldHighlight = true;
            this.previousHighlighted = highlightedEntity;
            this.setState({});
        }
        else{
            this.highlightedEntity = highlightedEntity;
            this.highlightedPrimitive = highlightedPrimitive;

            if(this.previousHighlighted !== null && this.previousHighlighted !== highlightedEntity && this.entityMap[this.previousHighlighted] !== undefined && this.entityMap[this.previousHighlighted] !== null){
                this.entityMap[this.previousHighlighted].resetHighlight(highlightedPrimitive);
            }

            if(highlightedEntity != null){
                if(this.entityMap[highlightedEntity] !== undefined && this.entityMap[highlightedEntity] !== null){
                    if(!this.entityMap[highlightedEntity].isExtensionSet()){
                        let mapEntity: MapEntity = LinkedHashMap.getItem(highlightedEntity);
                        this.entityMap[highlightedEntity].setEntity(mapEntity.entityExtension);
                    }
                    this.entityMap[highlightedEntity].setHighlight(highlightedPrimitive, this.isEdit);
                    if(this.shouldCenter){
                        this.centerMap(LinkedHashMap.getItem(highlightedEntity).entityExtension, highlightedPrimitive);
                        this.shouldCenter = false;
                    }
                }
                else{
                    this.shouldHighlight = true;
                }
                
                this.previousHighlighted = highlightedEntity;
            }
        }
    }

    public setEditingState = (isEdit: boolean) => {
        this.isEdit = isEdit;

        if(this.previousHighlighted && this.entityMap[this.previousHighlighted]){
            if(this.isEdit){
                this.entityMap[this.previousHighlighted].setEdit(this.highlightedPrimitive);
                this.editableEntity = this.previousHighlighted;
            }
            else{
                this.entityMap[this.previousHighlighted].setEdit(null);
                this.editableEntity = null;
            }
        }
    };

    public setShowMarkers = (flag: boolean) => {
        this.showMarkers = flag;

        Object.keys(this.entityMap).forEach(key => {
            if(this.entityMap[key] !== null)
                this.entityMap[key].showMarkers(flag);
        });
    };

    private updateMarkerNumbers(){
        let index: number = 1;

        Object.keys(this.props.basicEntities).forEach(key => {
            if(this.entityMap[key])
                this.entityMap[key].updateNumber(index);

            index++;
        });

        index = 1;

        this.entitiesKeptOnMap.forEach((key) => {
            if(this.entityMap[key] !== null && this.entityMap[key] !== undefined)
                    this.entityMap[key].updateNumber(index, true);

                index++;
        });

        if(this.isEdit && this.highlightedEntity && this.entityMap[this.highlightedEntity]){
                    this.entityMap[this.highlightedEntity].updateNumber(index, false, true);
        }
    }

    private updateEntity = (entityId: string) => {
        console.log(entityId);

        let entity: MapEntity = LinkedHashMap.getItem(entityId);
        console.log(entity);
        this.entityMap[entityId].setEntity(entity.entityExtension, true);
    };

    public componentDidUpdate(): void {
        this.updateMarkerNumbers();

        if(this.shouldFetch){
            this.props.setSearchProgress(0, Object.keys(this.props.basicEntities).length > 99 ? 99 : Object.keys(this.props.basicEntities).length);
            this.fetchEntityInformation();
        }

        if(this.highlightedEntity && this.entityMap[this.highlightedEntity]){
            if(!this.entityMap[this.highlightedEntity].isExtensionSet()){
                let mapEntity: MapEntity = LinkedHashMap.getItem(this.highlightedEntity);
                this.entityMap[this.highlightedEntity].setEntity(mapEntity.entityExtension);
            }

            this.entityMap[this.highlightedEntity].setHighlight(this.highlightedPrimitive, this.isEdit);
        }

        if(this.shouldHighlight && this.highlightedEntity !== null){
            if(this.shouldCenter){
                this.centerMap(LinkedHashMap.getItem(this.highlightedEntity).entityExtension, this.highlightedPrimitive);
                this.shouldCenter = false;
            }

            this.shouldHighlight = false;
        }
    }

    private fetchEntityInformation(){
        RequestUtils.sendRequestWithConfigString("/api/entity/continueDownload/post/", 'POST')
            .then(result => result.json())
            .then(data => {
                let cntBasicEntities = Object.keys(this.props.basicEntities).length;

                if(data && data.Entities){
                    this.passToEntities(data.Entities);

                    if(data.MoreLeft < 0){
                        //ispisi poruku o gresci
                        this.props.setSearchProgress(0, 0);
                    }
                    else{
                        this.props.setSearchProgress(cntBasicEntities - data.MoreLeft, cntBasicEntities);
                        if(data.MoreLeft > 0)
                            this.fetchEntityInformation();
                    }
                }
                else{
                    GlobalCallbacks.displaySnackbarMessage("Data fetching stopped!");
                    this.props.setSearchProgress(0, 0);
                }
            })
    }

    private passToEntities(entities: Array<Entity>){
        entities.map((entity) => {
            if(entity !== null){
                let id: string = entity.EntityID;
                let entry: any = this.entityMap[id];

                let mapEntity: MapEntity = ModelUtils.mapEntityFromEntity(entity, 1);
                this.props.registerLoadedEntity(id, mapEntity, false, false);
                
                if(entry !== undefined && entry !== null){
                    entry.setEntity(mapEntity.entityExtension);
                }
            }

            return null;
        })
    }

    private createEntities(basicEntities) {
        let entities: JSX.Element[] = [];

        if(!this.props.multipleEntityView && this.highlightedEntity !== null){
            let key: any;
            if(Object.keys(this.props.basicEntities).length === 1)
                key = Object.keys(this.props.basicEntities)[0];
            else
                key = this.highlightedEntity;

            let entityExtension: EntityExtension | null;
            let componentKey: string = LinkedHashMap.containsWholeItem(key) ? key : Guid.create().toString();
            if(LinkedHashMap.getItem(key) !== undefined)
                entityExtension = LinkedHashMap.getItem(key).entityExtension;
            else{
                entityExtension = null;
                this.shouldFetch = true;
            }

            let mapEntity: MapEntity = {
                basicEntity: basicEntities[key],
                entityExtension: entityExtension
            };

            entities.push(<EntityComponent 
                            key={componentKey}
                            ref={e => {this.entityMap[key] = e}}
                            mapEntity={mapEntity}    
                            showMarker={this.showMarkers}        
                        />);

            this.entitiesKeptOnMap.forEach((entityId) => {
                if(entityId !== key){
                    let mapEntity: MapEntity;
                    componentKey = LinkedHashMap.containsWholeItem(entityId) ? entityId : Guid.create().toString();
                    mapEntity = LinkedHashMap.getItem(entityId);
                    if(mapEntity)
                        entities.push(<EntityComponent
                                    key={componentKey}
                                    ref={e => {this.entityMap[entityId] = e}}
                                    mapEntity={mapEntity}
                                    showMarker={this.showMarkers}
                         />)
                }
            });

            if(this.editableEntity !== null && this.editableEntity !== key && !this.entitiesKeptOnMap.has(this.editableEntity)){
                let id = this.editableEntity;
                let mapEntity: MapEntity;
                componentKey = LinkedHashMap.containsWholeItem(id) ? id : Guid.create().toString();
                mapEntity = LinkedHashMap.getItem(id);
                if(mapEntity)
                    entities.push(<EntityComponent
                        key={componentKey}
                        ref={e => {this.entityMap[id] = e}}
                        mapEntity={mapEntity}
                        showMarker={this.showMarkers}
                    />)
            }
        }
        else{
            Object.keys(basicEntities).forEach(key =>{
                let entityExtension: EntityExtension | null;
                let componentKey: string;

                if(LinkedHashMap.contains(key)){
                    entityExtension = LinkedHashMap.getItem(key).entityExtension;
                    if(LinkedHashMap.containsWholeItem(key))
                        componentKey = key;
                    else
                        componentKey = Guid.create().toString();
                }
                else{
                    entityExtension = null;
                    this.shouldFetch = true;
                    componentKey = key;
                }

                let mapEntity: MapEntity = {
                    basicEntity: basicEntities[key],
                    entityExtension: entityExtension
                };

                entities.push(<EntityComponent
                    key={componentKey}
                    ref={e => {this.entityMap[key] = e}}
                    mapEntity={mapEntity}
                    showMarker={this.showMarkers}
                />)
            });

            this.entitiesKeptOnMap.forEach((entityId) => {
                if(!this.props.basicEntities.hasOwnProperty(entityId)){
                    let mapEntity: MapEntity;
                    let componentKey = LinkedHashMap.containsWholeItem(entityId) ? entityId : Guid.create().toString();
                    mapEntity = LinkedHashMap.getItem(entityId);
                    if(mapEntity)
                        entities.push(<EntityComponent
                                    key={componentKey}
                                    ref={e => {this.entityMap[entityId] = e}}
                                    mapEntity={mapEntity}
                                    showMarker={this.showMarkers}
                        />)
                }
            });

            if(this.editableEntity !== null && !this.props.basicEntities.hasOwnProperty(this.editableEntity) && !this.entitiesKeptOnMap.has(this.editableEntity)){
                let id = this.editableEntity;
                let mapEntity: MapEntity;
                let componentKey = LinkedHashMap.containsWholeItem(id) ? id : Guid.create().toString();
                mapEntity = LinkedHashMap.getItem(id);
                if(mapEntity)
                    entities.push(<EntityComponent
                        key={componentKey}
                        ref={e => {this.entityMap[id] = e}}
                        mapEntity={mapEntity}
                        showMarker={this.showMarkers}
                    />)
            }
        }

        return entities;
    }

    public render(): JSX.Element{
        this.entityMap = {};
        this.shouldFetch = false;

        let basicEntities = this.props.basicEntities;
        let length: number = Object.keys(basicEntities).length;

        return(
            <div>
                {
                    (length > 0 || this.entitiesKeptOnMap.size) && this.createEntities(basicEntities)
                }
                <KeepOnMapChangeListener setKeptEntities={this.setKeptEntities} />
                <HighlightChangeListener setHighlight={this.setHighlight}/>
                <ShowMarkersChangeListener setShowMarkers={this.setShowMarkers} />
                <EditingChangeListener setEditingState={this.setEditingState}/>
                <EntityToUpdateListener updateEntity={this.updateEntity}/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchProgress: (loaded: number, total: number) => dispatch(setSearchProgress(loaded, total)),
        registerLoadedEntity: (id: string, item: any, reduced: boolean, enforce: boolean) => dispatch(registerLoadedEntity(id, item, reduced, enforce))
}
};

const mapStateToProps = (state) => {
	return {
        basicEntities: state.searchResult.basicEntities,
        multipleEntityView: state.searchResult.multipleEntityView
	}
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef:true})(EntitiesGroup)