import {MapState} from "./MapState";
import {Primitive} from "../model/Primitive";
import {ArrayUtils} from "../utils/ArrayUtils";

export class MapStateWrapper{
    public static isPrimitiveHighlightPossible(mapState: MapState){
        return mapState === MapState.Visualizing || mapState === MapState.Editing;
    }

    public static isEntityHighlightPossible(mapState: MapState){
        return mapState === MapState.Visualizing;
    }

    public static shouldHandlePrimitiveClick(mapState: MapState, highlightedEntity: string | null, newHighlightedEntity: string, highlightedPrimitive: string | null, newHighlightedPrimitive: string){
        return (mapState === MapState.Visualizing || (mapState === MapState.Editing && highlightedEntity === newHighlightedEntity)) && !this.isInfoUnchanged(highlightedEntity, newHighlightedEntity, highlightedPrimitive, newHighlightedPrimitive);
    }

    public static isEditModeChangePossible(mapState: MapState){
        return mapState === MapState.Visualizing || mapState === MapState.Editing;
    }

    public static isEntityCreationPossible(mapState: MapState){
        return mapState === MapState.Visualizing;
    }

    public static isPrimitiveCreationPossible(mapState: MapState, creatingPrimitiveId: string | null, creatingPrimitivesList: Array<Primitive>){
        return mapState === MapState.Editing || (mapState === MapState.Creating && (creatingPrimitiveId === null || this.primitiveExistsInList(creatingPrimitiveId, creatingPrimitivesList)));
    }

    public static isSplittingState(mapState: MapState){
        return mapState === MapState.Splitting;
    }

    public static isMergingState(mapState: MapState){
        return mapState === MapState.Merging;
    }

    public static isReplacingState(mapState: MapState){
        return mapState === MapState.Replacing;
    }

    public static isBinaryEditState(mapState: MapState){
        return this.isMergingState(mapState) || this.isReplacingState(mapState);
    }

    public static isEditingState(mapState: MapState){
        return mapState === MapState.Editing;
    }

    public static shouldResetHighlightedPrimitive(mapState: MapState){
        return mapState === MapState.Editing;
    }

    private static primitiveExistsInList(creatingPrimitiveId: string | null, creatingPrimitivesList: Array<Primitive>){
        return creatingPrimitiveId && ArrayUtils.any(creatingPrimitivesList, (primitive) => primitive.PrimitiveID === creatingPrimitiveId);
    }

    private static isInfoUnchanged(highlightedEntity: string | null, newHighlightedEntity: string, highlightedPrimitive: string | null, newHighlightedPrimitive: string){
        return highlightedEntity === newHighlightedEntity && highlightedPrimitive === newHighlightedPrimitive;
    }
}