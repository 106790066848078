import * as L from 'leaflet'
import mapIcon from "./images/map-marker-icon.png";

class IconUtils{
    private static images = [];

    static DefaultMarkerIcon : L.Icon = new L.Icon({
        iconUrl: mapIcon,
        iconSize: [40, 40],
        iconAnchor: [20,40]
    });

    static initialize(){
        let r = require.context('./images', false, /.*/);
        r.keys().forEach((item) => { this.images[item.replace('./', '')] = r(item); });
    }
}

IconUtils.initialize();

export {IconUtils}