import * as React from 'react';
import {Property} from "../../../model/Property";
import {PropertiesTable} from "./PropertiesTable";
import {Button} from "@material-ui/core";

type PropertiesViewProps = {
    properties: Array<Property>,
    goBackCallback: any
}

type PropertiesViewState = {
    propertiesStack: Array<Array<Property>>
}

class PropertiesView extends React.Component<PropertiesViewProps, PropertiesViewState>{
    public constructor(props) {
        super(props);

        this.state = {
            propertiesStack: [this.props.properties]
        }
    }

    static defaultProps = {
        goBackCallback: null
    };

    public componentDidUpdate(prevProps: PropertiesViewProps){
        if(!this.compareProps(prevProps, this.props)){
            this.setState({
                propertiesStack: [this.props.properties]
            })
        }
    }

    private compareProps(lhs: PropertiesViewProps, rhs: PropertiesViewProps){
        if(lhs.properties === null || rhs.properties === null)
            return rhs.properties === lhs.properties;

        return JSON.stringify(lhs.properties.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.properties.map(p => JSON.stringify(p)).sort());
    }

    public setNewView = (properties: Array<Property>) => {

        let newProperties: Array<Array<Property>> = [];
        newProperties.push(properties);
        this.state.propertiesStack.forEach(property =>
            newProperties.push(property)
        );

        this.setState({
            propertiesStack: newProperties
        })
    };

    private onClick = () => {
        if(this.state.propertiesStack.length <= 1 && this.props.goBackCallback !== null){
            this.props.goBackCallback(null);
        }
        else{
            let newProperties: Array<Array<Property>>;
            newProperties = this.state.propertiesStack.slice();
            newProperties.shift();

            this.setState({
                propertiesStack: newProperties
            })
        }
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let propertiesStack: Array<Array<Property>> = this.state.propertiesStack;

        let length: number = propertiesStack[0] ? propertiesStack[0].length : 0;

        return(<div>
                {
                    (propertiesStack.length > 1 || this.props.goBackCallback !== null) &&
                    <Button disabled={propertiesStack.length <= 1 && this.props.goBackCallback === null} onClick={this.onClick} fullWidth={true} variant="outlined" size={"small"}>Go back</Button>
                }
                {
                    length > 0 &&
                    <div>
                        <PropertiesTable properties={propertiesStack[0]} setNewView={this.setNewView}/>
                    </div>
                }
            </div>
        )
    }
}

export {PropertiesView}