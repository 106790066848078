import * as React from 'react';
import {Property} from "../../../model/Property";
import {PropertyPath} from "./PropertyValueDisplayEdit";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import MaterialTable from "material-table";
import ValueForm from "./ValueForm";
import {ModelUtils} from "../../../utils/ModelUtils";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {MaterialTableUtils} from "../../../utils/MaterialTableUtils";

type ValuesFormProps = {
    propertyPath: PropertyPath,
    onDelete: (propertyPath: Array<{key: string | null, index: number | null}>) => void,
    onSave: (propertyPath: Array<{key: string | null, index: number | null}>, value: string) => void,
    onClose: any
}

class ValuesForm extends React.Component<ValuesFormProps, any>{
    private valueCount: number = 0;

    private static onChange(props, newValue){
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.value = newValue;
        props.onRowDataChange(rowData);
    }

    public closeDialog = () => {
        this.props.onClose();
    };

    private onRowAdd = (newData) => {
        return new Promise((resolve, reject) => {
            console.log(newData);
            if("value" in newData && newData["value"] !== ""){
                let valuePath: Array<{key: string | null, index: number | null}> = this.props.propertyPath.path.slice();
                let value: string = newData["value"];
                valuePath.push({key: null, index: this.valueCount});
                this.props.onSave(valuePath, value);
                resolve();
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("Set value!");
                reject();
            }
        })
    };

    private onRowUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            console.log(newData);
            console.log(oldData);
            if (newData["value"] !== "") {
                let valuePath: Array<{ key: string | null, index: number | null }> = newData["path"];
                let value: string = newData["value"];
                this.props.onSave(valuePath, value);
                resolve();
            } else {
                GlobalCallbacks.displaySnackbarMessage("Set value!");
                reject();
            }
        })
    };

    private onRowDelete = (oldData) => {
        return new Promise((resolve) => {
            console.log(oldData);
            let valuePath: Array<{ key: string | null, index: number | null }> = oldData["path"];
            this.props.onDelete(valuePath);
            resolve();
        })
    };

    private getColumns(){
        let columns: Array<{}> = [];
        columns.push({title: "Value", field: "value",
            editComponent: props => (<ValueForm value={props.rowData.value} keyName={this.props.propertyPath.property.PropertyKeyName} onChange={(newValue) => ValuesForm.onChange(props, newValue)}/>)});
        return columns;
    }

    private getData(){
        let data: Array<{}> = [];

        let parentProperty: Property = this.props.propertyPath.property;
        if(parentProperty.Value) {
            let path: any = this.props.propertyPath.path.slice();
            path.push({key: null, index: 0});

            data.push({value: parentProperty.Value, path: path});
            this.valueCount = 1;
        }
        else if(parentProperty.Values) {
            parentProperty.Values.forEach((property: Property, index: number) => {
                let path: any = this.props.propertyPath.path.slice();
                path.push({key: null, index: index});

                data.push({value: property.Value, path: path})
            });

            this.valueCount = parentProperty.Values.length;
        }

        return data;
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let columns: Array<{}> = this.getColumns();
        let data: Array<{}> = this.getData();

        let parentProperty: Property = this.props.propertyPath.property;
        return(
            <div>
                <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogContent>
                        <MaterialTable
                            options={MaterialTableUtils.tableOptions}
                            title={parentProperty.PropertyKeyName ? parentProperty.PropertyKeyName : ""}
                            columns={columns}
                            data={data}
                            editable={{
                                onRowAdd: this.onRowAdd,
                                onRowUpdate: this.onRowUpdate,
                                onRowDelete: this.onRowDelete
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} variant="outlined" color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default ValuesForm