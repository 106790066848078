import {RequestUtils} from "./RequestUtils";
import {EntityResponse} from '../model/EntityResponse';
import {EntityName} from "../model/EntityName";
import GlobalCallbacks from "./GlobalCallbacks";
import {Primitive} from "../model/Primitive";
import {ArrayUtils} from "./ArrayUtils";
import {Entity} from "../model/Entity";
import {createRelate, Relate} from "../model/Relate";
import {PrimitiveRelateEdit} from "../model/request/edit/PrimitiveRelateEdit";
import {PrimitiveSplitEdit} from "../model/request/edit/PrimitiveSplitEdit";
import {PrimitiveBinaryEdit} from "../model/request/edit/PrimitiveBinaryEdit";

export class EditServerProxy{
    private static editingEntityId: string | null = null;
    private static editingPrimitiveId: string | null = null;
    private static highlightedEntityId: string | null = null;
    private static highlightedPrimitiveId: string | null = null;
    private static editingWkt: string = "";
    private static isEdit: boolean;

    public static setEditingState(isEdit: boolean){
        if(isEdit){
            this.editingEntityId = this.highlightedEntityId;
            this.editingPrimitiveId = this.highlightedPrimitiveId;
        }
        else{
            this.editingEntityId = null;
            this.editingPrimitiveId = null;
        }
        
        this.isEdit = isEdit;
    }

    public static setHighlightedEntityAndPrimitiveId(entityId: string | null, primitiveId: string | null){
        EditServerProxy.highlightedEntityId = entityId;
        EditServerProxy.highlightedPrimitiveId = primitiveId;

        if(this.isEdit){
            EditServerProxy.editingEntityId = entityId;
            EditServerProxy.editingPrimitiveId = primitiveId;
        }

    }

    public static setEditingWkt(wkt: string){
        console.log(wkt);
        EditServerProxy.editingWkt = wkt;
    }

    public static getEditingWkt(){
        return EditServerProxy.editingWkt;
    }

    private static isResponseValid(data): boolean{
        return data && data.SingleEntity;
    }

    public static changeType(newType: string): Promise<any> | null{
        if(EditServerProxy.editingEntityId !== null){
            let data = {"EntityID": EditServerProxy.editingEntityId, "Type": newType};
            return RequestUtils.sendRequestWithConfigString("/api/entity/editType/post/", 'POST', data);
        }

        return null;
    }

    public static changeName(name: EntityName, shouldDelete: string){
        if(EditServerProxy.editingEntityId !== null){
            let id: string = EditServerProxy.editingEntityId;
            let data = {"Culture": name.CultureEnglishName, "Name": name.FriendlyName, "EntityId": id, "NameId": name.NameID, "Role": name.NameRoleKeyName, "Type": name.NameTypeKeyName, "Deleting": shouldDelete};
            return RequestUtils.sendRequestWithConfigString("/api/entity/savechangedname/post/", 'POST', data);
        }

        return null;
    }

    public static deleteRelationship(id: string, type: string){
        if(EditServerProxy.editingEntityId !== null){
            let entityId: string = EditServerProxy.editingEntityId;
            let data = {"EntityID": entityId, "ConnectingID": id, "Type": type};
            return RequestUtils.sendRequestWithConfigString("/api/entity/deleteRelate/post/", 'POST', data);
        }

        return null;
    }

    public static addRelationship(id: string, type: string){
        if(EditServerProxy.editingEntityId !== null){
            let entityId: string = EditServerProxy.editingEntityId;
            let data = {"EntityID": entityId, "ConnectingID": id, "RelationshipType": type, "TypeOfRelate": "parent"};
            return RequestUtils.sendRequestWithConfigString("/api/entity/addRelate/post/", 'POST', data);
        }

        return null;
    }

    public static changePrimitiveRelationship(id: string, type: string, shouldDelete: boolean){
        if(EditServerProxy.editingEntityId !== null && EditServerProxy.editingPrimitiveId !== null){
            let newRelationship: Relate = createRelate(type, EditServerProxy.editingPrimitiveId, id);
            let data: PrimitiveRelateEdit = {EntityId: EditServerProxy.editingEntityId, PrimitiveId: EditServerProxy.editingPrimitiveId , Delete: shouldDelete, Relationship: newRelationship};
            console.log(data);
            return RequestUtils.sendRequestWithConfigString("/api/entity/saveChangedPrimitiveRelationship/post/", 'POST', data);
        }

        return null;
    }

    public static deletePrimitiveRelationship(relate: Relate){
        if(EditServerProxy.editingEntityId !== null && EditServerProxy.editingPrimitiveId !== null){
            let data: PrimitiveRelateEdit = {EntityId: EditServerProxy.editingEntityId, PrimitiveId: EditServerProxy.editingPrimitiveId , Delete: true, Relationship: relate};
            console.log(data);
            return RequestUtils.sendRequestWithConfigString("/api/entity/saveChangedPrimitiveRelationship/post/", 'POST', data);
        }

        return null;
    }

    public static deleteChild(id: string, type: string){
        if(EditServerProxy.editingEntityId !== null){
            let entityId: string = EditServerProxy.editingEntityId;
            let data = {"EntityID": entityId, "Child": id + ";" + type};
            return RequestUtils.sendRequestWithConfigString("/api/entity/droprelate/post/", 'POST', data);
        }

        return null;
    }

    public static splitPrimitive(lat: number, long: number){
        if(!EditServerProxy.editingEntityId || !EditServerProxy.editingPrimitiveId){
            return null;
        }

        let entityId: string = EditServerProxy.editingEntityId;
        let primitiveId: string = EditServerProxy.editingPrimitiveId;
        let data: PrimitiveSplitEdit = {EntityID: entityId, PrimitiveID: primitiveId, DividingPoint: "POINT ("+ long + " " + lat + ")"};
        return RequestUtils.sendRequestWithConfigString("/api/entity/dividePrimitive/post/", 'POST', data);
    }

    public static dropPrimitive = () => {
        if(EditServerProxy.editingEntityId !== null && EditServerProxy.editingPrimitiveId !== null){
            let data = {"EntityID": EditServerProxy.editingEntityId, "PrimitivID": EditServerProxy.editingPrimitiveId, "Deleting": true};
            let response = RequestUtils.sendRequestWithConfigString("/api/entity/donePrimitive/post/", 'POST', data);

            console.log(data);
            response.then(result => result.clone().json())
                    .then(
                        (data: EntityResponse) => {
                            console.log(data);
                            if(data && data.SingleEntity && data.SingleEntity.Primitives.filter((primitive: Primitive) => primitive.PrimitiveID === EditServerProxy.editingPrimitiveId).length === 0)
                                GlobalCallbacks.displaySnackbarMessage("Primitive deleted");
                            else{
                                GlobalCallbacks.displaySnackbarMessage("Deletion failed, try again!");
                            }
                    });

            return response;
        }

        GlobalCallbacks.displaySnackbarMessage("Entities and primitives are not selected properly!");
        return null;
    };

    public static savePrimitive = () => {
        if(EditServerProxy.editingEntityId !== null && EditServerProxy.editingPrimitiveId !== null && EditServerProxy.editingWkt !== null){
            let data = {"PrimitiveId": EditServerProxy.editingPrimitiveId, "EntityId": EditServerProxy.editingEntityId, "ShapeWKT": EditServerProxy.editingWkt, "RingShapes": ""};
            console.log(data);
            let response = RequestUtils.sendRequestWithConfigString("/api/entity/savechangedprim/post/", 'POST', data);

            console.log(data);
            response.then(result => result.clone().json())
                    .then(
                        (data: EntityResponse) => {
                            console.log(data);
                            GlobalCallbacks.displaySnackbarMessage("Primitive saved");
                    });

            return response;
        }

        GlobalCallbacks.displaySnackbarMessage("Entities and primitives are not selected properly!");
        return null;
    };

    public static mergePrimitives = (chosenEntity: string, chosenPrimitive: string) => {
        if(!EditServerProxy.editingEntityId || !EditServerProxy.editingPrimitiveId)
            return null;

        let data: PrimitiveBinaryEdit = {IdentifierKeyDeleting: chosenEntity, IdentifierKeyEditing: EditServerProxy.editingEntityId, PrimitiveIdDeleting: chosenPrimitive, PrimitiveIdEditing: EditServerProxy.editingPrimitiveId};
        console.log(data);
        return RequestUtils.sendRequestWithConfigString("/api/entity/MergePrimitivesWKT/post/", 'POST', data);
    };

    public static snapPrimitives = () => {
        if(EditServerProxy.editingEntityId !== null && EditServerProxy.editingPrimitiveId !== null && EditServerProxy.editingWkt !== null && EditServerProxy.highlightedEntityId !== null && EditServerProxy.highlightedPrimitiveId !== null && EditServerProxy.editingPrimitiveId !== EditServerProxy.highlightedPrimitiveId){
            let data = {"IdentifierKeyDeleting": EditServerProxy.highlightedEntityId, "IdentifierKeyEditiong": EditServerProxy.editingEntityId, "PrimitiveIdDeleting": EditServerProxy.highlightedPrimitiveId, "PrimitiveIdEditing": EditServerProxy.editingPrimitiveId, "ShapeWKT": EditServerProxy.editingWkt};
            let response = RequestUtils.sendRequestWithConfigString("/api/entity/savechangedprimsnap/post/", 'POST', data);

            console.log(data);
            response.then(result => result.clone().json())
                    .then(
                        (data: EntityResponse) => {
                            console.log(data);
                            GlobalCallbacks.displaySnackbarMessage("Primitives snapped");
                    });

            return response;
        }

        GlobalCallbacks.displaySnackbarMessage("Entities and primitives are not selected properly!");
        return null;
    };

    public static replacePrimitive = (chosenEntity: string, chosenPrimitive: string) => {
        if(!EditServerProxy.editingEntityId || !EditServerProxy.editingPrimitiveId)
            return null;

        let data: PrimitiveBinaryEdit = {IdentifierKeyDeleting: chosenEntity, IdentifierKeyEditing: EditServerProxy.editingEntityId, PrimitiveIdDeleting: chosenPrimitive, PrimitiveIdEditing: EditServerProxy.editingPrimitiveId};
        console.log(data);
        return RequestUtils.sendRequestWithConfigString("/api/entity/savechangedprimreplace/post/", 'POST', data);
    };

    public static addPrimitive = (primitive: Primitive) => {
        if(EditServerProxy.editingEntityId !== null) {
            let data = {"Primitive": primitive, "EntityID": EditServerProxy.editingEntityId};
            let response = RequestUtils.sendRequestWithConfigString("/api/entity/addPrimitive/post/", 'POST', data);

            console.log(data);
            response.then(result => result.clone().json())
                    .then(
                        (data: EntityResponse) => {
                            console.log(data);
                            if(ArrayUtils.any(data.SingleEntity.Primitives, (p: Primitive) => primitive.PrimitiveID === p.PrimitiveID))
                                GlobalCallbacks.displaySnackbarMessage("Primitive added");
                            else {
                                console.log("Primitive not added, try again!");
                                GlobalCallbacks.displaySnackbarMessage("Primitive not added, try again!");
                            }
                        });

            return response;
        }

        GlobalCallbacks.displaySnackbarMessage("Entity is not selected properly!");
        return null;
    };

    public static addEntity = (entity: Entity) => {
        let data = {"Entity": entity};
        let response = RequestUtils.sendRequestWithConfigString("/api/entity/addEntity/post/", 'POST', data);

        console.log(data);
        response.then(result => result.clone().json())
            .then(
                (data: EntityResponse) => {
                    console.log(data);
                    if(entity.EntityID === data.SingleEntity.EntityID)
                        GlobalCallbacks.displaySnackbarMessage("Entity added");
                    else {
                        console.log("Entity not added, try again!");
                        GlobalCallbacks.displaySnackbarMessage("Entity not added, try again!");
                    }
                });

        return response;
    };

    public static changeProperty = (idsList: Array<string | null>, propertyPath: Array<{key: string | null, index: number | null}>, shouldDelete: boolean, value: string | null) => {
        if(idsList[0] === EditServerProxy.editingEntityId){
            let key: string | null = propertyPath[0].key;
            propertyPath.shift();
            let propPath: Array<{key: string | null, index: number | null}> = propertyPath.slice();

            let data = {"EntityId": idsList[0], "ObjectId": idsList[1], "ObjectType": idsList[2], "RelationshipId": idsList[3], "Delete": shouldDelete, "Value": value, "Key": key ,"PropertyPath": propPath};
            let response = RequestUtils.sendRequestWithConfigString("/api/entity/savePropertyChanges/post/", 'POST', data);
            console.log(data);

            return response;
        }

        GlobalCallbacks.displaySnackbarMessage("Entity is not selected properly!");
        return null;
    }
}