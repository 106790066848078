import * as React from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {ANYWHERE, CURRENT_MAP_VIEW} from "./SearchBoundsTypes";

type SearchBoundsTypeProps = {
    searchBoundsType: string,
    handleChange: (event, value) => void
}

class SearchBoundsTypeForm extends React.Component<SearchBoundsTypeProps, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
                <FormControl>
                    <RadioGroup value={this.props.searchBoundsType} onChange={this.props.handleChange} row>
                        <FormControlLabel value={ANYWHERE} control={<Radio color="primary"/>} label="Anywhere" labelPlacement="end"/>
                        <FormControlLabel value={CURRENT_MAP_VIEW} control={<Radio color="primary"/>} label="Current map view" labelPlacement="end"/>
                    </RadioGroup>
                </FormControl>
            </div>
        )
    }
}

export default SearchBoundsTypeForm