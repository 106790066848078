import * as React from 'react';
import {RequestUtils} from '../utils/RequestUtils';
import GlobalCallbacks from "../utils/GlobalCallbacks";
import {Button, Card, CardContent, CardHeader, Grid, TextField} from "@material-ui/core";
import {connect} from 'react-redux';
import {CosmosUtils} from "../utils/CosmosUtils";

type StreamLoaderProps = {
    isPolaris: boolean,
    updateStreamList: any
}

type StreamLoaderState = {
    stream: string,
    feed: string,
    version: string
}

class StreamLoader extends React.Component<StreamLoaderProps, StreamLoaderState>{
    public constructor(props){
        super(props);

        this.state = {
            stream: "",
            feed: "",
            version: ""
        }
    }

    private updateState = () => {
        let stream = document.getElementById("streamInput") as HTMLInputElement;
        let feed = document.getElementById("feedInput") as HTMLInputElement;
        let version = document.getElementById("versionInput") as HTMLInputElement;

        this.setState({
            stream: stream.value,
            feed: feed.value,
            version: version.value
        })
    };

    private static getFeedName(feed: string){
        let feedPath: Array<string> = feed.split('/');
        return feedPath[feedPath.length - 1];
    }

    private static getFeedPath(feed: string): string | null{
        let feedPath: Array<string> = feed.split('/');
        feedPath.pop();

        if(feedPath.length > 0)
            return feedPath.join('/');
        else
            return null;
    }

    private createRequestData(){
        let ret = {
            "stream": CosmosUtils.getStreamPathForUpload(this.state.stream),
            "feed": StreamLoader.getFeedName(this.state.feed),
            "version": this.state.version,
            "shouldRender": "1",
            "originalPath": this.state.stream
        };

        let feedPath: string | null = StreamLoader.getFeedPath(this.state.feed);
        if(feedPath){
            ret["pipelineIdentifier"] = feedPath;
        }

        return ret;
    }

    private submitForm = (event) => {
        event.preventDefault();

        if(this.state.stream === "" || this.state.feed === "" || this.state.version === "")
            GlobalCallbacks.displaySnackbarMessage("All fields must be populated!");
        else {
            let data = this.createRequestData();
            console.log(data);

            let path: string;
            if(this.props.isPolaris)
                path = "/api/StreamImport/ImportStream/";
            else
                path = "/api/StreamBuild/AddNewStream/";

            RequestUtils.sendRequestToUploadStreamService(path, 'POST', data)
                .then(result => result.json())
                .then(
                    data => {
                        if(data){
                            let streamID = Number(data);
                            console.log(data);
                            if (isNaN(streamID))
                                GlobalCallbacks.displaySnackbarMessage(data);
                            else{
                                GlobalCallbacks.displaySnackbarMessage("Stream loaded!");
                                this.props.updateStreamList();
                            }
                        }
                        else{
                            GlobalCallbacks.displaySnackbarMessage("Stream upload failed!");
                        }
                    }
                );

            GlobalCallbacks.displaySnackbarMessage("Started upload");
        }
    };

    public render(): JSX.Element{
        return(
            <div>
                <Card>
                    <CardHeader title={"Upload stream"} />
                    <CardContent>
                        <form className="form" onSubmit={this.submitForm}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <TextField id="streamInput" type="text" placeholder="Stream e.g. https://aad.cosmos09.../JefEntities.ss" onChange={this.updateState} fullWidth={true}/>
                                </Grid>
                                <Grid item>
                                    <TextField id="feedInput" type="text" placeholder="Feed name e.g. Test" onChange={this.updateState} fullWidth={true}/>
                                </Grid>
                                <Grid item>
                                    <TextField id="versionInput" type="text" placeholder="Version e.g. 1" onChange={this.updateState} fullWidth={true}/>
                                </Grid>
                                <Grid item>
                                    <Button color="primary" variant="contained" id="uploadStreamButton" type="submit" fullWidth={true}>
                                        Upload
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        isPolaris: state.appState.isPolaris
    }
};
export default connect(mapStateToProps)(StreamLoader)