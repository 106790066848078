import * as React from 'react';
import {connect} from "react-redux";
import {MapState} from "../../mapState/MapState";
import {MapStateWrapper} from "../../mapState/MapStateWrapper";
import {Snackbar, Theme, withTheme} from "@material-ui/core";
import {EditServerProxy} from "../../utils/EditServerProxy";
import GlobalCallbacks from "../../utils/GlobalCallbacks";
import {EntityResponse} from "../../model/EntityResponse";
import {Entity} from "../../model/Entity";
import {ModelUtils} from "../../utils/ModelUtils";
import {RequestUtils} from "../../utils/RequestUtils";
import {setEntityToUpdate} from "../../store/actions/changeEditingState";
import {registerLoadedEntity} from "../../store/actions/changeLinkedHashMap";
import {resetChosen, setMapState} from "../../store/actions/searchEntities";
import {GetEntity} from "../../model/request/search/GetEntity";

type MergePrimitiveProps = {
    mapState: MapState,
    chosenPrimitive: string | null,
    chosenEntity: string | null,
    highlightedPrimitive: string | null,
    theme: Theme,
    setEntityToUpdate: (entityId: string | null) => void,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void,
    setMapState: (mapState: MapState) => void,
    resetChosen: () => void
}

class PrimitiveBinaryEditListener extends React.Component<MergePrimitiveProps, any>{
    public componentDidUpdate(prevProps: Readonly<MergePrimitiveProps>, prevState: Readonly<any>, snapshot?: any): void {
        if(this.props.chosenEntity && this.props.chosenPrimitive){
            if(this.props.chosenPrimitive === this.props.highlightedPrimitive){
                if(MapStateWrapper.isMergingState(this.props.mapState))
                    GlobalCallbacks.displaySnackbarMessage("Primitive can't be merged to itself");
                else
                    GlobalCallbacks.displaySnackbarMessage("Primitive can't replace itself");

                this.props.resetChosen();
                return;
            }

            let response: Promise<any> | null = MapStateWrapper.isMergingState(this.props.mapState) ?
                                                EditServerProxy.mergePrimitives(this.props.chosenEntity, this.props.chosenPrimitive) :
                                                EditServerProxy.replacePrimitive(this.props.chosenEntity, this.props.chosenPrimitive);

            let chosenEntity: string = this.props.chosenEntity;
            if(response){
                response.then(result => result.json())
                    .then((data: EntityResponse) => {
                        console.log(data);
                        if (data && data.SingleEntity) {
                            this.processResponse(data.SingleEntity, chosenEntity);
                        } else {
                            GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                        }
                    })
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("Primitive is not properly selected!");
            }

            this.props.resetChosen();
        }
    }

    private processResponse(entity: Entity, chosenEntity: string){
        this.props.registerLoadedEntity(entity.EntityID, ModelUtils.mapEntityFromEntity(entity, 1), false);
        this.props.setEntityToUpdate(entity.EntityID);

        let data: GetEntity = {IdentifierKey: chosenEntity, ShouldFetchingStop: "false"};
        RequestUtils.sendRequestWithConfigString("/api/entity/getent/post/", 'POST', data)
            .then(result => result.json())
            .then(
                data => {
                    this.props.registerLoadedEntity(chosenEntity, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                    this.props.setEntityToUpdate(chosenEntity);
                }
            );

        this.props.setMapState(MapState.Editing);
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
                <Snackbar
                    open={MapStateWrapper.isMergingState(this.props.mapState) || MapStateWrapper.isReplacingState(this.props.mapState)}
                    message={<span>Choose a primitive from the map</span>}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mapState: state.searchResult.mapState,
        chosenPrimitive: state.searchResult.chosenPrimitive,
        chosenEntity: state.searchResult.chosenEntity,
        highlightedPrimitive: state.searchResult.highlightedPrimitive
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEntityToUpdate: (entityId: string | null) => dispatch(setEntityToUpdate(entityId)),
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced)),
        setMapState: (mapState: MapState) => dispatch(setMapState(mapState)),
        resetChosen: () => dispatch(resetChosen())
    }
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef:true})(withTheme(PrimitiveBinaryEditListener))