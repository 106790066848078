import * as React from 'react';
import { EntityName } from '../../../model/EntityName';
import { DisplayTable } from '../../../commonComponents/DisplayTable';
import {Property} from "../../../model/Property";
import {PropertiesView} from "../properties/PropertiesView";
import {Link} from "@material-ui/core";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type EntityNamesProps = {
    names: Array<EntityName>
}

type EntityNamesState = {
    properties: Array<Property> | null
}

class EntityNames extends React.Component<EntityNamesProps, EntityNamesState>{
    public constructor(props){
        super(props);

        this.state = {
            properties: null
        }
    }

    public componentDidUpdate(prevProps: Readonly<EntityNamesProps>, prevState: Readonly<EntityNamesState>, snapshot?: any): void {
        if(!EntityNames.compareProps(prevProps, this.props)){
            this.setState({
                properties: null
            })
        }
    }

    private static compareProps(lhs: EntityNamesProps, rhs: EntityNamesProps){
        if(lhs.names === null || rhs.names === null)
            return rhs.names === lhs.names;

            return JSON.stringify(lhs.names.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.names.map(p => JSON.stringify(p)).sort());
    }

    public setPropertyView = (properties: Array<Property> | null) => {
        this.setState({
            properties: properties
        })
    };

    public render(): JSX.Element{
        let headerList: Array<string> = [];
        let contentList: Array<Array<any>> = [];
        
        if(this.props.names !== null){
            headerList.push("Name");
            headerList.push("Role");
            headerList.push("Locale");
            headerList.push("Properties");

            contentList = this.props.names.map((name) => {
                let row: Array<any> = [];
                row.push(name.NameID);
                row.push(name.FriendlyName);
                row.push(name.NameRoleKeyName);
                row.push(name.CultureEnglishName);
                if(name.Properties && name.Properties.length > 0)
                    row.push(<Link component={"button"} variant={"body2"} onClick={() => this.setPropertyView(name.Properties)}>Open</Link>);
                else
                    row.push("");

                return row;
            })
        }

        let length: number = this.props.names !== null ? this.props.names.length : 0;

        return(
            <div>
                <ExpansionComponent title={"Names (count: " + length + ")"} componentId={"nameList"}>
                    {
                        this.props.names !== null && length > 0 && !this.state.properties &&
                        <DisplayTable headerList={headerList} contentList={contentList}/>
                    }
                    {
                        this.state.properties &&
                        <PropertiesView properties={this.state.properties} goBackCallback={this.setPropertyView}/>
                    }
                </ExpansionComponent>
            </div>
        )
    }
}

export { EntityNames }