import * as React from 'react';
import {Guid} from "guid-typescript";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";

type DisplayTableProps = {
    headerList: Array<string>;
    contentList: Array<Array<any>>
}

class DisplayTable extends React.Component<DisplayTableProps, any>{
    public render(): JSX.Element {
        return(
            <div>
                <TableContainer>
                    <Table size={"small"} stickyHeader>
                        <TableHead>
                            <TableRow>
                                {this.props.headerList.map((header: string) => (
                                    <TableCell
                                        key={header}
                                    >
                                        <Typography>{header}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.props.contentList.map((row: Array<any>) => {
                                    return(
                                        <TableRow key={Guid.create().toString()}>
                                            {
                                                row.map((data: any, index: number) => {
                                                    if(index !== 0)
                                                        return(
                                                            <TableCell key={Guid.create().toString()}>
                                                                <Typography>{data}</Typography>
                                                            </TableCell>
                                                        );
                                                    return null;
                                                })
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        )
    }
}

export { DisplayTable };
