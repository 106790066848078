import * as React from 'react'
import * as L from "leaflet";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import { MarkerWrapper } from './MarkerWrapper';

const LeafletMarker = require('leaflet-extra-markers/dist/js/leaflet.extra-markers');

type NumberMarkerProps = {
    position: L.LatLngExpression,
    index: number,
    onClick: any,
    showMarker: boolean
}

type NumberMarkerState = {
    index: number,
    color: string,
    showMarker: boolean
}

class NumberMarker extends React.Component<NumberMarkerProps, NumberMarkerState>{
    public constructor(props){
        super(props);

        this.state = {
            index: this.props.index,
            color: 'red',
            showMarker: this.props.showMarker
        }
    }

    public updateNumber(index: number, isKept: boolean, isEditable: boolean){
        this.setState({
            index: index,
            color: isKept ? 'green' : (isEditable ? 'black' : 'red')
        });
    }

    public showMarker(flag: boolean){
        this.setState({
            showMarker: flag
        })
    }

    public render(): JSX.Element{
        let icon = LeafletMarker.ExtraMarkers.icon({
            icon: 'fa-number',
            markerColor: this.state.color,
            shape: 'circle',
            number: this.state.index
        });

        return(
            <div>
                {this.state.showMarker && <MarkerWrapper position={this.props.position} icon={icon} onClick={() => this.props.onClick()}/>}
            </div>
        )
    }
}

export {NumberMarker}