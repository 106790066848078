 import {SolrEntity} from "../model/SolrEntity";
import {BasicEntity} from "../model/BasicEntity";
import {GeometryUtils} from './GeometryUtils'
import {Entity} from "../model/Entity";
import {EntityExtension} from "../model/EntityExtension";
import {MapEntity} from "../model/MapEntity";
 import {EntityName} from "../model/EntityName";
 import {SolrResponse} from "../model/SolrResponse";
 import {Point} from "../model/Point";
 import {Relate} from "../model/Relate";
 import {Primitive} from "../model/Primitive";
 import {Property} from "../model/Property";
 import {SourceInfoMap} from "../model/SourceInfoMap";

export class ModelUtils{
    private static getDisplayName(entityNames: Array<EntityName>):  string{
        let entityName = entityNames.find((name) => {
            return name.NameRoleKeyName === "MapDisplay" && (name.CultureEnglishName === "en" || name.CultureEnglishName === "");
        });

        if(entityName === undefined){
            entityName = entityNames.find((name) => {
                return name.CultureEnglishName === "en" || name.CultureEnglishName === "";
            })
        }

        if(entityName === undefined){
            if(entityNames.length > 0)
                entityName = entityNames[0];
        }

        let displayName: string;
        if(entityName === undefined)
            displayName = "";
        else
            displayName = entityName.FriendlyName;

        return displayName;
    }

    public static basicEntityFromEntityExtension(entityExtension: EntityExtension){
        let basicEntity: BasicEntity;
        let displayName: string = this.getDisplayName(entityExtension.entityNames);

        basicEntity = {
            id: entityExtension.entityId,
            type: entityExtension.entityTypeKeyName,
            name: displayName,
            center: GeometryUtils.getPointFromLatLong(entityExtension.lat, entityExtension.long),
            index: 1
        };

        return basicEntity;
    }

    public static basicEntityFromSolrEntity(solrEntity: SolrEntity, index: number): BasicEntity{
        let basicEntity: BasicEntity;
        basicEntity = {
            id: solrEntity.Id,
            type: solrEntity.Type,
            name: solrEntity.Name,
            center: GeometryUtils.getPointFromWkt(solrEntity.WktCenter),
            index: index
        };

        return basicEntity;
    }

    public static basicEntityFromEntity(entity: Entity, index: number): BasicEntity{
        let displayName: string = this.getDisplayName(entity.EntityNames);

        return {
            id: entity.EntityID,
            type: entity.EntityTypeKeyName,
            name: displayName,
            center: GeometryUtils.getPointFromLatLong(entity.Lat, entity.Long),
            index: index
        };
    }

    public static entityExtensionFromEntity(entity: Entity): EntityExtension{
        let entityExtension: EntityExtension;
        entityExtension = {
            entityId: entity.EntityID,
            entityTypeKeyName: entity.EntityTypeKeyName,
            sourceInfo: entity.SourceInfo ? entity.SourceInfo.split(',') :  [],
            contributors: entity.Contributors ? entity.Contributors.split(',') :  [],
            aggregatedSourceInfo: ModelUtils.getAggregatedSourceInfo(entity),
            entityNames: entity.EntityNames ? entity.EntityNames : [],
            primitives: entity.Primitives ? entity.Primitives : [],
            entityRelates: entity.EntityRelates ? entity.EntityRelates : [],
            children: entity.Children ? entity.Children : [],
            properties: entity.EntityProperties ? entity.EntityProperties : [],
            geoChain: entity.Geochain ? entity.Geochain : [],
            lat: entity.Lat,
            long: entity.Long,
            zoom: entity.Zoom
        };

        return entityExtension;
    }

    private static getAggregatedSourceInfo (entity: Entity): SourceInfoMap{
        let ret: SourceInfoMap = {};

        ModelUtils.parseSourceInfo(entity, "EntityID", "Entity", ret);
        ModelUtils.getSourceInfo(entity.EntityNames, "NameID", "Entity name", ret);
        ModelUtils.getSourceInfo(entity.Primitives, "PrimitiveID", "Primitive", ret);
        ModelUtils.getSourceInfo(entity.EntityRelates, "RelateID", "Relationship", ret);
        ModelUtils.getPropertiesSourceInfo(entity.EntityProperties, "Entity property", ret);

        if(entity.EntityNames)
            entity.EntityNames.forEach((name: EntityName) => ModelUtils.getPropertiesSourceInfo(name.Properties, "Name property", ret));
        if(entity.EntityRelates)
            entity.EntityRelates.forEach((relate: Relate) => ModelUtils.getPropertiesSourceInfo(relate.Properties, "Entity relate property", ret));
        if(entity.Primitives){
            entity.Primitives.forEach((primitive: Primitive) => {
                ModelUtils.getPropertiesSourceInfo(primitive.PrimitiveProperties, "Primitive property", ret);
                ModelUtils.getSourceInfo(primitive.Relationships, "RelateID", "Primitive relationship", ret);
                if(primitive.Relationships)
                    primitive.Relationships.forEach((relate: Relate) => ModelUtils.getPropertiesSourceInfo(relate.Properties, "Primitive relate property", ret))
            })
        }

        console.log(ret);
        return ret;
    }

    private static getSourceInfo(array: Array<any>, idParamName: string, objectType: string, result: SourceInfoMap){
        if(array)
            array.forEach((item: any) => {
                ModelUtils.parseSourceInfo(item, idParamName, objectType, result);
            });
    }

    private static getPropertiesSourceInfo(properties: Array<Property> | null, objectType: string, result: SourceInfoMap){
        if(properties)
            properties.forEach((property: Property) => {
                ModelUtils.parseSourceInfo(property, "PropertyKeyName", objectType, result);

                ModelUtils.getPropertiesSourceInfo(property.Values, objectType, result);
                ModelUtils.getPropertiesSourceInfo(property.Subproperties, objectType, result);
            });
    }

    private static parseSourceInfo(item: any, idParamName: string, objectType: string, result: SourceInfoMap){
        if (item.SourceInfo)
            item.SourceInfo.split(',').forEach((sourceInfo: string) => {
                if (!result[sourceInfo])
                    result[sourceInfo] = [];

                result[sourceInfo].push({objectId: item[idParamName] ? item[idParamName] : "", objectType: objectType});
            });
    }

    public static mapEntityFromEntity(entity: Entity, index: number): MapEntity{
        let mapEntity: MapEntity;
        mapEntity = {
            basicEntity: this.basicEntityFromEntity(entity, index),
            entityExtension: this.entityExtensionFromEntity(entity)
        };

        return mapEntity;
    }

    private static solrEntityFromEntity(entity: Entity): SolrEntity | null {
        return {
            Ent: null,
            From: null,
            Size: null,
            Id: entity.EntityID,
            Type: entity.EntityTypeKeyName,
            WktCenter: GeometryUtils.toWkt(new Point(entity.Lat, entity.Long)),
            Name: this.getDisplayName(entity.EntityNames)
        };
    }

    public static createSolrResponse(data){
        let solrResponse: SolrResponse = {
            TotalCount: data.Entities.length,
            Message: null,
            RenderingEntities: [],
            Stream: "",
            Time: -1,
            RemainingEntities: data.Entities.map(entity => ModelUtils.solrEntityFromEntity(entity))
        };

        return solrResponse;
    }

    public static deepCopy(object: any){
        return JSON.parse(JSON.stringify(object));
    }

    public static getBasicEntityInfo(basicEntity: BasicEntity, allInfo: boolean = false): string{
        let ret: string;

        if(allInfo)
            ret = basicEntity.id + (ModelUtils.hasName(basicEntity) ? ", " + basicEntity.name : "");
        else
            ret = ModelUtils.hasName(basicEntity) ? basicEntity.name : basicEntity.id;

        ret += ", " + basicEntity.type;

        return ret;
    }

    private static hasName(basicEntity: BasicEntity){
        return !!(basicEntity.name && basicEntity.name !== "");
    }
}