import {Ontology} from "../../model/Ontology";
import {OntologyTypesWrapper} from "./OntologyTypesWrapper";
import {primitiveFields} from "../OntologyFieldNames";

export class OntologyPrimitiveTypesWrapper extends OntologyTypesWrapper{
    private primitiveTypes: Array<string> = [];
    private geometryTypes: {[primitiveType: string]: string} = {};

    public constructor(ontology: Ontology | null){
        super(ontology, primitiveFields);

        if(ontology) {
            this.initPrimitiveTypes(ontology);
        }
    }

    public getPrimitiveTypes(): Array<string>{
        return this.primitiveTypes;
    }

    public getGeometryType(primitiveType: string){
        return this.geometryTypes[primitiveType];
    }

    private initPrimitiveTypes(ontology: Ontology){
        let ret: Array<string> = ontology.PrimitiveType.map(type => {
            let keyName = type["@KeyName"];
            this.geometryTypes[keyName] = type["@GeometryType"];

            return keyName;
        });

        this.primitiveTypes = ret.sort((lhs: string, rhs: string) => lhs > rhs ? 1 : -1);
    }
}