import * as React from 'react';
import {Relate} from '../../../model/Relate';
import PropertiesViewEdit from "../properties/PropertiesViewEdit";
import {EditServerProxy} from "../../../utils/EditServerProxy";
import {EntityResponse} from "../../../model/EntityResponse";
import {Primitive} from "../../../model/Primitive";
import {ArrayUtils} from "../../../utils/ArrayUtils";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {ModelUtils} from "../../../utils/ModelUtils";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";
import {Link} from "@material-ui/core";
import {connect} from "react-redux";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";
import DropdownList from "../../../commonComponents/DropdownList";
import MaterialTable from "material-table";
import {registerLoadedEntity} from "../../../store/actions/changeLinkedHashMap";
import {MaterialTableUtils} from "../../../utils/MaterialTableUtils";

type PrimitiveRelationshipsProps = {
    primitiveRelates: Array<Relate>,
    idsList: Array<string | null>,
    primitiveId: string,
    primitiveType: string,
    callback: any,
    propCallback: any,
    currentStreamId: string | null,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void
}

type PrimitiveRelationshipsState = {
    primitiveRelates: Array<Relate>,
    relationship: Relate | null
}

class PrimitiveRelationshipsEdit extends React.Component<PrimitiveRelationshipsProps, PrimitiveRelationshipsState>{
    public constructor(props) {
        super(props);

        this.state = {
            primitiveRelates: this.props.primitiveRelates ? this.props.primitiveRelates : [],
            relationship: null
        }
    }

    static defaultProps = {
        callback: null,
        propCallback: null
    };

    public componentDidUpdate(prevProps: PrimitiveRelationshipsProps){
        if(!this.compareProps(prevProps, this.props)){
            let relationship: Relate | null = this.state.relationship;

            if(relationship !== null){
                let filter: Array<Relate> = this.props.primitiveRelates.filter(relate => {return relationship !== null && relate.ToID === relationship.ToID && relate.RelateTypeKeyName === relationship.RelateTypeKeyName});
                if(filter.length > 0)
                    relationship = filter[0];
                else
                    relationship = null;
            }

            this.setState({
                primitiveRelates: this.props.primitiveRelates ? this.props.primitiveRelates.slice() : [],
                relationship: relationship
            })
        }
    }

    private compareProps(lhs: PrimitiveRelationshipsProps, rhs: PrimitiveRelationshipsProps){
        if(lhs === null || rhs === null)
            return lhs === rhs;

        if(lhs.primitiveRelates === null || rhs.primitiveRelates === null)
            return lhs.primitiveRelates === rhs.primitiveRelates;

        return JSON.stringify(lhs.primitiveRelates.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.primitiveRelates.map(p => JSON.stringify(p)).sort());
    }

    public onNewRelationshipSubmit = (id: string, type: string) => {
        if(this.props.callback === null) {
            let response: Promise<any> | null = EditServerProxy.changePrimitiveRelationship(id, type, false);
            if (response !== null) {
                response.then(result => result.json())
                    .then(
                        (data: EntityResponse) => {
                            if(data && data.SingleEntity){
                                let primitiveRelates: Array<Relate> = data.SingleEntity.Primitives.filter((primitive: Primitive) => primitive.PrimitiveID === this.props.primitiveId)[0].Relationships;
                                if(ArrayUtils.any(primitiveRelates, (relate: Relate) => relate.ToID === id && relate.RelateTypeKeyName === type)){
                                    GlobalCallbacks.displaySnackbarMessage("Relationship added");
                                    this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                                    this.setState({
                                        primitiveRelates: primitiveRelates
                                    })
                                }
                                else{
                                    GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                }
                            }
                            else{
                                GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                            }
                        })
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("Editing entity is not selected!");
            }
        }
        else{
            this.props.callback(id, type, false);
        }
    };

    public onDeletionChange = (relateId: string) => {
        if (this.props.callback === null) {
            let relate: Relate = this.state.primitiveRelates.filter((relate: Relate) => {
                return relate.RelateID === relateId;
            })[0];

            let response: Promise<any> | null = EditServerProxy.deletePrimitiveRelationship(relate);
            if (response !== null) {
                response.then(result => result.json())
                    .then(
                        (data: EntityResponse) => {
                            if(data && data.SingleEntity) {
                                let primitiveRelates: Array<Relate> = data.SingleEntity.Primitives.filter((primitive: Primitive) => primitive.PrimitiveID === this.props.primitiveId)[0].Relationships;
                                if(!ArrayUtils.any(primitiveRelates, (r: Relate) => r.RelateID === relate.RelateID)){
                                    GlobalCallbacks.displaySnackbarMessage("Relationship deleted");
                                    this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                                    this.setState({
                                        primitiveRelates: primitiveRelates
                                    });
                                }
                                else{
                                    GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                }
                            }
                            else{
                                GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                            }
                        })
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("Editing entity is not selected!");
            }
        } else {
            this.props.callback(relateId);
        }
    };

    private findRelationship(relateId: string){
        let i: number;
        for(i = 0; i < this.state.primitiveRelates.length; i++){
            if(this.state.primitiveRelates[i].RelateID === relateId)
                return this.state.primitiveRelates[i];
        }

        return null;
    }

    public setPropertyView = (rowData) => {
        if(rowData && rowData.relateId){
            let relationship: Relate | null= this.findRelationship(rowData.relateId);
            if(relationship)
                this.setState({
                    relationship: relationship
                });
        }
    };

    public resetPropertyView = () => {
        this.setState({
            relationship: null
        })
    };

    public onTypeChange = (props, selected) => {
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.type = selected;
        props.onRowDataChange(rowData);
    };

    private getColumns(){
        let columns: Array<{}> = [];

        let ontology: OntologyWrapper = OntologyMap.getOntology(this.props.currentStreamId);
        let relationshipTypes: Array<string> = ontology.getPrimitiveTypesWrapper().getRelatesOfType(this.props.primitiveType);

        columns.push({title: 'Id', field: 'id', editable: 'onAdd'});
        columns.push({title: 'Type', field: 'type', editable: 'onAdd', editComponent: props => (
                <DropdownList options={relationshipTypes} onChange={(selected) => this.onTypeChange(props, selected)} initialValue={props.value}/>
            )});
        columns.push({title: 'Properties', field: 'properties', editable: 'never',
            render: rowData => (
                <Link component={"button"} onClick={() => this.setPropertyView(rowData)}>Open</Link>
            )}
        );

        return columns;
    }

    private getData(){
        return this.state.primitiveRelates.map(relate => {
            return {id: relate.ToID, type: relate.RelateTypeKeyName, properties: "Open", relateId: relate.RelateID};
        });
    }

    private onRowAdd = (newData) => {
        return new Promise((resolve, reject) => {
            console.log(newData);
            if("id" in newData && newData["id"] !== "" && "type" in newData){
                this.onNewRelationshipSubmit(newData["id"], newData["type"]);
                resolve();
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("All fields are required!");
                reject();
            }
            resolve();
        })
    };

    private onRowDelete = (oldData) => {
        return new Promise((resolve) => {
            console.log(oldData);
            this.onDeletionChange(oldData["relateId"]);
            resolve();
        })
    };

    public render(): JSX.Element{
        let columns: Array<{}> = this.getColumns();
        let data: Array<{}> = this.getData();

        let length: number = this.props.primitiveRelates !== null ? this.props.primitiveRelates.length : 0;

        let idsList: Array<string | null> = this.props.idsList.slice();
        if(this.state.relationship){
            idsList[3] = this.state.relationship.RelateID
        }

        return(
            <div>
                <ExpansionComponent title={"Relationships (count: " + length + ")"} componentId={"primitiveRelates"}>
                    {
                        !this.state.relationship &&
                        <MaterialTable
                            options={MaterialTableUtils.tableOptions}
                            columns={columns}
                            data={data}
                            editable={{
                                onRowAdd: this.onRowAdd,
                                onRowDelete: this.onRowDelete
                            }}
                        />
                    }
                    {
                        this.state.relationship &&
                        <PropertiesViewEdit properties={this.state.relationship.Properties} goBackCallback={this.resetPropertyView} idsList={idsList} callback={this.props.propCallback} objectType={this.state.relationship.RelateTypeKeyName}/>
                    }
                </ExpansionComponent>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStreamId: state.streamState.currentStreamId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimitiveRelationshipsEdit)