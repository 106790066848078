import * as React from 'react';
import {Relate} from '../../../model/Relate';
import {DisplayTable} from '../../../commonComponents/DisplayTable';
import {Property} from '../../../model/Property';
import {PropertiesView} from "../properties/PropertiesView";
import {Link} from "@material-ui/core";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type PrimitiveRelationshipsProps = {
    primitiveRelates: Array<Relate>;
}

type PrimitiveRelationshipsState = {
    properties: Array<Property> | null
}

class PrimitiveRelationships extends React.Component<PrimitiveRelationshipsProps, PrimitiveRelationshipsState>{
    public constructor(props) {
        super(props);

        this.state = {
            properties: null
        }
    }

    public componentDidUpdate(prevProps: Readonly<PrimitiveRelationshipsProps>, prevState: Readonly<PrimitiveRelationshipsState>, snapshot?: any): void {
        if(!PrimitiveRelationships.compareProps(prevProps, this.props)){
            this.setState({
                properties: null
            })
        }
    }

    private static compareProps(lhs: PrimitiveRelationshipsProps, rhs: PrimitiveRelationshipsProps){
        if(lhs.primitiveRelates === null || rhs.primitiveRelates === null)
            return rhs.primitiveRelates === lhs.primitiveRelates;

        return JSON.stringify(lhs.primitiveRelates.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.primitiveRelates.map(p => JSON.stringify(p)).sort());
    }

    public setPropertyView = (properties: Array<Property> | null) => {
        this.setState({
            properties: properties
        })
    };

    public render(): JSX.Element{
        let headerList: Array<string> = [];
        let contentList: Array<Array<any>> = [];

        if(this.props.primitiveRelates !== null){
            headerList.push("ToId");
            headerList.push("Type");
            headerList.push("Properties");

            contentList = this.props.primitiveRelates.map((relate) => {
                let row: Array<any> = [];
                row.push(relate.ToID);
                row.push(relate.ToID);
                row.push(relate.RelateTypeKeyName);
                if(relate.Properties && relate.Properties.length > 0)
                    row.push(<Link component={"button"} variant={"body2"} onClick={() => this.setPropertyView(relate.Properties)}>Open</Link>);
                else
                    row.push("");

                return row;
            })
        }

        let length: number = this.props.primitiveRelates !== null ? this.props.primitiveRelates.length : 0;
        
        return(
            <ExpansionComponent title={"Relationships (count: " + length + ")"} componentId={"primitiveRelates"}>
                {
                    this.props.primitiveRelates !== null && length > 0 && !this.state.properties &&
                    <DisplayTable headerList={headerList} contentList={contentList}/>
                }
                {
                    this.state.properties &&
                    <PropertiesView properties={this.state.properties} goBackCallback={this.setPropertyView}/>
                }
            </ExpansionComponent>
        )
    }
}

export { PrimitiveRelationships }