import * as React from 'react';
import {IconButton, Tooltip} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {Direction} from "./Direction";

type MoveLayerProps = {
    direction: Direction,
    moveLayerCallback: (direction: Direction) => void
}

class MoveLayerButton extends React.Component<MoveLayerProps, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <IconButton aria-label="Move geometry" onClick={(e) => this.props.moveLayerCallback(this.props.direction)} size={"small"}>
                <Tooltip title={this.props.direction === Direction.UP ? "Bring primitive to front" : "Send primitive back"}>
                    {
                        this.props.direction === Direction.UP ?
                        <ArrowUpwardIcon /> :
                        <ArrowDownwardIcon />
                    }
                </Tooltip>
            </IconButton>
        )
    }
}

export default MoveLayerButton