import * as React from 'react';
import './EntityList.css';
import { DisplayList } from '../commonComponents/DisplayList';
import {Typography} from "@material-ui/core";
import {ModelUtils} from "../utils/ModelUtils";

type EntityListProps = {
    basicEntities: any,
    highlightedEntity: string | null,
    message: string | null,
    onEntityClick: (identifierKey) => void,
    showAllInfo: boolean
}

class EntityList extends React.Component<EntityListProps, any>{
    private displayList: any;

    static defaultProps = {
        showAllInfo: false
    };

    public constructor(props){
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    private onChange(id){
        this.props.onEntityClick(id);
    }

    public static createIdList(basicEntities): Array<any>{
        return Object.keys(basicEntities);
    }

    public static createItemsList(basicEntities, showAllInfo: boolean): Array<any>{
        return Object.keys(basicEntities).map(key => ModelUtils.getBasicEntityInfo(basicEntities[key], showAllInfo));
    }

    public componentDidUpdate(){
        if(this.displayList !== undefined && this.displayList !== null)
            this.displayList.setHighlighted(this.props.highlightedEntity);
    }

    public render(): JSX.Element{
        let basicEntities: {} = this.props.basicEntities;
        let length: number = Object.keys(basicEntities).length;

        let ids = EntityList.createIdList(basicEntities);
        let items = EntityList.createItemsList(basicEntities, this.props.showAllInfo);

        return(
            <div>
                {
                    length > 0 &&
                    <div>
                        <DisplayList ref={d => { this.displayList = d; }} ids={ids} items={items} onClick={this.onChange} initialHighlighted={this.props.highlightedEntity}/>
                    </div>
                }
                <br/>
                {
                    this.props.message !== null &&
                        <div style={{color:'red'}}>
                            <Typography>{this.props.message}</Typography>
                        </div>
                }
            </div>
        )
    }
}

export default EntityList