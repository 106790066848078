import * as React from 'react';
import { connect } from 'react-redux';
import { setShowMarkers } from '../store/actions/searchEntities';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

type TogglePropsType = {
    showMarkers: boolean,
    setShowMarkers: any
}

class ToggleShowMarkers extends React.Component<TogglePropsType,any>{
    private onChange = () => {
        this.props.setShowMarkers(!this.props.showMarkers);
    };

    public render(): JSX.Element{
        return(
            <div>
                <FormControlLabel
                control={
                    <Switch
                        checked={this.props.showMarkers}
                        onChange={this.onChange}
                        color="secondary"
                        size="small"
                    />
                }
                label="Show markers"
            />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        showMarkers: state.searchResult.showMarkers
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowMarkers: (flag: boolean) => dispatch(setShowMarkers(flag))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleShowMarkers)