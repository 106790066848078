import * as React from 'react';
import { connect } from 'react-redux';
import {Typography} from "@material-ui/core";

type ElapsedTimeProps = {
    elapsedTime: number
}

class ElapsedTime extends React.Component<ElapsedTimeProps, any>{
    public render(): JSX.Element{
        return(
            <div>
                {
                    this.props.elapsedTime >= 0 &&
                    <div>
                        <Typography align="center">
                            Elapsed time: {this.props.elapsedTime}ms
                        </Typography>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        elapsedTime: state.searchResult.elapsedTime,
    }
};

export default connect(mapStateToProps)(ElapsedTime)