import {Ontology} from "../../model/Ontology";
import {OntologyTypesWrapper} from "./OntologyTypesWrapper";
import {entityNameFields} from "../OntologyFieldNames";

export class OntologyNameTypesWrapper extends OntologyTypesWrapper{
    private entityNameTypes: Array<string> = [];

    public constructor(ontology: Ontology | null){
        super(ontology, entityNameFields);

        if(ontology) {
            this.initEntityNameTypes(ontology);
        }
    }

    public getEntityNameTypes(): Array<string> {
        return this.entityNameTypes;
    }

    private initEntityNameTypes(ontology: Ontology){
        let ret: Array<string> = ontology.NameType.map(name => {
            return name["@KeyName"];
        });

        this.entityNameTypes = ret.sort((lhs: string, rhs: string) => lhs > rhs ? 1 : -1);
    }
}