import * as React from 'react';
import {Property} from "../../../model/Property";
import {Guid} from "guid-typescript";
import './properties.css';
import '../relations/LinkButton.css';
import {Link, List, ListItem, Typography} from "@material-ui/core";

type PropertyValueProps = {
    property: Property,
    setNewView: any
}

class PropertyValue extends React.Component<PropertyValueProps, any>{
    private generateValue(property: Property): JSX.Element{
        let returnValue: JSX.Element = <div/>;

        if(property.Value){
            returnValue = (
                    <Typography>{property.Value}</Typography>);
        }
        else if(property.Values && property.Values.length > 0){
            returnValue = (<List dense={true}>
                {
                    property.Values.map(property => {
                        return <ListItem key={Guid.create().toString()}><PropertyValue property={property} setNewView={this.props.setNewView}/></ListItem>
                    })
                }
            </List>);
        }
        else if(property.Subproperties && property.Subproperties.length > 0){
            returnValue = <Link component={"button"} variant={"body2"} onClick={() => this.props.setNewView(property.Subproperties)}>Open</Link>;
        }

        return returnValue;
    }

    public render(): JSX.Element{
        return(
            this.generateValue(this.props.property)
        )
    }
}

export {PropertyValue}