import * as React from 'react';
import {DetailsFactory} from "../detailsFactory/DetailsFactory";
import {MapEntity} from "../../../model/MapEntity";

type EntityInfoProps = {
    entity: MapEntity | null,
    entityId: string | null,
    factory: DetailsFactory
}

class EntityInfo extends React.Component<EntityInfoProps, any>{
    public render(): JSX.Element{
        return (
            <div>
                { this.props.factory.generateEntityDetails(this.props.entity, this.props.entityId) }
            </div>
        )
    }
}

export {EntityInfo}