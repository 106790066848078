import * as React from 'react';
import {Parent} from "../../../model/Parent";
import {DisplayTable} from "../../../commonComponents/DisplayTable";
import {keepOnMap, searchByEntityId} from "../../../store/actions/searchEntities";
import {changeActiveTab} from "../../../store/actions/changeMapState";
import {connect} from "react-redux";
import {Link} from "@material-ui/core";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type GeoChainProps = {
    geoChain: Array<Parent>,
    entityId: string,
    keepOnMap: any,
    changeActiveTab: any,
    searchByEntityId: (identifierKey) => void
}

class GeoChain extends React.Component<GeoChainProps, any>{
    private onParentClick = (id) => {
        if(id !== null && id !== undefined) {
            this.props.keepOnMap(this.props.entityId);
            this.props.changeActiveTab("search");
            this.props.searchByEntityId(id);
        }
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let headerList: Array<string> = [];
        let contentList: Array<Array<any>> = [];

        if(this.props.geoChain){
            headerList.push("Id");
            headerList.push("Name");
            headerList.push("Type");

            this.props.geoChain.forEach((parent, index) => {
                if(index !== 0) {
                    let row: Array<any> = [];
                    row.push(parent.Id);
                    row.push(<Link component={"button"} variant={"body2"} onClick={() => this.onParentClick(parent.Id)}>{parent.Id}</Link>);
                    row.push(parent.Name);
                    row.push(parent.Type);

                    contentList.push(row);
                }
            })
        }

        let length: number = contentList.length;

        return(
            <ExpansionComponent title={"GeoChain (count: " + length + ")"} componentId={"geoChain"}>
                {
                    this.props.geoChain !== null && length > 0 &&
                    <DisplayTable contentList={contentList} headerList={headerList}/>
                }
            </ExpansionComponent>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchByEntityId: (identifierKey) => dispatch(searchByEntityId(identifierKey)),
        changeActiveTab: (key: string) => dispatch(changeActiveTab(key)),
        keepOnMap: (entityId: string) => dispatch(keepOnMap(entityId))
    }
};

export default connect(null, mapDispatchToProps)(GeoChain)