import * as React from 'react';
import { connect } from 'react-redux';
import {LinearProgress} from "@material-ui/core";

type EntityLoaderPropsType = {
    loaded: number,
    total: number
}

class EntityLoaderProgressBar extends React.Component<EntityLoaderPropsType, any>{
    public render(): JSX.Element{
        return (
            <div>
                {this.props.loaded < this.props.total && this.props.loaded >= 0 && this.props.total >= 0 &&
                    <LinearProgress variant="determinate" value={(this.props.loaded/this.props.total) * 100} color="secondary"/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        loaded: state.searchResult.loaded,
        total: state.searchResult.total
    }
};

export default connect(mapStateToProps)(EntityLoaderProgressBar)