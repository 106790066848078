import * as React from 'react';
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";
import {connect} from "react-redux";
import DropdownList from "../../../commonComponents/DropdownList";
import {TextField} from "@material-ui/core";

type ValueFormProps = {
    currentStreamId: string | null,
    value: string | null,
    keyName: string | null,
    onChange: (value: string) => void
}

class ValueForm extends React.Component<ValueFormProps, any>{
    private onDropdownChange = (value: string) => {
        this.props.onChange(value);
    };

    private onTextChange = (event) => {
        this.props.onChange(event.target.value);
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let ontology: OntologyWrapper = OntologyMap.getOntology(this.props.currentStreamId);
        let enumValues: Array<string> = ontology.getPropertiesWrapper().getEnumValues(this.props.keyName);

        if(enumValues.length > 0)
            return <DropdownList options={enumValues} initialValue={this.props.value} onChange={this.onDropdownChange}/>;
        else
            return <TextField type="text" placeholder={this.props.value ? this.props.value : ""} onChange={this.onTextChange} size="small"/>
    }
}

const mapStateToProps = (state) => {
    return {
        currentStreamId: state.streamState.currentStreamId
    }
};

export default connect(mapStateToProps)(ValueForm)