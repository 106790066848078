import * as React from 'react';
import { Relate } from '../../../model/Relate';
import {keepOnMap, searchByEntityId} from '../../../store/actions/searchEntities';
import { connect } from 'react-redux';
import {Property} from "../../../model/Property";
import {DisplayTable} from "../../../commonComponents/DisplayTable";
import {changeActiveTab} from "../../../store/actions/changeMapState";
import './LinkButton.css';
import {PropertiesView} from "../properties/PropertiesView";
import {Link} from "@material-ui/core";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type RelationshipsProps = {
    entityRelates: Array<Relate>,
    entityId: string,
    searchByEntityId: (identifierKey) => void,
    changeActiveTab: any,
    keepOnMap: any
}

type RelationshipState = {
    properties: Array<Property> | null
}

class Relationships extends React.Component<RelationshipsProps, RelationshipState>{
    public constructor(props){
        super(props);

        this.state = {
            properties: null
        }
    }

    public componentDidUpdate(prevProps: Readonly<RelationshipsProps>, prevState: Readonly<RelationshipState>, snapshot?: any): void {
        if(!Relationships.compareProps(prevProps, this.props)){
            this.setState({
                properties: null
            })
        }
    }

    private static compareProps(lhs: RelationshipsProps, rhs: RelationshipsProps){
        if(lhs.entityRelates === null || rhs.entityRelates === null)
            return rhs.entityRelates === lhs.entityRelates;

        return JSON.stringify(lhs.entityRelates.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.entityRelates.map(p => JSON.stringify(p)).sort());
    }


    public setPropertyView = (properties: Array<Property> | null) => {
        this.setState({
            properties: properties
        })
    };

    private onRelationshipClick = (id) => {
        if(id !== null && id !== undefined) {
            this.props.keepOnMap(this.props.entityId);
            this.props.changeActiveTab("search");
            this.props.searchByEntityId(id);
        }
    };

    public render(): JSX.Element{
        let headerList: Array<string> = [];
        let contentList: Array<Array<any>> = [];

        if(this.props.entityRelates !== null){
            headerList.push("Id");
            headerList.push("Type");
            headerList.push("Properties");

            contentList = this.props.entityRelates.map((relate) => {
                let row: Array<any> = [];
                row.push(relate.ToID);
                row.push(<Link component={"button"} variant={"body2"} onClick={() => this.onRelationshipClick(relate.ToID)}>{relate.ToID}</Link>);
                row.push(relate.RelateTypeKeyName);
                if(relate.Properties && relate.Properties.length > 0)
                    row.push(<Link component={"button"} variant={"body2"} onClick={() => this.setPropertyView(relate.Properties)}>Open</Link>);
                else
                    row.push("");

                return row;
            })
        }

        let length: number = this.props.entityRelates !== null ? this.props.entityRelates.length : 0;

        return(
            <ExpansionComponent title={"Relationships (count: " + length + ")"} componentId={"relatesList"}>
                {
                    this.props.entityRelates !== null && length > 0 && !this.state.properties &&
                    <DisplayTable contentList={contentList} headerList={headerList}/>
                }
                {
                    this.state.properties &&
                    <PropertiesView properties={this.state.properties} goBackCallback={this.setPropertyView}/>
                }
            </ExpansionComponent>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchByEntityId: (identifierKey) => dispatch(searchByEntityId(identifierKey)),
        changeActiveTab: (key: string) => dispatch(changeActiveTab(key)),
        keepOnMap: (entityId: string) => dispatch(keepOnMap(entityId))
    }
};

export default connect(null, mapDispatchToProps)(Relationships)