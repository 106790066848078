import {StreamUtils} from "../../utils/StreamUtils";
import {StreamInfo} from "../../streams/StreamInfo";

function createStreamMap(streamList: Array<string>): {[streamId: string]: StreamInfo}{
    let streamMap: {[streamId: string]: StreamInfo} = {};
    streamList.forEach(streamInfo => {
        let streamId: string = StreamUtils.getStreamId(streamInfo); //streamId is the last parameter
        let streamName: string = StreamUtils.getStreamName(streamInfo);
        let configString: string = StreamUtils.buildConfigString(streamInfo);
        streamMap[streamId] = {streamPath: streamName.split('/'), id: streamId, configString: configString};
    });

    return streamMap;
}

export const updateStreamInformation = (streamInformation: {}) => {
    let information: {} = streamInformation;

    if(information.hasOwnProperty('streamList')){
        let streamList: Array<string> = information['streamList'];
        information['streamList'] = createStreamMap(streamList);
    }

    console.log(information);
    return {type: 'UPDATE_STREAM_INFORMATION', data: information}
};

export const setCurrentStreamId = (streamId: string) => {
    return {type: 'SET_CURRENT_STREAM_ID', data: streamId}
};

export const setExpandedNodes = (nodeIds: Array<string>) => {
    return {type: 'SET_EXPANDED_NODES', data: nodeIds}
};

export const setErrorMessage = (errorMessage: string) => {
    return {type: 'SET_ERROR_MESSAGE', data: errorMessage}
};