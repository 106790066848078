type initialStateType = {
    isPolaris: boolean
}

const initState : initialStateType = {
    isPolaris: true
};

const appReducer = (state=initState, action) => {
    if(action.type === 'SET_SEARCH_SERVICE')
        return{
            ...state,
            isPolaris: action.data
        };
    else{
        return state;
    }
};

export default appReducer;