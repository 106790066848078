export enum MapState{
    Visualizing,
    Editing,
    CreatingPrimitive,
    Creating,
    Snapping,
    Replacing,
    Merging,
    Splitting,
    Copying,
    Moving
}