export enum SearchType {
    Equals = "Equals",
    StartsWith = "Starts with",
    EndsWith = "Ends with",
    Contains = "Contains"
}

export function fromString(searchType: string): SearchType {
    switch (searchType){
        case "Equals":
            return SearchType.Equals;
        case "Starts with":
            return SearchType.StartsWith;
        case "Ends with":
            return SearchType.EndsWith;
        case "Contains":
            return SearchType.Contains;
        default:
            return SearchType.Equals;
    }
}