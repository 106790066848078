import * as React from 'react';
import {findPrimitive, Primitive} from '../../../model/Primitive';
import { connect } from 'react-redux';
import { setHighlightedPrimitive } from '../../../store/actions/searchEntities';
import { DisplayList } from '../../../commonComponents/DisplayList';
import Select from 'react-select';
import {Theme, withTheme} from '@material-ui/core/styles';
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type PrimitivesListProps = {
    primitives: Array<Primitive>,
    highlightedPrimitive: string | null,
    setHighlightedPrimitive: (primitiveId: string, primitiveWkt: string) => void,
    theme: Theme
}

type PrimitivesListState = {
    possibleTypes: Set<string>,
    chosenTypes: Set<string>,
    clearSelect: boolean
}

class PrimitivesList extends React.Component<PrimitivesListProps, PrimitivesListState>{
    private displayList: any;

    public constructor(props){
        super(props);

        if(this.props.primitives){
            let possibleTypes: Set<string> = new Set();
            this.props.primitives.forEach((primitive: Primitive) => {
                possibleTypes.add(primitive.PrimitiveTypeKeyName);
            });

            this.state = {
                possibleTypes: possibleTypes,
                chosenTypes: new Set(),
                clearSelect: false
            }
        }
    }

    public componentDidUpdate(prevProps){
        if(this.displayList)
            this.displayList.setHighlighted(this.props.highlightedPrimitive);

        if(!PrimitivesList.compareProps(prevProps, this.props)){
            let possibleTypes: Set<string> = new Set();
            this.props.primitives.forEach((primitive) => {
                possibleTypes.add(primitive.PrimitiveTypeKeyName);
            });

            this.setState((prevState: PrimitivesListState) => {
                return{
                    possibleTypes: possibleTypes,
                    chosenTypes: new Set(),
                    clearSelect: !prevState.clearSelect
                }
            });
        }
    }

    private static compareProps(lhs: PrimitivesListProps, rhs: PrimitivesListProps){
        if(lhs === null || rhs === null)
            return lhs === rhs;

        if(lhs.primitives === null || rhs.primitives === null)
            return lhs.primitives === rhs.primitives;

        return JSON.stringify(lhs.primitives) === JSON.stringify(rhs.primitives);
    }

    private onPrimitiveClick = (id: string) => {
        let primitive: Primitive | null = findPrimitive(id, this.props.primitives);
        if(primitive)
            this.props.setHighlightedPrimitive(id, primitive.ShapeWKT);
    };

    private handleTypeChange = (selectedOptions) => {
        let chosenTypes: Set<string> = new Set();
        if(selectedOptions !== null)
            chosenTypes = new Set(selectedOptions.map((type) => type.value));
        else
            chosenTypes = new Set();

        this.setState({
            chosenTypes: chosenTypes
        })
    };

    public render(): JSX.Element{
        let ids: Array<string> = [];
        let items: Array<string> = [];

        
        if(this.props.primitives !== null){
            let primitives: Array<Primitive> = this.props.primitives;

            if(this.state.chosenTypes.size > 0){
                primitives = primitives.filter(p => this.state.chosenTypes.has(p.PrimitiveTypeKeyName));
            }

            if(primitives.length > 10000){
                primitives = primitives.slice(0, 10000);
            }

            ids = primitives.map(primitive => primitive.PrimitiveID);
            items = primitives.map(primitive => (primitive.PrimitiveID + ", " + primitive.PrimitiveTypeKeyName));
        }

        let options: Array<{value: string, label: string}> = [];
        this.state.possibleTypes.forEach((type) => options.push({value: type, label: type}));

        let length: number = this.props.primitives !== null ? this.props.primitives.length : 0;

        return (
            <ExpansionComponent title={"Primitives (count: " + length + ")"} componentId={"primitiveList"}>
                <Select
                    key={this.state.clearSelect + ""}
                    id="selectTypes"
                    isMulti
                    name="colors"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={this.handleTypeChange}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: this.props.theme.palette.secondary.main,
                            primary: this.props.theme.palette.primary.main
                        },
                    })}
                />
                {
                    this.props.primitives !== null && length > 0 &&
                    <div>
                        <DisplayList ref={d => { this.displayList = d; }}  ids={ids} items={items} onClick={this.onPrimitiveClick} initialHighlighted={this.props.highlightedPrimitive}/>
                    </div>
                }
            </ExpansionComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        highlightedPrimitive: state.searchResult.highlightedPrimitive
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHighlightedPrimitive: (primitiveId: string, primitiveWkt: string) => dispatch(setHighlightedPrimitive(primitiveId, primitiveWkt))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(PrimitivesList))