import * as React from 'react';
import { Relate } from '../../../model/Relate';
import { EditServerProxy } from '../../../utils/EditServerProxy';
import { EntityResponse } from '../../../model/EntityResponse';
import PropertiesViewEdit from "../properties/PropertiesViewEdit";
import {ArrayUtils} from "../../../utils/ArrayUtils";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {ModelUtils} from "../../../utils/ModelUtils";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";
import MaterialTable from "material-table";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";
import {connect} from "react-redux";
import DropdownList from "../../../commonComponents/DropdownList";
import {Link} from "@material-ui/core";
import {registerLoadedEntity} from "../../../store/actions/changeLinkedHashMap";
import RelationshipsForm from "./RelationshipsForm";
import {MaterialTableUtils} from "../../../utils/MaterialTableUtils";

type RelationshipsEditProps = {
    entityRelates: Array<Relate>,
    idsList: Array<string | null>,
    entityType: string,
    callback: any,
    propCallback: any,

    currentStreamId: string | null,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void
}

type RelationshipsEditState = {
    entityRelates: Array<Relate>,
    relationship: Relate | null
}

class RelationshipsEdit extends React.Component<RelationshipsEditProps, RelationshipsEditState>{
    public constructor(props){
        super(props);

        this.state = {
            entityRelates: this.props.entityRelates,
            relationship: null
        }
    }

    static defaultProps = {
        callback: null,
        propCallback: null
    };

    public componentDidUpdate(prevProps: RelationshipsEditProps){
        if(!this.compareProps(prevProps, this.props)){
            let relationship: Relate | null = this.state.relationship;
            if(relationship !== null){
                let filter: Array<Relate> = this.props.entityRelates.filter(relate => {return relationship !== null && relate.ToID === relationship.ToID && relate.RelateTypeKeyName === relationship.RelateTypeKeyName});
                if(filter.length > 0)
                    relationship = filter[0];
                else
                    relationship = null;
            }

            this.setState({
                entityRelates: this.props.entityRelates.slice(),
                relationship: relationship
            })
        }
    }

    private compareProps(lhs: RelationshipsEditProps, rhs: RelationshipsEditProps){
        if(lhs === null || rhs === null)
            return lhs === rhs;

        if(lhs.entityRelates === null || rhs.entityRelates === null)
            return lhs.entityRelates === rhs.entityRelates;

        return JSON.stringify(lhs.entityRelates.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.entityRelates.map(p => JSON.stringify(p)).sort());
    }

    public onNewRelationshipSubmit = (newData) => {
        if(newData && newData.id && newData.type){
            let id: string = newData.id;
            let type: string = newData.type;

            if(this.props.callback === null) {
                let response: Promise<any> | null = EditServerProxy.addRelationship(id, type);
                if (response !== null) {
                    response.then(result => result.json())
                        .then(
                            (data: EntityResponse) => {
                                if(data && data.SingleEntity){
                                    if(ArrayUtils.any(data.SingleEntity.EntityRelates, (relate: Relate) => relate.ToID === id && relate.RelateTypeKeyName === type)){
                                        GlobalCallbacks.displaySnackbarMessage("Relationship added");
                                        this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                                        this.setState({
                                            entityRelates: data.SingleEntity.EntityRelates
                                        });
                                    }
                                    else{
                                        GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                    }
                                }
                                else{
                                    GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                }
                            })
                }
                else{
                    GlobalCallbacks.displaySnackbarMessage("Editing entity is not selected!");
                }
            }
            else{
                this.props.callback(id, type, false);
            }
        }
    };

    public onDeletionChange = (oldData) => {
        if(oldData && oldData.id && oldData.type){
            let id: string = oldData.id;
            let type: string = oldData.type;

            if (this.props.callback === null) {
                let response: Promise<any> | null = EditServerProxy.deleteRelationship(id, type);
                if (response !== null) {
                    response.then(result => result.json())
                        .then(
                            (data: EntityResponse) => {
                                if(data && data.SingleEntity){
                                    if(!ArrayUtils.any(data.SingleEntity.EntityRelates, (relate: Relate) => relate.ToID === id && relate.RelateTypeKeyName === type)){
                                        GlobalCallbacks.displaySnackbarMessage("Relationship deleted");
                                        this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                                        this.setState({
                                            entityRelates: data.SingleEntity.EntityRelates
                                        });
                                    }
                                    else{
                                        GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                    }
                                }
                                else{
                                    GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                }
                            })
                }
                else{
                    GlobalCallbacks.displaySnackbarMessage("Editing entity is not selected!");
                }
            }
            else {
                this.props.callback(id, type, true);
            }
        }
    };

    private findRelationship(id: string, type: string){
        let i: number;
        for(i = 0; i < this.state.entityRelates.length; i++){
            if(this.state.entityRelates[i].ToID === id)
                break;
        }

        if(i < this.state.entityRelates.length)
            return this.state.entityRelates[i];
        else
            return null;
    }

    public setPropertyView = (rowData) => {
        if(rowData && rowData.id && rowData.type){
            let relationship: Relate | null= this.findRelationship(rowData.id, rowData.type);
            if(relationship)
                this.setState({
                    relationship: relationship
                });
        }
    };

    public resetPropertyView = () => {
        this.setState({
            relationship: null
        })
    };

    public onTypeChange = (props, selected) => {
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.type = selected;
        props.onRowDataChange(rowData);
    };

    public onToIdChange = (props, toId: string) => {
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.id = toId;
        props.onRowDataChange(rowData);
    };
    private onRowAdd = (newData) => {
        return new Promise((resolve, reject) => {
            console.log(newData);
            if("id" in newData && newData["id"] !== "" && "type" in newData){
                this.onNewRelationshipSubmit(newData);
                resolve();
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("All fields are required!");
                reject();
            }
            resolve();
        })
    };

    private onRowDelete = (oldData) => {
        return new Promise((resolve, ) => {
            console.log(oldData);
            this.onDeletionChange(oldData);
            resolve();
        })
    };
    public render(): JSX.Element{
        let columns: Array<{}> = [];
        let data: Array<{}> = [];

        let ontology: OntologyWrapper = OntologyMap.getOntology(this.props.currentStreamId);
        let relationshipTypes: Array<string> = ontology.getEntityTypesWrapper().getRelatesOfType(this.props.entityType);

        console.log(this.state.entityRelates);
        
        if(this.state.entityRelates !== null){
            columns.push({title: 'Id', field: 'id', editComponent: props => (
                    <RelationshipsForm onIdChange={(id: string) => this.onToIdChange(props, id)}/>
                )});
            columns.push({title: 'Type', field: 'type', editComponent: props => (
                    <DropdownList options={relationshipTypes} onChange={(selected) => this.onTypeChange(props, selected)} initialValue={props.value}/>
                )});
            columns.push({title: 'Properties', field: 'properties', editable: 'never', render: rowData => (
                    <Link component={"button"} onClick={() => this.setPropertyView(rowData)}>Open</Link>
                )
            });

            this.state.entityRelates.forEach((relate) => {
                let dataRow: {} = {id: relate.ToID, type: relate.RelateTypeKeyName, properties: "Open"};
                data.push(dataRow);
            });
        }

        let length: number = this.state.entityRelates !== null ? this.state.entityRelates.length : 0;

        let idsList: Array<string | null> = this.props.idsList.slice();
        if(this.state.relationship){
            idsList[1] = this.state.relationship.RelateID;
        }

        return(
            <div>
                <ExpansionComponent title={"Relationships (count: " + length + ")"} componentId={"relatesList"}>
                    {
                        !this.state.relationship &&
                            <MaterialTable
                                options={MaterialTableUtils.tableOptions}
                                columns={columns}
                                data={data}
                                editable={{
                                    onRowAdd: this.onRowAdd,
                                    onRowDelete: this.onRowDelete
                                }}
                            />
                    }
                    {
                        this.state.relationship &&
                        <PropertiesViewEdit properties={this.state.relationship.Properties ? this.state.relationship.Properties : []} goBackCallback={this.resetPropertyView} idsList={idsList} callback={this.props.propCallback} objectType={this.state.relationship.RelateTypeKeyName}/>
                    }
                </ExpansionComponent>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStreamId: state.streamState.currentStreamId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipsEdit)