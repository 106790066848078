import * as React from 'react';
import { connect } from 'react-redux';
import {changeActiveTab} from "../store/actions/changeMapState";
import '../App.css';
import {Tabs} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import {STREAMS, SEARCH, INFORMATION, WKT_PREVIEW} from './TabsIds';

type TabControlProps = {
    highlightedEntity: string | null,
    creatingEntityId: string | null,
    activeTab: string,
    changeActiveTab: (key: string) => void
}

type TabControlState = {
    key: string
}

class TabControl extends React.Component<TabControlProps, TabControlState>{
    public constructor(props){
        super(props);

        this.state = {
            key: "streams"
        }
    }

    private shouldDisplayInformationTab(): boolean{
        return this.props.highlightedEntity !== null || this.props.creatingEntityId !== null;
    }

    private handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.props.changeActiveTab(newValue)
    };

    public render(): JSX.Element{
        let activeTab = this.props.activeTab;

        if(!this.shouldDisplayInformationTab() && this.props.activeTab === INFORMATION) {
            this.props.changeActiveTab(SEARCH);
            activeTab = SEARCH;
        }

        return(
            <div>
                <Tabs value={activeTab} onChange={this.handleChange} variant="scrollable" scrollButtons="auto">
                    <Tab value={STREAMS} label={"Streams"} style={{fontSize: "inherit"}}/>
                    <Tab value={SEARCH} label={"Search"} style={{fontSize: "inherit"}}/>
                    {
                        this.shouldDisplayInformationTab() &&
                        <Tab value={INFORMATION} label={"Information"} style={{fontSize: "inherit"}}/>
                    }
                    <Tab value={WKT_PREVIEW} label={"Wkt preview"} style={{fontSize: "inherit"}}/>
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        highlightedEntity: state.searchResult.highlightedEntity,
        creatingEntityId: state.searchResult.creatingEntityId,
        activeTab: state.mapState.activeTab
	}
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: (key: string) => dispatch(changeActiveTab(key))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TabControl)