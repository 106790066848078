import * as React from 'react';
import { connect } from 'react-redux';
import {LinearProgress} from "@material-ui/core";

type BasicEntitiesProgressProps = {
    showBasicSearchProgress: boolean
}

class BasicEntitiesProgressBar extends React.Component<BasicEntitiesProgressProps, any>{
    public render():JSX.Element{
        return(
            <div>
                {this.props.showBasicSearchProgress && <LinearProgress color={"secondary"}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showBasicSearchProgress: state.searchResult.showBasicSearchProgress
    }
};

export default connect(mapStateToProps)(BasicEntitiesProgressBar)