import * as React from 'react';
import { connect } from 'react-redux';
import {UrlUtils} from "../../permalink/UrlUtils";

type ListenerProps = {
    entitiesKeptOnMap: Set<string>;
    setKeptEntities: any
}

class KeepOnMapChangeListener extends React.Component <ListenerProps, any>{
    private firstRender: boolean = true;

    public componentDidMount(){
        this.props.setKeptEntities(this.props.entitiesKeptOnMap);
    }

    public componentDidUpdate(){
        this.props.setKeptEntities(this.props.entitiesKeptOnMap);
    }
    
    public render(){
        if(this.firstRender){
            this.firstRender = false;
        }
        else {
            if (this.props.entitiesKeptOnMap.size === 0)
                UrlUtils.removeParameter("keptEntities");
            else
                UrlUtils.changeParameter("keptEntities", Array.from(this.props.entitiesKeptOnMap).join(";"));
        }

        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        entitiesKeptOnMap: state.searchResult.entitiesKeptOnMap
	}
};

export default connect(mapStateToProps, null, null, {forwardRef:true})(KeepOnMapChangeListener)