import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';

type WktProgressSpinnerProps = {
    showWktSpinner: boolean
}

class WktProgressSpinner extends React.Component<WktProgressSpinnerProps, any>{
    public render():JSX.Element{
        return(
            <div>
                {this.props.showWktSpinner && <Spinner animation="border" role="status" variant="primary"/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        showWktSpinner: state.mapState.showWktSpinner
	}
};

export default connect(mapStateToProps)(WktProgressSpinner)