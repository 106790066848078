import {Ontology} from "../model/Ontology";
import {OntologyPropertiesWrapper} from "./OntologyPropertiesWrapper";
import {OntologyNamesWrapper} from "./OntologyNamesWrapper";
import {OntologyEntityTypesWrapper} from "./ontologyTypes/OntologyEntityTypesWrapper";
import {OntologyPrimitiveTypesWrapper} from "./ontologyTypes/OntologyPrimitiveTypesWrapper";
import {OntologyNameTypesWrapper} from "./ontologyTypes/OntologyNameTypesWrapper";
import {OntologyRelatePropertiesWrapper} from "./OntologyRelatePropertiesWrapper";
import {entityRelateFields, primitiveRelateFields} from "./OntologyFieldNames";

export class OntologyWrapper{
    private readonly properties: OntologyPropertiesWrapper;
    private readonly entityTypes: OntologyEntityTypesWrapper;
    private readonly primitiveTypes: OntologyPrimitiveTypesWrapper;
    private readonly nameTypes: OntologyNameTypesWrapper;
    private readonly names: OntologyNamesWrapper;
    private readonly entityRelates: OntologyRelatePropertiesWrapper;
    private readonly primitiveRelates: OntologyRelatePropertiesWrapper;

    public constructor(ontology: Ontology | null){
        this.properties = new OntologyPropertiesWrapper(ontology);
        this.entityTypes = new OntologyEntityTypesWrapper(ontology);
        this.primitiveTypes = new OntologyPrimitiveTypesWrapper(ontology);
        this.nameTypes = new OntologyNameTypesWrapper(ontology);
        this.names = new OntologyNamesWrapper(ontology);
        this.entityRelates = new OntologyRelatePropertiesWrapper(ontology, entityRelateFields);
        this.primitiveRelates = new OntologyRelatePropertiesWrapper(ontology, primitiveRelateFields);
    }

    public getPropertiesOfType(type: string | null){
        let ret: Array<string> = [];
        ret = ret.concat(this.entityTypes.getPropertiesOfType(type))
            .concat(this.primitiveTypes.getPropertiesOfType(type))
            .concat(this.nameTypes.getPropertiesOfType(type))
            .concat(this.entityRelates.getPropertiesOfType(type))
            .concat(this.primitiveRelates.getPropertiesOfType(type));
        return ret;
    }

    public getPropertiesWrapper(){
        return this.properties;
    }

    public getEntityTypesWrapper(){
        return this.entityTypes;
    }

    public getPrimitiveTypesWrapper(){
        return this.primitiveTypes;
    }

    public getNameTypesWrapper(){
        return this.nameTypes;
    }

    public getNamesWrapper(){
        return this.names;
    }
}