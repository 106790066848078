import * as React from 'react';
import {createPrimitive, findPrimitive, Primitive} from '../../../model/Primitive';
import { connect } from 'react-redux';
import {setHighlightedPrimitive, setPrimitiveCreation} from '../../../store/actions/searchEntities';
import { DisplayList } from '../../../commonComponents/DisplayList';
import Select from 'react-select';
import {Guid} from "guid-typescript";
import DropdownList from "../../../commonComponents/DropdownList";
import {ModelUtils} from "../../../utils/ModelUtils";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";
import {Button, Grid, Theme, withTheme} from "@material-ui/core";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";

type PrimitivesListEditProps = {
    primitives: Array<Primitive>,
    currentStreamId: string | null,
    highlightedEntity: string | null,
    highlightedPrimitive: string | null,
    setHighlightedPrimitive: (primitiveId: string, primitiveWkt: string) => void,
    setPrimitiveCreation: (primitiveId: string, primitive: Primitive) => void,
    theme: Theme
}

type PrimitivesListEditState = {
    primitives: Array<Primitive>,
    primitiveType: string | null,
    possibleTypes: Set<string>,
    chosenTypes: Set<string>,
    clearSelect: boolean
}

class PrimitivesListEdit extends React.Component<PrimitivesListEditProps, PrimitivesListEditState>{
    private displayList: any;

    public constructor(props){
        super(props);

        let possibleTypes: Set<string> = new Set();
        this.props.primitives.forEach((primitive: Primitive) => {
            possibleTypes.add(primitive.PrimitiveTypeKeyName);
        });

        this.state = {
            primitives: this.props.primitives,
            primitiveType: null,
            possibleTypes: possibleTypes,
            chosenTypes: new Set(),
            clearSelect: false
        };
    }

    public componentDidMount(): void {
        GlobalCallbacks.setAddPrimitiveToList(this.setNewPrimitive);
    }

    public componentWillUnmount(){
        GlobalCallbacks.resetAddPrimitiveToList();
    }

    public componentDidUpdate(prevProps){
        if(this.displayList)
            this.displayList.setHighlighted(this.props.highlightedPrimitive);

        if(!PrimitivesListEdit.compareProps(prevProps, this.props)){
            let possibleTypes: Set<string> = new Set();
            this.props.primitives.forEach((primitive) => {
                possibleTypes.add(primitive.PrimitiveTypeKeyName);
            });

            this.setState((prevState: PrimitivesListEditState) => {
                return{
                    primitives: this.props.primitives,
                    possibleTypes: possibleTypes,
                    chosenTypes: new Set(),
                    clearSelect: !prevState.clearSelect
                }
            });
        }
    }

    private static compareProps(lhs: PrimitivesListEditProps, rhs: PrimitivesListEditProps){
        if(lhs === null || rhs === null)
            return lhs === rhs;

        if(lhs.primitives === null || rhs.primitives === null)
            return lhs.primitives === rhs.primitives;

        return JSON.stringify(lhs.primitives) === JSON.stringify(rhs.primitives);
    }

    public setNewPrimitive = (primitive: Primitive) => {
        let possibleTypes: Set<string> = new Set();
        this.state.possibleTypes.forEach(type => possibleTypes.add(type));
        possibleTypes.add(primitive.PrimitiveTypeKeyName);

        let primitives: Array<Primitive> = ModelUtils.deepCopy(this.state.primitives);
        primitives.push(primitive);

        this.props.setHighlightedPrimitive(primitive.PrimitiveID, primitive.ShapeWKT);

        this.setState({
            primitives: primitives,
            possibleTypes: possibleTypes,
            chosenTypes: new Set()
        })
    };

    public setPrimitiveCreation = () => {
        let primitiveId: string = Guid.create().toString();
        console.log(this.state.primitiveType);

        let primitive: Primitive = createPrimitive(this.props.highlightedEntity ? this.props.highlightedEntity : "", primitiveId, this.state.primitiveType ? this.state.primitiveType : "");

        this.props.setPrimitiveCreation(primitiveId, primitive);
    };

    private onPrimitiveClick = (id) => {
        let primitive: Primitive | null = findPrimitive(id, this.state.primitives);
        if(this.props.highlightedPrimitive !== id && primitive) {
            this.props.setHighlightedPrimitive(id, primitive.ShapeWKT);
        }
    };

    public onPrimitiveTypeChange = (type: string) => {
        this.setState({
            primitiveType: type
        })
    };

    private handleTypeChange = (selectedOptions) => {
        let chosenTypes: Set<string>;

        if(selectedOptions !== null)
            chosenTypes = new Set(selectedOptions.map((type) => type.value));
        else
            chosenTypes = new Set();

        this.setState({
            chosenTypes: chosenTypes
        })
    };

    public render(): JSX.Element{
        let ids: Array<string> = [];
        let items: Array<string> = [];


        if(this.state.primitives !== null){
            let primitives: Array<Primitive> = this.state.primitives;

            if(this.state.chosenTypes.size > 0){
                primitives = primitives.filter(p => this.state.chosenTypes.has(p.PrimitiveTypeKeyName));
            }

            if(primitives.length > 10000){
                primitives = primitives.slice(0, 10000);
            }

            ids = primitives.map(primitive => primitive.PrimitiveID);
            items = primitives.map(primitive => (primitive.PrimitiveID + ", " + primitive.PrimitiveTypeKeyName));
        }

        let options: Array<{value: string, label: string}> = [];
        this.state.possibleTypes.forEach((type) => options.push({value: type, label: type}));

        let length: number = this.state.primitives !== null ? this.state.primitives.length : 0;
        let ontology: OntologyWrapper | null = this.props.currentStreamId !== null ? OntologyMap.getOntology(this.props.currentStreamId) : null;

        return (
            <ExpansionComponent title={"Primitives (count: " + length + ")"} componentId={"primitiveListEdit"}>
                <Select
                    key={this.state.clearSelect + ""}
                    id="selectTypesEdit"
                    isMulti
                    name="colors"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={this.handleTypeChange}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: this.props.theme.palette.secondary.main,
                            primary: this.props.theme.palette.primary.main
                        },
                    })}
                />
                {
                    this.state.primitives !== null && length > 0 &&
                        <DisplayList ref={d => { this.displayList = d; }}  ids={ids} items={items} onClick={this.onPrimitiveClick} initialHighlighted={this.props.highlightedPrimitive}/>
                }
                <Grid container direction="row">
                    <Grid item md={9}>
                        <DropdownList options={ontology !== null ? ontology.getPrimitiveTypesWrapper().getPrimitiveTypes() : []} onChange={this.onPrimitiveTypeChange} placeholder={"Choose type"} initialValue={this.state.primitiveType}/>
                    </Grid>
                    <Grid item md={3}>
                        <Button onClick={this.setPrimitiveCreation} disabled={this.state.primitiveType === null} variant="outlined" fullWidth={true}>New primitive</Button>
                    </Grid>
                </Grid>
            </ExpansionComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        highlightedEntity: state.searchResult.highlightedEntity,
        highlightedPrimitive: state.searchResult.highlightedPrimitive,
        currentStreamId: state.streamState.currentStreamId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHighlightedPrimitive: (primitiveId: string, primitiveWkt: string) => dispatch(setHighlightedPrimitive(primitiveId, primitiveWkt)),
        setPrimitiveCreation: (primitiveId: string, primitive: Primitive) => dispatch(setPrimitiveCreation(primitiveId, primitive))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(PrimitivesListEdit))