import * as React from 'react';
import SearchForm from "./SearchForm";
import EntityList from "./EntityList";
import KeptOnMapList from "./KeptOnMapList";
import {Grid, Tab, Tabs} from "@material-ui/core";
import {TabContent} from "../commonComponents/TabContent";
import {connect} from "react-redux";
import {BasicEntity} from "../model/BasicEntity";
import AllLoadedTab from "../allLoaded/AllLoadedTab";
import {LinkedHashMap} from "../utils/LinkedHashMap";
import {searchByEntityId, searchEntityFromGroup} from "../store/actions/searchEntities";

type SearchTabProps = {
    highlightedEntity: string | null,
    basicEntities: {[entityId: string]: BasicEntity},
    message: string | null,
    entitiesKeptOnMap: Set<string>,
    allLoadedEntities: Array<BasicEntity>,

    searchByEntityId: (identifierKey: string) => void,
    searchEntityFromGroup: (identifierKey) => void
}

type SearchTabState = {
    activeTab: string
}

class SearchTab extends React.Component<SearchTabProps, SearchTabState>{
    public constructor(props){
        super(props);

        this.state = {
            activeTab: "searchTab"
        }
    }

    private handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({
            activeTab: newValue
        })
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let searchTabLabel: string = "Search result (" + Object.keys(this.props.basicEntities).length + ")";
        let keptTabLabel: string = "Kept entities (" + this.props.entitiesKeptOnMap.size + ")";
        let allLoaded: string = "All loaded (" + LinkedHashMap.getLength() + ")";

        return (
            <div>
                <Grid>
                    <Grid item>
                        <div>
                            <SearchForm />
                        </div>
                    </Grid>
                    <Grid>
                        <Tabs value={this.state.activeTab} onChange={this.handleChange} variant="scrollable" scrollButtons="auto">
                            <Tab value={"searchTab"} label={searchTabLabel}/>
                            <Tab value={"keptTab"} label={keptTabLabel}/>
                            <Tab value={"allLoaded"} label={allLoaded}/>
                        </Tabs>
                        <div>
                            <TabContent value={"searchTab"} selected={this.state.activeTab}>
                                <EntityList highlightedEntity={this.props.highlightedEntity} basicEntities={this.props.basicEntities} message={this.props.message} onEntityClick={this.props.searchEntityFromGroup}/>
                            </TabContent>
                            <TabContent value={"keptTab"} selected={this.state.activeTab}>
                                <KeptOnMapList highlightedEntity={this.props.highlightedEntity} entitiesKeptOnMap={this.props.entitiesKeptOnMap}/>
                            </TabContent>
                            <TabContent value={"allLoaded"} selected={this.state.activeTab}>
                                <AllLoadedTab highlightedEntity={this.props.highlightedEntity} allLoadedEntities={this.props.allLoadedEntities} onEntityClick={this.props.searchByEntityId}/>
                            </TabContent>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        basicEntities: state.searchResult.basicEntities,
        message: state.searchResult.message,
        entitiesKeptOnMap: state.searchResult.entitiesKeptOnMap,
        allLoadedEntities: state.linkedHashMapState.allLoadedEntities
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchByEntityId: (identifierKey: string) => dispatch(searchByEntityId(identifierKey)),
        searchEntityFromGroup: (identifierKey) => dispatch(searchEntityFromGroup(identifierKey))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchTab)