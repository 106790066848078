import {Property} from "./Property";
import {EntityName} from "./EntityName";
import {Child} from "./Child";
import {Primitive} from "./Primitive";
import {Relate} from "./Relate";
import {Parent} from "./Parent";

export interface Entity{
    EntityID: string;
    EntityTypeKeyName: string;
    SourceInfo: string | null;
    Contributors: string | null;
    EntityNames: Array<EntityName>;
    EntityProperties: Array<Property>;
    Primitives: Array<Primitive>;
    EntityRelates: Array<Relate>;
    Geochain: Array<Parent>;
    Lat: number;
    Long: number;
    Zoom: number;
    Children: Array<Child>;
    Confidence: any;
    Editing: boolean;
    IsDeleted: boolean;
    Completed: boolean;
    HasProblems: boolean
}

export function createEntity(entityId: string): Entity {
    return {
        EntityID: entityId,
        EntityTypeKeyName: "",
        SourceInfo: null,
        Contributors: null,
        EntityNames: [],
        EntityProperties: [],
        Primitives: [],
        EntityRelates: [],
        Geochain: [],
        Lat: 0,
        Long: 0,
        Zoom: 0,
        Children: [],
        Confidence: null,
        Editing: false,
        IsDeleted: false,
        Completed: false,
        HasProblems: false
    };
}