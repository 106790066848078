import * as React from 'react';
import Select from 'react-select';
import {Theme, withTheme} from '@material-ui/core/styles';

type pickerPropsType = {
    options: Array<string>,
    onChange: any,
    initialValue: string | null,
    placeholder: string,
    theme: Theme
}

type pickerStateType = {
    selectedOption: {value: string, label: string} | null
}

class DropdownList extends React.Component<pickerPropsType, pickerStateType>{
    public constructor(props){
        super(props);

        let initVal: string | null = this.props.initialValue;
        this.state = {
            selectedOption: initVal !== null ? {value: initVal, label: initVal} : null
        }
    }

    static defaultProps = {
        initialValue: null,
        placeholder: ""
    };

    private handleChange = (selectedOption) => {
        this.props.onChange(selectedOption.value);
        
        this.setState({
            selectedOption: selectedOption
        })
    };

    public setValue(value: string){
        this.setState({
            selectedOption: {value: value, label: value}
        })
    }

    public render(): JSX.Element{
        let options: Array<{value: string, label: string}> = this.props.options.map(option => ({
            value: option,
            label: option
        }));

        return(
            <div>
                <Select
                    value={this.state.selectedOption}
                    onChange={this.handleChange}
                    options={options}
                    placeholder={this.props.placeholder}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: this.props.theme.palette.secondary.main,
                            primary: this.props.theme.palette.primary.main
                        },
                    })}
                />
            </div>
        )
    }
}

export default withTheme(DropdownList);
