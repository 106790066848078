import {Entity} from "./Entity";

export interface SolrEntity{
    Id: string;
    Type: string;
    Name: string;
    WktCenter: string;
    Size: number | null;
    From: number | null;
    Ent: Entity | null;
}

export function findSolrEntity(entityId: string | null, list: Array<SolrEntity>): SolrEntity | null {
    let solrEntities: Array<SolrEntity> = list.filter((solrEntity: SolrEntity) => solrEntity.Id === entityId);
    return (solrEntities.length > 0 ? solrEntities[0] : null);
}