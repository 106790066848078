import * as React from 'react';
import {BasicEntity} from "../../model/BasicEntity";
import {EntityExtension} from "../../model/EntityExtension";
import {ModelUtils} from "../../utils/ModelUtils";
import EntityTypeEdit from "./generalInformation/EntityTypeEdit";
import EntityNamesEdit from "./names/EntityNamesEdit";
import PrimitivesListEdit from "./primitives/PrimitivesListEdit";
import RelationshipsEdit from "./relations/RelationshipsEdit";
import {SourceInfo} from "./sources/SourceInfo";
import {Contributors} from "./sources/Contributors";
import {ChildrenEdit} from "./relations/ChildrenEdit";
import {PropertiesEdit} from "./properties/PropertiesEdit";
import {MapEntity} from "../../model/MapEntity";
import LabelValue from "./generalInformation/LabelValue";
import {Divider, Grid} from "@material-ui/core";
import '../../App.css';

type EntityDetailsEditProps = {
    entity: MapEntity | null
}

class EntityDetailsEdit extends React.Component<EntityDetailsEditProps, any>{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if(this.props.entity !== null) {
            let basicEntity: BasicEntity = this.props.entity.basicEntity;
            let entityExtension: EntityExtension | null = this.props.entity.entityExtension;
            if ((basicEntity === null || basicEntity === undefined) && entityExtension !== null && entityExtension !== undefined) {
                basicEntity = ModelUtils.basicEntityFromEntityExtension(entityExtension);
            }

            return (
                <div>
                    <Grid container direction="column">
                        <Grid item>
                            <LabelValue label={"EntityID"} value={basicEntity.id}/>
                        </Grid>
                        <Grid item>
                            <EntityTypeEdit type={entityExtension !== null ? entityExtension.entityTypeKeyName : basicEntity.type}/>
                        </Grid>
                        <Grid item>
                            <LabelValue label={"Name"} value={basicEntity.name}/>
                        </Grid>
                    </Grid>
                    <Divider />
                    {
                        entityExtension !== null &&
                            <div>
                                <Grid container direction="column">
                                    <Grid item className="gridItemVertical">
                                        <EntityNamesEdit names={entityExtension.entityNames}
                                                         idsList={[basicEntity.id, null, "NAME", null]}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <PrimitivesListEdit primitives={entityExtension.primitives}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <RelationshipsEdit entityRelates={entityExtension.entityRelates}
                                                           idsList={[basicEntity.id, null, "RELATIONSHIP", null]}
                                                           entityType={entityExtension.entityTypeKeyName}
                                        />
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <SourceInfo sourceInfo={entityExtension.sourceInfo} componentId={"Entity"}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <Contributors contributors={entityExtension.contributors}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <ChildrenEdit children={entityExtension.children}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <PropertiesEdit idsList={[basicEntity.id, null, "ENTITY", null]}
                                                        properties={entityExtension.properties}
                                                        componentId={"entityProperties"}
                                                        objectType={entityExtension.entityTypeKeyName}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                            </div>
                    }
                </div>
            )
        }
    }
}

export {EntityDetailsEdit}