import {createMuiTheme} from "@material-ui/core/styles";
import {amber, deepPurple, pink} from "@material-ui/core/colors";
import {Color} from "@material-ui/core";

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        tertiary: Color
    }

    interface PaletteOptions {
        tertiary?: Color
    }
}

const fontSize: number = 12;
const browserFontSize: number = fontSize/14;
export const buttonFontSize: string = '0.75rem';

const theme = createMuiTheme({
    palette: {
        primary: deepPurple,
        secondary: amber,
        tertiary: pink
    },
    typography: {
        fontSize: fontSize
    },
    spacing: 4,
    props: {
        MuiRadio: {
            size: "small"
        },
        MuiSwitch: {
            size: "small"
        }
    },
    overrides: {
        MuiFormControlLabel: {
            root: {
                "margin-left": 0,
                "margin-right": 0,
                "margin-bottom": 0
            }
        },
        MuiCardHeader: {
            title: {
                height: 0
            }
        },
        MuiTab: {
            root: {
                minWidth: 0,
                '@media (min-width: 0px)': {
                    minWidth: 0,
                    fontSize: "0.75rem" // This is fontSize from MuiTab-root. It is not possible to inherit it, so I just copied it.
                },
            }
        },
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontSize: browserFontSize + "rem"
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: fontSize
            },
            arrow: {
                color: deepPurple["500"]
            },
            tooltipPlacementTop: {
                background: deepPurple["500"]
            },
            tooltipPlacementBottom: {
                background: deepPurple["500"]
            }
        },
        MuiButton: {
            containedSizeSmall: {
                fontSize: buttonFontSize + "rem"
            }
        }
    }
});

export default theme;
