import * as React from 'react';
import {createPrimitive, Primitive} from '../../../model/Primitive';
import { connect } from 'react-redux';
import { setPrimitiveCreation } from '../../../store/actions/searchEntities';
import { DisplayList } from '../../../commonComponents/DisplayList';
import {Guid} from "guid-typescript";
import DropdownList from "../../../commonComponents/DropdownList";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";
import {Button, Grid} from "@material-ui/core";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";

type PrimitivesListCreationProps = {
    primitives: Array<Primitive>,
    callback: any,
    currentStreamId: string | null,
    creatingPrimitiveId: string | null,
    creatingEntityId: string | null,
    setPrimitiveCreation: (primitiveId: string, primitive: Primitive) => void
}

type PrimitivesListCreationState = {
    primitiveType: string | null
}

class PrimitivesListCreation extends React.Component<PrimitivesListCreationProps, PrimitivesListCreationState>{
    private displayList: any;

    public constructor(props){
        super(props);

        this.state = {
            primitiveType: null
        }
    }

    public componentDidUpdate(){
        if(this.displayList)
            this.displayList.setHighlighted(this.props.creatingPrimitiveId);
    }

    public componentDidMount(): void {
        GlobalCallbacks.setAddPrimitiveToList(this.addPrimitive);
        GlobalCallbacks.setDropPrimitiveFromList(this.dropPrimitive);
    }

    public componentWillUnmount(){
        GlobalCallbacks.resetAddPrimitiveToList();
        GlobalCallbacks.resetDropPrimitiveFromList();
    }

    public addPrimitive = (primitive: Primitive) => {
        this.props.callback(primitive, false);
    };

    public dropPrimitive = (primitive: Primitive) => {
        this.props.callback(primitive, true);
    };

    public setPrimitiveCreation = () => {
        let primitiveId: string = Guid.create().toString();

        let primitive: Primitive = createPrimitive(this.props.creatingEntityId ? this.props.creatingEntityId : "", primitiveId, this.state.primitiveType ? this.state.primitiveType : "");

        this.props.setPrimitiveCreation(primitiveId, primitive);
    };

    private onPrimitiveClick = (id) => {
        let primitive: Primitive = this.props.primitives.filter(
            (primitive) => {
                return primitive.PrimitiveID === id;
            }
        )[0];

        if(this.props.creatingPrimitiveId !== id)
            this.props.setPrimitiveCreation(id, primitive);
    };

    public onPrimitiveTypeChange = (type: string) => {
        this.setState({
            primitiveType: type
        })
    };

    public render(): JSX.Element{
        let ids: Array<string> = [];
        let items: Array<string> = [];

        if(this.props.primitives !== null){
            let primitives: Array<Primitive> = this.props.primitives;

            if(primitives.length > 10000){
                primitives = primitives.slice(0, 10000);
            }

            ids = primitives.map(primitive => primitive.PrimitiveID);
            items = primitives.map(primitive => (primitive.PrimitiveID + ", " + primitive.PrimitiveTypeKeyName));
        }

        let length: number = this.props.primitives !== null ? this.props.primitives.length : 0;
        let ontology: OntologyWrapper | null = this.props.currentStreamId !== null ? OntologyMap.getOntology(this.props.currentStreamId) : null;

        return (
            <ExpansionComponent title={"Primitives (count: " + length + ")"} componentId={"primitiveListCreation"}>
                <Grid container direction="row">
                    <Grid item md={9}>
                        <DropdownList options={ontology !== null ? ontology.getPrimitiveTypesWrapper().getPrimitiveTypes() : []} onChange={this.onPrimitiveTypeChange} placeholder={"Choose type"} initialValue={this.state.primitiveType}/>
                    </Grid>
                    <Grid item md={3}>
                        <Button onClick={this.setPrimitiveCreation} disabled={this.state.primitiveType === null} variant="outlined" fullWidth={true}>New primitive</Button>
                    </Grid>
                </Grid>
                {
                    this.props.primitives !== null && length > 0 &&
                    <DisplayList ref={d => { this.displayList = d; }}  ids={ids} items={items} onClick={this.onPrimitiveClick} initialHighlighted={this.props.creatingPrimitiveId}/>
                }
            </ExpansionComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        creatingPrimitiveId: state.searchResult.creatingPrimitiveId,
        creatingEntityId: state.searchResult.creatingEntityId,
        currentStreamId: state.streamState.currentStreamId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPrimitiveCreation: (primitiveId: string, primitive: Primitive) => dispatch(setPrimitiveCreation(primitiveId, primitive))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimitivesListCreation)