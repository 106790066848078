import {LatLngBounds} from "leaflet";
import {UrlUtils} from "../../permalink/UrlUtils";

export const changeSearchBounds = (newBounds: LatLngBounds) => {
    UrlUtils.changeParameter("mapBounds[S]", newBounds.getSouth());
    UrlUtils.changeParameter("mapBounds[N]", newBounds.getNorth());
    UrlUtils.changeParameter("mapBounds[E]", newBounds.getEast());
    UrlUtils.changeParameter("mapBounds[W]", newBounds.getWest());

    return {type:'CHANGE_SEARCH_BOUNDS', data: newBounds}
};

export const setMapBounds = (newBounds: LatLngBounds) => {
    return {type:'SET_MAP_BOUNDS', data: newBounds}
};

export const setBaseMap = (baseMapName: string) => {
    return {type: 'SET_BASE_MAP', data: baseMapName}
};

export const addWkt = (wkt: string) => {
    return {type: 'ADD_WKT', data: wkt}
};

export const addWkts = (wkts: Array<string>) => {
    return {type: 'ADD_WKTS', data: wkts}
};

export const showWktSpinner = (flag: boolean) => {
    return {type: 'SHOW_WKT_SPINNER', data: flag}
};

export const removeWkt = (id: number) => {
    return {type: 'REMOVE_WKT', data: id}
};

export const removeAllWkts = () => {
    return {type: 'REMOVE_ALL_WKTS', data: null}
};

export const changeActiveTab = (key: string) => {
    return {type: 'CHANGE_ACTIVE_TAB', data: key}
};

export const selectWktById = (wktId: string | null) => {
    return {type: 'SELECT_WKT_BY_ID', data: wktId}
};

export const selectWkt = (wkt: string | null) => {
    return {type: 'SELECT_WKT', data: wkt}
};

export const resetMapStore = () => {
    return {type: 'RESET_MAP_STORE', data: null}
};