import * as React from 'react';
import {GeoJsonObject} from "geojson";
import * as WK from "wellknown";
import {GeoJSON} from "react-leaflet";
import {CircleMarker} from "leaflet";
import {GeometryUtils} from "../../utils/GeometryUtils";
import {GeomanUtils} from "../../utils/GeomanUtils";
import {Guid} from "guid-typescript";
import {EditServerProxy} from "../../utils/EditServerProxy";
import {Direction} from "../../entityInformation/entityDetails/primitives/Direction";

type ShapeProp = {
    geometry: any,
    onClick: any,
    color: string
}

const wktParser = require('terraformer-wkt-parser');

class Shape extends React.Component<ShapeProp, any>{
    private geoJson: GeoJsonObject | null;
    private previousColor: string | null = null;
    private readonly geoJsonLayer: any;
    private isEditable: boolean = false;
    private shouldForceUpdate: boolean = false;

    public constructor(props){
        super(props);
        
        this.geoJson = null;
        this.geoJsonLayer = React.createRef();
    }

    private onEachFeature = (feature, layer) => {
        layer.on({
            click: this.props.onClick
        });
    };

    public componentDidMount(): void {
        this.previousColor = this.props.color;

        this.geoJsonLayer.current.leafletElement.on('pm:edit', () => {
            EditServerProxy.setEditingWkt(wktParser.convert(this.geoJsonLayer.current.leafletElement.toGeoJSON().features[0].geometry));
        })
    }

    public componentDidUpdate(prevProps: Readonly<ShapeProp>, prevState: Readonly<any>, snapshot?: any): void {
        if(this.isEditable)
            this.geoJsonLayer.current.leafletElement.pm.enable({limitMarkersToCount: GeomanUtils.editingMarkersLimit});

        this.geoJsonLayer.current.leafletElement.on('pm:edit', () => {
            EditServerProxy.setEditingWkt(wktParser.convert(this.geoJsonLayer.current.leafletElement.toGeoJSON().features[0].geometry));
        });

        if((this.props.color === GeometryUtils.HighlightedEntityColor && this.previousColor !== GeometryUtils.HighlightedPrimitiveColor) || this.props.color === GeometryUtils.HighlightedPrimitiveColor)
            this.geoJsonLayer.current.leafletElement.bringToFront();
        else if(this.props.color === GeometryUtils.RegularColor)
            this.geoJsonLayer.current.leafletElement.bringToBack();

        this.previousColor = this.props.color;
    }

    public setEditable(editable: boolean){
        this.isEditable = editable;

        if(this.isEditable)
            this.geoJsonLayer.current.leafletElement.pm.enable({limitMarkersToCount: GeomanUtils.editingMarkersLimit});
        else {
            this.geoJsonLayer.current.leafletElement.pm.disable();
            if(this.props.geometry !== wktParser.convert(this.geoJsonLayer.current.leafletElement.toGeoJSON().features[0].geometry)) {
                this.shouldForceUpdate = true;
                this.forceUpdate();
            }
        }
    }

    private getKey(){
        if(this.shouldForceUpdate){
            this.shouldForceUpdate = false;
            return Guid.create().toString();
        }
        else
            return this.props.geometry;
    }

    public moveLayer(direction: Direction){
        if(direction === Direction.UP)
            this.geoJsonLayer.current.leafletElement.bringToFront();
        else
            this.geoJsonLayer.current.leafletElement.bringToBack();
    }

    public render(){
        let color: string = this.props.color;

        const tmp : GeoJsonObject= WK.parse(this.props.geometry) as GeoJsonObject;
        this.geoJson = {
            "type": "Feature",
            "geometry" : tmp
        } as GeoJsonObject;

        return(
            <div>
                <GeoJSON ref={this.geoJsonLayer} key={this.getKey()} data={this.geoJson} pointToLayer={function(feature, latlng){
                    return new CircleMarker(latlng, {color: color, weight: 7, opacity: 0.5});
                }} onEachFeature={this.onEachFeature} color={this.props.color} weight={7} opacity={0.5}/>
            </div>
        )
    }
}

export default Shape