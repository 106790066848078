import * as React from 'react';
import {createEntity, Entity} from "../../model/Entity";
import {EntityName} from "../../model/EntityName";
import {Property} from "../../model/Property";
import {Relate} from "../../model/Relate";
import EntityTypeEdit from "./generalInformation/EntityTypeEdit";
import EntityNamesEdit from "./names/EntityNamesEdit";
import {ModelUtils} from "../../utils/ModelUtils";
import {Primitive} from "../../model/Primitive";
import PrimitivesListCreation from "./primitives/PrimitivesListCreation";
import {
    addBasicEntity,
    setCreatingPrimitivesList,
    setHighlightedEntity, terminateEntityCreation
} from "../../store/actions/searchEntities";
import {connect} from "react-redux";
import {PropertiesEdit} from "./properties/PropertiesEdit";
import RelationshipsEdit from "./relations/RelationshipsEdit";
import {Guid} from "guid-typescript";
import {ArrayUtils} from "../../utils/ArrayUtils";
import {setEntityToUpdate} from "../../store/actions/changeEditingState";
import {EditServerProxy} from "../../utils/EditServerProxy";
import {EntityResponse} from "../../model/EntityResponse";
import GlobalCallbacks from "../../utils/GlobalCallbacks";
import LabelValue from "./generalInformation/LabelValue";
import {Button, Divider, Grid} from "@material-ui/core";
import {registerLoadedEntity} from "../../store/actions/changeLinkedHashMap";

type NewEntityFormProps = {
    creatingEntityId: string,
    creatingEntityObject: Entity | null,
    terminateEntityCreation: any,
    setHighlightedEntity: (entityId: string) => void,
    setEntityToUpdate: any,
    setCreatingPrimitivesList: (primitives: Array<Primitive>) => void,
    addBasicEntity: any,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void
}

type NewEntityFormState = {
    entity: Entity
}

class NewEntityForm extends React.Component<NewEntityFormProps, NewEntityFormState>{
    public constructor(props){
        super(props);

        if(this.props.creatingEntityObject){
            this.state = {
                entity: this.props.creatingEntityObject
            }
        }
        else {
            this.state = {
                entity: createEntity(this.props.creatingEntityId)
            }
        }
    }

    public componentDidUpdate(prevProps: Readonly<NewEntityFormProps>, prevState: Readonly<NewEntityFormState>, snapshot?: any): void {
        if(!NewEntityForm.compareProps(prevProps, this.props)){
            if(this.props.creatingEntityObject){
                this.setState({
                    entity: this.props.creatingEntityObject
                })
            }
            else {
                this.setState({
                    entity: createEntity(this.props.creatingEntityId)});
            }
        }
    }

    private static compareProps(lhs: NewEntityFormProps, rhs: NewEntityFormProps){
        return JSON.stringify(lhs.creatingEntityObject) === JSON.stringify(rhs.creatingEntityObject);
    }

    public setEntityType = (type: string) => {
        this.setState((prevState) => {
            let tmp: Entity = ModelUtils.deepCopy(prevState.entity);
            tmp.EntityTypeKeyName = type;

            return{
                entity: tmp
            }
        })
    };

    public changeEntityName = (name: EntityName, shouldDelete: boolean) => {
        let tmp: Entity = ModelUtils.deepCopy(this.state.entity);

        let filteredNames: Array<EntityName> = tmp.EntityNames.filter(n => n.NameID === name.NameID);
        if(filteredNames.length > 0){
            tmp.EntityNames.splice(tmp.EntityNames.indexOf(filteredNames[0]), 1);
        }

        if(!shouldDelete)
            tmp.EntityNames.push(name);

        this.setState({
            entity: tmp
        })
    };

    public changeProperty = (idsList: Array<string | null>, propertyPath: Array<{key: string | null, index: number | null}>, shouldDelete: boolean, value: string | null) => {
        let properties: Array<Property> = [];
        let property: Property | null = null;
        let entity: Entity = ModelUtils.deepCopy(this.state.entity);
        let id: string | null = null;

        console.log(entity);

        switch(idsList[2]){
            case 'ENTITY':
                properties = entity.EntityProperties;
                id = idsList[0];
                break;
            case 'NAME':
                let names: Array<EntityName> = entity.EntityNames.filter(name => name.NameID === idsList[1]);
                if(names.length > 0) {
                    properties = names[0].Properties;
                    id = idsList[1];
                }
                else{

                }
                break;
            case 'RELATIONSHIP':
                let relates: Array<Relate> = entity.EntityRelates.filter(relate => relate.RelateID === idsList[1]);
                if(relates.length > 0) {
                    properties = relates[0].Properties;
                    id = idsList[1];
                }
                else{

                }
        }

        for(let i: number = 0; i < propertyPath.length; i++){
            let node: {key: string | null, index: number | null} = propertyPath[i];

            if(node.key !== null){
                if(ArrayUtils.any(properties, (property: Property) => property.PropertyKeyName === node.key)) {
                    property = properties.filter(property => property.PropertyKeyName === node.key)[0];
                }
                else{
                    if(!shouldDelete){
                        property = {
                            ID: id,
                            PropertyID: Guid.create().toString(),
                            ParentPropertyID: null,
                            PropertyKeyName: node.key,
                            Value: null,
                            SourceInfo: null,
                            Confidence: null,
                            Values: null,
                            Subproperties: null,
                            Name: node.key
                        };

                        properties.push(property);
                    }
                    else{
                        this.forceUpdate();
                        return;
                    }
                }
            }
            else {
                if(node.index !== null) {
                    if(properties[node.index]) {
                        property = properties[node.index];
                    }
                    else{
                        if(!shouldDelete){
                            property = {
                                ID: id,
                                PropertyID: Guid.create().toString(),
                                ParentPropertyID: null,
                                PropertyKeyName: null,
                                Value: null,
                                SourceInfo: null,
                                Confidence: null,
                                Values: null,
                                Subproperties: null,
                                Name: null
                            };

                            properties[node.index] = property;
                        }
                        else{
                            this.forceUpdate();
                            return;
                        }
                    }
                }
            }

            if(property !== null && i < propertyPath.length - 1) {
                let nextNode: {key: string | null, index: number | null} = propertyPath[i + 1];

                if(property.Values === null && property.Subproperties === null){
                    if(!shouldDelete) {
                        if (nextNode.index !== null)
                            property.Values = [];
                        else
                            property.Subproperties = [];
                    }
                    else{
                        this.forceUpdate();
                        return;
                    }
                }

                if (property.Values !== null) {
                    properties = property.Values;
                } else if (property.Subproperties !== null) {
                    properties = property.Subproperties;
                }
            }
        }

        if(shouldDelete && property !== null)
            properties.splice(properties.indexOf(property), 1);
        else if(!shouldDelete && property !== null) {
            property.Value = value;
        }

        if(JSON.stringify(entity) === JSON.stringify(this.state.entity)){
            console.log("jednako");
            this.forceUpdate();
        }
        else {
            console.log("razlicito");
            this.setState({
                entity: entity
            })
        }
    };

    public setNewPrimitive = (primitive: Primitive, shouldDelete: boolean = false) => {
        let entity: Entity = ModelUtils.deepCopy(this.state.entity);

        let primitives: Array<Primitive> = entity.Primitives.filter((p: Primitive) =>
            primitive.PrimitiveID === p.PrimitiveID
        );
        console.log(primitive);
        console.log(shouldDelete);
        if(primitives.length === 1){
            if(shouldDelete)
                entity.Primitives.splice(entity.Primitives.indexOf(primitives[0]), 1);
            else
                entity.Primitives.splice(entity.Primitives.indexOf(primitives[0]), 1, primitive);
        }
        else {
            if(!shouldDelete)
                entity.Primitives.push(primitive);
        }

        this.props.setCreatingPrimitivesList(entity.Primitives);

        this.setState({
            entity: entity
        })
    };

    public saveEntity = (e) => {
        e.preventDefault();

        let response: Promise<any> | null = EditServerProxy.addEntity(this.state.entity);

        if(response !== null){
            response.then(result => result.json())
                    .then((data: EntityResponse) => {
                        console.log(data);

                        if(data && data.SingleEntity){
                            this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                            console.log(this.props.creatingEntityId);
                            this.props.addBasicEntity(data.SingleEntity);
                            this.props.terminateEntityCreation();
                            this.props.setHighlightedEntity(this.props.creatingEntityId);
                        }
                        else{
                            GlobalCallbacks.displaySnackbarMessage("Change is not applied!");
                        }
                    })
        }
    };

    public cancelEntityCreation = () => {
        this.props.terminateEntityCreation();
    };

    public changeRelation = (id: string, type: string, shouldDelete: boolean) => {
        console.log("change relation");
        let entity: Entity = ModelUtils.deepCopy(this.state.entity);

        if(shouldDelete) {
            let relate: Relate = entity.EntityRelates.filter(relate => relate.ToID === id && relate.RelateTypeKeyName === type)[0];
            entity.EntityRelates.splice(entity.EntityRelates.indexOf(relate), 1);
        }
        else{
            let relate: Relate = {
                RelateTypeKeyName: type,
                RelateID: Guid.create().toString(),
                FromID: entity.EntityID,
                ToID: id,
                EditedBy: "",
                ToName: null,
                SourceInfo: "",
                Confidence: null,
                Properties: []
            };
            entity.EntityRelates.push(relate);
        }

        this.setState({
            entity: entity
        })
    };

    public render(){
        return(
            <div>
                <Grid container direction="row">
                    <Grid item md={9}>
                        <Button id="saveEntityButton" onClick={this.saveEntity} variant="contained" size="small">Save</Button>
                    </Grid>
                    <Grid item md={3}>
                        <Button id="cancelEntityButton" onClick={this.cancelEntityCreation} variant="contained" size="small">Cancel</Button>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container direction="column">
                    <Grid item>
                        <LabelValue label={"EntityID"} value={this.props.creatingEntityId}/>
                    </Grid>
                    <Grid item>
                        <EntityTypeEdit type={this.state.entity.EntityTypeKeyName} callback={this.setEntityType}/>
                    </Grid>
                    <Grid item>
                        <EntityNamesEdit names={this.state.entity.EntityNames} idsList={[this.state.entity.EntityID, null, "NAME", null]} callback={this.changeEntityName} propCallback={this.changeProperty} entityId={this.state.entity.EntityID}/>
                    </Grid>
                    <Grid item>
                        <PrimitivesListCreation primitives={this.state.entity.Primitives} callback={this.setNewPrimitive}/>
                    </Grid>
                    <Grid item>
                        <PropertiesEdit idsList={[this.state.entity.EntityID, null, "ENTITY", null]} properties={this.state.entity.EntityProperties} componentId={"entityProperties"} callback={this.changeProperty} objectType={this.state.entity.EntityTypeKeyName}/>
                    </Grid>
                    <Grid item>
                        <RelationshipsEdit idsList={[this.state.entity.EntityID, null, "RELATIONSHIP", null]} entityRelates={this.state.entity.EntityRelates} entityType={this.state.entity.EntityTypeKeyName} callback={this.changeRelation} propCallback={this.changeProperty} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        creatingEntityObject: state.searchResult.creatingEntityObject
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        terminateEntityCreation: () => dispatch(terminateEntityCreation()),
        setHighlightedEntity: (entityId: string) => dispatch(setHighlightedEntity(entityId)),
        setEntityToUpdate: (entityId: string | null) => dispatch(setEntityToUpdate(entityId)),
        setCreatingPrimitivesList: (primitives: Array<Primitive>) => dispatch(setCreatingPrimitivesList(primitives)),
        addBasicEntity: (entity: Entity) => dispatch(addBasicEntity(entity)),
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewEntityForm)