import { MapEntity } from '../../../model/MapEntity';
import { Primitive } from '../../../model/Primitive';
import { DetailsFactory } from './DetailsFactory';
import ToggleSingleEntityView from "../../general/ToggleSingleEntityView";
import ToggleKeepOnMap from "../../general/ToggleKeepOnMap";
import ToggleEditMode from '../../general/ToggleEditMode';
import * as React from 'react';
import PrimitiveDetails from '../primitives/PrimitiveDetails';
import {EntityDetails} from "../EntityDetails";
import {Divider, Grid} from "@material-ui/core";
import CloneEntity from "../../general/CloneEntity";
import {EntityExtension} from "../../../model/EntityExtension";

export class InfoDetailsFactory extends DetailsFactory{
    public getToggles(entity: MapEntity | null){
        if(entity !== null) {
            let entityExtension: EntityExtension | null = entity.entityExtension;

            return (
                <div>
                    <Grid container direction="row">
                        <Grid item md={4}>
                            <ToggleSingleEntityView/>
                        </Grid>
                        <Grid item md={4}>
                            <ToggleKeepOnMap entityId={entity.basicEntity.id}/>
                        </Grid>
                        <Grid item md={4}>
                            <ToggleEditMode/>
                        </Grid>
                    </Grid>
                    {
                        entityExtension &&
                        <CloneEntity entity={entityExtension}/>
                    }
                    <Divider/>
                </div>
            )
        }
    }

    public getEntityInfo(entity: MapEntity | null = null, entityId: string | null = null){
        return (
            <EntityDetails entity={entity} />
        )
    }

    public getPrimitiveInfo(primitive: Primitive){
        return(
            <PrimitiveDetails primitive={primitive}/>
        )
    }
}