import * as React from 'react';
import {connect} from "react-redux";
import {MapState} from "../../mapState/MapState";
import {MapStateWrapper} from "../../mapState/MapStateWrapper";
import {setMapState, updateInformationTab} from "../../store/actions/searchEntities";
import {EditServerProxy} from "../../utils/EditServerProxy";
import GlobalCallbacks from "../../utils/GlobalCallbacks";
import {EntityResponse} from "../../model/EntityResponse";
import {ModelUtils} from "../../utils/ModelUtils";
import {registerLoadedEntity} from "../../store/actions/changeLinkedHashMap";
import {setEntityToUpdate} from "../../store/actions/changeEditingState";
import * as L from "leaflet";
import {CircleMarker, LatLng} from "leaflet";
import {GeometryUtils} from "../../utils/GeometryUtils";
import {Entity} from "../../model/Entity";

type SplitPrimitiveProps = {
    getMapReference: any,
    mapState: MapState,
    setMapState: (mapState: MapState) => void,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void,
    setEntityToUpdate: (entityId: string | null) => void,
    updateInformationTab: () => void
}

class SplitPrimitiveListener extends React.Component<SplitPrimitiveProps, any>{
    private splitStateActive: boolean = false;
    private mapReference: any;
    private marker: CircleMarker | null = null;

    public componentDidMount(): void {
        this.mapReference = this.props.getMapReference();
    }

    public componentDidUpdate(prevProps: Readonly<SplitPrimitiveProps>, prevState: Readonly<any>, snapshot?: any): void {
        if(MapStateWrapper.isSplittingState(this.props.mapState)) {
            this.startSplitting();
        }
        else if(this.splitStateActive){
            this.endSplitting();
        }
    }

    private splitPrimitive(point: LatLng){
        if(GeometryUtils.isFirstOrLastPoint(EditServerProxy.getEditingWkt(), point.lng, point.lat)){
            GlobalCallbacks.displaySnackbarMessage("Split point can't be the first nor the last point!");
            return;
        }

        let response: Promise<any> | null = EditServerProxy.splitPrimitive(point.lng, point.lat);

        if(response){
            response.then(result => result.json())
                .then((data: EntityResponse) => {
                    console.log(data);
                    if (data && data.SingleEntity) {
                        this.processResponse(data.SingleEntity);
                    } else {
                        GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                    }
                })
        }
        else{
            GlobalCallbacks.displaySnackbarMessage("Primitive is not properly selected!");
        }
    }

    private processResponse(entity: Entity){
        this.props.registerLoadedEntity(entity.EntityID, ModelUtils.mapEntityFromEntity(entity, 1), false);
        this.props.setEntityToUpdate(entity.EntityID);
        this.props.updateInformationTab();
        this.endSplitting();
        this.props.setMapState(MapState.Editing);
    }

    public startSplitting = () => {
        let midPoint = GeometryUtils.interpolateOnLine(this.mapReference, EditServerProxy.getEditingWkt(), 0.5);
        this.marker = new L.CircleMarker(new LatLng(midPoint.latLng.lng, midPoint.latLng.lat), GeometryUtils.splittingPointOptions);
        this.marker.addTo(this.mapReference);

        this.mapReference.on('mousemove', this.onMouseMove);
        this.mapReference.on('click', this.onClick);
        this.splitStateActive = true;
    };

    private onMouseMove = (e) => {
        let closestLatLngOnGeometry = GeometryUtils.closest(this.mapReference, EditServerProxy.getEditingWkt(), [e.latlng.lng, e.latlng.lat], null);
        if(this.marker)
            this.marker.setLatLng(new LatLng(closestLatLngOnGeometry.lng, closestLatLngOnGeometry.lat));
    };

    private onClick = (e) => {
        this.splitPrimitive(GeometryUtils.closest(this.mapReference, EditServerProxy.getEditingWkt(), [e.latlng.lng, e.latlng.lat], null));
    };

    private endSplitting = () => {
        this.mapReference.off('mousemove', this.onMouseMove);
        this.mapReference.off('click', this.onClick);
        this.splitStateActive = false;

        if(this.marker)
            this.marker.remove();
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mapState: state.searchResult.mapState
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMapState: (mapState: MapState) => dispatch(setMapState(mapState)),
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced)),
        setEntityToUpdate: (entityId: string | null) => dispatch(setEntityToUpdate(entityId)),
        updateInformationTab: () => dispatch(updateInformationTab())
    }
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef:true})(SplitPrimitiveListener)