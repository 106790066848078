import * as React from 'react';
import {Property} from "../../../model/Property";
import './properties.css';
import '../relations/LinkButton.css';
import {Button, Typography} from "@material-ui/core";
import ValuesForm from "./ValuesForm";
import {connect} from "react-redux";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";
import {PropertyType} from "./PropertyType";
import SubpropertiesForm from "./SubpropertiesForm";

export type PropertiesPath = {
    properties: Array<Property>,
    path: Array<{key: string | null, index: number | null}>
}

 export type PropertyPath = {
    property: Property,
    path: Array<{key: string | null, index: number | null}>
}

type PropertyValueProps = {
    propertyPath: PropertyPath,
    onDelete: (propertyPath: Array<{key: string | null, index: number | null}>) => void,
    onSave: (propertyPath: Array<{key: string | null, index: number | null}>, value: string) => void,
    currentStreamId: string | null
}

type PropertyValueState = {
    isOpen: boolean
}

class PropertyValueDisplayEdit extends React.Component<PropertyValueProps, PropertyValueState>{
    public constructor(props){
        super(props);

        this.state = {
            isOpen: false
        }
    }

    public openDialog = () => {
        this.setState({
            isOpen: true
        })
    };

    private generateValue(propertyPath: PropertyPath): JSX.Element{
        let property: Property = propertyPath.property;

        let ontology: OntologyWrapper = OntologyMap.getOntology(this.props.currentStreamId);

        let propertyType: PropertyType = ontology.getPropertiesWrapper().getPropertyType(property.PropertyKeyName);

        if (propertyType === PropertyType.Value) {
            return (<Typography>{property.Value}</Typography>);
        } else if(propertyType === PropertyType.Values || propertyType === PropertyType.Subproperties) {
            return (
                <Button onClick={this.openDialog} variant="outlined" size="small"
                        style={{textTransform: 'none'}}>Open
                    to edit</Button>);
        }

        return <div/>;
    }

    private getDialogFromType(propertyPath: PropertyPath): JSX.Element{
        let ontology: OntologyWrapper = OntologyMap.getOntology(this.props.currentStreamId);
        let property: Property = propertyPath.property;

        let propertyType: PropertyType = ontology.getPropertiesWrapper().getPropertyType(property.PropertyKeyName);
        if(propertyType === PropertyType.Values)
            return <ValuesForm propertyPath={this.props.propertyPath} onDelete={this.props.onDelete} onSave={this.props.onSave} onClose={() => {console.log('closed'); this.setState({isOpen: false})}}/>;
        else
            return <SubpropertiesForm propertyPath={this.props.propertyPath} onDelete={this.props.onDelete} onSave={this.props.onSave} onClose={() => {console.log('closed'); this.setState({isOpen: false})}}/>
    }

    public render(): JSX.Element{
        return(
            <div>
                {this.generateValue(this.props.propertyPath)}
                {this.state.isOpen &&
                    this.getDialogFromType(this.props.propertyPath)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStreamId: state.streamState.currentStreamId
    }
};

export default connect(mapStateToProps)(PropertyValueDisplayEdit)