import * as React from 'react';
import {Property} from "../../../model/Property";
import PropertiesTableEdit from "./PropertiesTableEdit";
import {EditServerProxy} from "../../../utils/EditServerProxy";
import {EntityResponse} from "../../../model/EntityResponse";
import {Relate} from "../../../model/Relate";
import {EntityName} from "../../../model/EntityName";
import {Primitive} from "../../../model/Primitive";
import {ModelUtils} from "../../../utils/ModelUtils";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {Button} from "@material-ui/core";
import {registerLoadedEntity} from "../../../store/actions/changeLinkedHashMap";
import {connect} from "react-redux";

type PropertiesPath = {
    properties: Array<Property>,
    path: Array<{key: string | null, index: number | null}>
}

type PropertiesViewProps = {
    properties: Array<Property>,
    idsList: Array<string | null>,
    goBackCallback: any,
    callback: any,
    objectType: string,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void
}

type PropertiesViewState = {
    propertiesPath: PropertiesPath
}

class PropertiesViewEdit extends React.Component<PropertiesViewProps, PropertiesViewState>{
    public constructor(props){
        super(props);

        let properties: Array<Property> = this.props.properties ? this.props.properties : [];

        this.state = {
            propertiesPath: {properties: ModelUtils.deepCopy(properties), path: []},
        }
    }

    static defaultProps = {
        goBackCallback: null,
        callback: null
    };

    public componentDidUpdate(prevProps: PropertiesViewProps){
        if(!this.compareProps(prevProps, this.props)){
            let properties: Array<Property> = this.props.properties ? this.props.properties : [];

            this.setState({
                propertiesPath: {properties: properties, path: []}
            })
        }
    }

    private compareProps(lhs: PropertiesViewProps, rhs: PropertiesViewProps){
        if(lhs === null || rhs === null)
            return lhs === rhs;

        if(lhs.properties === null || rhs.properties === null)
            return rhs.properties === lhs.properties;
            
        return JSON.stringify(lhs.properties.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.properties.map(p => JSON.stringify(p)).sort());
    }

    public processResponse = (data: EntityResponse) => {
        let properties: Array<Property> = [];

        if(data && data.SingleEntity) {
            this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);

            switch (this.props.idsList[2]) {
                case 'PRIMITIVE':
                    let primitive: Primitive = data.SingleEntity.Primitives.filter((prim: Primitive) => prim.PrimitiveID === this.props.idsList[1])[0];
                    if (this.props.idsList[3]) {
                        properties = primitive.Relationships.filter((relationship: Relate) => relationship.ToID === this.props.idsList[3])[0].Properties;
                    } else {
                        properties = primitive.PrimitiveProperties;
                    }
                    break;
                case 'NAME':
                    properties = data.SingleEntity.EntityNames.filter((name: EntityName) => name.NameID === this.props.idsList[1])[0].Properties;
                    break;
                case 'RELATIONSHIP':
                    properties = data.SingleEntity.EntityRelates.filter((relate: Relate) => relate.ToID === this.props.idsList[1])[0].Properties;
                    break;
                case 'ENTITY':
                    properties = data.SingleEntity.EntityProperties;
            }

            properties = properties ? properties : [];
            this.setState({
                propertiesPath: {properties: properties, path: []}
            });

            GlobalCallbacks.displaySnackbarMessage("Property changed");
        }
        else{
            GlobalCallbacks.displaySnackbarMessage("Change is not applied!");
        }
    };

    public saveProperty = (propertyPath: Array<{key: string | null, index: number | null}>, value: string) => {
        if(this.props.callback === null) {
            let response: Promise<any> | null = EditServerProxy.changeProperty(this.props.idsList, propertyPath, false, value);
            if (response !== null) {
                response.then(result => result.json())
                    .then(
                        (data: EntityResponse) => {
                            this.processResponse(data);
                        });
            }
        }
        else{
            this.props.callback(this.props.idsList, propertyPath, false, value);
        }
    };

    public onPropertyDeletion = (propertyPath: Array<{key: string | null, index: number | null}>) => {
        if (this.props.callback === null) {
            let response: Promise<any> | null = EditServerProxy.changeProperty(this.props.idsList, propertyPath, true, null);
            if (response !== null) {
                response.then(result => result.json())
                    .then(
                        (data: EntityResponse) => {
                            this.processResponse(data);
                        });
            }
        } else {
            this.props.callback(this.props.idsList, propertyPath, true, null);
        }
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let propertiesPath: PropertiesPath = this.state.propertiesPath;
        return(
            <div>
                {
                    this.props.goBackCallback !== null &&
                    <Button onClick={this.props.goBackCallback} fullWidth={true} variant="outlined" size={"small"}>Go back</Button>
                }
                {
                    <PropertiesTableEdit propertiesPath={propertiesPath} onPropertyDeletion={this.onPropertyDeletion} onPropertySave={this.saveProperty} objectType={this.props.objectType}/>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced))
    }
};

export default connect(null, mapDispatchToProps)(PropertiesViewEdit)