import {Queue} from './Queue';
import GlobalCallbacks from "./GlobalCallbacks";
import {BasicEntity} from "../model/BasicEntity";

const sizeof = require('object-sizeof');

export class LinkedHashMap{
    private static map = {};
    private static queue = new Queue();

    private static sizeLimit = 20000000000;
    private static size = 0;

    private static keptEntities: Set<string> = new Set();

    private static highlightedEntity: string | null = null;

    public static getLength(): number{
        return Object.keys(this.map).length;
    }

    public static getAllBasicEntities(): Array<BasicEntity>{
        return Object.keys(this.map).map(id => this.map[id].item.basicEntity);
    }

    public static setItem(id: string, item: any, reduced: boolean, enforce: boolean = true){
        if(this.contains(id) && !enforce){
            return;
        }

        let itemSize: number = sizeof(item);
        let requiredSpace = itemSize + this.size - this.sizeLimit;

        if(requiredSpace > 0){
            if(!this.freeUpSpace(requiredSpace)){
                GlobalCallbacks.displaySnackbarMessage("There is not enough space!");
            }
        }

        if(this.contains(id))
            this.queue.enqueue({id: id, size: itemSize});
        this.map[id] = { item, reduced};
        this.size = sizeof(this.map);
    }

    private static freeUpSpace(requiredSpace: number){
        let leftToFreeUp: number = requiredSpace;

        while(leftToFreeUp > 0){
            let nextItem = this.queue.dequeue();
            if(this.keptEntities.has(nextItem['id']) || this.highlightedEntity === nextItem['id']){
                this.queue.enqueue(nextItem);
                continue;
            }

            let id: number = nextItem['id'];
            delete this.map[id];
            this.size -= nextItem.size;
            leftToFreeUp -= nextItem.size;
        }

        return leftToFreeUp <= 0;
    }

    public static getItem(id: string){
        if(this.map[id])
            return (this.map[id])['item'];
        else
            return null;
    }

    public static contains(id: string){
        return this.map[id] !== undefined && this.map[id] !== null;
    }

    public static containsWholeItem(id: string){
        return this.contains(id) && !(this.map[id])['reduced'];
    }

    public static clear(){
        this.map = {};
        this.queue = new Queue();
        this.size = 0;
    }

    public static addKeptEntity(id: string){
        this.keptEntities.add(id);
    }

    public static removeKeptEntity(id: string){
        this.keptEntities.delete(id);
    }

    public static removeAllKeptEntities(){
        this.keptEntities = new Set();
    }

    public static setHighlightedEntity(entityId: string | null){
        this.highlightedEntity = entityId;
    }
}