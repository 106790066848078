import {ModelUtils} from "../utils/ModelUtils";

const queryString = require('query-string');
const buildUrl = require('build-url');

export class UrlUtils{
    public static changeParameter(key: string, value: any){
        let parameters: {[key: string]: string} = queryString.parse(window.location.search);
        parameters[key] = value;
        parameters = ModelUtils.deepCopy(parameters);
        let newUrl: string = (
            buildUrl("/", {
                queryParams: parameters
            })
        );

        window.history.pushState({}, "", newUrl);
    }

    public static removeParameter(key: string){
        let parameters: {[key: string]: string} = queryString.parse(window.location.search);
        delete parameters[key];
        parameters = ModelUtils.deepCopy(parameters);
        let newUrl: string = (
            buildUrl("/", {
                queryParams: parameters
            })
        );

        window.history.pushState({}, "", newUrl);
    }

    public static removeParameters(keys: Array<string>){
        let parameters: {[key: string]: string} = queryString.parse(window.location.search);

        keys.forEach((key: string) => {
            delete parameters[key];
        });

        parameters = ModelUtils.deepCopy(parameters);
        let newUrl: string = (
            buildUrl("/", {
                queryParams: parameters
            })
        );

        window.history.pushState({}, "", newUrl);
    }
}