import * as React from 'react';
import DropdownList from '../../../commonComponents/DropdownList';
import { EditServerProxy } from '../../../utils/EditServerProxy';
import { EntityResponse } from '../../../model/EntityResponse';
import {ModelUtils} from "../../../utils/ModelUtils";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {connect} from "react-redux";
import {OntologyMap} from "../../../ontology/OntologyMap";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {Grid} from "@material-ui/core";
import {registerLoadedEntity} from "../../../store/actions/changeLinkedHashMap";

type EntityTypeEditProps = {
    type: string,
    callback: any,
    currentStreamId: string | null,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void
}

class EntityTypeEdit extends React.Component<EntityTypeEditProps, any>{
    private reference: any;

    static defaultProps = {
        callback: null
    };

    public onChange = (newType: string) => {
        if(this.props.callback === null) {
            let response: Promise<any> | null = EditServerProxy.changeType(newType);

            if(response !== null) {
                response.then(result => result.json())
                    .then(
                        (data: EntityResponse) => {
                            if (data && data.SingleEntity) {
                                if (data.SingleEntity.EntityTypeKeyName === newType) {
                                    GlobalCallbacks.displaySnackbarMessage("Type changed");
                                    this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                                }
                                else
                                    GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                            } else {
                                GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                            }
                        });
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("Editing entity is not selected!");
            }
        }
        else{
            this.props.callback(newType);
        }
    };

    public componentDidUpdate(){
        this.reference.setValue(this.props.type);
    }

    public render(): JSX.Element{
        let ontology: OntologyWrapper | null = this.props.currentStreamId !== null ? OntologyMap.getOntology(this.props.currentStreamId) : null;

        return(
            <Grid container direction="row">
                <Grid item md={2}>Type</Grid>
                <Grid item md={10}>
                    {
                        <DropdownList ref={e => {
                            this.reference = e
                        }} options={ontology !== null ? ontology.getEntityTypesWrapper().getEntityTypes() : []} onChange={this.onChange}
                                      initialValue={this.props.type}/>
                    }
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStreamId: state.streamState.currentStreamId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityTypeEdit)