import * as React from 'react';
import {Grid, Typography} from "@material-ui/core";

type LabelValueProps = {
    label: string,
    value: string
}

class LabelValue extends React.Component<LabelValueProps, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div>
                <Grid container direction="row">
                    <Grid item md={2}>
                        <Typography>
                            {this.props.label}
                        </Typography>
                    </Grid>
                    {
                        this.props.value &&
                        <Grid item md={10}>
                            <Typography>
                                {this.props.value}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        )
    }
}

export default LabelValue