import * as React from 'react';
import {Property} from "../../../model/Property";
import PropertiesViewEdit from "./PropertiesViewEdit";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type PropertiesViewProps = {
    properties: Array<Property>,
    componentId: string,
    idsList: Array<string | null>,
    callback: any,
    objectType: string
}

class PropertiesEdit extends React.Component<PropertiesViewProps, any>{
    static defaultProps = {
        callback: null
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let length: number = this.props.properties ? this.props.properties.length : 0;
        return(
            <ExpansionComponent title={"Properties (count: " + length + ")"} componentId={this.props.componentId}>
                <PropertiesViewEdit properties={this.props.properties} idsList={this.props.idsList} callback={this.props.callback} objectType={this.props.objectType}/>
            </ExpansionComponent>
        )
    }
}

export {PropertiesEdit}