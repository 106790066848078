import {LinkedHashMap} from "../../utils/LinkedHashMap";
import {BasicEntity} from "../../model/BasicEntity";

export const registerLoadedEntity = (id: string, item: any, reduced: boolean, enforce: boolean = true) => {
    LinkedHashMap.setItem(id, item, reduced, enforce);
    let allLoadedEntities: Array<BasicEntity> = LinkedHashMap.getAllBasicEntities();
    return {type:'SET_ALL_LOADED', data: allLoadedEntities}
};

export const clearLoadedEntities = () => {
    LinkedHashMap.clear();
    let allLoadedEntities: Array<BasicEntity> = LinkedHashMap.getAllBasicEntities();
    return {type:'SET_ALL_LOADED', data: allLoadedEntities}
};