import * as React from 'react';
import {connect} from "react-redux";
import {Primitive} from "../../model/Primitive";
import Shape from "../views/Shape";
import {GeometryUtils} from "../../utils/GeometryUtils";
import {setPrimitiveCreation} from "../../store/actions/searchEntities";
import {Guid} from "guid-typescript";

type CreatingEntityProps = {
    creatingPrimitivesList: Array<Primitive>,
    creatingPrimitiveId: string | null,
    setPrimitiveCreation: (primitiveId: string, primitive: Primitive) => void
}

class CreatingEntity extends React.Component<CreatingEntityProps, any>{
    private editablePrimitiveId: string | null = null;
    private primitivesMap: {[primitiveId: string]: any} = {};

    public componentDidUpdate(prevProps: Readonly<CreatingEntityProps>, prevState: Readonly<any>, snapshot?: any) {
        if(this.editablePrimitiveId && this.primitivesMap[this.editablePrimitiveId])
            this.primitivesMap[this.editablePrimitiveId].setEditable(false);

        if(this.props.creatingPrimitiveId && this.primitivesMap[this.props.creatingPrimitiveId])
            this.primitivesMap[this.props.creatingPrimitiveId].setEditable(true);

        this.editablePrimitiveId = this.props.creatingPrimitiveId;
    }

    public onClick = (primitiveId: string, primitive: Primitive) => {
        if(this.props.creatingPrimitiveId !== primitiveId)
            this.props.setPrimitiveCreation(primitiveId, primitive);
    };

    private createPrimitives(primitives: Array<Primitive>): JSX.Element[]{
        let shapes: JSX.Element[] = [];

        primitives.forEach((primitive: Primitive) => shapes.push(
            <Shape ref={p => {this.primitivesMap[primitive.PrimitiveID] = p}} key={Guid.create().toString()} geometry={primitive.ShapeWKT} onClick={() => this.onClick(primitive.PrimitiveID, primitive)} color={GeometryUtils.CreatingColor} />
        ));

        return shapes;
    }

    public render() {
        return(
            <div>
                {
                    this.props.creatingPrimitivesList.length > 0 &&
                        this.createPrimitives(this.props.creatingPrimitivesList)
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        creatingPrimitivesList: state.searchResult.creatingPrimitivesList,
        creatingPrimitiveId: state.searchResult.creatingPrimitiveId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPrimitiveCreation: (primitiveId: string, primitive: Primitive) => dispatch(setPrimitiveCreation(primitiveId, primitive))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatingEntity)