import * as React from 'react';
import {PrimitivesGroup} from './PrimitivesGroup';
import {NumberMarker} from '../views/NumberMarker';
import { connect } from 'react-redux';
import {handlePrimitiveClick, searchEntityFromGroup} from '../../store/actions/searchEntities';
import { MapEntity } from '../../model/MapEntity';
import { BasicEntity } from '../../model/BasicEntity';
import { EntityExtension } from '../../model/EntityExtension';
import {Direction} from "../../entityInformation/entityDetails/primitives/Direction";

type EntityProps = {
    searchEntityFromGroup: (identifierKey) => void,
    mapEntity: MapEntity,
    handlePrimitiveClick: (entityId: string, primitiveId: string, primitiveWkt: string) => void,
    showMarker: boolean
}

type EntityState = {
    mapEntity: MapEntity
}

class EntityComponent extends React.Component<EntityProps, EntityState>{
    private primitivesGroup : any;
    private numberMarker: any;

    private highlighted: boolean = false;
    private primitiveId: string | null = null;

    private shouldHighlight: boolean = false;
    private isEdit: boolean = false;

    public constructor(props){
        super(props);

        this.state = {
            mapEntity: this.props.mapEntity
        }
    }

    public updateNumber(index: number, isKept: boolean = false, isEditable = false){
        this.numberMarker.updateNumber(index, isKept, isEditable);
    }

    public componentDidUpdate(){
        if(this.primitivesGroup !== undefined && this.shouldHighlight){
            this.shouldHighlight = false;
            this.primitivesGroup.setHighlight(this.highlighted, this.primitiveId, this.isEdit);
        }
    }

    public isExtensionSet(){
        return this.state.mapEntity.entityExtension !== null;
    }

    public setHighlight(primitiveId: string | null, isEdit: boolean){
        this.highlighted = true;
        this.primitiveId = primitiveId;

        if(this.primitivesGroup !== undefined)
            this.primitivesGroup.setHighlight(this.highlighted, this.primitiveId, isEdit);
        else {
            this.isEdit = isEdit;
            this.shouldHighlight = true;
        }
    }

    public resetHighlight(){
        this.highlighted = false;
        this.primitiveId = null;
        
        if(this.primitivesGroup !== undefined)
            this.primitivesGroup.setHighlight(this.highlighted, this.primitiveId, false);
        else {
            this.shouldHighlight = true;
            this.isEdit = false;
        }
    }

    public setEdit(primitiveToEdit: string | null){
        this.primitivesGroup.setEdit(primitiveToEdit);
    }

    public showMarkers(flag: boolean){
        this.numberMarker.showMarker(flag);
    }

    public setEntity(entityExtension: EntityExtension, enforce: boolean = false) {
        let basicEntity: BasicEntity = this.state.mapEntity.basicEntity;

        if(this.state.mapEntity.entityExtension === null || enforce){
            this.setState({
                mapEntity: {
                    basicEntity: basicEntity,
                    entityExtension: entityExtension
                }
            })
        }
    }

    public handleMarkerClick(){
        this.props.searchEntityFromGroup(this.state.mapEntity.basicEntity.id);
        window.console.log("Clicked on marker " + (this.state.mapEntity.basicEntity.id));
    }

    public handlePrimitiveClick(primitiveId: string, primitiveWkt: string){
        let entityExtension: EntityExtension | null = this.state.mapEntity.entityExtension;
        let basicEntity: BasicEntity = this.state.mapEntity.basicEntity;

        let id = entityExtension !== null ? entityExtension.entityId : basicEntity.id;
        this.props.handlePrimitiveClick(id, primitiveId, primitiveWkt);
    }

    public moveLayer(primitiveId: string, direction: Direction){
        this.primitivesGroup.moveLayer(primitiveId, direction);
    }

    public render(): JSX.Element{
        let basicEntity: BasicEntity = this.state.mapEntity.basicEntity;
        let entityExtension: EntityExtension | null = this.state.mapEntity.entityExtension;

        return(
            <div>
                {basicEntity !== null && <NumberMarker ref={n => { this.numberMarker = n; }} position={[basicEntity.center.lat, basicEntity.center.long]} index={basicEntity.index} onClick={() => this.handleMarkerClick()} showMarker={this.props.showMarker}/>}
                {entityExtension !== null && entityExtension !== undefined && <PrimitivesGroup ref={p => { this.primitivesGroup = p; }} primitives={entityExtension.primitives} onClick={(id, wkt) => this.handlePrimitiveClick(id, wkt)}/>}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchEntityFromGroup: (identifierKey) => dispatch(searchEntityFromGroup(identifierKey)),
        handlePrimitiveClick: (entityId: string, primitiveId: string, primitiveWkt: string) => dispatch(handlePrimitiveClick(entityId, primitiveId, primitiveWkt))
    }
};

export default connect(null, mapDispatchToProps, null, {forwardRef:true})(EntityComponent)