import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {PropertiesPath, PropertyPath} from "./PropertyValueDisplayEdit";
import {Property} from "../../../model/Property";
import PropertiesTableEdit from "./PropertiesTableEdit";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";

type SubpropertiesFormProps = {
    propertyPath: PropertyPath,
    onDelete: (propertyPath: Array<{key: string | null, index: number | null}>) => void,
    onSave: (propertyPath: Array<{key: string | null, index: number | null}>, value: string) => void,
    onClose: any
}

class SubpropertiesForm extends React.Component<SubpropertiesFormProps, any>{
    private subpropertiesCount: number = 0;
    private newPropertySet: Array<Property> | null = null;
    private newPropertySetRendered: boolean = false;

    public deletePropertySet = (index: number) => {
        if(index === this.subpropertiesCount)
            this.forceUpdate();
        else {
            let path: Array<{key: string | null, index: number | null}> = this.props.propertyPath.path.slice();
            path.push({key: null, index: index});
            this.props.onDelete(path);
        }
    };

    private generatePropertySets(properties: Array<Property>, parentPath: Array<{key: string | null, index: number | null}>, parentProperty: Property){
        let returnValue: Array<JSX.Element> = [];

        properties.forEach((property: Property, index: number) => {
            let path = parentPath.slice();
            path.push({key: null, index: index});

            let propertiesPath: PropertiesPath = {properties: property.Subproperties ? property.Subproperties : [], path: path};
            returnValue.push(<PropertiesTableEdit propertiesPath={propertiesPath} onPropertyDeletion={this.props.onDelete} onPropertySave={this.props.onSave} parentProperty={parentProperty.PropertyKeyName} deletePropertySet={() => this.deletePropertySet(index)}/>);
        });

        return returnValue;
    }

    private generateNewPropertySet(displayComponent: Array<JSX.Element>, parentPath: Array<{key: string | null, index: number | null}>, parentProperty: Property){
        if(this.newPropertySet !== null){
            let path: Array<{key: string | null, index: number | null}> = parentPath.slice();
            path.push({key: null, index: this.subpropertiesCount});
            let propertiesPath: PropertiesPath = {properties: this.newPropertySet, path: path};
            displayComponent.push(<PropertiesTableEdit propertiesPath={propertiesPath} onPropertyDeletion={this.props.onDelete} onPropertySave={this.props.onSave} parentProperty={parentProperty.PropertyKeyName} deletePropertySet={() => this.deletePropertySet(this.subpropertiesCount)}/>);
            this.newPropertySet = null;
            this.newPropertySetRendered = true;
        }
        else{
            this.newPropertySetRendered = false;
        }
    }

    private generateComponent(): Array<JSX.Element>{
        let displayComponent: Array<JSX.Element>;
        let parentProperty: Property = this.props.propertyPath.property;
        let parentPath: any = this.props.propertyPath.path;
        let properties: Array<Property> = [];

        if(parentProperty.Subproperties){
            properties.push(parentProperty);
        }
        else if(parentProperty.Values){
            properties = parentProperty.Values;
        }

        this.subpropertiesCount = properties.length;
        displayComponent = this.generatePropertySets(properties, parentPath, parentProperty);

        this.generateNewPropertySet(displayComponent, parentPath, parentProperty);

        return displayComponent;
    }

    public addPropertySet = () => {
        if(this.newPropertySetRendered) {
            GlobalCallbacks.displaySnackbarMessage("There is already one empty property set!");
            return;
        }

        this.newPropertySet = [];
        this.forceUpdate();
    };

    public closeDialog = () => {
        this.props.onClose();
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let property: Property = this.props.propertyPath.property;

        return(
            <div>
                <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle>
                        {property.PropertyKeyName ? property.PropertyKeyName : ""}
                    </DialogTitle>
                    <DialogContent>
                        {this.generateComponent()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.addPropertySet} variant="outlined" color="primary">
                            Add property set
                        </Button>
                        <Button onClick={this.closeDialog} variant="outlined" color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default SubpropertiesForm