import * as React from "react";
import {Primitive} from "../../model/Primitive";
import {connect} from "react-redux";
import {ArrayUtils} from "../../utils/ArrayUtils";
import {OntologyWrapper} from "../../ontology/OntologyWrapper";
import {OntologyMap} from "../../ontology/OntologyMap";
import {EditServerProxy} from "../../utils/EditServerProxy";
import {GeomanUtils} from "../../utils/GeomanUtils";
import GlobalCallbacks from "../../utils/GlobalCallbacks";

type CreatingPrimitiveProps = {
    getMapReference: any,
    creatingPrimitivesList: Array<Primitive>,
    creatingPrimitiveObject: Primitive | null,
    isEdit: boolean,
    currentStreamId: string | null
}

const wktParser = require('terraformer-wkt-parser');

class CreatingPrimitive extends React.Component<CreatingPrimitiveProps, any>{
    private geometryType: string = "";
    private drawingInProgress: boolean = false;
    private mapReference: any;
    private createdLayer: any;

    public onCreate = (e) => {
        let geomanType: string = GeomanUtils.getGeomanGeometryType(this.geometryType);
        this.props.getMapReference().pm.disableDraw(geomanType);
        this.createdLayer = e.layer;
        EditServerProxy.setEditingWkt(wktParser.convert(e.layer.toGeoJSON().geometry));
        e.layer.pm.enable({limitMarkersToCount: GeomanUtils.editingMarkersLimit});
        e.layer.on('pm:edit', () => {
            EditServerProxy.setEditingWkt(wktParser.convert(e.layer.toGeoJSON().geometry));
        })
    };

    private shouldRenderCreatingPrimitive(){
        if(!this.props.creatingPrimitiveObject) {
            return false;
        }

        let creatingPrimitiveObject: Primitive = this.props.creatingPrimitiveObject;
        let primitiveAlreadyExists: boolean = this.checkPrimitivesExistence(creatingPrimitiveObject);

        return (!this.props.isEdit && !primitiveAlreadyExists) || this.props.isEdit;
    }

    private checkPrimitivesExistence(creatingPrimitiveObject: Primitive) {
        return ArrayUtils.any(this.props.creatingPrimitivesList, (primitive) => {
            return primitive.PrimitiveID === creatingPrimitiveObject.PrimitiveID
        });
    }

    private getGeometryType(primitiveType: string){
        let ontology: OntologyWrapper = OntologyMap.getOntology(this.props.currentStreamId);
        return ontology.getPrimitiveTypesWrapper().getGeometryType(primitiveType);
    }

    private enableShapeCreation(){
        this.geometryType = this.getGeometryType(this.props.creatingPrimitiveObject ? this.props.creatingPrimitiveObject.PrimitiveTypeKeyName : "");
        this.drawingInProgress = this.enableShapeCreationOnMap();
    }

    private disableShapeCreation(){
        if(this.drawingInProgress) {
            this.disableShapeCreationOnMap(this.geometryType);
            this.drawingInProgress = false;
        }
    }

    public enableShapeCreationOnMap = (): boolean => {
        try{
            let geomanType: string = GeomanUtils.getGeomanGeometryType(this.geometryType);

            this.props.getMapReference().pm.enableDraw(geomanType, {
                snappable: false
            });
            this.props.getMapReference().on('pm:create', this.onCreate);
            return true;
        }
        catch (e) {
            GlobalCallbacks.displaySnackbarMessage("There is no appropriate geometry type in the editing library!");
            return false;
        }
    };

    public disableShapeCreationOnMap = (geometryType: string) => {
        let geomanType: string = GeomanUtils.getGeomanGeometryType(geometryType);
        this.props.getMapReference().pm.disableDraw(geomanType);

        if(this.createdLayer)
            this.props.getMapReference().removeLayer(this.createdLayer);

        this.props.getMapReference().off('pm:create', this.onCreate);
    };

    public componentDidUpdate(prevProps: Readonly<CreatingPrimitiveProps>, prevState: Readonly<any>, snapshot?: any) {
        if(this.shouldRenderCreatingPrimitive()){
            this.disableShapeCreation();
            this.enableShapeCreation();
        }
        else {
            this.disableShapeCreation();
        }
    }

    public render() {
        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        creatingPrimitiveObject: state.searchResult.creatingPrimitiveObject,
        creatingPrimitivesList: state.searchResult.creatingPrimitivesList,
        isEdit: state.searchResult.isEdit,
        currentStreamId: state.streamState.currentStreamId
    }
};

export default connect(mapStateToProps)(CreatingPrimitive)