import * as React from 'react';
import {BasicEntity} from "../model/BasicEntity";
import {DisplayList} from "../commonComponents/DisplayList";
import {ModelUtils} from "../utils/ModelUtils";

type AllLoadedTabProps = {
    showAllBasicInfo: boolean,
    allLoadedEntities: Array<BasicEntity>,
    highlightedEntity: string | null,

    onEntityClick: (entityId: string) => void,
    height: string
}

class AllLoadedTab extends React.Component<AllLoadedTabProps, any>{
    private displayList: any;

    static defaultProps = {
        showAllBasicInfo: false,
        height: '50vh'
    };

    public componentDidUpdate(){
        if(this.displayList !== undefined && this.displayList !== null)
            this.displayList.setHighlighted(this.props.highlightedEntity);
    }

    private createItemsList(): Array<any>{
        let basicEntities: Array<BasicEntity> = this.props.allLoadedEntities;
        return Object.keys(basicEntities).map(key => ModelUtils.getBasicEntityInfo(basicEntities[key], true));
    }

    private createIdsList(): Array<string>{
        return this.props.allLoadedEntities.map(basicEntity => basicEntity.id);
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let ids = this.createIdsList();
        let items: Array<string> = this.createItemsList();

        return(
            <div>
                <div>
                    <DisplayList ref={d => { this.displayList = d; }} ids={ids} items={items} onClick={this.props.onEntityClick} initialHighlighted={this.props.highlightedEntity}/>
                </div>
            </div>
        )
    }
}

export default AllLoadedTab