import * as React from 'react';
import { EntityName } from '../../../model/EntityName';
import DropdownList from '../../../commonComponents/DropdownList';
import { EditServerProxy } from '../../../utils/EditServerProxy';
import { EntityResponse } from '../../../model/EntityResponse';
import PropertiesViewEdit from "../properties/PropertiesViewEdit";
import {Guid} from "guid-typescript";
import {ModelUtils} from "../../../utils/ModelUtils";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {ArrayUtils} from "../../../utils/ArrayUtils";
import {connect} from "react-redux";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";
import {Link} from "@material-ui/core";
import MaterialTable from "material-table";
import '../relations/LinkButton.css';
import EntityNameForm from "./EntityNameForm";
import {registerLoadedEntity} from "../../../store/actions/changeLinkedHashMap";
import {MaterialTableUtils} from "../../../utils/MaterialTableUtils";

type EntityNamesEditProps = {
    names: Array<EntityName>,
    idsList: Array<string | null>,
    callback: any,
    propCallback: any,
    entityId: string,
    currentStreamId: string | null,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void
}

type EntityNamesEditState = {
    names: Array<EntityName>,
    name: EntityName | null
}

class EntityNamesEdit extends React.Component<EntityNamesEditProps, EntityNamesEditState>{
    public constructor(props){
        super(props);

        this.state = {
            names: this.props.names.slice(),
            name: null
        }
    }

    static defaultProps = {
        callback: null,
        propCallback: null,
        entityId: ""
    };

    public componentDidUpdate(prevProps: EntityNamesEditProps){
        if(!this.compareProps(prevProps, this.props)){
            let name: EntityName | null = null;
            if(this.state.name){
                name = this.findNameById(this.state.name.NameID);
            }

            this.setState({
                names: this.props.names.slice(),
                name: name
            })
        }
    }

    private compareProps(lhs: EntityNamesEditProps, rhs: EntityNamesEditProps){
        if(lhs === null || rhs === null) {
            return lhs === rhs;
        }

        if(lhs.names === null || rhs.names === null) {
            return lhs.names === rhs.names;
        }

        return JSON.stringify(lhs.names.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.names.map(p => JSON.stringify(p)).sort());
    }

    public onAddNewName = (newData) => {
        let newName: EntityName = this.generateNewName(newData.name, newData.role, newData.locale, newData.type);

        if(this.props.callback === null) {
            let response: Promise<any> | null = EditServerProxy.changeName(newName, "NO");
            if (response !== null) {
                response.then(result => result.json())
                    .then(
                        (data: EntityResponse) => {
                            if(data && data.SingleEntity){
                                if(ArrayUtils.any(data.SingleEntity.EntityNames, (name: EntityName) => name.FriendlyName === newData.name && name.NameRoleKeyName === newData.role && name.NameTypeKeyName === newData.type && name.CultureEnglishName === newData.locale)){
                                    GlobalCallbacks.displaySnackbarMessage("Name added");
                                    this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                                    this.setState({
                                        names: data.SingleEntity.EntityNames
                                    });
                                }
                                else{
                                    GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                }
                            }
                            else{
                                GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                            }
                        })
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("Editing entity is not selected!");
            }
        }
        else{
            newName.NameID = Guid.create().toString();
            newName.TokenizedName = newName.FriendlyName;
            this.props.callback(newName, false);
        }
    };

    public onNameChange = (newData) => {
        console.log(newData);
        if(newData && newData.id !== undefined) {
            let name: EntityName | null = this.findNameById(newData.id);
            if(name){
                let clonedName: EntityName = ModelUtils.deepCopy(name);
                clonedName.NameRoleKeyName = newData.role;
                clonedName.FriendlyName = newData.name;

                if(this.props.callback === null) {
                    let response: Promise<any> | null = EditServerProxy.changeName(clonedName, "NO");
                    if (response !== null) {
                        response.then(result => result.json())
                            .then(
                                (data: EntityResponse) => {
                                    if(data && data.SingleEntity){
                                        if(ArrayUtils.any(data.SingleEntity.EntityNames, (name: EntityName) => name.FriendlyName === clonedName.FriendlyName && name.NameRoleKeyName === clonedName.NameRoleKeyName && name.NameTypeKeyName === clonedName.NameTypeKeyName && name.CultureEnglishName === clonedName.CultureEnglishName) && !ArrayUtils.any(data.SingleEntity.EntityNames, (name: EntityName) => name.NameID === clonedName.NameID)){
                                            GlobalCallbacks.displaySnackbarMessage("Name changed");
                                            this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                                            this.setState({
                                                names: data.SingleEntity.EntityNames
                                            });
                                        }
                                        else{
                                            GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                        }
                                    }
                                    else{
                                        GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                    }

                                    //changing deletes and then add new name, so it gets new id. If prev id doesn't exist, that seems right
                                })
                    }
                    else{
                        GlobalCallbacks.displaySnackbarMessage("Editing entity is not selected!");
                    }
                }
                else{
                    this.props.callback(clonedName, false);
                }
            }
        }
    };

    public onNameDeletion = (oldData) => {
        if(oldData && oldData.id !== undefined){
            let name: EntityName | null = this.findNameById(oldData.id);

            if(name){
                if (this.props.callback === null) {
                    let response: Promise<any> | null = EditServerProxy.changeName(name, "YES");
                    if (response !== null) {
                        response.then(result => result.json())
                            .then(
                                (data: EntityResponse) => {

                                    if(data && data.SingleEntity){
                                        if(!ArrayUtils.any(data.SingleEntity.EntityNames,  (n: EntityName) => name !== null && name.NameID === n.NameID)){
                                            GlobalCallbacks.displaySnackbarMessage("Name deleted");
                                            this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                                            this.setState({
                                                names: data.SingleEntity.EntityNames
                                            });
                                        }
                                        else{
                                            GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                        }
                                    }
                                    else{
                                        GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                                    }
                                })
                    }
                    else{
                        GlobalCallbacks.displaySnackbarMessage("Editing entity is not selected!");
                    }
                } else {
                    this.props.callback(name, true);
                }
            }
        }
    };

    private findNameById = (id: string): EntityName | null => {
        let i: number;
        for(i = 0; i < this.state.names.length; i++){
            if(this.state.names[i].NameID === id)
                break;
        }

        if(i < this.state.names.length)
            return this.state.names[i];
        else
            return null;
    };

    private generateNewName(entityName: string, role: string, locale: string, type: string){
        let newName: EntityName = {
            EntityID: this.props.entityId,
            NameID: "/",
            CultureEnglishName: locale,
            ScriptName: null,
            NameTypeKeyName: type,
            NameRoleKeyName: role,
            TokenizedName: "",
            FriendlyName: entityName,
            SourceInfo: null,
            Properties: [],
            Confidence: null
        };

        return newName;
    }

    public setPropertyView = (rowData) => {
        console.log(rowData);
        if(rowData && rowData.id !== undefined){
            let name: EntityName | null = this.findNameById(rowData.id);
            if(name)
                this.setState({
                    name: name
                });
        }
    };

    public resetPropertyView = () => {
        this.setState({
            name: null
        })
    };

    public onRoleChange = (props, selected) => {
        console.log(props);
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.role = selected;
        props.onRowDataChange(rowData);
    };

    public onLocaleChange = (props, selected) => {
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.locale = selected;
        props.onRowDataChange(rowData);
    };

    public onTypeChange = (props, selected) => {
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.type = selected;
        props.onRowDataChange(rowData);
    };

    public changeName = (props, newName) => {
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.name = newName;
        props.onRowDataChange(rowData);
    };

    public onRowAdd = (newData) => {
        return new Promise((resolve, reject) => {
            console.log(newData);
            if ("name" in newData && newData["name"] !== "" && "type" in newData && "role" in newData) {
                this.onAddNewName(newData);
                resolve();
            } else {
                GlobalCallbacks.displaySnackbarMessage("All fields are required!");
                reject();
            }
        })
    };


    public onRowUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            console.log(newData);
            console.log(oldData);
            if (newData["name"] === "") {
                GlobalCallbacks.displaySnackbarMessage("Name field is required!");
                reject();
            } else {
                this.onNameChange(newData);
                resolve();
            }
        });
    };

    public onRowDelete = (oldData) => {
        return new Promise((resolve) => {
            console.log(oldData);
            this.onNameDeletion(oldData);
            resolve();
        });
    };

    public render(): JSX.Element{
        let columns: Array<{}> = [];
        let data: Array<{}> = [];

        let ontology: OntologyWrapper | null = this.props.currentStreamId !== null ? OntologyMap.getOntology(this.props.currentStreamId) : null;
        let nameCulture: Array<string> = [];
        if(ontology){
            nameCulture = ontology.getNamesWrapper().getCulture().map(culture => culture.cultureName);
        }

        if(this.state.names !== null){
            columns.push({title: 'Name', field: 'name',
                editComponent: props => (
                    <EntityNameForm name={props.value} nameType={props.rowData.type} onChange={(newName) => this.changeName(props, newName)}/>
                )});
            columns.push({title: 'Role', field: 'role', editComponent: props => (
                    <DropdownList options={ontology !== null ? ontology.getNamesWrapper().getEntityNameRoles() : []} onChange={(selected) => this.onRoleChange(props, selected)} initialValue={props.value}/>
                )});
            columns.push({title: 'Locale', field: 'locale', editable: 'onAdd', editComponent: props => (
                    <DropdownList options={nameCulture} onChange={(selected) => this.onLocaleChange(props, selected)} initialValue={props.value}/>
                )});
            columns.push({title: 'Type', field: 'type', editable: 'onAdd', editComponent: props => (
                    <DropdownList options={ontology !== null ? ontology.getNameTypesWrapper().getEntityNameTypes() : []} onChange={(selected) => this.onTypeChange(props, selected)} initialValue={props.value}/>
                )});
            columns.push({title: 'Properties', field: 'properties', editable: 'onUpdate',
                render: rowData => (
                    <Link component={"button"} onClick={() => this.setPropertyView(rowData)}>Open</Link>
                ),
                editComponent: props => (
                    <Link component={"button"} onClick={() => this.setPropertyView(props.rowData)}>Open</Link>
                ),
                cellStyle:
                    {
                        background: "none",
                        color: "#069"}
            });

            this.state.names.forEach((name) => {
                let dataRow: {} = {id: name.NameID, name: name.FriendlyName, role: name.NameRoleKeyName, locale: name.CultureEnglishName, type: name.NameTypeKeyName, properties: "Open"};
                data.push(dataRow);
            })
        }

        let length: number = this.state.names !== null ? this.state.names.length : 0;

        let idsList: Array<string | null> = this.props.idsList.slice();
        if(this.state.name){
            idsList[1] = this.state.name.NameID
        }

        return(
            <div>
                <ExpansionComponent title={"Names (count: " + length + ")"} componentId={"nameList"}>
                    {
                        !this.state.name &&
                        <div>
                            <MaterialTable
                                options={MaterialTableUtils.tableOptions}
                                columns={columns}
                                data={data}
                                editable={{
                                    onRowAdd: this.onRowAdd,
                                    onRowUpdate: this.onRowUpdate,
                                    onRowDelete: this.onRowDelete
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.name &&
                        <PropertiesViewEdit properties={this.state.name.Properties} goBackCallback={this.resetPropertyView} idsList={idsList} callback={this.props.propCallback} objectType={this.state.name.NameTypeKeyName}/>
                    }
                </ExpansionComponent>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStreamId: state.streamState.currentStreamId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityNamesEdit)