import * as React from 'react';
import {setErrorMessage, setExpandedNodes, updateStreamInformation} from "../store/actions/changeStreamState";
import {
    fetchAndKeepEntities,
    resetSearchStore, searchEntitiesGroup,
    searchByEntityId, searchPrimitiveTypes,
    setHighlightedEntity, searchPrimitive, searchByContributor, setHighlightedPrimitive, searchEntityFromGroup
} from "../store/actions/searchEntities";
import {changeActiveTab, setBaseMap, setMapBounds} from "../store/actions/changeMapState";
import {connect} from "react-redux";
import {RequestUtils} from "../utils/RequestUtils";
import GlobalCallbacks from "../utils/GlobalCallbacks";
import {LatLng, LatLngBounds} from "leaflet";
import {StreamUtils} from "../utils/StreamUtils";
import {BaseMapUtils} from "../utils/BaseMapUtils";
import {registerLoadedEntity} from "../store/actions/changeLinkedHashMap";
import {fromString, SearchType} from "../search/SearchType";
import {SolrResponse} from "../model/SolrResponse";
import {findSolrEntity} from "../model/SolrEntity";
import {EntityExtension} from "../model/EntityExtension";
import {LinkedHashMap} from "../utils/LinkedHashMap";
import {findPrimitive, Primitive} from "../model/Primitive";

const queryString = require('query-string');

type UrlHandlerProps = {
    isPolaris: boolean,
    updateStreamInformation: (streamInformation: {}) => any,
    setMapBounds: (newBounds: LatLngBounds) => any,
    fetchAndKeepEntities: (entities: Array<string>, processSearch: ((keptEntities: Array<string>) => void) | null) => void,
    setHighlightedEntity: (entityId: string) => any,
    setHighlightedPrimitive: (primitiveId: string, primitiveWkt: string) => void,
    searchByEntityId: (entityId: string, shouldSetHighlight: boolean, fetchWholeEntities: boolean, onFinish: (entityId: string | null) => void) => any,
    searchEntitiesGroup: (isPolaris: boolean, searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, shouldSetHighlight: boolean, nameSearchType: SearchType, fetchWholeEntities: boolean, onFinish: (solrResponse: SolrResponse | null) => void) => any,
    searchPrimitiveTypes: (searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, nameSearchType: SearchType, fetchWholeEntities: boolean, shouldSetHighlight: boolean, onFinish: (solrResponse: SolrResponse | null) => void) => void,
    searchPrimitive: (primitiveId: string, fetchWholeEntities: boolean, shouldSetHighlight: boolean, onFinish: (entityId: string | null) => void) => void,
    searchByContributor: (contributor: string, searchType: SearchType, fetchWholeEntities: boolean, shouldSetHighlight: boolean, onFinish: (solrResponse: SolrResponse | null) => void) => void,
    searchEntityFromGroup : (entityId: string, shouldSetHighlight: boolean, onFinish: (entityId: string | null) => void) => void,
    changeActiveTab: (key: string) => void,
    setBaseMap: (baseMapName: string) => void,
    setExpandedNodes: (nodeIds: Array<string>) => void,
    setErrorMessage: (errorMessage: string) => void,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void
}

type Highlighted = {
    highlightedEntity: string | null,
    highlightedPrimitive: string | null
}

class UrlHandler extends React.Component<UrlHandlerProps, any>{
    private parameters: {[name: string]: string} = {};
    private highlighted: Highlighted = {highlightedEntity: null, highlightedPrimitive: null};
    private keptEntities: Array<string> = [];

    private processStream(streamList: {[streamId: string]: string}){
        let streamId: string = this.parameters['id'];

        if(streamId in streamList){
            let configString: string = StreamUtils.buildConfigString(streamList[streamId]);
            sessionStorage.setItem("configString", configString);

            RequestUtils.sendRequestWithConfigString("/api/entity/setSolrCore/post/", 'POST').then(() => {
                    StreamUtils.setOntology(streamId, this.props.updateStreamInformation, this.props.changeActiveTab, this.processKeptEntities);

                    let streamName: string = StreamUtils.getStreamName(streamList[streamId]);
                    let streamPath: Array<string> = StreamUtils.getStreamPathFromName(streamName);
                    let nodePaths: Array<string> = StreamUtils.buildNodePaths(streamPath);
                    this.props.setExpandedNodes(nodePaths);
                }
            );
        }
        else{
            GlobalCallbacks.displaySnackbarMessage("Stream with id " + streamId + " doesn't exist");
            this.props.updateStreamInformation({showSpinner: false});
        }
    }

    private processBaseMap(parameters: {[name: string]: string}){
        if('baseMapName' in parameters){
            let baseMapName: string = parameters['baseMapName'];
            console.log(baseMapName);

            if(BaseMapUtils.getLayerInfoByName(baseMapName) !== null)
                this.props.setBaseMap(baseMapName);
        }
    }

    private static createBounds(mapBoundsS: number, mapBoundsN: number, mapBoundsE: number, mapBoundsW: number): LatLngBounds | null{
        if(!isNaN(mapBoundsS) && !isNaN(mapBoundsN) && !isNaN(mapBoundsE) && !isNaN(mapBoundsW)){
            return new LatLngBounds(new LatLng(mapBoundsN, mapBoundsE), new LatLng(mapBoundsS, mapBoundsW));
        }
        else{
            return null;
        }
    }

    private processMapBounds(parameters: {[name: string]: string}){
        if('mapBounds[S]' in parameters && 'mapBounds[N]' in parameters && 'mapBounds[E]' in parameters && 'mapBounds[W]' in parameters){
            let mapBoundsS: number = Number(parameters['mapBounds[S]']);
            let mapBoundsN: number = Number(parameters['mapBounds[N]']);
            let mapBoundsE: number = Number(parameters['mapBounds[E]']);
            let mapBoundsW: number = Number(parameters['mapBounds[W]']);

            let mapBounds: LatLngBounds | null = UrlHandler.createBounds(mapBoundsS, mapBoundsN, mapBoundsE, mapBoundsW);
            if(mapBounds)
                this.props.setMapBounds(mapBounds);
        }
    }

    private processKeptEntities: () => void = () => {
        if('keptEntities' in this.parameters){
            let keptEntities: Array<string> = (this.parameters['keptEntities'] as string).split(';');

            if(keptEntities.length > 0)
                this.props.fetchAndKeepEntities(keptEntities, this.processSearch);
        }
        else{
            this.processSearch([]);
        }
    };

    public processEntity = (entityId: string | null) => {
        if(this.highlighted.highlightedEntity && (this.keptEntities.includes(this.highlighted.highlightedEntity) || this.highlighted.highlightedEntity === entityId)){
            this.setHighlighted();
        }
    };

    public processEntities = (response: SolrResponse | null) => {
        if(this.highlighted.highlightedEntity){
            if(this.keptEntities.includes(this.highlighted.highlightedEntity))
                this.setHighlighted();
            else if(response && findSolrEntity(this.highlighted.highlightedEntity, response.RemainingEntities)){
                this.props.searchEntityFromGroup(this.highlighted.highlightedEntity, false, this.processEntity);
            }
        }
    };

    private setHighlighted(){
        if(this.highlighted.highlightedEntity) {
            this.props.setHighlightedEntity(this.highlighted.highlightedEntity);
            let entity: EntityExtension = LinkedHashMap.getItem(this.highlighted.highlightedEntity).entityExtension;

            let primitive: Primitive | null = findPrimitive(this.highlighted.highlightedPrimitive, entity.primitives);
            if(primitive)
                this.props.setHighlightedPrimitive(primitive.PrimitiveID, primitive.ShapeWKT);
        }
    }

    private getHighlighted = (): Highlighted => {
        let highlightedEntity: string | null = null;
        let highlightedPrimitive: string | null = null;

        if('highlightedEntity' in this.parameters) {
            highlightedEntity = this.parameters['highlightedEntity'];

            if ('highlightedPrimitive' in this.parameters)
                highlightedPrimitive = this.parameters['highlightedPrimitive'];
        }

        return {highlightedEntity, highlightedPrimitive}
    };

    private fetchSearchResult(){
        if('search[entityId]' in this.parameters){
            let entityId: string = this.parameters['search[entityId]'];
            this.props.searchByEntityId(entityId, false, true, this.processEntity);
        }
        else if('search[primitiveId]' in this.parameters){
            let primitiveId: string = this.parameters['search[primitiveId]'];
            this.props.searchPrimitive(primitiveId, true, false, this.processEntity);
        }
        else if('search[contributor]' in this.parameters){
            let contributor: string = this.parameters['search[contributor]'];
            let searchType: SearchType = SearchType.Equals;

            if('search[contributorSearchType]' in this.parameters)
                searchType = fromString(this.parameters['search[contributorSearchType]']);

            this.props.searchByContributor(contributor, searchType, true, false, this.processEntities);
        }
        else{
            if('search[bounds][S]' in this.parameters && 'search[bounds][N]' in this.parameters && 'search[bounds][E]' in this.parameters && 'search[bounds][W]' in this.parameters){
                let name: string = "";
                let nameSearchType: SearchType = SearchType.Equals;
                let types: Array<string> = [];
                if('search[name]' in this.parameters)
                    name = this.parameters['search[name]'];

                let onlyNamedEntities: boolean = 'search[onlyNamed]' in this.parameters;

                if('search[nameSearchType]' in this.parameters)
                    nameSearchType = fromString(this.parameters['search[nameSearchType]']);

                if('search[primitiveTypes]' in this.parameters)
                    types = (this.parameters['search[primitiveTypes]'] as string).split(';');
                else if('search[types]' in this.parameters)
                    types = (this.parameters['search[types]'] as string).split(';');

                let mapBoundsS: number = Number(this.parameters['search[bounds][S]']);
                let mapBoundsN: number = Number(this.parameters['search[bounds][N]']);
                let mapBoundsE: number = Number(this.parameters['search[bounds][E]']);
                let mapBoundsW: number = Number(this.parameters['search[bounds][W]']);

                let mapBounds: LatLngBounds | null = UrlHandler.createBounds(mapBoundsS, mapBoundsN, mapBoundsE, mapBoundsW);
                if(mapBounds){
                    if('search[primitiveTypes]' in this.parameters){
                        this.props.searchPrimitiveTypes(mapBounds, name, onlyNamedEntities, types, "", nameSearchType, true, false, this.processEntities);
                    }
                    else {
                        this.props.searchEntitiesGroup(this.props.isPolaris, mapBounds, name, onlyNamedEntities, types, "", false, nameSearchType, true, this.processEntities);
                    }
                }
            }
        }
    }

    private processSearch = (keptEntities: Array<string>) => {
        this.keptEntities = keptEntities;
        this.fetchSearchResult();
    };

    public componentDidMount(): void {
        this.parameters = queryString.parse(window.location.search);
        this.highlighted = this.getHighlighted();

        let id: number = -1;

        this.processBaseMap(this.parameters);
        this.processMapBounds(this.parameters);

        let data = {"ID": id};

        RequestUtils.sendRequest("/api/entity/getDropDown/post/", 'POST', data)
            .then(result => result.json())
            .then(
                data => {
                    if(data){
                        let streamList: Array<string> = data;

                        this.props.updateStreamInformation({
                            streamList: streamList,
                            showSpinner: 'id' in this.parameters
                        });

                        if('id' in this.parameters) {
                            let list: {[streamId: string]: string} = {};
                            streamList.forEach(stream => {
                                let streamInfo: Array<string> = stream.split(';');
                                let streamId: string = streamInfo[streamInfo.length - 1];
                                list[streamId] = stream;
                            });
                            this.processStream(list);
                        }
                    }
                    else{
                        GlobalCallbacks.displaySnackbarMessage("Stream list fetching failed!");
                        this.props.updateStreamInformation({showSpinner: false});
                    }
                }
            )
            .catch((/*error*/) => {
                this.props.setErrorMessage("Error: Streams are not available!");
            })
        ;
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        isPolaris: state.appState.isPolaris
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetSearchStore: () => dispatch(resetSearchStore()),
        changeActiveTab: (key: string) => dispatch(changeActiveTab(key)),
        updateStreamInformation: (streamInformation: {}) => dispatch(updateStreamInformation(streamInformation)),
        fetchAndKeepEntities: (entities: Array<string>, processSearch: ((keptEntities: Array<string>) => void) | null) => dispatch(fetchAndKeepEntities(entities, processSearch)),
        setHighlightedEntity: (entityId: string) => dispatch(setHighlightedEntity(entityId)),
        setHighlightedPrimitive: (primitiveId: string, primitiveWkt: string) => dispatch(setHighlightedPrimitive(primitiveId, primitiveWkt)),
        searchByEntityId: (entityId: string, shouldSetHighlight: boolean, fetchWholeEntities: boolean, onFinish: (entityId: string | null) => void) => dispatch(searchByEntityId(entityId, shouldSetHighlight, fetchWholeEntities, onFinish)),
        searchEntitiesGroup: (isPolaris: boolean, searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, shouldSetHighlight: boolean, nameSearchType: SearchType, fetchWholeEntities: boolean, onFinish: (solrResponse: SolrResponse | null) => void) => dispatch(searchEntitiesGroup(isPolaris, searchBounds, name, onlyNamedEntities, types, entityInfo, shouldSetHighlight, nameSearchType, fetchWholeEntities, onFinish)),
        searchPrimitiveTypes: (searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, nameSearchType: SearchType, fetchWholeEntities: boolean, shouldSetHighlight: boolean, onFinish: (solrResponse: SolrResponse | null) => void) => dispatch(searchPrimitiveTypes(searchBounds, name, onlyNamedEntities, types, entityInfo, nameSearchType, fetchWholeEntities, shouldSetHighlight, onFinish)),
        searchPrimitive: (primitiveId: string, fetchWholeEntities: boolean, shouldSetHighlight: boolean, onFinish: (entityId: string | null) => void) => dispatch(searchPrimitive(primitiveId, fetchWholeEntities, shouldSetHighlight, onFinish)),
        searchByContributor: (contributor: string, searchType: SearchType, fetchWholeEntities: boolean, shouldSetHighlight: boolean, onFinish: (solrResponse: SolrResponse | null) => void) => dispatch(searchByContributor(contributor, searchType, fetchWholeEntities, shouldSetHighlight, onFinish)),
        searchEntityFromGroup : (entityId: string, shouldSetHighlight: boolean, onFinish: (entityId: string | null) => void) => dispatch(searchEntityFromGroup(entityId, shouldSetHighlight, onFinish)),

        setMapBounds: (newBounds: LatLngBounds) => dispatch(setMapBounds(newBounds)),
        setBaseMap: (baseMapName: string) => dispatch(setBaseMap(baseMapName)),
        setExpandedNodes: (nodeIds: Array<string>) => dispatch(setExpandedNodes(nodeIds)),
        setErrorMessage: (errorMessage: string) => dispatch(setErrorMessage(errorMessage)),

        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UrlHandler)