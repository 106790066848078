import { MapEntity } from '../../../model/MapEntity';
import { Primitive } from '../../../model/Primitive';
import { DetailsFactory } from './DetailsFactory';
import ToggleEditMode from "../../general/ToggleEditMode";
import * as React from "react";
import PrimitiveDetailsEdit from "../primitives/PrimitiveDetailsEdit";
import {EntityDetailsEdit} from "../EntityDetailsEdit";
import {Grid} from "@material-ui/core";

export class EditDetailsFactory extends DetailsFactory{
    public getToggles(entity: MapEntity | null){
        if(entity !== null)
            return(
                <Grid container direction="row">
                    <Grid item md={6}>
                    </Grid>
                    <Grid item md={3}>
                        <ToggleEditMode/>
                    </Grid>
                    <Grid item md={3}>
                    </Grid>
                </Grid>
            )
    }

    public getEntityInfo(entity: MapEntity | null = null, entityId: string | null = null){
        return (
            <EntityDetailsEdit entity={entity}/>
        )
    }

    public getPrimitiveInfo(primitive: Primitive){
        return(
            <PrimitiveDetailsEdit primitive={primitive}/>
        )
    }
}