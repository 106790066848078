import * as React from 'react';
import { Child } from '../../../model/Child';
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";
import MaterialTable from "material-table";
import {MaterialTableUtils} from "../../../utils/MaterialTableUtils";

type ChildrenEditProps = {
    children: Array<Child>
}

type ChildrenEditState = {
    children: Array<Child>,
    deletionPendingChild: string | null,
    openDialog: boolean
}

class ChildrenEdit extends React.Component<ChildrenEditProps, ChildrenEditState>{
    public constructor(props){
        super(props);

        this.state = {
            children: this.props.children,
            deletionPendingChild: null,
            openDialog: false
        }
    }

    public componentDidUpdate(prevProps: ChildrenEditProps){
        if(!this.compareProps(prevProps, this.props)){
            this.setState({
                children: this.props.children.slice()
            })
        }
    }

    private compareProps(lhs: ChildrenEditProps, rhs: ChildrenEditProps){
        return JSON.stringify(lhs.children.map(p => JSON.stringify(p)).sort()) === JSON.stringify(rhs.children.map(p => JSON.stringify(p)).sort());
    }

    public render(): JSX.Element{
        let columns: Array<{}> = [];
        let data: Array<{}> = [];
        
        if(this.state.children !== null){
            columns.push({title: 'Id', field: 'id'});
            columns.push({title: 'Type', field: 'type'});

            this.state.children.forEach((child) => {
                let dataRow: {} = {id: child.Id, type: child.TypeOfRelate};
                data.push(dataRow);
            });
        }

        let length: number = this.state.children !== null ? this.state.children.length : 0;

        return(
            <div>
                <ExpansionComponent title={"Children (count: " + length + ")"} componentId={"children"}>
                    {
                        this.state.children !== null && length > 0 &&
                        <MaterialTable
                            options={MaterialTableUtils.tableOptions}
                            columns={columns}
                            data={data}/>
                    }
                </ExpansionComponent>
            </div>
        )
    }
}

export { ChildrenEdit }