import * as React from 'react';
import {changeActiveTab} from "../store/actions/changeMapState";
import {setEntityCreation} from "../store/actions/searchEntities";
import {Guid} from "guid-typescript";
import {connect} from "react-redux";
import {Button} from "@material-ui/core";
import {createEntity, Entity} from "../model/Entity";

type CreateEntityButtonProps = {
    setEntityCreation: (entityId: string, entity: Entity) => void,
    changeActiveTab: (tabName: string) => void
}

class CreateEntityButton extends React.Component<CreateEntityButtonProps, any>{
    public createNewEntity = () => {
        let entityId: string = Guid.create().toString();
        this.props.setEntityCreation(entityId, createEntity(entityId));
        this.props.changeActiveTab("information");
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
                <Button onClick={this.createNewEntity} variant="outlined">New entity</Button>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setEntityCreation: (entityId: string, entity: Entity) => dispatch(setEntityCreation(entityId, entity)),
        changeActiveTab: (tabName: string) => dispatch(changeActiveTab(tabName))
    }
};

export default connect(null, mapDispatchToProps)(CreateEntityButton)