import * as React from 'react';
import { connect } from 'react-redux';
import { searchEntityFromGroup } from '../store/actions/searchEntities';
import { DisplayList } from '../commonComponents/DisplayList';
import { LinkedHashMap } from '../utils/LinkedHashMap';
import { MapEntity } from '../model/MapEntity';
import { BasicEntity } from '../model/BasicEntity';
import { ModelUtils } from '../utils/ModelUtils';

type KeptOnMapListProps = {
    entitiesKeptOnMap: Set<string>,
    highlightedEntity: string | null,
    searchEntityFromGroup: (identifierKey) => void
}

class KeptOnMapList extends React.Component<KeptOnMapListProps, any>{
    private displayList: any;

    public constructor(props){
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    private onChange(id){
        this.props.searchEntityFromGroup(id);
    }

    public componentDidUpdate(){
        if(this.displayList !== undefined && this.displayList !== null)
            this.displayList.setHighlighted(this.props.highlightedEntity);
    }

    public render(): JSX.Element{
        let ids: Array<any> = [];
        let items: Array<any> = [];

        this.props.entitiesKeptOnMap.forEach((entityId) => {
            ids.push(entityId);

            let mapEntity: MapEntity = LinkedHashMap.getItem(entityId);
            let basicEntity: BasicEntity = mapEntity.basicEntity;
            if((basicEntity === null || basicEntity === undefined) && mapEntity.entityExtension !== null && mapEntity.entityExtension !== undefined){
                basicEntity = ModelUtils.basicEntityFromEntityExtension(mapEntity.entityExtension);
            }

            items.push(ModelUtils.getBasicEntityInfo(basicEntity));
        });

        return(
            <div>
                {
                    this.props.entitiesKeptOnMap.size > 0 &&
                    <div>
                        <DisplayList ref={d => { this.displayList = d; }} ids={ids} items={items} onClick={this.onChange} initialHighlighted={this.props.highlightedEntity}/>
                    </div>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchEntityFromGroup: (identifierKey) => dispatch(searchEntityFromGroup(identifierKey))
    }
};

export default connect(null, mapDispatchToProps)(KeptOnMapList)