export class GeomanUtils {
    public static editingMarkersLimit: number = 10;

    public static configureGeomanToolbar(leafletMapRef: any){
        leafletMapRef.pm.addControls({
            position: 'bottomleft',
            drawCircle: false,
            drawMarker: false,
            drawCircleMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawPolygon: false,
            editMode: false,
            dragMode: false,
            cutPolygon: false,
            removalMode: false,
            pinningOption: false,
            snappingOption: false
        });
    }

    public static getGeomanGeometryType(ontologyGeometryType: string){
        switch (ontologyGeometryType) {
            case "Point2D":
                return "Marker";
            case "Polyline2D":
                return "Line";
            case "Polygon2D":
                return "Polygon";
            default:
                throw new Error();
        }
    }
}