import * as React from 'react'
import {Primitive} from "../../model/Primitive";
import { PrimitiveComponent } from './PrimitiveComponent';
import {Direction} from "../../entityInformation/entityDetails/primitives/Direction";

type PrimitivesGroupProps = {
    primitives: Array<Primitive>,
    onClick: (id: string, wkt: string) => void
}

type PrimitiveGroupState = {
    highlightedEntity: boolean
}

class PrimitivesGroup extends React.Component<PrimitivesGroupProps,PrimitiveGroupState>{
    private primitiveMap: any = {};
    private highlightedPrimitiveId: string | null = null;
    private primitiveIdToHighlight: string | null = null;
    private shouldHighlight: boolean = false;
    private editablePrimitiveId: string | null = null;
    private isEdit: boolean = false;

    public constructor(props){
        super(props);

        this.state = {
            highlightedEntity: false
        }
    }

    private set(primitiveIdToHighlight: string){
        this.primitiveMap[primitiveIdToHighlight].setHighlight();

        if(this.isEdit) {
            this.primitiveMap[primitiveIdToHighlight].setEditable(true);
            this.editablePrimitiveId = primitiveIdToHighlight;
        }
    }

    private reset(highlightedPrimitiveId: string){
        this.primitiveMap[highlightedPrimitiveId].resetHighlight();

        if(this.editablePrimitiveId === highlightedPrimitiveId) {
            this.primitiveMap[highlightedPrimitiveId].setEditable(false);
            this.editablePrimitiveId = null;
        }
    }

    public componentDidUpdate(){
        if(this.shouldHighlight){
            if(this.highlightedPrimitiveId !== null && this.highlightedPrimitiveId !== this.primitiveIdToHighlight && this.primitiveMap[this.highlightedPrimitiveId] !== undefined && this.primitiveMap[this.highlightedPrimitiveId] !== null){
                this.reset(this.highlightedPrimitiveId);
            }

            if(this.primitiveIdToHighlight != null && this.primitiveMap[this.primitiveIdToHighlight] !== undefined && this.primitiveMap[this.primitiveIdToHighlight] !== null){
                this.set(this.primitiveIdToHighlight);
            }

            this.highlightedPrimitiveId = this.primitiveIdToHighlight;
            this.shouldHighlight = false;
            this.primitiveIdToHighlight = null;
        }
    }

    public setHighlight(highlighted: boolean, primitiveId: string | null, isEdit: boolean){
        this.isEdit = isEdit;

        if(this.state.highlightedEntity !== highlighted){
            this.shouldHighlight = true;
            this.primitiveIdToHighlight = primitiveId;

            this.setState({
                highlightedEntity: highlighted
            })
        }
        else{
            if(this.highlightedPrimitiveId !== null && this.highlightedPrimitiveId !== primitiveId && this.primitiveMap[this.highlightedPrimitiveId] !== undefined && this.primitiveMap[this.highlightedPrimitiveId] !== null){
                this.reset(this.highlightedPrimitiveId);
            }
    
            if(primitiveId != null && this.primitiveMap[primitiveId] !== undefined && this.primitiveMap[primitiveId] !== null){
                this.set(primitiveId);
            }
    
            this.highlightedPrimitiveId = primitiveId;
        }
    }

    public setEdit(primitiveToEdit: string | null){
        if(this.editablePrimitiveId && this.editablePrimitiveId !== primitiveToEdit && this.primitiveMap[this.editablePrimitiveId]){
            this.primitiveMap[this.editablePrimitiveId].setEditable(false);
        }

        if(primitiveToEdit && this.primitiveMap[primitiveToEdit]){
            this.primitiveMap[primitiveToEdit].setEditable(true);
        }

        this.editablePrimitiveId = primitiveToEdit;
    }

    public moveLayer(primitiveId: string, direction: Direction){
        this.primitiveMap[primitiveId].moveLayer(direction);
    }

    public render(): JSX.Element{
        let primitives : Array<Primitive>= this.props.primitives;

        return(
            <div>
                {primitives.map((primitive) =>{
                    let key = primitive.PrimitiveID;
                    return (<PrimitiveComponent ref={e => {this.primitiveMap[key] = e}} primitive={primitive} key={key} onClick={(id, wkt) => this.props.onClick(id, wkt)} highlightedEntity={this.state.highlightedEntity}/>)
                })}
            </div>
        )
    }
}

export { PrimitivesGroup } 