import * as React from 'react';
import {LatLngBounds} from "leaflet";
import { connect } from 'react-redux'
import {
    searchByContributor,
    searchEntitiesGroup,
    searchPrimitive,
    searchPrimitiveTypes
} from '../store/actions/searchEntities';
import { searchByEntityId, searchReducedEntity } from '../store/actions/searchEntities';
import EntityLoaderProgressBar from './EntitiesLoaderProgressBar';
import SearchLinearProgress from './SearchLinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {OntologyWrapper} from "../ontology/OntologyWrapper";
import {OntologyMap} from "../ontology/OntologyMap";
import {Button, Grid, Tab, Tabs, TextField, Theme, Tooltip, withTheme} from "@material-ui/core";
import Select from "react-select";
import DropdownList from "../commonComponents/DropdownList";
import {TabContent} from "../commonComponents/TabContent";
import {setSearchFormState} from "../store/actions/searchFormState";
import SearchBoundsTypeForm from "./SearchBoundsTypeForm";
import {ANYWHERE} from "./SearchBoundsTypes";
import {GeometryUtils} from "../utils/GeometryUtils";
import {ChangeEvent} from "react";
import BasicEntitiesProgressBar from "./BasicEntitiesProgressBar";
import {fromString, SearchType} from "./SearchType";

type SearchFormProps = {
    entityId: string,
    primitiveId: string,
    contributor: string,
    searchBoundsType: string,
    nameSearchType: SearchType,
    contributorSearchType: SearchType,
    onlyNamedEntities: boolean,
    entityName: string,
    entityTypes: Array<{label: string, value: string}>;
    primitiveType: string,
    activeTab: string,
    entityToggle: boolean

    isPolaris: boolean,
    searchBounds: LatLngBounds,
    currentStreamId: string | null,
    fetchWholeEntities: boolean,

    setSearchFormState: (state: SearchFormState) => void,
    searchEntitiesGroup: (isPolaris: boolean, searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, nameSearchType: SearchType, fetchWholeEntities: boolean) => void,
    searchByEntityId: (identifierKey: string, shouldSetHighlight, fetchWholeEntities) => void,
    searchByContributor: (identifierKey: string, contributorSearchType: SearchType, fetchWholeEntities: boolean) => void,
    searchReducedEntity: (identifierKey, boundingBox, fetchWholeEntities: boolean) => void,
    searchPrimitive: (primitiveId: string, fetchWholeEntities: boolean) => void,
    searchPrimitiveTypes: (searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, nameSearchType: SearchType, fetchWholeEntities: boolean) => void,
    theme: Theme
}

export type SearchFormState = {
    searchBoundsType: string,
    nameSearchType: SearchType,
    contributorSearchType: SearchType,
    onlyNamedEntities: boolean,
    entityName: string,
    entityTypes: Array<{value: string, label: string}>,
    contributor: string,
    entityId: string,
    primitiveId: string,
    primitiveType: string,
    entityToggle: boolean,
    activeTab: string
}

class SearchForm extends React.Component<SearchFormProps, any>{
    private primitiveTypeList: any;
    private nameTypeList: any;
    private contributorTypeList: any;

    private getStateFromProps = (): SearchFormState => {
        return {
            searchBoundsType: this.props.searchBoundsType,
            nameSearchType: this.props.nameSearchType,
            contributorSearchType: this.props.contributorSearchType,
            onlyNamedEntities: this.props.onlyNamedEntities,
            entityName: this.props.entityName,
            entityTypes: this.props.entityTypes,
            contributor: this.props.contributor,
            entityId: this.props.entityId,
            primitiveId: this.props.primitiveId,
            primitiveType: this.props.primitiveType,
            entityToggle: this.props.entityToggle,
            activeTab: this.props.activeTab
        }
    };

    static defaultProps = {
        fetchWholeEntities: true
    };

    public componentDidMount(): void {
        if(this.nameTypeList)
            this.nameTypeList.setValue(this.props.nameSearchType);

        if(this.contributorTypeList)
            this.contributorTypeList.setValue(this.props.contributorSearchType);

        if(this.primitiveTypeList && this.props.primitiveType !== "")
            this.primitiveTypeList.setValue(this.props.primitiveType);
    }

    public componentDidUpdate(prevProps: Readonly<SearchFormProps>, prevState: Readonly<SearchFormState>, snapshot?: any): void {
        if(this.nameTypeList)
            this.nameTypeList.setValue(this.props.nameSearchType);

        if(this.contributorTypeList)
            this.contributorTypeList.setValue(this.props.contributorSearchType);

        if(this.primitiveTypeList && this.props.primitiveType !== "")
            this.primitiveTypeList.setValue(this.props.primitiveType);
    }

    private entityIdSearch(identifierKey: string){
        if(!this.props.entityToggle)
            this.props.searchByEntityId(identifierKey, true, this.props.fetchWholeEntities);
        else{
            this.props.searchReducedEntity(identifierKey, this.props.searchBounds, this.props.fetchWholeEntities);
        }

        window.console.log("Searched:" + identifierKey);
    }

    private readonly searchById = (event) => {
        event.preventDefault();
        let entityId = this.props.entityId;
        let contributor = this.props.contributor;
        let contributorSearchType: SearchType = this.props.contributorSearchType;
        let primitiveId: string = this.props.primitiveId;

        if(entityId !== ""){
            this.entityIdSearch(entityId)
        }
        else if(contributor !== ""){
            this.props.searchByContributor(contributor, contributorSearchType, this.props.fetchWholeEntities);
        }
        else if(primitiveId !== ""){
            this.props.searchPrimitive(primitiveId, this.props.fetchWholeEntities);
        }
    };

    private readonly searchByType = (event) => {
        event.preventDefault();
        let entityName: string = this.props.entityName;
        let entityTypes: Array<string> = this.props.entityTypes.map(type => {
            return type.value;
        });
        let primitiveType: string = this.props.primitiveType;

        let searchBounds: LatLngBounds;
        if(this.props.searchBoundsType === ANYWHERE)
            searchBounds = GeometryUtils.maxSearchBounds;
        else
            searchBounds = this.props.searchBounds;

        if(primitiveType !== ""){
            let primitiveTypes: Array<string> = [];
            primitiveTypes.push(primitiveType);
            this.props.searchPrimitiveTypes(searchBounds, entityName, this.props.onlyNamedEntities, primitiveTypes, "", this.props.nameSearchType, this.props.fetchWholeEntities);
        }
        else{
            this.props.searchEntitiesGroup(this.props.isPolaris, searchBounds, entityName, this.props.onlyNamedEntities, entityTypes, "", this.props.nameSearchType, this.props.fetchWholeEntities);
        }
    };

    private readonly updateIdState = () => {
        let contributor = document.getElementById("contributorTag") as HTMLInputElement;
        let entityId = document.getElementById("entityId") as HTMLInputElement;
        let primitiveId = document.getElementById("primitiveId") as HTMLInputElement;

        let formState: SearchFormState = this.getStateFromProps();

        if(this.props.isPolaris) {
            formState.contributor = contributor.value;
            formState.entityId = entityId.value;
            formState.primitiveId = primitiveId.value;
        }
        else
            formState.entityId = entityId.value;

        this.props.setSearchFormState(formState);
    };

    private readonly updateName = () => {
        let entityName;
        if(this.props.isPolaris){
            entityName = document.getElementById("entityNamePolaris") as HTMLInputElement;
        }
        else{
            entityName = document.getElementById("entityNameSolr") as HTMLInputElement;
        }

        let formState: SearchFormState = this.getStateFromProps();
        formState.entityName = entityName.value;
        this.props.setSearchFormState(formState);
    };

    private readonly onEntityTypeChange = (selectedOptions) => {
        let formState: SearchFormState = this.getStateFromProps();
        if(selectedOptions !== null)
            formState.entityTypes = selectedOptions;
        else
            formState.entityTypes = [];

        this.props.setSearchFormState(formState);
    };

    private readonly onPrimitiveTypeChange = (selectedOption: string) => {
        let formState: SearchFormState = this.getStateFromProps();
        if(selectedOption !== null)
            formState.primitiveType = selectedOption;
        else
            formState.primitiveType = "";

        this.props.setSearchFormState(formState);
    };

    private onNameSearchTypeChange = (selectedOption: string) => {
        let formState: SearchFormState = this.getStateFromProps();
        formState.nameSearchType = fromString(selectedOption);

        this.props.setSearchFormState(formState);
    };

    private onContributorSearchTypeChange = (selectedOption: string) => {
        let formState: SearchFormState = this.getStateFromProps();
        formState.contributorSearchType = fromString(selectedOption);

        this.props.setSearchFormState(formState);
    };

    private onOnlyNamedEntitiesChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let formState: SearchFormState = this.getStateFromProps();
        formState.onlyNamedEntities = checked;

        this.props.setSearchFormState(formState);
    };

    public onToggleChange = () => {
        let formState: SearchFormState = this.getStateFromProps();
        formState.entityToggle = !formState.entityToggle;
        this.props.setSearchFormState(formState);
    };

    public changeSearchBoundsType = (event, value) => {
        let formState: SearchFormState = this.getStateFromProps();
        formState.searchBoundsType = value;
        this.props.setSearchFormState(formState);
    };

    private handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        let formState: SearchFormState = this.getStateFromProps();
        formState.activeTab = newValue;
        this.props.setSearchFormState(formState);
    };

    public render(): JSX.Element{
        let entityTypes: Array<{value: string, label: string}> = [];
        let primitiveTypes: Array<string> = [];
        let ontology: OntologyWrapper | null = this.props.currentStreamId !== null ? OntologyMap.getOntology(this.props.currentStreamId) : null;
        if(ontology !== null) {
            ontology.getEntityTypesWrapper().getEntityTypes().forEach((type) => entityTypes.push({value: type, label: type}));
            primitiveTypes = ontology.getPrimitiveTypesWrapper().getPrimitiveTypes();
            primitiveTypes.unshift("");
        }

        let searchTypes: Array<SearchType> = Object.values(SearchType);

        return(
            <div>
                <Tabs value={this.props.activeTab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
                    <Tab value={"typeSearch"} label={"Search by data"}/>
                    <Tab value={"idSearch"} label={"Search by id"}/>
                </Tabs>
                    <TabContent value={"idSearch"} selected={this.props.activeTab}>
                        <form className="form" acceptCharset="UTF-8" onSubmit={this.searchById}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Tooltip title={"Fetch only primitives inside viewport"} placement={"top"} arrow={true}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.props.entityToggle}
                                                    onChange={this.onToggleChange}
                                                    color="secondary"
                                                />
                                            }
                                            label="Only contained"
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <TextField id="entityId" value={this.props.entityId} type="text" label="Entity ID" variant="outlined" onChange={this.updateIdState} fullWidth={true} size="small"/>
                                </Grid>
                                {
                                    this.props.isPolaris &&
                                    <Grid item>
                                        <TextField id="primitiveId" value={this.props.primitiveId} type="text" label="Primitive ID" variant="outlined"
                                                   onChange={this.updateIdState} fullWidth={true} size="small"/>
                                    </Grid>
                                }
                                {
                                    this.props.isPolaris &&
                                    <Grid item>
                                        <Grid container direction="row" spacing={1}>
                                            <Grid item md={9}>
                                                <TextField id="contributorTag" value={this.props.contributor} type="text" label="Contributor" variant="outlined"
                                                           onChange={this.updateIdState} fullWidth={true} size="small"/>
                                            </Grid>
                                            <Grid item md={3}>
                                                <DropdownList
                                                    ref={e => {
                                                        this.contributorTypeList = e
                                                    }}
                                                    options={searchTypes}
                                                    onChange={this.onContributorSearchTypeChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item>
                                    <Button id="searchByIdButton" color="primary" variant="contained" type="submit" fullWidth={true}> Search</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </TabContent>
                    <TabContent value={"typeSearch"} selected={this.props.activeTab}>
                        <form className="form" acceptCharset="UTF-8" onSubmit={this.searchByType}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <SearchBoundsTypeForm searchBoundsType={this.props.searchBoundsType} handleChange={this.changeSearchBoundsType}/>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.props.onlyNamedEntities}
                                                onChange={this.onOnlyNamedEntitiesChange}
                                            />
                                        }
                                        label="Only named entities"
                                    />
                                </Grid>
                                {
                                    this.props.isPolaris &&
                                        <Grid item>
                                            <Grid container direction="row" spacing={1}>
                                                <Grid item md={9}>
                                                    <TextField id="entityNamePolaris" value={this.props.entityName} type="text" label="Entity name" variant="outlined" onChange={this.updateName} fullWidth={true} size="small"/>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <DropdownList
                                                        ref={e => {
                                                            this.nameTypeList = e
                                                        }}
                                                        options={searchTypes}
                                                        onChange={this.onNameSearchTypeChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                }
                                {
                                    !this.props.isPolaris &&
                                    <Grid item>
                                        <TextField id="entityNameSolr" value={this.props.entityName} type="text" label="Entity name" variant="outlined" onChange={this.updateName} fullWidth={true} size="small"/>
                                    </Grid>
                                }
                                <Grid item>
                                    <Select
                                        id="selectTypes"
                                        value={this.props.entityTypes}
                                        isMulti
                                        options={entityTypes}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.onEntityTypeChange}
                                        placeholder={"Entity types"}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: this.props.theme.palette.secondary.main,
                                                primary: this.props.theme.palette.primary.main
                                            },
                                        })}
                                    />
                                </Grid>
                                {
                                    this.props.isPolaris &&
                                    <Grid item>
                                        <DropdownList
                                            ref={e => {
                                                this.primitiveTypeList = e
                                            }}
                                            options={primitiveTypes}
                                            onChange={this.onPrimitiveTypeChange}
                                            placeholder={"Primitive type"}
                                        />
                                    </Grid>
                                }
                                <Grid item>
                                    <Button id="searchByTypeButton" color="primary" variant="contained" type="submit" fullWidth={true}> Search</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </TabContent>
                    <Grid item>
                        {
                            this.props.fetchWholeEntities ?
                                <div>
                                    <SearchLinearProgress/>
                                    <EntityLoaderProgressBar/>
                                </div>
                            :
                                <BasicEntitiesProgressBar/>
                        }
                    </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        entityId: state.searchFormState.entityId,
        primitiveId: state.searchFormState.primitiveId,
        contributor: state.searchFormState.contributor,
        searchBoundsType: state.searchFormState.searchBoundsType,
        nameSearchType: state.searchFormState.nameSearchType,
        contributorSearchType: state.searchFormState.contributorSearchType,
        onlyNamedEntities: state.searchFormState.onlyNamedEntities,
        entityName: state.searchFormState.entityName,
        entityTypes: state.searchFormState.entityTypes,
        primitiveType: state.searchFormState.primitiveType,
        activeTab: state.searchFormState.activeTab,
        entityToggle: state.searchFormState.entityToggle,

        isPolaris: state.appState.isPolaris,
        searchBounds: state.mapState.searchBounds,
        currentStreamId: state.streamState.currentStreamId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchFormState: (state: SearchFormState) => dispatch(setSearchFormState(state)),
        searchEntitiesGroup: (isPolaris: boolean, searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, nameSearchType: SearchType, fetchWholeEntities: boolean) => dispatch(searchEntitiesGroup(isPolaris, searchBounds, name, onlyNamedEntities, types, entityInfo, true, nameSearchType, fetchWholeEntities)),
        searchPrimitiveTypes: (searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, nameSearchType: SearchType, fetchWholeEntities: boolean) => dispatch(searchPrimitiveTypes(searchBounds, name, onlyNamedEntities, types, entityInfo, nameSearchType, fetchWholeEntities)),
        searchByEntityId: (identifierKey, shouldSetHighlight: boolean, fetchWholeEntities: boolean) => dispatch(searchByEntityId(identifierKey, shouldSetHighlight, fetchWholeEntities)),
        searchByContributor: (identifierKey: string, contributorSearchType: SearchType, fetchWholeEntities: boolean) => dispatch(searchByContributor(identifierKey, contributorSearchType, fetchWholeEntities)),
        searchReducedEntity: (identifierKey, boundingBox, fetchedWholeEntities: boolean) => dispatch(searchReducedEntity(identifierKey, boundingBox, fetchedWholeEntities)),
        searchPrimitive: (primitiveId: string, fetchWholeEntities: boolean) => dispatch(searchPrimitive(primitiveId, fetchWholeEntities))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SearchForm))