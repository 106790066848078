import {BasicEntity} from "../../model/BasicEntity";

type InitStateType = {
    allLoadedEntities: Array<BasicEntity>
}

const initState: InitStateType = {
    allLoadedEntities: []
};

const linkedHashMapReducer = (state: InitStateType = initState, action) => {
    switch(action.type){
        case 'SET_ALL_LOADED':
            return{
                ...state,
                allLoadedEntities: action.data
            };
        default:
            return state;
    }
};

export default linkedHashMapReducer