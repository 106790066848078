import * as React from 'react';
import { connect } from 'react-redux';
import {removeAllWkts, removeWkt, selectWktById, setMapBounds} from '../store/actions/changeMapState';
import { DisplayList } from '../commonComponents/DisplayList';
import WktPreviewForm from './WktPreviewForm';
import WktProgressSpinner from './WktProgressSpinner';
import { LatLngBounds } from 'leaflet';
import '../App.css';
import {Button, Grid} from "@material-ui/core";

type WktPreviewProps = {
    wktArray : {},
    removeAllWkts: any,
    removeWkt: any,
    setMapBounds: any,
    selectWktById: any,
    selectedWktId: string | null
}

class WktPreview extends React.Component<WktPreviewProps, any> {
    private displayList: any;

    public componentDidUpdate(){
        this.displayList.setHighlighted(this.props.selectedWktId);
    }

    public handleListClick = (id) => {
        this.props.selectWktById(id);
    };

    public handleRemoveAllClick = () => {
        this.props.selectWktById(null);

        this.props.removeAllWkts();
    };

    public handleRemoveClick = () => {
        this.props.selectWktById(null);

        if(this.props.selectedWktId)
            this.props.removeWkt(this.props.selectedWktId);
    };

    public render(): JSX.Element{
        let ids: Array<string> = [];
        let items: Array<string> = [];

        Object.keys(this.props.wktArray).forEach(key =>{
            ids.push(key);
            items.push(this.props.wktArray[key].slice(0, 40) + "...");
        });

        let length: number = Object.keys(this.props.wktArray).length;

        return(
            <div>
                <Grid container direction="column">
                    <Grid item>
                        <WktPreviewForm />
                    </Grid>
                    <Grid item>
                        <WktProgressSpinner />
                    </Grid>
                    <Grid item>
                        <DisplayList ref={d => { this.displayList = d; }} ids={ids} items={items} onClick={this.handleListClick} initialHighlighted={this.props.selectedWktId}/>
                    </Grid>
                    <Grid item>
                        {length > 0 && <Button id="removeAllButtonId" onClick={this.handleRemoveAllClick} variant="outlined"> Remove all</Button>}
                        {length > 0 && this.props.selectedWktId && <Button id="removeButtonId" onClick={this.handleRemoveClick} variant="outlined"> Remove selected</Button>}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        wktArray: state.mapState.wktArray,
        selectedWktId: state.mapState.selectedWktId
	}
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeAllWkts: () => dispatch(removeAllWkts()),
        removeWkt: (id: number) => dispatch(removeWkt(id)),
        setMapBounds: (newBounds: LatLngBounds) => dispatch(setMapBounds(newBounds)),
        selectWktById: (wktId: string | null) => dispatch(selectWktById(wktId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WktPreview)