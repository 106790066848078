import * as React from 'react';
import { DisplayTable } from '../../../commonComponents/DisplayTable';
import {Guid} from "guid-typescript";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type SourceInfoProps={
    sourceInfo: Array<string>,
    componentId: string
}

class SourceInfo extends React.Component<SourceInfoProps, any> {
    public render(): JSX.Element{
        let headerList: Array<string> = [];
        let contentList: Array<Array<string>> = [];
        
        if(this.props.sourceInfo !== null){
            headerList.push("Source");
            headerList.push("Id");

            contentList = this.props.sourceInfo.map((sourceInfo) => {
                let row: Array<string> = [];
                row.push(Guid.create().toString());
                row.push(sourceInfo.split(':')[0]);
                row.push(sourceInfo.split(':')[1]);

                return row;
            })
        }

        let length: number = this.props.sourceInfo !== null ? this.props.sourceInfo.length : 0;

        return(
            <ExpansionComponent title={"Source info (count: " + length + ")"} componentId={this.props.componentId}>
                {
                    this.props.sourceInfo !== null && length > 0 &&
                    <DisplayTable headerList={headerList} contentList={contentList}/>
                }
            </ExpansionComponent>
        )
    }
}

export { SourceInfo }