import * as React from 'react';
import {Button, Divider, Grid} from '@material-ui/core';
import { GeometryUtils } from '../utils/GeometryUtils';
import {addWkt, addWkts, selectWktById, showWktSpinner} from '../store/actions/changeMapState';
import { connect } from 'react-redux';
import './WktPreviewForm.css';
import GlobalCallbacks from "../utils/GlobalCallbacks";
import {buttonFontSize} from "../theme/Theme";

type WktPreviewFormProps = {
    addWkt: (wkt: string) => void,
    addWkts: (wkts: Array<string>) => void,
    showWktSpinner: (flag: boolean) => void,
    selectedWkt: string,
    selectWktById: (wktId: string | null) => void
}

type WktPreviewFormState = {
    color: string,
}

class WktPreviewForm extends React.Component<WktPreviewFormProps, WktPreviewFormState>{
    public componentDidMount(): void {
        let textArea: any = document.getElementById("wktTextBox");
        if(textArea)
            textArea.value = this.props.selectedWkt;
    }

    public componentDidUpdate(prevProps: Readonly<WktPreviewFormProps>, prevState: Readonly<WktPreviewFormState>, snapshot?: any): void {
        let textArea: any = document.getElementById("wktTextBox");
        if(textArea)
            textArea.value = this.props.selectedWkt;
    }

    public handlePreviewClick = (event) => {
        event.preventDefault();

        this.props.showWktSpinner(true);

        let textInputElement = document.getElementById("wktTextBox") as HTMLInputElement;
        let wkt: string = textInputElement.value.trim();

        let validation = GeometryUtils.isWktValid(wkt);
        if(validation.valid){
            this.props.selectWktById(null);
            this.props.addWkt(wkt);
        }

        GlobalCallbacks.displaySnackbarMessage(validation.message);
        
        this.props.showWktSpinner(false);
    };

    public uploadFile = () => {
        let fileInputElement = document.getElementById("fileUpload") as HTMLInputElement;
        if(fileInputElement !== null && fileInputElement.files !== null)
            if(fileInputElement.files[0]){
                this.props.showWktSpinner(true);
                let file = fileInputElement.files[0];
                let reader = new FileReader();
                reader.readAsText(file);

                reader.onload = this.fileUploaded;
            }
    };

    public fileUploaded = (e) => {
        let fileString = e.target.result;

        fileString = fileString.replace("\n", " ").replace("\r", " ");
        let wkts: Array<string> = fileString.match(/(((POLYGON|MULTILINESTRING|MULTIPOINT) ?\(\(.*?\)\))|((POINT|MULTIPOINT|LINESTRING) ?\(.*?\))|((MULTIPOLYGON) ?\(\(\(.*?\)\)\)))/g);

        (document.getElementById("uploadFormId") as HTMLFormElement).reset();

        for(let i = 0; i < wkts.length; i++){
            if(wkts[i] === "" || wkts[i] === " " || wkts[i].charAt(0) === '#'){
                wkts.splice(i, 1);
                i--;
                continue;
            }
            
            let validation = GeometryUtils.isWktValid(wkts[i]);

            if(!validation.valid){
                GlobalCallbacks.displaySnackbarMessage((i + 1) + ". wkt in the file is invalid!");
                this.props.showWktSpinner(false);
                return;
            }
        }

        this.props.addWkts(wkts);

        GlobalCallbacks.displaySnackbarMessage("File uploaded");

        this.props.selectWktById(null);
        this.props.showWktSpinner(false);
    };

    public render(): JSX.Element{
        return(
            <div>
                <Grid container direction="column">
                    <Grid item>
                        <form id="uploadFormId" className="form">
                            <Button
                                variant="outlined"
                                component="label"
                                >
                                Choose file
                                <input
                                    id="fileUpload"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={this.uploadFile}
                                />
                            </Button>
                        </form>
                    </Grid>
                    <Divider/>
                    <Grid item>
                        <form className="form" onSubmit={this.handlePreviewClick}>
                            <Grid container direction="column">
                                <Grid item>
                                    <textarea id="wktTextBox" placeholder="WKT" className="form-control" style={{fontSize: buttonFontSize, height: 100}}/>
                                </Grid>
                                <Grid item>
                                    <Button id="previewButtonId" type="submit" variant="outlined"> Preview </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider/>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedWkt: state.mapState.selectedWkt
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addWkt: (wkt: string) => dispatch(addWkt(wkt)),
        addWkts: (wkts: Array<string>) => dispatch(addWkts(wkts)),
        showWktSpinner: (flag: boolean) => dispatch(showWktSpinner(flag)),
        selectWktById: (wktId: string | null) => dispatch(selectWktById(wktId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef:true})(WktPreviewForm)