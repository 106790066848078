import * as React from 'react';
import {Property} from "../../../model/Property";
import {PropertiesView} from "./PropertiesView";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type PropertiesViewProps = {
    properties: Array<Property>,
    componentId: string
}

class Properties extends React.Component<PropertiesViewProps, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let length: number = this.props.properties ? this.props.properties.length : 0;

        return(
            <ExpansionComponent title={"Properties (count: " + length + ")"} componentId={this.props.componentId}>
                <PropertiesView properties={this.props.properties}/>
            </ExpansionComponent>
        )
    }
}

export {Properties}