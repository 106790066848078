import {StreamInfo} from "../../streams/StreamInfo";

type initialStateType = {
    streamList: {[streamId: string]: StreamInfo},
    currentStreamId: string,
    showSpinner: boolean,
    cosmosPath: string | null,
    expandedNodes: Array<string>,
    errorMessage: string
}

const initState: initialStateType = {
    streamList: {},
    currentStreamId: "",
    showSpinner: true,
    cosmosPath: null,
    expandedNodes: [],
    errorMessage: ""
};

const streamReducer = (state: initialStateType=initState, action) => {
    switch(action.type){
        case 'UPDATE_STREAM_INFORMATION':
            let streamList: {[streamId: string]: StreamInfo} = action.data.hasOwnProperty('streamList') ? action.data.streamList : state.streamList;
            let currentStreamId: string = action.data.hasOwnProperty('currentStreamId') ? action.data.currentStreamId : state.currentStreamId;
            let showSpinner: boolean = action.data.hasOwnProperty('showSpinner') ? action.data.showSpinner : state.showSpinner;
            let cosmosPath: string | null = action.data.hasOwnProperty('cosmosPath') ? action.data.cosmosPath : state.cosmosPath;

            return{
                ...state,
                streamList: streamList,
                currentStreamId: currentStreamId,
                showSpinner: showSpinner,
                cosmosPath: cosmosPath,
                errorMessage: ""
            };
        case 'SET_CURRENT_STREAM_ID':
            return{
                ...state,
                currentStreamId: action.data
            };
        case 'SET_EXPANDED_NODES':
            return{
                ...state,
                expandedNodes: action.data
            };
        case 'SET_ERROR_MESSAGE':
            return{
                ...state,
                errorMessage: action.data,
                showSpinner: false
            };
        default:
            return state;
    }
};

export default streamReducer