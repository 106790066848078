import * as React from 'react';
import { connect } from 'react-redux';
import { resetSearchStore } from '../store/actions/searchEntities';
import {resetEditingStore} from "../store/actions/changeEditingState";
import {resetMapStore} from "../store/actions/changeMapState";
import {Button} from "@material-ui/core";

type ClearMapProps = {
    resetSearchStore: any,
    resetEditingStore: any,
    resetMapStore: any
}

class ClearMap extends React.Component<ClearMapProps, any>{
    private handleClick = (event) => {
        event.preventDefault();
        this.props.resetSearchStore();
        this.props.resetEditingStore();
        this.props.resetMapStore();
    };

    public render(): JSX.Element{
        return(
            <div>
                <form className="form" onSubmit={this.handleClick}>
                    <Button id="clearButtonId" type="submit" variant="outlined">Clear map</Button>
                </form>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetSearchStore: () => dispatch(resetSearchStore()),
        resetEditingStore: () => dispatch(resetEditingStore()),
        resetMapStore: () => dispatch(resetMapStore())

    }
};

export default connect(null, mapDispatchToProps)(ClearMap)