import * as React from 'react';
import {connect} from "react-redux";
import StreamChooser from "../streams/StreamChooser";
import SearchTab from "../search/SearchTab";
import InformationTab from "../entityInformation/InformationTab";
import WktPreview from "../wktPreview/WktPreview";
import {TabContent} from "../commonComponents/TabContent";
import {STREAMS, SEARCH, INFORMATION, WKT_PREVIEW} from './TabsIds';

type TabContentProps = {
    highlightedEntity: string | null,
    creatingEntityId: string | null,
    activeTab: string
}

class TabContentControl extends React.Component<TabContentProps, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div className="scrollable-container">
                <TabContent value={STREAMS} selected={this.props.activeTab}>
                    <StreamChooser />
                </TabContent>
                <TabContent value={SEARCH} selected={this.props.activeTab}>
                    <SearchTab highlightedEntity={this.props.highlightedEntity}/>
                </TabContent>
                <TabContent value={INFORMATION} selected={this.props.activeTab}>
                    <InformationTab highlightedEntity={this.props.highlightedEntity} creatingEntityId={this.props.creatingEntityId}/>
                </TabContent>
                <TabContent value={WKT_PREVIEW} selected={this.props.activeTab}>
                    <WktPreview />
                </TabContent>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        highlightedEntity: state.searchResult.highlightedEntity,
        creatingEntityId: state.searchResult.creatingEntityId,
        activeTab: state.mapState.activeTab
    }
};

export default connect(mapStateToProps)(TabContentControl)