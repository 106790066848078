import * as React from 'react'
import {Marker} from "react-leaflet";
import * as L from 'leaflet'
import {IconUtils} from "../../utils/IconUtils";

type MyMarkerProps = {
    position: L.LatLngExpression,
    icon: L.Icon,
    onClick
}

class MarkerWrapper extends React.Component<MyMarkerProps, any>{
    public static defaultProps = {
        icon:  IconUtils.DefaultMarkerIcon
    };

    public render(): JSX.Element{
        return(
            <div>
                <Marker position={this.props.position} icon={this.props.icon} onClick={this.props.onClick}>
                </Marker>
            </div>
        )
    }
}

export {MarkerWrapper}