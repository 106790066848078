import {createMuiTheme} from "@material-ui/core/styles";
import theme, {buttonFontSize} from "./Theme";

const textFieldTheme = createMuiTheme({
    ...theme,
    overrides: {
        MuiInputBase: {
            input: {
                fontSize: buttonFontSize
            }
        },
        MuiOutlinedInput: {
            inputMarginDense: {
                paddingTop: "9px",
                paddingBottom: "9px"
            }
        }
    }
});

export default textFieldTheme;
