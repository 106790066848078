import * as React from 'react';
import { connect } from 'react-redux';
import {EditServerProxy} from "../../utils/EditServerProxy";

type EditingChangeProps = {
    isEdit: boolean,
    setEditingState: any
}

class EditingChangeListener extends React.Component <EditingChangeProps, any>{
    public componentDidMount(){
        this.props.setEditingState(this.props.isEdit);
    }

    public componentDidUpdate(){
        this.props.setEditingState(this.props.isEdit);
    }

    public render(){
        EditServerProxy.setEditingState(this.props.isEdit);

        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        isEdit: state.searchResult.isEdit
	}
};

export default connect(mapStateToProps, null, null, {forwardRef:true})(EditingChangeListener)