import * as React from 'react';
import {EntityNames} from "./names/EntityNames";
import PrimitivesList from "./primitives/PrimitivesList";
import Relationships from "./relations/Relationships";
import GeoChain from "./relations/GeoChain";
import {SourceInfo} from "./sources/SourceInfo";
import {Contributors} from "./sources/Contributors";
import Children from "./relations/Children";
import {Properties} from "./properties/Properties";
import {BasicEntity} from "../../model/BasicEntity";
import {EntityExtension} from "../../model/EntityExtension";
import {ModelUtils} from "../../utils/ModelUtils";
import {MapEntity} from "../../model/MapEntity";
import {Divider, Grid} from "@material-ui/core";
import LabelValue from "./generalInformation/LabelValue";
import AggregatedSourceInfo from "./sources/AggregatedSourceInfo";

type EntityDetailsProps = {
    entity: MapEntity | null
}

class EntityDetails extends React.Component<EntityDetailsProps, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if(this.props.entity !== null) {
            let basicEntity: BasicEntity = this.props.entity.basicEntity;
            let entityExtension: EntityExtension | null = this.props.entity.entityExtension;
            if ((basicEntity === null || basicEntity === undefined) && entityExtension !== null && entityExtension !== undefined) {
                basicEntity = ModelUtils.basicEntityFromEntityExtension(entityExtension);
            }

            return (
                <div>
                    <Grid container direction="column">
                        <Grid item>
                            <LabelValue label={"EntityID"} value={basicEntity.id}/>
                        </Grid>
                        <Grid item>
                            <LabelValue label={"Type"} value={entityExtension !== null ? entityExtension.entityTypeKeyName : basicEntity.type}/>
                        </Grid>
                        <Grid item>
                            <LabelValue label={"Name"} value={basicEntity.name}/>
                        </Grid>
                    </Grid>
                    <Divider />
                    {
                        entityExtension !== null &&
                            <div>
                                <Grid container direction="column">
                                    <Grid item className="gridItemVertical">
                                        <EntityNames names={entityExtension.entityNames}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <PrimitivesList primitives={entityExtension.primitives}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <Relationships entityRelates={entityExtension.entityRelates} entityId={basicEntity.id}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <GeoChain geoChain={entityExtension.geoChain} entityId={basicEntity.id}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <SourceInfo sourceInfo={entityExtension.sourceInfo} componentId={"Entity"}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <Contributors contributors={entityExtension.contributors}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <AggregatedSourceInfo sourceInfo={entityExtension.aggregatedSourceInfo}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <Children children={entityExtension.children} entityId={basicEntity.id}/>
                                    </Grid>
                                    <Grid item className="gridItemVertical">
                                        <Properties properties={entityExtension.properties}
                                                    componentId={"entityProperties"}/>
                                    </Grid>
                                </Grid>
                                <Divider/>
                            </div>
                    }
                </div>
            )
        }
    }

}

export {EntityDetails}
