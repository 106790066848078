import * as React from 'react';
import {IconButton, Snackbar} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GlobalCallbacks from "../utils/GlobalCallbacks";

type SnackbarWrapperState = {
    snackbarOpen: boolean,
    message: string
}

class SnackbarWrapper extends React.Component<any, SnackbarWrapperState>{
    public constructor(props) {
        super(props);

        this.state = {
            snackbarOpen: false,
            message: ""
        };
    }

    public componentDidMount(): void {
        GlobalCallbacks.setDisplaySnackbarMessage(this.onSnackbarOpen);
    }

    public componentWillUnmount(): void {
        GlobalCallbacks.resetDisplaySnackbarMessage();
    }

    public onSnackbarOpen = (message) => {
        this.setState({
            snackbarOpen: true,
            message: message
        });
    };

    public onSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    public render(): JSX.Element{
        return(
            <Snackbar
                open={this.state.snackbarOpen}
                message={<span>{this.state.message}</span>}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                action={<IconButton onClick={this.onSnackbarClose}><CloseIcon /></IconButton>}
            />
        )
    }
}

export default SnackbarWrapper;