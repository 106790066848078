import {Ontology} from "../model/Ontology";

export class OntologyNamesWrapper{
    private entityNameRoles: Array<string> = [];
    private culture: Array<{ cultureName: string, englishName: string }> = [];

    public constructor(ontology: Ontology | null){
        if(ontology) {
            this.initEntityNameRoles(ontology);
            this.initCulture(ontology);
        }
    }

    public getEntityNameRoles(): Array<string>{
        return this.entityNameRoles;
    }

    public getCulture(): Array<{cultureName: string, englishName: string}>{
        return this.culture;
    }

    private initEntityNameRoles(ontology: Ontology){
        let ret: Array<string> = ontology.NameRole.map(name => {
            return name["@KeyName"];
        });

        this.entityNameRoles = ret.sort((lhs: string, rhs: string) => lhs > rhs ? 1 : -1);
    }

    private initCulture(ontology: Ontology){
        this.culture = ontology.Culture.map(culture => {
            return {cultureName: culture["@CultureName"], englishName: culture["@EnglishName"]}
        });
    }
}