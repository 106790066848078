import * as React from 'react';
import {Theme, Typography, withTheme} from "@material-ui/core";
import "../App.css";
import {Tooltip} from "@material-ui/core";

type ExpansionComponentProps = {
    title: string,
    componentId: string,
    theme: Theme,
    tooltip: string | null
}

class ExpansionComponent extends React.Component<ExpansionComponentProps, any>{
    public static defaultProps = {
        tooltip: null
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
                <div>
                    <a data-toggle="collapse" href={"#" + this.props.componentId} style={{color: this.props.theme.palette.primary.main}}>
                        {
                            this.props.tooltip ?
                                <Tooltip title={this.props.tooltip}>
                                    <Typography>
                                        {this.props.title}
                                    </Typography>
                                </Tooltip>
                                :
                                <Typography>
                                    {this.props.title}
                                </Typography>
                        }
                    </a>
                </div>
                <div id={this.props.componentId} className="collapse">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default withTheme(ExpansionComponent)
