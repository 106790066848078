import * as React from 'react';
import '../App.css'
import {FormControlLabel, RadioGroup} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

type DisplayListProps = {
    ids: Array<any>;
    items: Array<string>;
    onClick: any,
    initialHighlighted: any
}

type DisplayListState = {
    highlighted: any
}

class DisplayList extends React.Component<DisplayListProps, DisplayListState>{
    public constructor(props){
        super(props);

        this.state = {
            highlighted: this.props.initialHighlighted
        }
    }

    public setHighlighted(item: any){
        if(this.state.highlighted !== item){
            this.setState({
                highlighted: item
            })
        }
    }

    public render(): JSX.Element{
        return(
            <div>
                <RadioGroup>
                    {
                        this.props.items.map((item, index) => {
                            let id = this.props.ids[index];
                            return (
                                <div key={id}>
                                    <FormControlLabel
                                        control={
                                            <Radio value={id}
                                               key={id}
                                               checked={this.state.highlighted === id}
                                               onClick={() => this.props.onClick(id)}
                                            />
                                        }
                                        label={(index + 1) + ". " + item}
                                    />
                                </div>
                            )
                        })
                    }
                </RadioGroup>
            </div>
        )
    }
}

export { DisplayList }