import * as React from 'react';
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";

type ConfirmDialogProps = {
    callback: any,
    dialogOpen: boolean
}

type ConfirmDialogState = {
    dialogOpen: boolean
};

class ConfirmDialog extends React.Component<ConfirmDialogProps, ConfirmDialogState>{
    public constructor(props){
        super(props);

        this.state = {
            dialogOpen: this.props.dialogOpen
        }
    }

    public componentDidUpdate(prevProps: Readonly<ConfirmDialogProps>, prevState: Readonly<ConfirmDialogState>, snapshot?: any): void {
        if(this.props.dialogOpen !== prevProps.dialogOpen)
            this.setState({
                dialogOpen: this.props.dialogOpen
            })
    }

    public onDialogClose = () => {
        this.props.callback(false);

        this.setState({
            dialogOpen: false
        })
    };

    public onConfirm = () => {
        this.props.callback(true);

        this.setState({
            dialogOpen: false
        })
    };

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
                <Dialog open={this.state.dialogOpen} onClose={this.onDialogClose} fullWidth={true}>
                    <DialogTitle>
                        Are you sure?
                    </DialogTitle>
                    <DialogActions>
                        <button className="btn btn-dark" onClick={this.onDialogClose}>Cancel</button>
                        <button className="btn btn-dark" onClick={this.onConfirm}>Confirm</button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export {ConfirmDialog}