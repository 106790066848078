import * as L from 'leaflet';
import 'leaflet-bing-layer';
import {TileLayer} from "leaflet";

type LayerInfo = {
    tileLayer: TileLayer,
    maxZoom: number,
    label: string
}

class BaseMapUtils{
    private static osmRoadLayer: LayerInfo =
        {
            tileLayer: L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png',
                {
                    attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                    maxZoom: 19
                }),
            maxZoom: 19,
            label: "OSM Road"
        };

    private static bingRoadLayer: LayerInfo = BaseMapUtils.getBingLayer("RoadOnDemand", "Bing Road");

    private static bingAerialLayer: LayerInfo = BaseMapUtils.getBingLayer("AerialWithLabelsOnDemand", "Bing Aerial");

    private static googleRoadLayer: LayerInfo =
        {
            tileLayer: L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
                {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                }),
            maxZoom: 20,
            label: "Google Road"
        };

    private static googleAerialLayer: LayerInfo =
        {
            tileLayer: L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
                {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                }),
            maxZoom: 20,
            label: "Google Aerial"
        };

    private static hereRoadLayer: LayerInfo =
        {
            tileLayer: L.tileLayer("http://{s}.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=jTtAvkJ6MYOpnCvEnhS8&app_code=PjlOqX3NN8hK0HTSVVyMeA&lg=eng",
                {
                    attribution: 'Map &copy; 2016 <a href="http://developer.here.com">HERE</a>',
                    subdomains: '1234',
                    maxZoom: 20
                }),
            maxZoom: 20,
            label: "HERE Road"
        };

    private static hereAerialLayer: LayerInfo =
        {
            tileLayer: L.tileLayer('http://{s}.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?app_id=jTtAvkJ6MYOpnCvEnhS8&app_code=PjlOqX3NN8hK0HTSVVyMeA&lg=eng',
                {
                    attribution: 'Map &copy; 1987-2014 <a href="http://developer.here.com">HERE</a>',
                    subdomains: '1234',
                    maxZoom: 20
                }),
            maxZoom: 20,
            label: "HERE Aerial"
        };

    private static tomtomRoadLayer: LayerInfo =
        {
            tileLayer: L.tileLayer('https://api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?view=Unified&key=cP6sqfV8zbYpjYpSrGQaAibC2q37pHEB',
                {
                    maxZoom: 20
                }),
            maxZoom: 20,
            label: "TomTom Road"
        };

    public static baseMapList: Array<LayerInfo> = [ BaseMapUtils.osmRoadLayer, BaseMapUtils.bingRoadLayer, BaseMapUtils.bingAerialLayer, BaseMapUtils.googleRoadLayer, BaseMapUtils.googleAerialLayer, BaseMapUtils.hereRoadLayer, BaseMapUtils.hereAerialLayer, BaseMapUtils.tomtomRoadLayer];

    private static getBingLayer(imagerySet: string, label: string){
        return {
            tileLayer: (L as any).tileLayer.bing({
                bingMapsKey: "ArHlff05CzKbW7cPTGBWogNYIHoJrZR_GMYV9RguCxD4SZZbUKcAWvi8I3DrMPyk",
                imagerySet: imagerySet,
                maxZoom: 30
            }),
            maxZoom: 30,
            label: label
        }
    }

    public static getTileLayer(index: number){
        return BaseMapUtils.baseMapList[index]['tileLayer'];
    }

    public static getLayerInfoByName(name: string): LayerInfo | null{
        let baseMaps:Array<LayerInfo> = BaseMapUtils.baseMapList.filter((baseMap) => {
            return baseMap.label === name;
        });

        if(baseMaps.length === 0)
            return null;
        else
            return baseMaps[0];
    }

    public static getMaxZoom(index: number){
        return BaseMapUtils.baseMapList[index]['maxZoom'];
    }
}

export { BaseMapUtils }