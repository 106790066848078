import * as React from 'react';
import {createProperty, Property} from "../../../model/Property";
import MaterialTable from "material-table";
import PropertyValueDisplayEdit from "./PropertyValueDisplayEdit";
import {ModelUtils} from "../../../utils/ModelUtils";
import {connect} from "react-redux";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";
import {PropertyType} from "./PropertyType";
import ValueForm from "./ValueForm";
import KeyForm from "./KeyForm";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {MaterialTableUtils} from "../../../utils/MaterialTableUtils";

type PropertiesPath = {
    properties: Array<Property>,
    path: Array<{key: string | null, index: number | null}>
}

type PropertyPath = {
    property: Property,
    path: Array<{key: string | null, index: number | null}>
}

type PropertiesTableProps = {
    propertiesPath: PropertiesPath,
    onPropertyDeletion: (propertyPath: Array<{key: string | null, index: number | null}>) => void,
    onPropertySave: (propertyPath: Array<{key: string | null, index: number | null}>, value: string) => void,
    objectType: string | null,
    parentProperty: string | null,
    currentStreamId: string | null,
    deletePropertySet: (() => void) | null
}

class PropertiesTableEdit extends React.Component<PropertiesTableProps, any>{
    private newProperty: Property | null = null;
    private newPropertyRendered: boolean = false;

    static defaultProps = {
        objectType: null,
        parentProperty: null,
        deletePropertySet: null
    };

    public onPropertyChange = (props, newValue: string) => {
        console.log(props);
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.propertyPath.property.Value = newValue;
        props.onRowDataChange(rowData);
    };

    public createNewProperty(key: string){
        let newProperty: Property = createProperty(key);
        let propertyType: PropertyType | null = this.getPropertyType(key);
        switch (propertyType) {
            case PropertyType.Value:
                newProperty["Value"] = "";
                break;
            case PropertyType.Values:
                newProperty["Values"] = [];
                break;
            case PropertyType.Subproperties:
                newProperty["Subproperties"] = [];
                break;
        }

        return newProperty;
    }

    public getPropertyType(key: string){
        let ontology: OntologyWrapper = OntologyMap.getOntology(this.props.currentStreamId);
        return ontology.getPropertiesWrapper().getPropertyType(key);
    }

    public isEditable = (rowData) => {
        let property: Property = rowData.propertyPath.property;
        if(property.PropertyKeyName) {
            let propertyType: PropertyType = this.getPropertyType(property.PropertyKeyName);
            if(propertyType === PropertyType.Value)
                return true;
        }

        return false;
    };

    private setKey = (props, newKey: string | null) => {
        let rowData = ModelUtils.deepCopy(props.rowData);
        rowData.key = newKey;
        props.onRowDataChange(rowData);
    };

    public deletePropertySet = () => {
        if(this.props.deletePropertySet)
            this.props.deletePropertySet();
    };

    private alreadyExists(key: string){
        let ret: boolean = false;

        this.props.propertiesPath.properties.forEach(property => {
            if(property.PropertyKeyName === key) {
                ret = true;
            }
        });

        return ret;
    }

    private onRowAdd = (newData) => {
        return new Promise((resolve, reject) => {
            console.log(newData);

            if (this.newPropertyRendered) {
                GlobalCallbacks.displaySnackbarMessage("There is already one empty property!");
                resolve();
            } else if (!("key" in newData)) {
                GlobalCallbacks.displaySnackbarMessage("Set key!");
                reject();
            } else if (newData["key"] !== null) {
                let key: string = newData["key"];
                if (this.alreadyExists(key)) {
                    GlobalCallbacks.displaySnackbarMessage("Property already exists!");
                    reject();
                } else {
                    this.newProperty = this.createNewProperty(key);
                    this.forceUpdate();
                    resolve();
                }
            }

            resolve();
        });
    };

    private onRowUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            console.log(oldData);
            console.log(newData);
            if (newData["propertyPath"].property.Value !== "") {
                let valuePath: Array<{ key: string | null, index: number | null }> = newData["propertyPath"].path;
                let value: string = newData["propertyPath"].property.Value;
                this.props.onPropertySave(valuePath, value);
                resolve();
            } else {
                GlobalCallbacks.displaySnackbarMessage("Set value!");
                reject();
            }
        });
    };

    private onRowDelete = (oldData) => {
        return new Promise((resolve) => {
            console.log(oldData);
            if (oldData["newProperty"])
                this.forceUpdate();
            else
                this.props.onPropertyDeletion(oldData["propertyPath"].path);
            resolve();
        });
    };

    private getColumns(){
        let columns: Array<{}> = [];

        columns.push({title: "Key", field: "key", editable: 'onAdd', editComponent: props => (<KeyForm objectType={this.props.objectType} parentProperty={this.props.parentProperty} setKey={(newKey) => this.setKey(props, newKey)}/>)});
        columns.push({title: "Value", field: "propertyPath", editable: 'onUpdate', render: rowData => {
                if(rowData)
                    return <PropertyValueDisplayEdit propertyPath={rowData.propertyPath} onDelete={this.props.onPropertyDeletion} onSave={this.props.onPropertySave}/>;
                else
                    return <div/>
            },
            editComponent: props => (<ValueForm keyName={props.rowData.propertyPath.property.PropertyKeyName} value={props.rowData.propertyPath.property.Value} onChange={(newValue) => this.onPropertyChange(props, newValue)}/>)});

        return columns;
    }

    private getData(){
        let data: Array<{}> = this.props.propertiesPath.properties.map(property => {
            let path: any = this.props.propertiesPath.path.slice();
            path.push({key: property.PropertyKeyName, index: null});

            let propertyPath: PropertyPath = {property: property, path: path};
            return {key: property.PropertyKeyName, propertyPath: propertyPath, newProperty: false};
        });

        if(this.newProperty){
            let path: any = this.props.propertiesPath.path.slice();
            path.push({key: this.newProperty.PropertyKeyName, index: null});
            let propertyPath: PropertyPath = {property: this.newProperty, path: path};
            let dataRow: {} = {key: this.newProperty.PropertyKeyName, propertyPath: propertyPath, newProperty: true};
            data.push(dataRow);

            this.newProperty = null;
            this.newPropertyRendered = true;
        }
        else{
            this.newPropertyRendered = false;
        }

        return data;
    }

    public render(): JSX.Element {
        let columns: Array<{}> = this.getColumns();
        let data: Array<{}> = this.getData();

        return(
            <div>
                <MaterialTable
                    options={MaterialTableUtils.tableOptions}
                    columns={columns} data={data}
                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Delete property set',
                            isFreeAction: true,
                            hidden: this.props.parentProperty === null,
                            onClick: () => this.deletePropertySet()
                        }
                    ]}
                    editable={{
                        isEditable: (rowData) => this.isEditable(rowData),
                        onRowAdd: this.onRowAdd,
                        onRowUpdate: this.onRowUpdate,
                        onRowDelete: this.onRowDelete
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStreamId: state.streamState.currentStreamId
    }
};

export default connect(mapStateToProps)(PropertiesTableEdit)