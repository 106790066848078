import * as React from 'react';
import {Primitive} from '../../../model/Primitive';
import {PrimitiveRelationships} from './PrimitiveRelationships';
import {Properties} from "../properties/Properties";
import {changeActiveTab, selectWkt} from "../../../store/actions/changeMapState";
import {connect} from "react-redux";
import {SourceInfo} from "../sources/SourceInfo";
import {Button, Grid, Typography} from "@material-ui/core";
import LabelValue from "../generalInformation/LabelValue";
import '../../../App.css';
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import MoveLayerButton from "./MoveLayerButton";
import {Direction} from "./Direction";

type PrimitiveDetailsProps = {
    primitive: Primitive,
    selectWkt: any,
    changeActiveTab: any
}

class PrimitiveDetails extends React.Component<PrimitiveDetailsProps, any>{
    private copyToClipboard = () => {
        this.props.selectWkt(this.props.primitive.ShapeWKT);
        this.props.changeActiveTab("wktPreview");
    };

    private moveLayer = (direction: Direction) => {
        GlobalCallbacks.moveLayer(this.props.primitive.EntityID, this.props.primitive.PrimitiveID, direction);
    };

    public render(): JSX.Element{
        console.log("Highlighted primitive: " + this.props.primitive.PrimitiveID);

        return(
            <Grid>
                <Grid container direction="row">
                    <Grid item md={7}>
                        <Typography variant="h6">
                            Primitive information
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <MoveLayerButton direction={Direction.UP} moveLayerCallback={this.moveLayer}/>
                    </Grid>
                    <Grid item md={1}>
                        <MoveLayerButton direction={Direction.DOWN} moveLayerCallback={this.moveLayer}/>
                    </Grid>
                    <Grid item md={3}>
                        <Button id="copyWktButton" fullWidth={true} onClick={this.copyToClipboard} variant="contained" size={"small"}>Get WKT</Button>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className="gridItemVertical">
                        <LabelValue label={"ID"} value={this.props.primitive.PrimitiveID}/>
                    </Grid>
                    <Grid item className="gridItemVertical">
                        <LabelValue label={"Type"} value={this.props.primitive.PrimitiveTypeKeyName}/>
                    </Grid>
                    <Grid item className="gridItemVertical">
                        <SourceInfo sourceInfo={this.props.primitive.SourceInfo !== null ? this.props.primitive.SourceInfo.split(';') : []} componentId={"Primitive"}/>
                    </Grid>
                    <Grid item className="gridItemVertical">
                        <Properties properties={this.props.primitive.PrimitiveProperties} componentId={"primitiveProperties"}/>
                    </Grid>
                    <Grid item className="gridItemVertical">
                        <PrimitiveRelationships primitiveRelates={this.props.primitive.Relationships} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectWkt: (wkt: string) => dispatch(selectWkt(wkt)),
        changeActiveTab: (key: string) => dispatch(changeActiveTab(key))
    }
};


export default connect(null, mapDispatchToProps)(PrimitiveDetails)