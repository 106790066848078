import {SolrResponse} from "../../model/SolrResponse";
import {SolrEntity} from "../../model/SolrEntity";
import {BasicEntity} from "../../model/BasicEntity";
import {ModelUtils} from "../../utils/ModelUtils";
import {LinkedHashMap} from "../../utils/LinkedHashMap";
import {Primitive} from "../../model/Primitive";
import {Entity} from "../../model/Entity";
import {UrlUtils} from "../../permalink/UrlUtils";
import {MapState} from "../../mapState/MapState";
import {MapStateWrapper} from "../../mapState/MapStateWrapper";
import {EditServerProxy} from "../../utils/EditServerProxy";

type InitStateType = {
    mapState: MapState,
    basicEntities: {},
    highlightedEntity: string | null,
    highlightedPrimitive: string | null,
    updateInformation: boolean,
    loaded : number,
    total: number,
    showSearchProgress: boolean,
    showMarkers: boolean,
    multipleEntityView: boolean,
    entitiesKeptOnMap: Set<string>,
    elapsedTime: number,
    message: string | null,
    isEdit: boolean,
    creatingEntityId: string | null,
    creatingEntityObject: Entity | null,
    creatingPrimitivesList: Array<Primitive>,
    creatingPrimitiveId: string | null,
    creatingPrimitiveObject: Primitive | null,
    formBasicEntities: {[entityId: string]: BasicEntity},
    basicFetchMessage: string | null,
    showBasicSearchProgress: boolean,
    chosenPrimitive: string | null,
    chosenEntity: string | null
}

const initState : InitStateType = {
    mapState: MapState.Visualizing,
    basicEntities: {},
    highlightedEntity: null,
    highlightedPrimitive: null,
    updateInformation: false,
    loaded: 0,
    total: 0,
    showSearchProgress: false,
    showMarkers: true,
    multipleEntityView: true,
    entitiesKeptOnMap: new Set(),
    elapsedTime: -1,
    message: null,
    isEdit: false,
    creatingEntityId: null,
    creatingEntityObject: null,
    creatingPrimitivesList: [],
    creatingPrimitiveId: null,
    creatingPrimitiveObject: null,
    formBasicEntities: {},
    basicFetchMessage: null,
    showBasicSearchProgress: false,
    chosenPrimitive: null,
    chosenEntity: null
};

function removeUrlParameters(){
    UrlUtils.removeParameters(["highlightedEntity", "highlightedPrimitive", "keptEntities", "search[entityId]", "search[primitiveId]", "search[contributorSearchType]", "search[contributor]", "search[nameSearchType]", "search[name]", "search[onlyNamed]", "search[primitiveTypes]", "search[types]", "search[bounds][S]", "search[bounds][N]", "search[bounds][E]", "search[bounds][W]"]);
}

const searchReducer = (state: InitStateType = initState, action) => {
    let basicEntities: {[entityId: string]: BasicEntity} = {};
    let highlightedEntity: string | null;
    let highlightedPrimitive: string | null;
    let keptOnMap: Set<string>;
    let entityId: string | null = null;
    let primitiveId: string | null = null;
    let basicEntity: BasicEntity;
    let updateInformation: boolean;

    switch(action.type){
        case 'SEARCH_ENTITIES_GROUP':
            window.console.log(action.type);
            let loaded: number = 0;
            let total: number = 0;

            let response: SolrResponse = action.data.data;
            if(response.TotalCount > 0){
                response.RemainingEntities.map((entity: SolrEntity, index: number) => {
                    basicEntity = ModelUtils.basicEntityFromSolrEntity(entity, index + 1);
                    basicEntities[entity.Id] = basicEntity;
                    return null;
                })
            }

            entityId = action.data.shouldSetHighlight ? null : state.highlightedEntity;
            primitiveId = action.data.shouldSetHighlight ? null : state.highlightedPrimitive;

            return{
                ...state,
                basicEntities: basicEntities,
                highlightedEntity: entityId,
                isEdit: false,
                highlightedPrimitive: primitiveId,
                updateInformation: !state.updateInformation,
                loaded: loaded,
                total: total,
                multipleEntityView: true,
                elapsedTime: response.Time,
                message: response.Message
            };

        case 'ADD_BASIC_ENTITY':
            basicEntity = ModelUtils.basicEntityFromEntity(action.data, 1);
            let entities: {[entityId: string]: BasicEntity} = ModelUtils.deepCopy(state.basicEntities);
            entities[basicEntity.id] = basicEntity;
            console.log(entities);
            return{
                ...state,
                basicEntities: entities
            };


        case 'SEARCH_ENTITY':
            window.console.log(action.type);

            highlightedEntity = action.data.id;
            if(highlightedEntity !== null){
                basicEntities[highlightedEntity] = LinkedHashMap.getItem(highlightedEntity).basicEntity;
            }

            entityId = action.data.shouldSetHighlight ? highlightedEntity : state.highlightedEntity;
            primitiveId = action.data.shouldSetHighlight ? null : state.highlightedPrimitive;
            
            return{
                ...state,
                basicEntities: basicEntities,
                highlightedEntity: entityId,
                isEdit: false,
                highlightedPrimitive: primitiveId,
                updateInformation: !state.updateInformation,
                loaded: 0,
                total: 0,
                showSearchProgress: false,
                elapsedTime: action.data.time,
                message: action.data.message
            };

        case 'PRIMITIVE_SEARCH':
            highlightedEntity = action.data.id;
            if(highlightedEntity){
                basicEntities[highlightedEntity] = LinkedHashMap.getItem(highlightedEntity).basicEntity;
            }
            EditServerProxy.setEditingWkt(action.data.primitiveWkt);

            entityId = action.data.shouldSetHighlight ? highlightedEntity : state.highlightedEntity;
            primitiveId = action.data.shouldSetHighlight ? action.data.primitiveId : state.highlightedPrimitive;

            return{
                ...state,
                basicEntities: basicEntities,
                highlightedEntity: entityId,
                isEdit: false,
                highlightedPrimitive: primitiveId,
                updateInformation: !state.updateInformation,
                loaded: 0,
                total: 0,
                showSearchProgress: false,
                elapsedTime: action.data.time,
                message: action.data.message
            };

        case 'SEARCH_ENTITY_FROM_GROUP':
            window.console.log(action);

            if(!action.data.shouldSetHighlight || (state.isEdit && action.data.id !== state.highlightedEntity)){
                highlightedEntity = state.highlightedEntity;
                highlightedPrimitive = state.highlightedPrimitive;
                updateInformation = state.updateInformation;
            }
            else {
                highlightedEntity = action.data.id;
                highlightedPrimitive = null;
                updateInformation = !state.updateInformation;
            }

            return{
                ...state,
                highlightedEntity: highlightedEntity,
                highlightedPrimitive: highlightedPrimitive,
                updateInformation: updateInformation,
                showSearchProgress: false,
                elapsedTime: action.data.time,
                message: action.data.message
            };

        case 'SET_SEARCH_PROGRESS':
            return{
                ...state,
                loaded: action.data.loaded,
                total: action.data.total
            };

        case 'SHOW_BASIC_SEARCH_PROGRESS':
            return{
                ...state,
                showBasicSearchProgress: true
            };

        case 'SHOW_SEARCH_PROGRESS':
            return{
                ...state,
                showSearchProgress: true
            };

        case 'HIDE_SEARCH_PROGRESS':
            return {
                ...state,
                showSearchProgress: false
            };

        case 'SET_MULTIPLE_ENTITY_VIEW':
            return{
                ...state,
                multipleEntityView: action.data
            };

        case 'SET_SHOW_MARKERS':
            return{
                ...state,
                showMarkers: action.data
            };

        case 'SET_HIGHLIGHTED_PRIMITIVE':
            if(MapStateWrapper.isPrimitiveHighlightPossible(state.mapState)) {
                EditServerProxy.setEditingWkt(action.data.primitiveWkt);
                return {
                    ...state,
                    highlightedPrimitive: action.data.primitiveId,
                    updateInformation: !state.updateInformation
                };
            }

            return state;

        case 'RESET_HIGHLIGHTED_PRIMITIVE':
            if(MapStateWrapper.shouldResetHighlightedPrimitive(state.mapState)) {
                return{
                    ...state,
                    highlightedPrimitive: null,
                    updateInformation: !state.updateInformation
                };
            }

            return state;

        case 'SET_HIGHLIGHTED_ENTITY':
            if(MapStateWrapper.isEntityHighlightPossible(state.mapState))
                return{
                    ...state,
                    highlightedEntity: action.data.entityId,
                    highlightedPrimitive: null,
                    updateInformation: !state.updateInformation
                };

            return state;

        case 'HANDLE_PRIMITIVE_CLICK':
            if(MapStateWrapper.shouldHandlePrimitiveClick(state.mapState, state.highlightedEntity, action.data.entityId, state.highlightedPrimitive, action.data.primitiveId)) {
                EditServerProxy.setEditingWkt(action.data.primitiveWkt);
                return {
                    ...state,
                    highlightedEntity: action.data.entityId,
                    highlightedPrimitive: action.data.primitiveId,
                    updateInformation: !state.updateInformation
                };
            }
            else if(MapStateWrapper.isBinaryEditState(state.mapState)){
                return {
                    ...state,
                    chosenEntity: action.data.entityId,
                    chosenPrimitive: action.data.primitiveId
                }
            }

            return state;

        case 'RESET_SEARCH_STORE':
            removeUrlParameters();

            return{
                ...state,
                basicEntities: {},
                highlightedEntity: null,
                highlightedPrimitive: null,
                updateInformation: !state.updateInformation,
                loaded: 0,
                total: 0,
                showSearchProgress: false,
                showMarkers: true,
                multipleEntityView: true,
                entitiesKeptOnMap: new Set(),
                elapsedTime: -1,
                message: null,
                isEdit: false,
                creatingEntityId: null,
                creatingEntityObject: null,
                creatingPrimitiveId: null,
                creatingPrimitiveObject: null
            };

        case 'KEEP_ON_MAP':
            keptOnMap = new Set(state.entitiesKeptOnMap);
            keptOnMap.add(action.data);
            LinkedHashMap.addKeptEntity(action.data);

            return{
                ...state,
                entitiesKeptOnMap: keptOnMap
            };

        case 'KEEP_ENTITIES':
            keptOnMap = new Set(state.entitiesKeptOnMap);

            action.data.forEach((entityId: string) => {
                keptOnMap.add(entityId);
                LinkedHashMap.addKeptEntity(entityId);
            });

            return{
                ...state,
                entitiesKeptOnMap: keptOnMap
            };

        case 'REMOVE_FROM_MAP':
            keptOnMap = new Set(state.entitiesKeptOnMap);
            keptOnMap.delete(action.data);
            LinkedHashMap.removeKeptEntity(action.data);

            highlightedEntity = null;
            highlightedPrimitive = null;

            if(state.basicEntities[action.data]){
                highlightedEntity = state.highlightedEntity;
                highlightedPrimitive = state.highlightedPrimitive;
            }

            return{
                ...state,
                highlightedEntity: highlightedEntity,
                highlightedPrimitive: highlightedPrimitive,
                updateInformation: !state.updateInformation,
                entitiesKeptOnMap: keptOnMap
            };

        case 'CLEAR_KEPT_ON_MAP':
            keptOnMap = new Set();
            LinkedHashMap.removeAllKeptEntities();

            return{
                ...state,
                entitiesKeptOnMap: keptOnMap
            };

        case 'SET_EDIT_MODE':
            if(MapStateWrapper.isEditModeChangePossible(state.mapState)) {
                return {
                    ...state,
                    mapState: action.data ? MapState.Editing : MapState.Visualizing,
                    isEdit: action.data
                };
            }

            return state;

        case 'SET_ENTITY_CREATION':
            if(MapStateWrapper.isEntityCreationPossible(state.mapState))
                return{
                    ...state,
                    mapState: MapState.Creating,
                    creatingEntityId: action.data.entityId,
                    creatingEntityObject: action.data.entity,
                    creatingPrimitivesList: action.data.entity.Primitives,
                    creatingPrimitiveId: null,
                    creatingPrimitiveObject: null,
                    isEdit: false,
                    highlightedEntity: null,
                    highlightedPrimitive: null
                };

            return state;

        case 'TERMINATE_ENTITY_CREATION':
            return{
                ...state,
                mapState: MapState.Visualizing,
                creatingEntityId: null,
                creatingEntityObject: null,
                creatingPrimitiveId: null,
                creatingPrimitiveObject: null,
                creatingPrimitivesList: []
            };

        case 'SET_CREATING_PRIMITIVES_LIST':
            return{
                ...state,
                creatingPrimitivesList: action.data
            };

        case 'SET_PRIMITIVE_CREATION':
            if(MapStateWrapper.isPrimitiveCreationPossible(state.mapState, state.creatingPrimitiveId, state.creatingPrimitivesList)) {
                EditServerProxy.setEditingWkt(action.data.primitive.ShapeWKT);
                return {
                    ...state,
                    mapState: state.mapState === MapState.Creating ? MapState.Creating : MapState.CreatingPrimitive,
                    creatingPrimitiveId: action.data.primitiveId,
                    creatingPrimitiveObject: action.data.primitive,
                    highlightedPrimitive: null
                };
            }

            return state;

        case 'TERMINATE_PRIMITIVE_CREATION':
            return{
                ...state,
                mapState: state.mapState === MapState.Creating ? MapState.Creating : MapState.Editing,
                creatingPrimitiveId: null,
                creatingPrimitiveObject: null
            };

        case 'SEARCH_BASIC_ENTITIES':
            let solrResponse: SolrResponse = action.data;
            basicEntities = {};
            if(solrResponse.TotalCount > 0){
                solrResponse.RemainingEntities.forEach((entity: SolrEntity, index: number) => {
                    basicEntity = ModelUtils.basicEntityFromSolrEntity(entity, index + 1);
                    basicEntities[entity.Id] = basicEntity;
                })
            }

            return{
                ...state,
                formBasicEntities: basicEntities,
                basicFetchMessage: solrResponse.Message,
                showBasicSearchProgress: false
            };

        case 'SEARCH_BASIC_ENTITY':
            basicEntity = ModelUtils.basicEntityFromEntity(action.data.data, 1);
            basicEntities = {};
            basicEntities[basicEntity.id] = basicEntity;

            return{
                ...state,
                formBasicEntities: basicEntities,
                basicFetchMessage: action.data.message,
                showBasicSearchProgress: false
            };

        case 'SET_MAP_STATE':
            if(MapStateWrapper.isEditingState(state.mapState) || MapStateWrapper.isEditingState(action.data))
                return{
                    ...state,
                    mapState: action.data
                };

            return state;

        case 'UPDATE_INFORMATION_TAB':
            return{
                ...state,
                updateInformation: !state.updateInformation
            };

        case 'UPDATE_CHOSEN':
            return{
                ...state,
                chosenEntity: null,
                chosenPrimitive: null
            };

        default:
            return state
    }
};

export default searchReducer