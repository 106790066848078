import {CURRENT_MAP_VIEW} from "../../search/SearchBoundsTypes";
import {SearchFormState} from "../../search/SearchForm";
import {SearchType} from "../../search/SearchType";

const initState: SearchFormState = {
    entityId: "",
    primitiveId: "",
    contributor: "",
    searchBoundsType: CURRENT_MAP_VIEW,
    nameSearchType: SearchType.Equals,
    contributorSearchType: SearchType.Equals,
    onlyNamedEntities: false,
    entityName: "",
    entityTypes: [],
    primitiveType: "",
    entityToggle: false,
    activeTab: "typeSearch"
};

const searchFormReducer = (state: SearchFormState = initState, action) => {
    switch(action.type){
        case 'SET_SEARCH_FORM_STATE':
            return action.data;
        default:
            return state;
    }
};

export default searchFormReducer