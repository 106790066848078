import * as React from 'react';
import { connect } from 'react-redux';
import {LinearProgress} from "@material-ui/core";

type SearchLinearProgressProps = {
    showSearchProgress: boolean
}

class SearchLinearProgress extends React.Component<SearchLinearProgressProps, any>{
    public render():JSX.Element{
        return(
            <div>
                {this.props.showSearchProgress && <LinearProgress color={"secondary"}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        showSearchProgress: state.searchResult.showSearchProgress
	}
};

export default connect(mapStateToProps)(SearchLinearProgress)