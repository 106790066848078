import * as React from 'react';
import TabControl from "./TabControl";
import {Grid, Toolbar} from "@material-ui/core";
import {MapCenter} from "./MapCenter";
import ElapsedTime from "./ElapsedTime";
import ClearMap from "./ClearMap";
import CreateEntityButton from "./CreateEntityButton";
import ToggleShowMarkers from "./ToggleShowMarkers";
import MaterialAppBar from "@material-ui/core/AppBar";

class AppBar extends React.Component<any, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <MaterialAppBar color="default" position="static">
                <Toolbar variant={"dense"}>
                    <Grid container direction="row" alignItems="center" justify="center">
                        <Grid item md={4}>
                            <TabControl/>
                        </Grid>
                        <Grid item md={2}>
                            <ElapsedTime />
                        </Grid>
                        <Grid item md={6}>
                            <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
                                <Grid item>
                                    <MapCenter />
                                </Grid>
                                <Grid item>
                                    <ClearMap />
                                </Grid>
                                <Grid item>
                                    <CreateEntityButton />
                                </Grid>
                                <Grid item>
                                    <ToggleShowMarkers />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </MaterialAppBar>
        )
    }
}

export default AppBar