import * as React from 'react';
import {PropertiesEdit} from "../properties/PropertiesEdit";
import PrimitiveRelationshipsEdit from "./PrimitiveRelationshipsEdit";
import {Primitive} from "../../../model/Primitive";
import {connect} from "react-redux";
import {terminatePrimitiveCreation} from "../../../store/actions/searchEntities";
import {Property} from "../../../model/Property";
import {Relate} from "../../../model/Relate";
import {ModelUtils} from "../../../utils/ModelUtils";
import {Guid} from "guid-typescript";
import {ArrayUtils} from "../../../utils/ArrayUtils";
import {setEntityToUpdate} from "../../../store/actions/changeEditingState";
import {EditServerProxy} from "../../../utils/EditServerProxy";
import {EntityResponse} from "../../../model/EntityResponse";
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {Button, Grid, Typography} from "@material-ui/core";
import LabelValue from "../generalInformation/LabelValue";
import {registerLoadedEntity} from "../../../store/actions/changeLinkedHashMap";

type NewPrimitiveProps = {
    creatingPrimitivesList: Array<Primitive>,
    creatingEntityId: string | null,
    primitive: Primitive,
    terminatePrimitiveCreation: () => void,
    setEntityToUpdate: any,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void
}

type NewPrimitiveState = {
    primitive: Primitive
}

class NewPrimitiveForm extends React.Component<NewPrimitiveProps, NewPrimitiveState>{
    public constructor(props){
        super(props);

        this.state = {
            primitive: ModelUtils.deepCopy(this.props.primitive)
        }
    }

    public componentDidUpdate(prevProps: NewPrimitiveProps){
        if(!NewPrimitiveForm.compareProps(prevProps, this.props)){
            this.setState({
                primitive: ModelUtils.deepCopy(this.props.primitive)
            })
        }
    }

    private static compareProps(lhs: NewPrimitiveProps, rhs: NewPrimitiveProps){
        if(lhs === null || rhs === null)
            return lhs === rhs;

        if(lhs.primitive === null || rhs.primitive === null)
            return lhs.primitive === rhs.primitive;

        return JSON.stringify(lhs.primitive) === JSON.stringify(rhs.primitive);
    }

    public savePrimitive = (e) => {
        e.preventDefault();

        let primitive: Primitive = ModelUtils.deepCopy(this.state.primitive);
        let editingWkt: string | null = EditServerProxy.getEditingWkt();

        if(editingWkt === this.state.primitive.ShapeWKT) {
            GlobalCallbacks.displaySnackbarMessage("Primitive geometry is not changed!");
            return;
        }

        primitive.ShapeWKT = editingWkt;

        if(this.props.creatingEntityId !== null) {
            this.props.terminatePrimitiveCreation();
            GlobalCallbacks.addPrimitiveToList(primitive);
            return;
        }

        let response: Promise<any> | null = EditServerProxy.addPrimitive(primitive);

        if(response !== null){
            response.then(result => result.json())
                    .then((data: EntityResponse) => {
                        console.log(data);

                        if(data && data.SingleEntity){
                            this.props.registerLoadedEntity(primitive.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);

                            let primitives: Array<Primitive> = data.SingleEntity.Primitives.filter(
                                (p) => {
                                    return p.PrimitiveID === primitive.PrimitiveID;
                                }
                            );

                            if(primitives.length === 1) {
                                this.props.terminatePrimitiveCreation();
                                this.props.setEntityToUpdate(primitive.EntityID);
                                GlobalCallbacks.addPrimitiveToList(primitives[0]);
                            }
                            else {
                                console.log("Change is not applied!");
                                GlobalCallbacks.displaySnackbarMessage("Change is not applied!");
                            }
                        }
                        else{
                            GlobalCallbacks.displaySnackbarMessage("Change is not applied!");
                        }
                    })
        }
    };

    public onCreationCancel = () => {
        this.props.terminatePrimitiveCreation();
    };

    public changeProperty = (idsList: Array<string | null>, propertyPath: Array<{key: string | null, index: number | null}>, shouldDelete: boolean, value: string | null) => {
        console.log(propertyPath);

        let properties: Array<Property> = [];
        let property: Property | null = null;
        let primitive: Primitive = ModelUtils.deepCopy(this.state.primitive);
        let id: string | null = null;

        if(idsList[3] !== null){
            let relationships: Array<Relate> = primitive.Relationships.filter(relate => relate.RelateID === idsList[3]);
            if(relationships.length > 0){
                id = idsList[3];
                properties = relationships[0].Properties;
            }
            else{
                return;
            }
        }
        else{
            id = idsList[1];
            properties = primitive.PrimitiveProperties;
        }

        console.log(properties);

        for(let i: number = 0; i < propertyPath.length; i++){
            let node: {key: string | null, index: number | null} = propertyPath[i];

            if(node.key !== null){
                if(ArrayUtils.any(properties, (property: Property) => property.PropertyKeyName === node.key)){
                    property = properties.filter(property => property.PropertyKeyName === node.key)[0];
                }
                else{
                    if(!shouldDelete){
                        property = {
                            ID: id,
                            PropertyID: Guid.create().toString(),
                            ParentPropertyID: null,
                            PropertyKeyName: node.key,
                            Value: null,
                            SourceInfo: null,
                            Confidence: null,
                            Values: null,
                            Subproperties: null,
                            Name: node.key
                        };

                        properties.push(property);
                    }
                    else{
                        return;
                    }
                }
            }
            else {
                if(node.index !== null) {
                    if(properties[node.index]) {
                        property = properties[node.index];
                    }
                    else{
                        if(!shouldDelete){
                            property = {
                                ID: id,
                                PropertyID: Guid.create().toString(),
                                ParentPropertyID: null,
                                PropertyKeyName: null,
                                Value: null,
                                SourceInfo: null,
                                Confidence: null,
                                Values: null,
                                Subproperties: null,
                                Name: null
                            };

                            properties[node.index] = property;
                        }
                        else{
                            return;
                        }
                    }
                }
            }

            if(property !== null && i < propertyPath.length - 1) {
                let nextNode: {key: string | null, index: number | null} = propertyPath[i + 1];

                if(property.Values === null && property.Subproperties === null){
                    if(!shouldDelete) {
                        if (nextNode.index !== null)
                            property.Values = [];
                        else
                            property.Subproperties = [];
                    }
                    else{
                        return;
                    }
                }

                if (property.Values !== null) {
                    properties = property.Values;
                } else if (property.Subproperties !== null) {
                    properties = property.Subproperties;
                }
            }
        }

        if(shouldDelete && property !== null) {
            properties.splice(properties.indexOf(property), 1);
        }
        else if(!shouldDelete && property !== null) {
            console.log(property);
            property.Value = value;
            console.log(property);
        }

        this.setState({
            primitive: primitive
        })
    };

    public changeRelation = (id: string, type: string, shouldDelete: boolean) => {
        let primitive: Primitive = ModelUtils.deepCopy(this.state.primitive);

        if(shouldDelete) {
            let relate: Relate = primitive.Relationships.filter(relate => relate.ToID === id && relate.RelateTypeKeyName === type)[0];
            primitive.Relationships.splice(primitive.Relationships.indexOf(relate), 1);
        }
        else{
            let relate: Relate = {
                RelateTypeKeyName: type,
                RelateID: Guid.create().toString(),
                FromID: primitive.PrimitiveID,
                ToID: id,
                EditedBy: "",
                ToName: null,
                SourceInfo: "",
                Confidence: null,
                Properties: []
            };
            primitive.Relationships.push(relate);
        }

        this.setState({
            primitive: primitive
        })
    };

    public onPrimitiveDrop = () => {
        GlobalCallbacks.dropPrimitiveFromList(this.state.primitive);

        this.props.terminatePrimitiveCreation();
    };

    private shouldRenderDropButton(){
        let isPrimitiveCreated: boolean = ArrayUtils.any(this.props.creatingPrimitivesList, primitive => primitive.PrimitiveID === this.props.primitive.PrimitiveID);

        return this.props.creatingEntityId && isPrimitiveCreated;
    }

    public render(){
        console.log(this.props);
        let primitive: Primitive = this.state.primitive;

        return(
            <div>
                <Grid container direction="row">
                    <Grid item md={6}>
                        <Typography variant="h6">New primitive</Typography>
                    </Grid>
                    <Grid item md={3}>
                        {
                            this.shouldRenderDropButton() &&
                            <Button id="dropPrimitiveButton" onClick={this.onPrimitiveDrop} variant="contained" size="small">Drop</Button>
                        }
                    </Grid>
                    <Grid item md={3}>
                        <Button id="savePrimitiveButton" onClick={this.savePrimitive} variant="contained" size="small">Save</Button>
                    </Grid>
                </Grid>

                <Grid container direction="column">
                    <Grid item>
                        <LabelValue label={"ID"} value={primitive.PrimitiveID}/>
                    </Grid>
                    <Grid item>
                        <LabelValue label={"Type"} value={primitive.PrimitiveTypeKeyName}/>
                    </Grid>
                    <Grid item>
                            <PropertiesEdit idsList={[primitive.EntityID, primitive.PrimitiveID, "PRIMITIVE", null]} properties={primitive.PrimitiveProperties} componentId={"primitiveProperties"} callback={this.changeProperty} objectType={primitive.PrimitiveTypeKeyName}/>
                    </Grid>
                    <Grid item>
                            <PrimitiveRelationshipsEdit idsList={[primitive.EntityID, primitive.PrimitiveID, "PRIMITIVE", null]} primitiveRelates={primitive.Relationships} primitiveId={primitive.PrimitiveID} primitiveType={primitive.PrimitiveTypeKeyName} callback={this.changeRelation} propCallback={this.changeProperty}/>
                    </Grid>
                </Grid>

                <Button id="cancelPrimitiveButton" onClick={this.onCreationCancel} variant="contained" fullWidth={true} size="small">Cancel</Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        creatingPrimitivesList: state.searchResult.creatingPrimitivesList,
        creatingEntityId: state.searchResult.creatingEntityId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        terminatePrimitiveCreation: () => dispatch(terminatePrimitiveCreation()),
        setEntityToUpdate: (entityId: string | null) => dispatch(setEntityToUpdate(entityId)),
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPrimitiveForm)