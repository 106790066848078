import * as React from 'react';
import {Property} from "../../../model/Property";
import {PropertyValue} from "./PropertyValue";
import {DisplayTable} from "../../../commonComponents/DisplayTable";

type PropertiesTableProps = {
    properties: Array<Property>,
    setNewView: any
}

class PropertiesTable extends React.Component<PropertiesTableProps, any>{
    public render(): JSX.Element {
        let headerList: Array<string> = [];
        let contentList: Array<any> = [];

        if(this.props.properties !== null){
            headerList.push("Key");
            headerList.push("Value");

            contentList = this.props.properties.map((property) => {
                let row: Array<any> = [];
                row.push(property.PropertyKeyName);
                row.push(property.PropertyKeyName);
                row.push(<PropertyValue property={property} setNewView={this.props.setNewView}/>);

                return row;
            })
        }

        return(
            <div>
                <DisplayTable headerList={headerList} contentList={contentList}/>
            </div>
        )
    }
}

export {PropertiesTable}