import {ModelUtils} from "../utils/ModelUtils";

export class OntologyUtils{
    public static getDescendantsMap(idPropName: string, parentIdPropName: string, inheritanceObjects: Array<{}>): {[ancestorId: string]: Array<string>}{
        let map: {[ancestorId: string]: Array<string>} = OntologyUtils.getDirectDescendantsMap(idPropName, parentIdPropName, inheritanceObjects);
        map = OntologyUtils.directToFullDescendants(map);

        return map;
    }

    public static ensureIsArray(object: any){
        let types: Array<any> = [];

        if(!Array.isArray(object))
            types.push(object);
        else
            types = object;

        return types;
    }

    public static changeIdsToNames(descendantsMap: {[ancestorId: string]: Array<string>}, idToNameMap: {[objectId: string]: string}){
        let nameMap: {[keyName: string]: Array<string>} = {};

        Object.keys(descendantsMap).forEach(keyObjectId => {
            let keyName = idToNameMap[keyObjectId];
            nameMap[keyName] = [];

            descendantsMap[keyObjectId].forEach(valueObjectId => {
                let valueName = idToNameMap[valueObjectId];
                nameMap[keyName].push(valueName);
            })
        });

        return nameMap;
    }

    public static completeNameToPropertiesMap(nameToCompleteDescendantsMap: {[keyName: string]: Array<string>}, nameToPropertiesMap: {[keyName: string]: Set<string>}){
        Object.keys(nameToCompleteDescendantsMap).forEach(keyName => {
            let descendants: Array<string> = nameToCompleteDescendantsMap[keyName];

            if(keyName in nameToPropertiesMap){
                let ancestorProperties: Set<string> = nameToPropertiesMap[keyName];
                descendants.forEach(descendant => {
                    let descendantProperties: Set<string> = descendant in nameToPropertiesMap ? nameToPropertiesMap[descendant] : new Set();
                    nameToPropertiesMap[descendant] = new Set([...Array.from(descendantProperties), ...Array.from(ancestorProperties)]);
                })
            }
        });
    }

    private static getDirectDescendantsMap(idProp: string, parentIdProp: string, inheritanceObjects: Array<{}>): {[ancestorId: string]: Array<string>}{
        let ret: {[ancestorId: string]: Array<string>} = {};

        inheritanceObjects.forEach(object => {
            let parentId: string = object[parentIdProp];
            let objectId: string = object[idProp];

            if(!(parentId in ret))
                ret[parentId] = [];

            ret[parentId].push(objectId);
        });

        return ret;
    }

    private static directToFullDescendants(map: {[ancestorId: string]: Array<string>}): {[ancestorId: string]: Array<string>}{
        let ret: {[ancestorId: string]: Array<string>} = ModelUtils.deepCopy(map);

        while(true){
            let isChanged: boolean = false;

            Object.keys(ret).forEach(parentId => {
                let descendants: Array<string> = ret[parentId];
                let updatedDescendants: Array<string> = ModelUtils.deepCopy(descendants);

                descendants.forEach(descendant => {
                    if(descendant in ret){
                        let indirectDescendants: Array<string> = ret[descendant];

                        indirectDescendants.forEach(indDesc => {
                            if(updatedDescendants.filter((element => {
                                return element === indDesc;
                            })).length === 0){
                                updatedDescendants.push(indDesc);
                                isChanged = true;
                            }
                        })
                    }
                });

                ret[parentId] = updatedDescendants;
            });

            if(!isChanged){
                break;
            }
        }

        return ret;
    }
}