import {Point} from "../model/Point";
import * as L from "leaflet";
import {LatLngBounds} from "leaflet";
import {GeoJsonObject} from "geojson";
import * as WK from "wellknown";
import {EditServerProxy} from "./EditServerProxy";
import * as GeometryUtil from 'leaflet-geometryutil';

const geojsonBbox = require('geojson-bbox');
const jsts = require('jsts');

class GeometryUtils{
    public static RegularColor: string = '#0000ff';
    public static HighlightedEntityColor: string = '#ff0000';
    public static HighlightedPrimitiveColor: string = '#00ff00';
    public static EditingEntityColor: string = '#ffff00';
    public static WktColor: string = "#000000";
    public static CreatingColor: string = "#ff4500";

    public static initialMapCenter: [number, number] = [51.505, -0.09];
    public static initialMapZoom: number = 4;
    public static initialMinMapZoom: number = 2;
    public static maxMapBounds: LatLngBounds = new LatLngBounds([-90,-270],[90,270]);
    public static maxSearchBounds: LatLngBounds = new LatLngBounds([-90,-180], [90, 180]);

    public static getPointFromWkt(wkt: string): Point {
        let position: string[] = wkt.split('(')[1].split(')')[0].split(' ');

        let long: number= (position[0] as unknown) as number;
        let lat: number= (position[1] as unknown) as number;
        return new Point(lat, long);
    }

    public static toWkt(point: Point): string {
        return 'POINT ('+ point.long + " " + point.lat + ")";
    }

    public static getPointFromLatLong(lat: number, long: number): Point{
        return new Point(lat, long);
    }

    public static getBoundsFromWkt(wkt: string): LatLngBounds {
        const tmp : GeoJsonObject= WK.parse(wkt) as GeoJsonObject;
        let geoJson: GeoJsonObject = {
            "type": "Feature",
            "geometry" : tmp
        } as GeoJsonObject;

        let bounds: number[] = geojsonBbox(geoJson);

        let southWest = L.latLng(bounds[1], bounds[0]);
        let northEast = L.latLng(bounds[3], bounds[2]);

        return L.latLngBounds(southWest, northEast);
    }

    public static getPolygonWktFromBounds(bounds: LatLngBounds): string{
        let westLng: number = bounds.getWest();
        let eastLng: number = bounds.getEast();
        let southLat: number = bounds.getSouth();
        let northLat: number = bounds.getNorth();

        return 'POLYGON ((' + westLng + ' ' + northLat + ',' + eastLng + ' ' + northLat + ',' + eastLng + ' ' + southLat + ',' + westLng + ' ' + southLat + ',' + westLng + ' ' + northLat + '))';
    }

    public static isWktValid(wkt: string){
        try{
            let reader = new jsts.io.WKTReader();
            reader.read(wkt);

            return {
                valid: true,
                message: "Successful preview!"
            };
        }
        catch(err){
            return {
                valid: false,
                message: err.message
            };
        }
    }

    public static getGeoJsonType(primitiveType: string | null): string | null{
        if(primitiveType && primitiveType !== ""){
            switch (primitiveType) {
                case 'Area':
                case 'Bag':
                case 'Footprint':
                    return 'Polygon';
                case 'RoutingEdge':
                case 'Boundary':
                case 'Line':
                    return 'LineString';
                case 'Point':
                case 'AnchorPoint':
                case 'RoutingNode':
                    return 'Point';
            }
        }

        return null;
    }

    public static splittingPointOptions = {color: GeometryUtils.HighlightedEntityColor, weight: 7, opacity: 0.5, radius: 3};

    public static isFirstOrLastPoint(wkt: string, lat: number, lng: number){
        let coordinates: Array<Array<number>> = WK.parse(EditServerProxy.getEditingWkt())['coordinates'];
        return GeometryUtils.areSamePoint(coordinates[0][1], lat, coordinates[0][0], lng) || GeometryUtils.areSamePoint(coordinates[coordinates.length - 1][1], lat, coordinates[coordinates.length - 1][0], lng);
    }

    public static interpolateOnLine(map, wkt, ratio) {
        return GeometryUtil.interpolateOnLine(map, WK.parse(wkt)['coordinates'], ratio);
    }

    public static closest(map, wkt, latlng, vertices) {
        return GeometryUtil.closest(map, WK.parse(wkt)['coordinates'], latlng, vertices);
    }

    private static areSamePoint(lat1: number, lat2: number, lng1: number, lng2: number){
        return GeometryUtils.areSameNumber(lat1, lat2) && GeometryUtils.areSameNumber(lng1, lng2);
    }

    private static areSameNumber(num1: number, num2: number){
        return Math.abs(num1 - num2) < 0.0000001;
    }
}

export {GeometryUtils}