import * as React from 'react';
import {DetailsFactory} from "../detailsFactory/DetailsFactory";
import {Primitive} from "../../../model/Primitive";

type PrimitiveInfoProps = {
    primitive: Primitive,
    factory: DetailsFactory
}

class PrimitiveInfo extends React.Component<PrimitiveInfoProps, any>{
    public render(){
        return(
            <div>
                { this.props.factory.getPrimitiveInfo(this.props.primitive) }
            </div>
        )
    }
}

export {PrimitiveInfo}