import {Options} from "material-table";

export class MaterialTableUtils{
    public static tableOptions: Options = {
        paging: false,
        showTitle: false,
        padding: 'dense',
        addRowPosition: 'first',
        tableLayout: 'fixed'
    }
}