import * as React from 'react';
import {connect} from "react-redux";
import {Button, Dialog, DialogContent, Grid, Tab, Tabs} from "@material-ui/core";
import AllLoadedTab from "../../../allLoaded/AllLoadedTab";
import {BasicEntity} from "../../../model/BasicEntity";
import "./RelationshipsForm.css";
import {TabContent} from "../../../commonComponents/TabContent";
import SearchForm from "../../../search/SearchForm";
import EntityList from "../../../search/EntityList";

type RelationshipsProps = {
    allLoadedEntities: Array<BasicEntity>,
    basicFetchMessage: string | null,
    onIdChange: (id: string) => void,
    formBasicEntities: {[entityId: string]: BasicEntity}
}

type RelationshipsState = {
    toId: string,
    isOpen: boolean,
    activeTab: string
}

class RelationshipsForm extends React.Component<RelationshipsProps, RelationshipsState>{
    public constructor(props){
        super(props);

        this.state = {
            toId: "",
            isOpen: false,
            activeTab: "allLoaded"
        }
    }

    public openDialog = () => {
        this.setState({
            isOpen: true
        })
    };

    public handleClose = () => {
        this.setState({
            isOpen: false
        })
    };

    public onEntityClick = (id: string) => {
        this.props.onIdChange(id);
        this.setState({
            toId: id,
            isOpen: false
        })
    };

    public handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({
            activeTab: newValue
        })
    };

    public render(): JSX.Element{
        let buttonLabel: string = this.state.toId !== "" ? this.state.toId : "Choose entity";

        return(
            <div>
                <Button onClick={this.openDialog} variant="outlined" size="small" style={{textTransform: 'none'}}>{buttonLabel}</Button>
                {
                    this.state.isOpen &&
                        <Dialog open={true} onClose={this.handleClose} fullWidth={true}>
                            <DialogContent>
                                <Tabs value={this.state.activeTab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
                                    <Tab value={"allLoaded"} label={"All loaded"}/>
                                    <Tab value={"searchForm"} label={"Search"}/>
                                </Tabs>
                                <TabContent value={"allLoaded"} selected={this.state.activeTab}>
                                    <div className="RelationshipsEditDialog">
                                        <AllLoadedTab allLoadedEntities={this.props.allLoadedEntities} highlightedEntity={null} onEntityClick={this.onEntityClick} height={'80vh'}/>
                                    </div>
                                </TabContent>
                                <TabContent value={"searchForm"} selected={this.state.activeTab}>
                                    <div className="RelationshipsEditDialog">
                                        <Grid>
                                            <Grid item>
                                                <SearchForm fetchWholeEntities={false}/>
                                            </Grid>
                                            <Grid item>
                                                <EntityList highlightedEntity={null} basicEntities={this.props.formBasicEntities} message={this.props.basicFetchMessage} onEntityClick={this.onEntityClick} showAllInfo={true}/>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </TabContent>
                            </DialogContent>
                        </Dialog>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allLoadedEntities: state.linkedHashMapState.allLoadedEntities,
        formBasicEntities: state.searchResult.formBasicEntities,
        basicFetchMessage: state.searchResult.basicFetchMessage
    }
};

export default connect(mapStateToProps)(RelationshipsForm)