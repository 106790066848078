import * as React from 'react'
import GlobalCallbacks from "../utils/GlobalCallbacks";
import {Button, Grid, TextField} from "@material-ui/core";
import TextFieldTheme from "../theme/TextFieldTheme";
import {ThemeProvider} from "@material-ui/styles";

class MapCenter extends React.Component<any, any>{
    public handleClick = (event) => {
        event.preventDefault();

        let input : string = (document.getElementById("centerMapTextBox") as HTMLInputElement).value;
        input = input.trim();
        let coordinates = input.split(/[\s,]+/);

        if(coordinates.length === 2){
            let lat: number = Number(coordinates[0]);
            let long: number = Number(coordinates[1]);

            if(!isNaN(lat) && !isNaN(long)){
                GlobalCallbacks.centerMap(lat, long);
                return;
            }
        }

        GlobalCallbacks.displaySnackbarMessage("Input format: Lat, Long");
    };

    render(){
        return(
            <div>
                <form className="form" onSubmit={this.handleClick}>
                    <Grid container direction="row" alignItems="center">
                        <Grid>
                            <ThemeProvider theme={TextFieldTheme} >
                                <TextField id="centerMapTextBox" type="text" label="Lat, Long" variant="outlined" color="secondary" size="small" style={{width: 100}}/>
                            </ThemeProvider>
                        </Grid>
                        <Grid>
                            <Button id="centerButtonId" type="submit" variant="outlined">Center</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

export {MapCenter}