import {OntologyWrapper} from "./OntologyWrapper";
import {Ontology} from "../model/Ontology";

export class OntologyMap{
    private static map: {[streamId: string]: OntologyWrapper} = {};
    private static dummyOntology: OntologyWrapper = new OntologyWrapper(null);

    public static setOntology(streamId: string, ontology: Ontology){
        console.log(ontology);
        if(streamId !== null && !OntologyMap.alreadyExists(streamId))
            this.map[streamId] = new OntologyWrapper(ontology);
    }

    public static getOntology(streamId: string | null): OntologyWrapper{
        if(streamId)
            return OntologyMap.alreadyExists(streamId) ? this.map[streamId] : this.dummyOntology;

        return this.dummyOntology;
    }

    public static alreadyExists(streamId: string): boolean{
        return this.map.hasOwnProperty(streamId);
    }
}