import * as React from 'react';
import { connect } from 'react-redux';
import { setEditMode } from '../../store/actions/searchEntities';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

type TogglePropsType = {
    isEdit: boolean,
    setEditMode: (isEdit: boolean) => void
}

class ToggleEditMode extends React.Component<TogglePropsType, any>{
    private onChange = () => {
        this.props.setEditMode(!this.props.isEdit);
    };

    public render(): JSX.Element{
        return(
            <div>
                <FormControlLabel
                    control={
                    <Switch
                        checked={this.props.isEdit}
                        onChange={this.onChange}
                        color="secondary"
                        size="small"
                    />
                    }
                    label="Edit mode"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        isEdit: state.searchResult.isEdit
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEditMode: (isEdit: boolean) => dispatch(setEditMode(isEdit))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleEditMode)