import {DetailsFactory} from "./DetailsFactory";
import {MapEntity} from "../../../model/MapEntity";
import {Primitive} from "../../../model/Primitive";
import * as React from "react";
import NewPrimitiveForm from "../primitives/NewPrimitiveForm";
import NewEntityForm from "../NewEntityForm";

export class CreateFactory extends DetailsFactory{
    public getToggles(entity: MapEntity | null){

    }

    public getEntityInfo(entity: MapEntity | null = null, entityId: string | null = null){
        if(entityId !== null)
            return(<NewEntityForm creatingEntityId={entityId}/>);
    }

    public getPrimitiveInfo(primitive: Primitive): JSX.Element{
        return( <NewPrimitiveForm primitive = {primitive} />)
    }
}