import {RequestUtils} from "./RequestUtils";
import {Ontology} from "../model/Ontology";
import {OntologyMap} from "../ontology/OntologyMap";
import GlobalCallbacks from "./GlobalCallbacks";
import {CosmosUtils} from "./CosmosUtils";

export class StreamUtils{
    public static buildNodePaths(streamPath: Array<string>): Array<string>{
        let nodePaths: Array<string> = [];
        for(let i: number = 0; i < streamPath.length; i++){
            let nodePath: Array<string> = [];
            for(let j: number = 0; j <= i; j++){
                nodePath.push(streamPath[j]);
            }

            nodePaths.push(nodePath.join('/'));
        }

        return nodePaths;
    }

    public static getStreamPathFromName(streamName: string): Array<string>{
        let streamPath: Array<string> = streamName.split('/');
        streamPath.pop(); //last element represents a stream name, which can not be expanded
        return streamPath;
    }

    public static buildConfigString(streamInfo: string): string{
        let splitStreamInformation: string[] = streamInfo.split(';');
        return splitStreamInformation[0] + ";" + splitStreamInformation[1] + ";" + splitStreamInformation[2] + ";" + splitStreamInformation[3] + ";" + splitStreamInformation[5] + ";" + splitStreamInformation[6] + ";" + splitStreamInformation[7] + ";false;0";
    }

    public static getStreamName(streamInfo: string): string{
        let splitStreamInformation: string[] = streamInfo.split(';');
        let name: string = splitStreamInformation[4];

        if(!name.includes('/')){
            if(name.toLowerCase().includes('tln')){
                name = "TLN/" + name;
            }
            else{
                name = "Other/" + name;
            }
        }

        return name;
    }

    public static getStreamId(streamInfo: string): string{
        let splitStreamInformation: string[] = streamInfo.split(';');
        return splitStreamInformation[splitStreamInformation.length - 1];
    }

    public static setOntology(
        streamId: string,
        updateStreamInformation: (streamInformation: {}) => void,
        changeActiveTab: (key: string) => void,
        processKeptEntities: (() => void) | null = null
    ){

    RequestUtils.sendRequestWithConfigString("/api/entity/getOntology/post/", 'POST')
        .then(result => {
            console.log(result);
            return result.json()
        })
        .then(data => {
            console.log(data);
            if(data) {
                let ontology: Ontology = data.Ontology;
                console.log(ontology.EntityType[0]["@KeyName"]);
                OntologyMap.setOntology(streamId, ontology);
            }
            else{
                GlobalCallbacks.displaySnackbarMessage("Ontology fetch failed!");
            }

            this.setCosmosPath(streamId, updateStreamInformation, changeActiveTab, processKeptEntities);
        });
    }

    public static setCosmosPath(
        streamId: string,
        updateStreamInformation: (streamInformation: {}) => void,
        changeActiveTab: (key: string) => void,
        processKeptEntities: (() => void) | null = null
    ){
        RequestUtils.sendRequestWithConfigString("/api/entity/getoriginaljef/post/", 'POST')
            .then(result => result.json())
            .then(data => {
                let path: string = "";

                if(data){
                    path = data;
                    console.log(data);
                    if(path !== "" && path !== null) {
                        console.log(path);
                        let prefix: string = "https://aad.cosmos09.osdinfra.net/cosmos/dsdr.local.prod";
                        let suffix: string = "?property=info";
                        if(path.charAt(0) === '/'){
                            path = prefix + path;
                        }

                        if(path.charAt(path.length - 1) !== '/'){
                            path = path + suffix;
                        }

                        path = CosmosUtils.getStreamPathForRedirection(path);
                        console.log(path);
                    }
                }

                console.log(data);
                updateStreamInformation({
                    currentStreamId: streamId,
                    showSpinner: false,
                    cosmosPath: path
                });

                changeActiveTab("search");

                if(processKeptEntities){
                    processKeptEntities();
                }
            });
    }
}