import * as React from 'react';
import {Primitive} from '../../../model/Primitive';
import {EditServerProxy} from '../../../utils/EditServerProxy';
import {resetHighlightedPrimitive, setMapState} from '../../../store/actions/searchEntities';
import {connect} from 'react-redux';
import {setEntityToUpdate} from '../../../store/actions/changeEditingState';
import {EntityResponse} from '../../../model/EntityResponse';
import {ModelUtils} from '../../../utils/ModelUtils';
import GlobalCallbacks from "../../../utils/GlobalCallbacks";
import {PropertiesEdit} from "../properties/PropertiesEdit";
import PrimitiveRelationshipsEdit from "./PrimitiveRelationshipsEdit";
import {ConfirmDialog} from "../ConfirmDialog";
import {SourceInfo} from "../sources/SourceInfo";
import {Button, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import LabelValue from "../generalInformation/LabelValue";
import {registerLoadedEntity} from "../../../store/actions/changeLinkedHashMap";
import {MapState} from "../../../mapState/MapState";
import {MapStateWrapper} from "../../../mapState/MapStateWrapper";
import SaveIcon from '@material-ui/icons/Save';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import CallSplitOutlinedIcon from '@material-ui/icons/CallSplitOutlined';
import MoveLayerButton from "./MoveLayerButton";
import {Direction} from "./Direction";

type PrimitiveDetailsProps = {
    primitive: Primitive,
    mapState: MapState,
    resetHighlightedPrimitive: () => void,
    setEntityToUpdate: any,
    registerLoadedEntity: (id: string, item: any, reduced: boolean) => void,
    setMapState: (mapState: MapState) => void
}

type PrimitiveDetailsState = {
    primitive: Primitive,
    openDialog: boolean
}

class PrimitiveDetailsEdit extends React.Component<PrimitiveDetailsProps, PrimitiveDetailsState>{
    public constructor(props){
        super(props);

        this.state = {
            primitive: this.props.primitive,
            openDialog: false
        }
    }

    public componentDidUpdate(prevProps: PrimitiveDetailsProps){
        if(!PrimitiveDetailsEdit.compareProps(prevProps, this.props)){
            this.setState({
                primitive: this.props.primitive
            })
        }
    }

    private static compareProps(lhs: PrimitiveDetailsProps, rhs: PrimitiveDetailsProps){
        return JSON.stringify(lhs.primitive) === JSON.stringify(rhs.primitive);
    }

    private static isResponseValid(data): boolean{
        return data && data.SingleEntity;
    }

    public onDeletionAttempt = () => {
        this.setState({
            openDialog: true
        })
    };

    private onPrimitiveDrop = (deletionsConfirmed: boolean) => {
        if(deletionsConfirmed) {
            let response: Promise<any> | null = EditServerProxy.dropPrimitive();

            if (response !== null) {
                response.then(result => result.json())
                    .then((data: EntityResponse) => {
                        console.log(data);
                        if (PrimitiveDetailsEdit.isResponseValid(data)) {
                            this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                            this.props.resetHighlightedPrimitive();

                            this.props.setEntityToUpdate(data.SingleEntity.EntityID);
                        } else {
                            GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                        }
                    })
            } else {
                GlobalCallbacks.displaySnackbarMessage("Primitive is not properly selected!");
            }
        }

        this.setState({
            openDialog: false
        })
    };

    private onPrimitiveSave = () => {
        let response: Promise<any> | null = EditServerProxy.savePrimitive();

        if(response !== null){
            response.then(result => result.json())
                    .then((data: EntityResponse) => {
                        if(PrimitiveDetailsEdit.isResponseValid(data)) {
                            this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);

                            this.props.setEntityToUpdate(data.SingleEntity.EntityID);
                        }
                        else{
                            GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                        }
                    })
        }
        else{
            GlobalCallbacks.displaySnackbarMessage("Primitive is not properly selected!");
        }
    };

    private onPrimitiveSnap = () => {
        let response: Promise<any> | null = EditServerProxy.snapPrimitives();

        if(response !== null){
            response.then(result => result.json())
                .then((data: EntityResponse) => {
                    console.log(data);
                    if(PrimitiveDetailsEdit.isResponseValid(data)) {
                        this.props.registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false);
                        let primitive: Primitive = data.SingleEntity.Primitives.filter(
                            (primitive) => {
                                return primitive.PrimitiveID === this.state.primitive.PrimitiveID;
                            }
                        )[0];
                        this.props.setEntityToUpdate(data.SingleEntity.EntityID);

                        this.setState({
                            primitive: primitive
                        })
                    }
                    else{
                        GlobalCallbacks.displaySnackbarMessage("Change is not applied");
                    }
                })
        }
        else{
            GlobalCallbacks.displaySnackbarMessage("Primitives are not properly selected!");
        }
    };

    private togglePrimitiveSplit = () => {
        if(MapStateWrapper.isSplittingState(this.props.mapState))
            this.props.setMapState(MapState.Editing);
        else
            this.props.setMapState(MapState.Splitting);
    };

    private togglePrimitiveMerge = () => {
        if(MapStateWrapper.isMergingState(this.props.mapState))
            this.props.setMapState(MapState.Editing);
        else
            this.props.setMapState(MapState.Merging);
    };

    private togglePrimitiveReplace = () => {
        if(MapStateWrapper.isReplacingState(this.props.mapState))
            this.props.setMapState(MapState.Editing);
        else
            this.props.setMapState(MapState.Replacing);
    };

    private onEditCancel = () => {
        this.props.resetHighlightedPrimitive();
    };

    private moveLayer = (direction: Direction) => {
        GlobalCallbacks.moveLayer(this.props.primitive.EntityID, this.props.primitive.PrimitiveID, direction);
    };

    public render(): JSX.Element{
        console.log("Highlighted primitive: " + this.state.primitive.PrimitiveID);
        console.log("render");

        return(
            <Grid>
                <Grid container direction="row">
                    <Grid item md={7}>
                        <Typography variant="h6">Primitive information</Typography>
                    </Grid>
                    <Grid item md={1}>
                        <MoveLayerButton direction={Direction.UP} moveLayerCallback={this.moveLayer}/>
                    </Grid>
                    <Grid item md={1}>
                        <MoveLayerButton direction={Direction.DOWN} moveLayerCallback={this.moveLayer}/>
                    </Grid>
                    <Grid item md={3}>
                        <Button id="dropPrimitiveButton" fullWidth={true} onClick={this.onDeletionAttempt}  variant="outlined" size={"small"}>Drop</Button>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item>
                        <LabelValue label={"ID"} value={this.state.primitive.PrimitiveID}/>
                    </Grid>
                    <Grid item>
                        <LabelValue label={"Type"} value={this.state.primitive.PrimitiveTypeKeyName}/>
                    </Grid>
                    <Grid item>
                        <SourceInfo sourceInfo={this.props.primitive.SourceInfo !== null ? this.props.primitive.SourceInfo.split(';') : []} componentId={"Primitive"}/>
                    </Grid>
                    <Grid item>
                        <PropertiesEdit idsList={[this.state.primitive.EntityID, this.state.primitive.PrimitiveID, "PRIMITIVE", null]} properties={this.state.primitive.PrimitiveProperties} componentId={"primitiveProperties"} objectType={this.state.primitive.PrimitiveTypeKeyName}/>
                    </Grid>
                    <Grid item>
                        <PrimitiveRelationshipsEdit primitiveRelates={this.state.primitive.Relationships} idsList={[this.state.primitive.EntityID, this.state.primitive.PrimitiveID, "PRIMITIVE", null]} primitiveId={this.state.primitive.PrimitiveID} primitiveType={this.state.primitive.PrimitiveTypeKeyName}/>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row">
                            <Grid item md={3}>
                                <IconButton aria-label="Save geometry" id="savePrimitiveButton" onClick={this.onPrimitiveSave} size={"small"}>
                                    <Tooltip title={"Save geometry"}>
                                        <SaveIcon />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            <Grid item md={3}>
                                <IconButton aria-label="Merge"  id="mergePrimitiveButton" onClick={this.togglePrimitiveMerge} color={MapStateWrapper.isMergingState(this.props.mapState) ? "secondary" : "default"} size={"small"}>
                                    <Tooltip title={"Merge"}>
                                        <CallMergeIcon />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            <Grid item md={3}>
                                <IconButton aria-label="Replace" id="replacePrimitiveButton" onClick={this.togglePrimitiveReplace} color={MapStateWrapper.isReplacingState(this.props.mapState) ? "secondary" : "default"} size={"small"}>
                                    <Tooltip title={"Replace with another primitive"}>
                                        <FindReplaceIcon />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            {this.state.primitive.PrimitiveTypeKeyName === "RoutingEdge" &&
                            <Grid item md={3}>
                                <IconButton aria-label="Split" id="splitPrimitiveButton" onClick={this.togglePrimitiveSplit} color={MapStateWrapper.isSplittingState(this.props.mapState) ? "secondary" : "default"} size={"small"}>
                                    <Tooltip title={"Split"}>
                                        <CallSplitOutlinedIcon />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                
                <Button id="cancelPrimitiveButton" onClick={this.onEditCancel} fullWidth={true} variant="outlined" size={"small"}>Cancel</Button>
                <ConfirmDialog callback={this.onPrimitiveDrop} dialogOpen={this.state.openDialog} />
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        mapState: state.searchResult.mapState
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetHighlightedPrimitive: () => dispatch(resetHighlightedPrimitive()),
        setEntityToUpdate: (entityId: string | null) => dispatch(setEntityToUpdate(entityId)),
        registerLoadedEntity: (id: string, item: any, reduced: boolean) => dispatch(registerLoadedEntity(id, item, reduced)),
        setMapState: (mapState: MapState) => dispatch(setMapState(mapState))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimitiveDetailsEdit)