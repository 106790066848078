import * as React from 'react';
import { connect } from 'react-redux';
import {changeActiveTab} from "../../store/actions/changeMapState";
import {UrlUtils} from "../../permalink/UrlUtils";
import {EditServerProxy} from "../../utils/EditServerProxy";
import {LinkedHashMap} from "../../utils/LinkedHashMap";

type HighlightChangeProps = {
    highlightedEntity: string | null,
    highlightedPrimitive: string | null,
    setHighlight: any,
    changeActiveTab: any
}

class HighlightChangeListener extends React.Component <HighlightChangeProps, any>{
    private firstRender: boolean = true;

    public componentDidMount(){
        this.props.setHighlight(this.props.highlightedEntity, this.props.highlightedPrimitive);
    }

    public componentDidUpdate(prevProps: HighlightChangeProps){
        this.props.setHighlight(this.props.highlightedEntity, this.props.highlightedPrimitive);

        if(prevProps.highlightedEntity !== this.props.highlightedEntity && this.props.highlightedEntity !== null){
            this.props.changeActiveTab("information");
        }
    }

    public render(){
        EditServerProxy.setHighlightedEntityAndPrimitiveId(this.props.highlightedEntity, this.props.highlightedPrimitive);
        LinkedHashMap.setHighlightedEntity(this.props.highlightedEntity);

        if(this.firstRender) {
            this.firstRender = false;
        }
        else{
            if (this.props.highlightedEntity) {
                UrlUtils.changeParameter("highlightedEntity", this.props.highlightedEntity);
                if (this.props.highlightedPrimitive) {
                    UrlUtils.changeParameter("highlightedPrimitive", this.props.highlightedPrimitive);
                } else {
                    UrlUtils.removeParameter("highlightedPrimitive");
                }
            } else {
                UrlUtils.removeParameter("highlightedEntity");
                UrlUtils.removeParameter("highlightedPrimitive");
            }
        }

        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        highlightedEntity: state.searchResult.highlightedEntity,
        highlightedPrimitive: state.searchResult.highlightedPrimitive
	}
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: (key: string) => dispatch(changeActiveTab(key))
    }
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef:true})(HighlightChangeListener)