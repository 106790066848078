import * as React from 'react';
import { Child } from '../../../model/Child';
import {keepOnMap, searchByEntityId} from '../../../store/actions/searchEntities';
import { connect } from 'react-redux';
import {DisplayTable} from "../../../commonComponents/DisplayTable";
import './LinkButton.css';
import {changeActiveTab} from "../../../store/actions/changeMapState";
import {Link} from "@material-ui/core";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type ChildrenProps={
    children: Array<Child>,
    entityId: string,
    searchByEntityId: (identifierKey) => void,
    changeActiveTab: any,
    keepOnMap: any
}

class Children extends React.Component<ChildrenProps, any>{
    private onRelationshipClick = (id) => {
        if(id !== null && id !== undefined)
            this.props.keepOnMap(this.props.entityId);
            this.props.changeActiveTab("search");
            this.props.searchByEntityId(id);
    };

    public render(): JSX.Element{
        let headerList: Array<string> = [];
        let contentList: Array<Array<any>> = [];

        if(this.props.children !== null){
            headerList.push("Id");
            headerList.push("Type");

            contentList = this.props.children.map((child) => {
                let row: Array<any> = [];
                row.push(child.Id);
                row.push(<Link component={"button"} variant={"body2"} onClick={() => this.onRelationshipClick(child.Id)}>{child.Id}</Link>);
                row.push(child.TypeOfRelate);

                return row;
            })
        }

        let length: number = this.props.children !== null ? this.props.children.length : 0;
        
        return(
            <ExpansionComponent title={"Children (count: " + length + ")"} componentId={"children"}>
                {
                    this.props.children !== null && length > 0 &&
                    <DisplayTable contentList={contentList} headerList={headerList}/>
                }
            </ExpansionComponent>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchByEntityId: (identifierKey) => dispatch(searchByEntityId(identifierKey)),
        changeActiveTab: (key: string) => dispatch(changeActiveTab(key)),
        keepOnMap: (entityId: string) => dispatch(keepOnMap(entityId))
    }
};

export default connect(null, mapDispatchToProps)(Children)