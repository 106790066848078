import * as React from 'react';
import { DisplayTable } from '../../../commonComponents/DisplayTable';
import {Guid} from "guid-typescript";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";

type ContributorsProps={
    contributors: Array<string>
}

class Contributors extends React.Component<ContributorsProps, any>{
    public render(): JSX.Element{
        let headerList: Array<string> = [];
        let contentList: Array<Array<string>> = [];
        
        if(this.props.contributors !== null){
            headerList.push("Source");
            headerList.push("Id");

            contentList = this.props.contributors.map((contributor) => {
                let row: Array<string> = [];
                row.push(Guid.create().toString());
                row.push(contributor.split(':')[0]);
                row.push(contributor.split(':')[1]);

                return row;
            })
        }

        let length: number = this.props.contributors !== null ? this.props.contributors.length : 0;

        return(
            <ExpansionComponent title={"Contributors (count: " + length + ")"} componentId={"contributors"}>
                {
                    this.props.contributors !== null && length > 0 &&
                    <DisplayTable headerList={headerList} contentList={contentList}/>
                }
            </ExpansionComponent>
        )
    }
}

export { Contributors }