import * as React from 'react';
import {Guid} from "guid-typescript";
import ExpansionComponent from "../../../commonComponents/ExpansionComponent";
import {DisplayTable} from "../../../commonComponents/DisplayTable";
import {SourceInfoMap} from "../../../model/SourceInfoMap";

type AggregatedSourceInfoProps = {
    sourceInfo: SourceInfoMap
}

class AggregatedSourceInfo extends React.Component<AggregatedSourceInfoProps, any>{
    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let headerList: Array<string> = [];
        let contentList: Array<Array<string>> = [];

        if(this.props.sourceInfo !== null){
            headerList.push("Source");
            headerList.push("Id");

            contentList = Object.keys(this.props.sourceInfo).map((sourceInfo: string) => {
                let row: Array<string> = [];
                row.push(Guid.create().toString());
                row.push(sourceInfo.split(':')[0]);
                row.push(sourceInfo.split(':')[1]);

                return row;
            });
        }

        let length: number = Object.keys(this.props.sourceInfo).length;

        return(
            <ExpansionComponent title={"Data contributors (count: " + length + ")"} componentId={"aggregatedSourceInfo"} tooltip={"Aggregated SourceInfo from all entity fields"}>
                {
                    this.props.sourceInfo !== null && length > 0 &&
                    <DisplayTable headerList={headerList} contentList={contentList}/>
                }
            </ExpansionComponent>
        )
    }
}

export default AggregatedSourceInfo