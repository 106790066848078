import * as React from 'react'
import { connect } from 'react-redux'
import { keepOnMap, removeFromMap } from '../../store/actions/searchEntities';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {Tooltip} from "@material-ui/core";

type TogglePropsType = {
    entityId: string,
    entitiesKeptOnMap: Set<string>,
    keepOnMap: any,
    removeFromMap: any
}

class ToggleKeepOnMap extends React.Component<TogglePropsType, any>{
    private value: boolean = false;

    public constructor(props){
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    private onChange(): void{
        this.value = !this.value;

        if(this.value){
            this.props.keepOnMap(this.props.entityId);
        }
        else{
            this.props.removeFromMap(this.props.entityId);
        }
    }

    public render(): JSX.Element{
        this.value = this.props.entitiesKeptOnMap.has(this.props.entityId);
        return(
            <div>
                <Tooltip title={"Preserve entity on the map even after subsequent searches"} placement={"bottom"} arrow={true}>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={this.value}
                            onChange={this.onChange}
                            color="secondary"
                            size="small"
                        />
                        }
                        label="Keep on map"
                    />
                </Tooltip>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        entitiesKeptOnMap: state.searchResult.entitiesKeptOnMap
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        keepOnMap: (id: string) => dispatch(keepOnMap(id)),
        removeFromMap: (id: string) => dispatch(removeFromMap(id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleKeepOnMap)