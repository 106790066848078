export interface OntologyFieldNames {
    type: string,
    properties: string,
    enumValues: string,
    relateProp: string,
    idField: string,
    parentIdField: string
}

export const entityNameFields: OntologyFieldNames = {
    type: "NameType",
    properties: "EntityNameProperty",
    enumValues: "EntityNameEnumValue",
    relateProp: "",
    idField: "@NameTypeID",
    parentIdField: "@ParentTypeID"
};
export const entityFields: OntologyFieldNames = {
    type: "EntityType",
    properties: "EntityProperty",
    enumValues: "EntityEnumValue",
    relateProp: "EntityRelateType",
    idField: "@EntityTypeID",
    parentIdField: "@ParentID"
};
export const primitiveFields: OntologyFieldNames = {
    type: "PrimitiveType",
    properties: "PrimitiveProperty",
    enumValues: "PrimitiveEnumValue",
    relateProp: "PrimitiveRelateType",
    idField: "@PrimitiveTypeID",
    parentIdField: "@ParentTypeID"
};
export const entityRelateFields: OntologyFieldNames = {
    type: "EntityType",
    properties: "EntityRelateProperty",
    enumValues: "EntityRelateEnumValue",
    relateProp: "EntityRelateType",
    idField: "@EntityRelateTypeID",
    parentIdField: "@ParentTypeID"
};
export const primitiveRelateFields: OntologyFieldNames = {
    type: "PrimitiveType",
    properties: "PrimitiveRelateProperty",
    enumValues: "PrimitiveRelateEnumValue",
    relateProp: "PrimitiveRelateType",
    idField: "@PrimitiveRelateTypeID",
    parentIdField: "@ParentTypeID"
};