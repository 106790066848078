import * as React from 'react'
import { connect } from 'react-redux'
import { setMultipleEntityView } from '../../store/actions/searchEntities';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {Tooltip} from "@material-ui/core";

type TogglePropsType = {
    setMultipleEntityView: any
    multipleEntityView: boolean
    highlightedEntity: string | null
}

class ToggleSingleEntityView extends React.Component<TogglePropsType, any>{
    public constructor(props){
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    private onChange(): void{
        this.props.setMultipleEntityView(!this.props.multipleEntityView);
    }

    public render(): JSX.Element{
        return(
            <div>
                <Tooltip title={"Show all searched entities/Show only highlighted entity"} placement={"bottom"} arrow={true}>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={this.props.multipleEntityView}
                            onChange={this.onChange}
                            color="secondary"
                            size="small"
                        />
                        }
                        label="Show all"
                    />
                </Tooltip>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        multipleEntityView: state.searchResult.multipleEntityView,
        highlightedEntity: state.searchResult.highlightedEntity
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMultipleEntityView: (flag: boolean) => dispatch(setMultipleEntityView(flag))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleSingleEntityView)