import * as React from 'react';
import { Map } from "react-leaflet";
import './MapWrapper.css';
import {LatLngBounds} from "leaflet";
import * as L from 'leaflet';
import 'geoman-pro/leaflet-geoman.min.js';
import 'geoman-pro/leaflet-geoman.css';
import EntitiesGroup from "./entities/EnititiesGroup";
import { connect } from 'react-redux';
import {changeActiveTab, changeSearchBounds} from '../store/actions/changeMapState';
import WktGroup from '../wktPreview/WktGroup';
import { BaseMapUtils } from '../utils/BaseMapUtils';
import MapBoundsChangeListener from './MapBoundsChangeListener';
import CreatingEntity from "./editingComponents/CreatingEntity";
import {GeometryUtils} from "../utils/GeometryUtils";
import GlobalCallbacks from "../utils/GlobalCallbacks";
import {UrlUtils} from "../permalink/UrlUtils";
import CreatingPrimitive from "./editingComponents/CreatingPrimitive";
import {GeomanUtils} from "../utils/GeomanUtils";
import SplitPrimitiveListener from "./listeners/SplitPrimitiveListener";
import PrimitiveBinaryEditListener from "./listeners/PrimitiveBinaryEditListener";

type MapProps = {
    changeSearchBounds: any,
    baseMapName: string,
    changeActiveTab: (tabName: string) => void
}

class MapWrapper extends React.Component<MapProps, any>{
    private leafletMap: any;
    private previousLayer: any;
    private createdLayer: any;
    private onCreateListener: any;

    public componentDidMount(): void {
        GlobalCallbacks.setCenterMap(this.centerMap);

        const leafletMap = this.leafletMap.leafletElement;
        this.previousLayer = BaseMapUtils.getLayerInfoByName(this.props.baseMapName);
        this.previousLayer.tileLayer.addTo(leafletMap);

        GeomanUtils.configureGeomanToolbar(leafletMap);

        leafletMap.setMaxBounds(GeometryUtils.maxMapBounds);
        leafletMap.setMaxZoom(this.previousLayer.maxZoom);

        let baseMaps: {} = {};
        BaseMapUtils.baseMapList.forEach((baseMap) => {
            baseMaps[baseMap.label] = baseMap.tileLayer;
        });
        L.control.layers(baseMaps).addTo(leafletMap);

        leafletMap.on('contextmenu', (event) => {
            L.popup({ autoClose: false }).setLatLng(event.latlng).setContent("lat, long: " + event.latlng.lat + ", " + event.latlng.lng).openOn(leafletMap);
        });

        leafletMap.on('moveend', () => {
            this.props.changeSearchBounds(leafletMap.getBounds());
        });

        leafletMap.on('baselayerchange', (e) => {
            console.log(e);
            UrlUtils.changeParameter("baseMapName", e.name);
        })
    }

    public componentDidUpdate(): void{
        const leafletMap = this.leafletMap.leafletElement;
        leafletMap.removeLayer(this.previousLayer.tileLayer);
        this.previousLayer =  BaseMapUtils.getLayerInfoByName(this.props.baseMapName);
        leafletMap.addLayer(this.previousLayer.tileLayer);
        this.props.changeSearchBounds(leafletMap.getBounds());
        leafletMap.setMaxZoom(this.previousLayer.maxZoom);
    }

    public componentWillUnmount(): void {
        GlobalCallbacks.resetCenterMap();
    }

    public centerAndZoomMap = (lat: number, long: number, zoom: number | null = null) => {
        const leafletMap = this.leafletMap.leafletElement;
        if(zoom === null)
            leafletMap.setView(new L.LatLng(lat, long));
        else{
            zoom = zoom > 18 ? 18 : zoom;
            leafletMap.setView(new L.LatLng(lat, long), zoom);
        }
    };

    public centerMap = (lat: number, long: number) => {
        const leafletMap = this.leafletMap.leafletElement;
        leafletMap.setView(new L.LatLng(lat, long));
    };

    public fitBounds = (bounds: LatLngBounds) => {
        const leafletMap = this.leafletMap.leafletElement;

        leafletMap.fitBounds(bounds);
        if(leafletMap.getZoom() > 19){
            leafletMap.setZoom(19);
        }
    };

    public getMapReference = () => {
        return this.leafletMap.leafletElement;
    };

    public render(): JSX.Element{
        let center: [number, number] = this.leafletMap === undefined ? GeometryUtils.initialMapCenter : this.leafletMap.leafletElement.getCenter();
        let zoom: number = this.leafletMap === undefined ? GeometryUtils.initialMapZoom : this.leafletMap.leafletElement.getZoom();
        let minZoom : number = GeometryUtils.initialMinMapZoom;

        return(
            <div>
                <div className="leaflet-container">
                    <Map
                        ref={m => { this.leafletMap = m; }}
                        preferCanvas={true}
                        center={center}
                        zoom={zoom}
                        minZoom={minZoom}
                    >
                        <EntitiesGroup centerMap={this.centerAndZoomMap} fitBounds={this.fitBounds}/>
                        <WktGroup />
                        <CreatingEntity/>
                        <CreatingPrimitive getMapReference={this.getMapReference}/>
                    </Map>
                </div>
                <SplitPrimitiveListener getMapReference={this.getMapReference}/>
                <PrimitiveBinaryEditListener />
                <MapBoundsChangeListener fitBounds={this.fitBounds} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
	return {
        baseMapName: state.mapState.baseMapName
	}
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSearchBounds: (newBounds: LatLngBounds) => dispatch(changeSearchBounds(newBounds)),
        changeActiveTab: (tabName: string) => dispatch(changeActiveTab(tabName))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MapWrapper)