import * as React from 'react';
import { MapEntity } from '../../../model/MapEntity';
import { Primitive } from '../../../model/Primitive';

export class DetailsFactory{
    public generateEntityDetails(entity: MapEntity | null = null, entityId: string | null = null): JSX.Element{
        return(
            <div>
                {this.getToggles(entity)}
                {this.getEntityInfo(entity, entityId)}
            </div>
        )
    }

    public getToggles(entity: MapEntity | null){

    }

    public getEntityInfo(entity: MapEntity | null = null, entityId: string | null = null){

    }

    public getPrimitiveInfo(primitive: Primitive): JSX.Element{
        return(
            <div>
            </div>
        )
    }
}