import * as React from 'react';
import { connect } from 'react-redux';
import {ShapeWrapper} from "../map/views/ShapeWrapper";
import WktHighlightChangeListener from "./WktHighlightChangeListener";
import {GeometryUtils} from "../utils/GeometryUtils";
import {selectWktById, setMapBounds} from "../store/actions/changeMapState";
import {LatLngBounds} from "leaflet";

type WktGroupProps = {
    wktArray : {},
    setMapBounds: (newBounds: LatLngBounds) => void,
    selectWktById: (wktId: string | null) => void
}

class WktGroup extends React.Component<WktGroupProps, any>{
    private wktMap: {[wktId : string]: any} = {};
    private previousHighlighted: string | null = null;

    private setHighlight = (wktId: string | null) => {
        if(this.previousHighlighted && this.previousHighlighted !== wktId && this.wktMap[this.previousHighlighted]){
            this.wktMap[this.previousHighlighted].setHighlight(false);
        }

        if(wktId && this.wktMap[wktId]){
            this.wktMap[wktId].setHighlight(true);
            this.props.setMapBounds(GeometryUtils.getBoundsFromWkt(this.props.wktArray[wktId]));
        }

        this.previousHighlighted = wktId;
    };

    private createShapes(wktArray): JSX.Element[]{
        return Object.keys(wktArray).map(key => <ShapeWrapper key={key} ref={s => {this.wktMap[key] = s}} geometry={wktArray[key]} onClick={() => this.props.selectWktById(key)}/>);
    }

    public render(): JSX.Element{
        this.wktMap = {};
        let length: number = Object.keys(this.props.wktArray).length;

        return(
            <div>
                {length > 0 && this.createShapes(this.props.wktArray)}
                <WktHighlightChangeListener setHighlight={this.setHighlight}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        wktArray: state.mapState.wktArray
	}
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMapBounds: (newBounds: LatLngBounds) => dispatch(setMapBounds(newBounds)),
        selectWktById: (wktId: string | null) => dispatch(selectWktById(wktId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WktGroup)