import * as React from 'react';
import { connect } from 'react-redux';

type ListenerProps = {
    entityToUpdate: string | null,
    enforceUpdate: boolean,
    updateEntity: any
}

class EntityToUpdateListener extends React.Component <ListenerProps, any>{
    public componentDidMount(){
        if(this.props.entityToUpdate !== null)
            this.props.updateEntity(this.props.entityToUpdate);
    }

    public componentDidUpdate(){
        if(this.props.entityToUpdate !== null)
            this.props.updateEntity(this.props.entityToUpdate);
    }

    public render(){
        console.log(this.props.entityToUpdate);

        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        entityToUpdate: state.editing.entityToUpdate,
        enforceUpdate: state.editing.enforceUpdate
	}
};

export default connect(mapStateToProps, null, null, {forwardRef:true})(EntityToUpdateListener)