import * as React from 'react';
import DropdownList from "../../../commonComponents/DropdownList";
import {connect} from "react-redux";
import {OntologyWrapper} from "../../../ontology/OntologyWrapper";
import {OntologyMap} from "../../../ontology/OntologyMap";

type KeyFormProps = {
    objectType: string | null,
    parentProperty: string | null,
    setKey: (key: string | null) => void,
    currentStreamId: string | null
}

class KeyForm extends React.Component<KeyFormProps, any>{
    private getProperties(){
        let properties: Array<string> = [];
        let ontology: OntologyWrapper = OntologyMap.getOntology(this.props.currentStreamId);

        if(this.props.parentProperty !== null){
            properties = ontology.getPropertiesWrapper().getSubproperties(this.props.parentProperty);
        }
        else if(this.props.objectType !== null && this.props.objectType !== ""){
            properties = ontology.getPropertiesOfType(this.props.objectType)
        }

        return properties;
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let message: string = "";

        let properties: Array<string> = this.getProperties();

        if(this.props.objectType === "") {
            //only case when object type is not chosen, is at the beginning of entity creation.
            message = "First choose entity type!";
        }
        else if(properties.length === 0){
            message = "There are no suggested properties!";
        }

        return (
            <div>
                {message === "" && properties &&
                <DropdownList options={properties} onChange={this.props.setKey}/>}

                {message !== "" &&
                <div>{message}</div>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStreamId: state.streamState.currentStreamId
    }
};

export default connect(mapStateToProps)(KeyForm)