import {Property} from "./Property";
import {Relate} from "./Relate";

export interface Primitive{
    PrimitiveID: string;
    PrimitiveTypeKeyName: string;
    EntityID: string;
    ShapeWKT: string;
    SourceInfo: string | null;
    Contributors: string | null;
    PointIds: string;
    PrimitiveProperties: Array<Property>;
    Relationships: Array<Relate>;
    Topology: string;
    Confidence: any;
    EntityIdConfidence: any;
    EntitySourceInfo: string;
    Zoom: number;
    Lat: number;
    Long: number;
}

export function createPrimitive(entityId: string, primitiveId: string, primitiveType: string): Primitive {
    return {
        Confidence: null,
        Contributors: "",
        EntityID: entityId,
        EntityIdConfidence: null,
        EntitySourceInfo: "",
        Lat: 0,
        Long: 0,
        PointIds: "",
        PrimitiveID: primitiveId,
        PrimitiveProperties: [],
        PrimitiveTypeKeyName: primitiveType,
        Relationships: [],
        ShapeWKT: "",
        SourceInfo: "",
        Topology: "",
        Zoom: 0
    };
}

export function findPrimitive(primitiveId: string | null, list: Array<Primitive>): Primitive | null {
    let primitives: Array<Primitive> = list.filter((primitive: Primitive) => primitive.PrimitiveID === primitiveId);
    return (primitives.length > 0 ? primitives[0] : null);
}