import * as React from 'react';
import {connect} from "react-redux";
import {InfoDetailsFactory} from "./entityDetails/detailsFactory/InfoDetailsFactory";
import {EditDetailsFactory} from "./entityDetails/detailsFactory/EditDetailsFactory";
import {MapEntity} from "../model/MapEntity";
import {createPrimitive, Primitive} from "../model/Primitive";
import {DetailsFactory} from "./entityDetails/detailsFactory/DetailsFactory";
import {LinkedHashMap} from "../utils/LinkedHashMap";
import {EntityInfo} from "./entityDetails/generalInformation/EntityInfo";
import {PrimitiveInfo} from './entityDetails/primitives/PrimitiveInfo';
import {CreateFactory} from "./entityDetails/detailsFactory/CreateFactory";
import '../App.css';

import {ModelUtils} from "../utils/ModelUtils";

type InformationTabProps = {
    highlightedEntity: string | null,
    isEdit: boolean,
    creatingEntityId: string | null,
    highlightedPrimitive: string | null,
    creatingPrimitiveId: string | null,
    updateInformation: boolean,
    creatingPrimitiveObject: Primitive | null
}

class InformationTab extends React.Component<InformationTabProps, any>{
    private readonly infoDetailsFactory: InfoDetailsFactory;
    private readonly editDetailsFactory: EditDetailsFactory;
    private readonly createFactory: CreateFactory;

    public constructor(props){
        super(props);

        this.infoDetailsFactory = new InfoDetailsFactory();
        this.editDetailsFactory = new EditDetailsFactory();
        this.createFactory = new CreateFactory();
    }

    private static findPrimitive(entity: MapEntity | null, primitiveId: string | null): Primitive | null{
        let primitive: Primitive | null = null;

        if(entity !== null && entity.entityExtension !== null && primitiveId !== null){
            let length = entity.entityExtension.primitives.length;
            for(let i = 0; i < length; i++){
                if(entity.entityExtension.primitives[i].PrimitiveID === primitiveId){
                    primitive = entity.entityExtension.primitives[i];
                    break;
                }
            }
        }

        return primitive;
    }

    public render(): JSX.Element{
        console.log(this.props.creatingEntityId);

        let entity: MapEntity | null = null;
        let primitive: Primitive | null = null;

        let entityFactory: DetailsFactory | null = null;
        let primitiveFactory: DetailsFactory | null = null;

        if(this.props.creatingEntityId !== null) {
            entityFactory = this.createFactory;
            entity = null;

            if(this.props.creatingPrimitiveId){
                primitiveFactory = this.createFactory;

                if(this.props.creatingPrimitiveObject !== null){
                    primitive = ModelUtils.deepCopy(this.props.creatingPrimitiveObject);
                }
                else{
                    primitive = createPrimitive(this.props.creatingEntityId, this.props.creatingPrimitiveId, "");
                }
            }
            else{
                primitive = null;
                primitiveFactory = null;
            }
        }
        else{
            if(this.props.isEdit && this.props.highlightedEntity){
                entityFactory = this.editDetailsFactory;
                entity = LinkedHashMap.getItem(this.props.highlightedEntity);

                if(this.props.creatingPrimitiveId){
                    primitiveFactory = this.createFactory;

                    if(this.props.creatingPrimitiveObject !== null){
                        primitive = ModelUtils.deepCopy(this.props.creatingPrimitiveObject);
                    }
                    else{
                        primitive = createPrimitive(this.props.highlightedEntity, this.props.creatingPrimitiveId, "");
                    }
                }
                else{
                    primitiveFactory = this.editDetailsFactory;
                    primitive = InformationTab.findPrimitive(entity, this.props.highlightedPrimitive);
                }
            }
            else if(this.props.highlightedEntity !== null){
                entityFactory = this.infoDetailsFactory;
                entity = LinkedHashMap.getItem(this.props.highlightedEntity);

                primitiveFactory = this.infoDetailsFactory;
                primitive = InformationTab.findPrimitive(entity, this.props.highlightedPrimitive);
            }
        }
        console.log(this.props.creatingPrimitiveId);

        return (
            <div className="scrollable">
                {
                    ((entity !== undefined && entity !== null) || this.props.creatingEntityId !== null) && entityFactory !== null &&
                    <EntityInfo entity={entity} factory={entityFactory} entityId={this.props.creatingEntityId}/>
                }
                {
                    primitive !== undefined && primitive !== null && primitiveFactory !== null &&
                    <PrimitiveInfo primitive={primitive} factory={primitiveFactory}/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        highlightedPrimitive: state.searchResult.highlightedPrimitive,
        isEdit: state.searchResult.isEdit,
        updateInformation: state.searchResult.updateInformation,
        creatingPrimitiveId: state.searchResult.creatingPrimitiveId,
        creatingPrimitiveObject: state.searchResult.creatingPrimitiveObject
    }
};

export default connect(mapStateToProps)(InformationTab)