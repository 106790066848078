import * as React from 'react';
import {RequestUtils} from "../utils/RequestUtils";
import {setSearchService} from "../store/actions/changeAppState";
import {connect} from "react-redux";

type SearchServiceRetrieverProps = {
    setSearchService: (isPolaris: boolean) => void
}

class SearchServiceRetriever extends React.Component<SearchServiceRetrieverProps, any>{
    public componentDidMount(): void {
        RequestUtils.sendRequest("/api/entity/ispolaris/post/", 'POST')
            .then(result => result.json())
            .then(
                data => {
                    this.props.setSearchService(data);
                }
            );
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchService: (isPolaris: boolean) => dispatch(setSearchService(isPolaris))
    }
};


export default connect(null, mapDispatchToProps)(SearchServiceRetriever)