import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import mapReducer from './mapReducer';
import editingReducer from './editingReducer';
import streamReducer from "./streamReducer";
import appReducer from "./appReducer";
import searchFormReducer from "./searchFormReducer";
import linkedHashMapReducer from "./linkedHashMapReducer";

const rootReducer = combineReducers({
    searchResult: searchReducer,
    mapState: mapReducer,
    editing: editingReducer,
    streamState: streamReducer,
    appState: appReducer,
    searchFormState: searchFormReducer,
    linkedHashMapState: linkedHashMapReducer
});

export default rootReducer