import {RequestUtils} from "../../utils/RequestUtils";
import {LinkedHashMap} from "../../utils/LinkedHashMap";
import {ModelUtils} from "../../utils/ModelUtils";
import {GeometryUtils} from "../../utils/GeometryUtils";
import {findPrimitive, Primitive} from "../../model/Primitive";
import {EditServerProxy} from "../../utils/EditServerProxy";
import {Entity} from "../../model/Entity";
import GlobalCallbacks from "../../utils/GlobalCallbacks";
import {UrlUtils} from "../../permalink/UrlUtils";
import {LatLngBounds} from "leaflet";
import {TypeUtils} from "../../utils/TypeUtils";
import {clearLoadedEntities, registerLoadedEntity} from "./changeLinkedHashMap";
import {SearchType} from "../../search/SearchType";
import {SolrResponse} from "../../model/SolrResponse";
import {MapState} from "../../mapState/MapState";
import {GetEntity} from "../../model/request/search/GetEntity";

let numOfFetchAttempts: number = 0;
let successfullyFetched: Array<string> = [];
let shouldBeFetched: Array<string> = [];

function formSolrQuery(searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>){
    let query : string = "";

    if(searchBounds.getEast() <= 180 || searchBounds.getWest() >= -180) {
        query = "long_west:[-180 TO " + searchBounds.getEast() + "] AND long_east:[" + searchBounds.getWest() + " TO 180]";
    }

    if(searchBounds.getNorth() <= 90 || searchBounds.getSouth() >= -90) {
        let latCond = "lat_south :[-90 TO " + searchBounds.getNorth() + "] AND lat_north:[" + searchBounds.getSouth() + " TO 90]";
        query = query !== "" ? query + " AND " + latCond : latCond;
    }

    if(name !== ""){
        let nameCond : string = "name:(" + name + ")";
        query = query !== "" ? query + " AND " + nameCond : nameCond;
    }

    if(onlyNamedEntities){
        let nameCond : string = "name:[\"\" TO *]";
        query = query !== "" ? query + " AND " + nameCond : nameCond;
    }

    if(types.length > 0){
        let simpleTypes: Array<string> = types.map((type: string) => {
            if(type === "All roads")
                return TypeUtils.RoadTypes.join(" OR ");
            else
                return type;
        });

        let typeCond: string = "type:(" + simpleTypes.join(" OR ") + ")";

        query = query !== "" ? query + " AND " + typeCond : typeCond;
    }

    return query;
}

function formNameQuery(nameSearchType: SearchType, name: string){
    let nameCond: string = "";
    switch (nameSearchType){
        case SearchType.Equals:
            nameCond = "Name = '" + name + "'";
            break;
        case SearchType.StartsWith:
            nameCond = "Name LIKE '" + name + "%'";
            break;
        case SearchType.EndsWith:
            nameCond = "Name LIKE '%" + name + "'";
            break;
        case SearchType.Contains:
            nameCond = "Name LIKE '%" + name + "%'";
    }

    return nameCond;
}

function formPolarisQuery(searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, typeParam: string, nameSearchType: SearchType){
    let query : string = "";

    if(searchBounds.getEast() >= searchBounds.getWest()){
        query = 'Long_west BETWEEN -180 AND '+ searchBounds.getEast() + ' AND Long_east BETWEEN ' + searchBounds.getWest() +' AND 180';
    }

    if(searchBounds.getNorth() >= searchBounds.getSouth()){
        let latCond: string =  'Lat_south BETWEEN -90 AND ' + searchBounds.getNorth() +' AND Lat_north BETWEEN ' + searchBounds.getSouth() + ' AND 90';
        query = query !== "" ? query + " AND " + latCond : latCond;
    }

    if(name !== ""){
        let nameCond: string = formNameQuery(nameSearchType, name);
        query = query !== "" ? query + " AND " + nameCond : nameCond;
    }

    if(onlyNamedEntities){
        let nameCond : string = "Name != ''";
        query = query !== "" ? query + " AND " + nameCond : nameCond;
    }

    if(types.length > 0){
        let simpleTypes: Array<string> = types.map((type: string) => {
            if(type === "All roads")
                return TypeUtils.RoadTypes.map(roadType => {return "'" + roadType + "'"}).join(", ");
            else
                return "'" + type + "'";
        });

        let typeCond: string = typeParam + " IN (" + simpleTypes.join(", ") + ")";

        query = query !== "" ? query + " AND " + typeCond : typeCond;
    }

    return query;
}

function setDataSearchParametersToUrl(searchBounds: LatLngBounds, nameSearchType: SearchType, name: string, onlyNamedEntities: boolean, entityTypes: Array<string>, primitiveTypes: Array<string>){
    UrlUtils.removeParameter('search[entityId]');
    UrlUtils.removeParameter('search[primitiveId]');
    UrlUtils.removeParameter('search[contributor]');
    UrlUtils.removeParameter('search[contributorSearchType]');

    if(name !== "") {
        UrlUtils.changeParameter('search[name]', name);
        UrlUtils.changeParameter('search[nameSearchType]', nameSearchType);
    }
    else
    {
        UrlUtils.removeParameter('search[name]');
        UrlUtils.removeParameter('search[nameSearchType]');
    }

    if(onlyNamedEntities)
        UrlUtils.changeParameter('search[onlyNamed]', true);
    else
        UrlUtils.removeParameter('search[onlyNamed]');

    if(entityTypes.length > 0)
        UrlUtils.changeParameter("search[types]", entityTypes.join(';'));
    else
        UrlUtils.removeParameter("search[types]");

    if(primitiveTypes.length > 0)
        UrlUtils.changeParameter("search[primitiveTypes]", primitiveTypes.join(';'));
    else
        UrlUtils.removeParameter("search[primitiveTypes]");

    UrlUtils.changeParameter('search[bounds][S]', searchBounds.getSouth());
    UrlUtils.changeParameter('search[bounds][N]', searchBounds.getNorth());
    UrlUtils.changeParameter('search[bounds][E]', searchBounds.getEast());
    UrlUtils.changeParameter('search[bounds][W]', searchBounds.getWest());
}

export const searchEntitiesGroup = (isPolaris: boolean, searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, shouldSetHighlight: boolean, nameSearchType: SearchType, fetchWholeEntities: boolean = true, onFinish: (solrResponse: SolrResponse | null) => void = () => {}) => {
    return(dispatch) => {
        if(fetchWholeEntities)
            dispatch(showSearchProgress());
        else
            dispatch(showBasicSearchProgress());

        let query: string;
        if(isPolaris)
            query = formPolarisQuery(searchBounds, name, onlyNamedEntities, types, "Type", nameSearchType);
        else
            query = formSolrQuery(searchBounds, name, onlyNamedEntities, types);
        console.log(query);
        let data = {"QueryString": query, "EntityInfo": entityInfo};
        RequestUtils.sendRequestWithConfigString("/api/entity/solrsearch/post/", 'POST', data)
            .then(result => result.json())
            .then(
                data => {
                    if(data){
                        if(!fetchWholeEntities) {
                            onFinish(data);
                            dispatch({type: 'SEARCH_BASIC_ENTITIES', data: data});
                        }
                        else {
                            dispatch({type: 'HIDE_SEARCH_PROGRESS'});
                            onFinish(data);
                            window.console.log('Number of found entities ->', data.TotalCount);
                            setDataSearchParametersToUrl(searchBounds, nameSearchType, name, onlyNamedEntities, types, []);
                            dispatch({type: 'SEARCH_ENTITIES_GROUP', data: {data, shouldSetHighlight}});
                        }
                    }
                    else{
                        onFinish(null);
                        GlobalCallbacks.displaySnackbarMessage("Search failed!");
                        dispatch({type: 'HIDE_SEARCH_PROGRESS', data: null})
                    }
                }
            )
    }
};

export const addBasicEntity = (entity: Entity) => {
    return {type: 'ADD_BASIC_ENTITY', data: entity}
};

function setIdSearchParameterToUrl(entityId: string, primitiveId: string, contributor: string, contributorSearchType: SearchType = SearchType.Equals){
    UrlUtils.removeParameter('search[name]');
    UrlUtils.removeParameter('search[nameSearchType]');
    UrlUtils.removeParameter('search[types]');
    UrlUtils.removeParameter('search[primitiveTypes]');
    UrlUtils.removeParameter('search[bounds][S]');
    UrlUtils.removeParameter('search[bounds][N]');
    UrlUtils.removeParameter('search[bounds][E]');
    UrlUtils.removeParameter('search[bounds][W]');

    if(entityId !== "")
        UrlUtils.changeParameter('search[entityId]', entityId);
    else
        UrlUtils.removeParameter('search[entityId]');

    if(primitiveId !== "")
        UrlUtils.changeParameter('search[primitiveId]', primitiveId);
    else
        UrlUtils.removeParameter('search[primitiveId]');

    if(contributor !== "") {
        UrlUtils.changeParameter('search[contributor]', contributor);
        UrlUtils.changeParameter('search[contributorSearchType]', contributorSearchType);
    }
    else {
        UrlUtils.removeParameter('search[contributor]');
        UrlUtils.removeParameter('search[contributorSearchType]');
    }
}

export const searchByEntityId = (identifierKey, shouldSetHighlight: boolean = true, fetchWholeEntities: boolean = true, onFinish: (entityId: string | null) => void = () => {}) => {
    if(LinkedHashMap.containsWholeItem(identifierKey)){
        onFinish(identifierKey);
        setIdSearchParameterToUrl(identifierKey, "", "");
        return {type: 'SEARCH_ENTITY', data: {id: identifierKey, time: -1, message: null, shouldSetHighlight}}
    }
    else{
        return(dispatch) => {
            if(fetchWholeEntities)
                dispatch(showSearchProgress());
            else
                dispatch(showBasicSearchProgress());
            let data: GetEntity = {IdentifierKey: identifierKey, ShouldFetchingStop: "true"};
            RequestUtils.sendRequestWithConfigString("/api/entity/getent/post/", 'POST', data)
                .then(result => result.json())
                .then(
                    data => {
                        window.console.log('SearchForm, finished, entityId ->', data);
                        if(data && data.SingleEntity) {
                            if(!fetchWholeEntities){
                                dispatch({type: 'SEARCH_BASIC_ENTITY', data: {data: data.SingleEntity, message: data.Message}})
                            }
                            else {
                                dispatch(registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false));
                                onFinish(identifierKey);
                                let id: string = data.SingleEntity !== null ? data.SingleEntity.EntityID : null;
                                setIdSearchParameterToUrl(identifierKey, "", "");
                                dispatch({
                                    type: 'SEARCH_ENTITY',
                                    data: {id: id, time: data.Time, message: data.Message, shouldSetHighlight}
                                });
                            }
                        }
                        else{
                            onFinish(null);
                            GlobalCallbacks.displaySnackbarMessage("Search failed!");
                            dispatch({type: 'HIDE_SEARCH_PROGRESS', data: null});
                        }
                    }
                )
        }
    }
};

export const searchByContributor = (identifierKey, contributorSearchType: SearchType, fetchWholeEntities: boolean = true, shouldSetHighlight: boolean = true, onFinish: (solrResponse: SolrResponse | null) => void = () => {}) => {
        return(dispatch) => {
            if(fetchWholeEntities)
                dispatch(showSearchProgress());
            else
                dispatch(showBasicSearchProgress());
            let data = {"IdentifierKey": identifierKey, "ShouldFetchingStop": "true", "SearchType": contributorSearchType};
            RequestUtils.sendRequestWithConfigString("/api/entity/getentbycont/post/", 'POST', data)
                .then(result => result.json())
                .then(
                    data => {
                        window.console.log('SearchForm, finished, contributor ->', data);

                        if(data && data.Entities){
                            data.Entities.forEach(entity => {
                                dispatch(registerLoadedEntity(entity.EntityID, ModelUtils.mapEntityFromEntity(entity, 1), false));
                            });

                            let solrResponse: SolrResponse | null = ModelUtils.createSolrResponse(data);

                            if(!fetchWholeEntities){
                                onFinish(solrResponse);
                                dispatch({type: 'SEARCH_BASIC_ENTITIES', data: solrResponse});
                            }
                            else{
                                dispatch({type: 'HIDE_SEARCH_PROGRESS'});
                                onFinish(solrResponse);
                                window.console.log('Number of found entities ->', data.TotalCount);
                                EditServerProxy.setHighlightedEntityAndPrimitiveId(null, null);
                                setIdSearchParameterToUrl("", "", identifierKey, contributorSearchType);
                                dispatch({type: 'SEARCH_ENTITIES_GROUP', data: {data: solrResponse, shouldSetHighlight}});
                            }
                        }
                        else{
                            onFinish(null);
                            GlobalCallbacks.displaySnackbarMessage("Search failed!");
                            dispatch({type: 'HIDE_SEARCH_PROGRESS', data: null})
                        }
                    }
                )
        }
};

export const searchReducedEntity = (identifierKey, boundingBox, fetchWholeEntities: boolean = true) => {
    return(dispatch) => {
        if(fetchWholeEntities)
            dispatch(showSearchProgress());
        else
            dispatch(showBasicSearchProgress());
        console.log(boundingBox);
        let wkt: string = GeometryUtils.getPolygonWktFromBounds(boundingBox);
        console.log(wkt);
        let data = {"IdentifierKey": identifierKey, "ShouldFetchingStop": "true", "BoundingBox": wkt};
        RequestUtils.sendRequestWithConfigString("/api/entity/getentboundingboxfilter/post/", 'POST', data)
            .then(result => result.json())
            .then(
                data => {
                    window.console.log('SearchForm, finished, entityId ->', data);
                    if(data && data.SingleEntity !== null) {
                        if(!fetchWholeEntities){
                            dispatch({type: 'SEARCH_BASIC_ENTITY', data: {data: data.SingleEntity, message: data.Message}})
                        }
                        else {
                            dispatch(registerLoadedEntity(data.SingleEntity.EntityID, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), true));

                            let id: string = data.SingleEntity !== null ? data.SingleEntity.EntityID : null;
                            dispatch({type: 'SEARCH_ENTITY', data: {id: id, time: data.Time, message: data.Message}});
                        }
                    }
                    else{
                        GlobalCallbacks.displaySnackbarMessage("Search failed!");
                        dispatch({type: 'HIDE_SEARCH_PROGRESS', data: null});
                    }
                }
            )
    }
};

export const searchPrimitiveTypes = (searchBounds: LatLngBounds, name: string, onlyNamedEntities: boolean, types: Array<string>, entityInfo: string, nameSearchType: SearchType, fetchWholeEntities: boolean = true, shouldSetHighlight: boolean = true, onFinish: (solrResponse: SolrResponse | null) => void = () => {}) => {
    return(dispatch) => {
        if(fetchWholeEntities)
            dispatch(showSearchProgress());
        else
            dispatch(showBasicSearchProgress());
        console.log(types);
        let query: string = formPolarisQuery(searchBounds, name, onlyNamedEntities, types, "PrimitiveType", nameSearchType);
        console.log(query);
        let data = {"QueryString": query, "EntityInfo": entityInfo, "Type": types[0]};
        RequestUtils.sendRequestWithConfigString("/api/entity/primitivetypesearch/post/", 'POST', data)
            .then(result => result.json())
            .then(
                data => {
                    console.log(data);
                    if(data){
                        if(!fetchWholeEntities) {
                            onFinish(data);
                            dispatch({type: 'SEARCH_BASIC_ENTITIES', data: data});
                        }
                        else {
                            dispatch({type: 'HIDE_SEARCH_PROGRESS'});
                            onFinish(data);
                            window.console.log('Number of found entities ->', data.TotalCount);
                            setDataSearchParametersToUrl(searchBounds, nameSearchType, name, onlyNamedEntities, [], types);
                            dispatch({type: 'SEARCH_ENTITIES_GROUP', data: {data, shouldSetHighlight}});
                        }
                    }
                    else{
                        onFinish(null);
                        GlobalCallbacks.displaySnackbarMessage("Search failed!");
                        dispatch({type: 'HIDE_SEARCH_PROGRESS', data: null})
                    }
                }
            )
    }
};


export const searchPrimitive = (primitiveId: string, fetchWholeEntities: boolean = true, shouldSetHighlight: boolean = true, onFinish: (entityId: string | null) => void = () => {}) => {
    return(dispatch) => {
        if(fetchWholeEntities)
            dispatch(showSearchProgress());
        else
            dispatch(showBasicSearchProgress());
        let data = {"IdentifierKey": primitiveId, "ShouldFetchingStop": true};
        RequestUtils.sendRequestWithConfigString("/api/entity/getentbyprimitiveid/post", 'POST', data)
            .then(result => result.json())
            .then(
                data => {
                    if(data && data.SingleEntity && data.SingleEntity.EntityID){
                        if(!fetchWholeEntities){
                            dispatch({type: 'SEARCH_BASIC_ENTITY', data: {data: data.SingleEntity, message: data.Message}})
                        }
                        else {
                            let entityId: string = data.SingleEntity.EntityID;
                            dispatch(registerLoadedEntity(entityId, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), true));
                            onFinish(entityId);
                            setIdSearchParameterToUrl("", primitiveId, "");
                            let primitive: Primitive | null = findPrimitive(primitiveId, data.SingleEntity.Primitives);
                            if(primitive) {
                                dispatch({
                                    type: 'PRIMITIVE_SEARCH',
                                    data: {id: entityId, primitiveId: primitiveId, primitiveWkt: primitive.ShapeWKT, time: data.Time, message: data.Message, shouldSetHighlight}
                                });
                            }
                            else{
                                GlobalCallbacks.displaySnackbarMessage("Fetched entity doesn't contain searched primitive");
                                dispatch({
                                    type: 'SEARCH_ENTITY',
                                    data: {id: entityId, time: data.Time, message: data.Message, shouldSetHighlight}
                                });
                            }
                        }
                    }
                    else{
                        onFinish(null);
                        GlobalCallbacks.displaySnackbarMessage("Search failed!");
                        dispatch({type: 'HIDE_SEARCH_PROGRESS', data: null});
                    }
                }
            )
    }
};

export const searchEntityFromGroup = (identifierKey, shouldSetHighlight: boolean = true, onFinish: (entityId: string | null) => void = () => {}) => {
    if(LinkedHashMap.contains(identifierKey)){
        onFinish(identifierKey);
        return {type: 'SEARCH_ENTITY_FROM_GROUP', data: {id: identifierKey, time: -1, message: null, shouldSetHighlight}};
    }
    else{
        return(dispatch) => {
            dispatch(showSearchProgress());
            let data: GetEntity = {IdentifierKey: identifierKey, ShouldFetchingStop: "false"};
            RequestUtils.sendRequestWithConfigString("/api/entity/getent/post/", 'POST', data)
                .then(result => result.json())
                .then(
                    data => {
                        window.console.log('SearchForm, finished, entityId ->', data);
                        if(data && data.SingleEntity !== null) {
                            dispatch(registerLoadedEntity(identifierKey, ModelUtils.mapEntityFromEntity(data.SingleEntity, 1), false));
                            onFinish(identifierKey);
                            dispatch({type: 'SEARCH_ENTITY_FROM_GROUP', data: {id: identifierKey, time: data.Time, message: data.Message, shouldSetHighlight}});
                        }
                        else{
                            onFinish(null);
                            GlobalCallbacks.displaySnackbarMessage("Search failed!");
                            dispatch({type: 'HIDE_SEARCH_PROGRESS', data: null})
                        }
                    }
                )
        }
    }
};

export const setSearchProgress = (loaded, total) => {
    return {type: 'SET_SEARCH_PROGRESS', data:{loaded, total}};
};

export const showSearchProgress = () => {
    return {type: 'SHOW_SEARCH_PROGRESS', data: null}
};

export const showBasicSearchProgress = () => {
    return {type: 'SHOW_BASIC_SEARCH_PROGRESS'}
};

export const setShowMarkers = (flag: boolean) => {
    return {type: 'SET_SHOW_MARKERS', data: flag}
};

export const setMultipleEntityView = (flag: boolean) => {
    return {type: 'SET_MULTIPLE_ENTITY_VIEW', data: flag}
};

export const setHighlightedPrimitive = (primitiveId: string, primitiveWkt: string) => {
    return {type: 'SET_HIGHLIGHTED_PRIMITIVE', data: {primitiveId, primitiveWkt}}
};

export const resetHighlightedPrimitive = () => {
    return {type: 'RESET_HIGHLIGHTED_PRIMITIVE'}
};

export const setHighlightedEntity = (entityId: string) => {
    return {type: 'SET_HIGHLIGHTED_ENTITY', data: {entityId}}
};

export const handlePrimitiveClick = (entityId: string, primitiveId: string, primitiveWkt: string) => {
    return {type: 'HANDLE_PRIMITIVE_CLICK', data: {entityId, primitiveId, primitiveWkt}}
};

export const keepOnMap = (entityId) => {
    return {type: 'KEEP_ON_MAP', data: entityId}
};

function processFetchedEntity(fetchedData: any, length: number, dispatch: any, onFinish: ((keptEntities: Array<string>) => void) | null = null) {
    if (fetchedData && fetchedData.SingleEntity) {
        let id: string = fetchedData.SingleEntity.EntityID;
        dispatch(registerLoadedEntity(id, ModelUtils.mapEntityFromEntity(fetchedData.SingleEntity, 1), false));
        successfullyFetched.push(id);
        shouldBeFetched.splice(shouldBeFetched.indexOf(id), 1);
    }

    numOfFetchAttempts++;

    if (numOfFetchAttempts === length) {
        numOfFetchAttempts = 0;
        dispatch({type: 'KEEP_ENTITIES', data: successfullyFetched});

        if(shouldBeFetched.length > 0){
            let message: string = "These kept entities could not be fetched: " + shouldBeFetched.join(", ") + ". Please, try again.";
            GlobalCallbacks.displaySnackbarMessage(message);
            shouldBeFetched = [];
        }

        dispatch({type: 'HIDE_SEARCH_PROGRESS'});

        if(onFinish){
            onFinish(ModelUtils.deepCopy(successfullyFetched));
        }

        successfullyFetched = [];
    }
}

export const fetchAndKeepEntities = (entities: Array<string>, onFinish: ((keptEntities: Array<string>) => void) | null = null) => {
    shouldBeFetched = ModelUtils.deepCopy(entities);

    return(dispatch) => {
        dispatch(showSearchProgress());
        for (let i: number = 0; i < entities.length; i++) {
            let data: GetEntity = {IdentifierKey: entities[i], ShouldFetchingStop: "false"};
            RequestUtils.sendRequestWithConfigString("/api/entity/getent/post/", 'POST', data)
                .then(result => result.json())
                .then(
                    data => {
                        processFetchedEntity(data, entities.length, dispatch, onFinish);
                    }
                )
        }
    }
};

export const removeFromMap = (entityId) => {
    return {type: 'REMOVE_FROM_MAP', data: entityId}
};

export const clearKeptOnMap = () => {
    return {type: 'CLEAR_KEPT_ON_MAP', data: null}
};

export const resetSearchStore = () => {
    return(dispatch) => {
        dispatch(clearLoadedEntities());
        dispatch({type: 'RESET_SEARCH_STORE', data: null})
    }
};

export const setEditMode = (isEdit: boolean) => {
    return {type: 'SET_EDIT_MODE', data: isEdit}
};

export const setEntityCreation = (entityId: string, entity: Entity) => {
    console.log(entityId);
    return {type: 'SET_ENTITY_CREATION', data: {entityId, entity}}
};

export const terminateEntityCreation = () => {
    return {type: 'TERMINATE_ENTITY_CREATION'}
};

export const setCreatingPrimitivesList = (primitives: Array<Primitive>) => {
    return {type: 'SET_CREATING_PRIMITIVES_LIST', data: primitives}
};

export const setPrimitiveCreation = (primitiveId: string, primitive: Primitive) => {
    console.log(primitiveId);
    console.log(primitive);
    return {type: 'SET_PRIMITIVE_CREATION', data: {primitiveId, primitive}}
};

export const terminatePrimitiveCreation = () => {
    return {type: 'TERMINATE_PRIMITIVE_CREATION'}
};

export const setMapState = (mapState: MapState) => {
    return {type: 'SET_MAP_STATE', data: mapState}
};

export const updateInformationTab = () => {
    return {type: 'UPDATE_INFORMATION_TAB'}
};

export const resetChosen = () => {
    return {type: 'UPDATE_CHOSEN'}
};