import * as React from 'react';
import { connect } from 'react-redux';

type ListenerProps = {
    showMarkers: boolean,
    setShowMarkers: any
}

class ShowMarkersChangeListener extends React.Component<ListenerProps, any>{
    public componentDidMount(){
        this.props.setShowMarkers(this.props.showMarkers);
    }

    public componentDidUpdate(){
        this.props.setShowMarkers(this.props.showMarkers);
    }
    
    public render(): JSX.Element{
        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        showMarkers: state.searchResult.showMarkers
    }
};

export default connect(mapStateToProps)(ShowMarkersChangeListener)