import {LatLngBounds} from "leaflet";
import {ModelUtils} from "../../utils/ModelUtils";
import {BaseMapUtils} from "../../utils/BaseMapUtils";

let counter: number = 0;

type initialStateType = {
    searchBounds: LatLngBounds,
    mapBounds: LatLngBounds | null,
    wktArray: {},
    baseMapId: number,
    baseMapName: string,
    showWktSpinner: boolean,
    activeTab: string,
    selectedWkt: string,
    selectedWktId: string | null
}

const initState : initialStateType = {
    searchBounds: new LatLngBounds([24.36,-44.03],[68.59,43.85]),
    mapBounds: null,
    wktArray: {},
    baseMapId: 0,
    baseMapName: BaseMapUtils.baseMapList[0].label,
    showWktSpinner: false,
    activeTab: "streams",
    selectedWkt: "",
    selectedWktId: null
};

const mapReducer = (state=initState, action) => {
    let tmp = {};

    switch(action.type){
        case 'CHANGE_SEARCH_BOUNDS':
            return{
                ...state,
                searchBounds: action.data
            };

        case 'SET_MAP_BOUNDS':
            return{
                ...state,
                mapBounds: action.data
            };

        case 'SET_BASE_MAP':
            return{
                ...state,
                baseMapName: action.data
            };

        case 'ADD_WKT':
            tmp = ModelUtils.deepCopy(state.wktArray);

            let index: number = counter;
            tmp[index] = action.data;
            counter++;

            return{
                ...state,
                wktArray: tmp,
                selectedWkt: action.data,
                selectedWktId: String(index)
            };
            
        case 'ADD_WKTS':
            tmp = ModelUtils.deepCopy(state.wktArray);
            let wkts = action.data;

            wkts.map((w: string) => {
                tmp[counter++] = w;
                return null;
            });

            return{
                ...state,
                wktArray: tmp
            };

        case 'SHOW_WKT_SPINNER':
            return{
                ...state,
                showWktSpinner: action.data
            };

        case 'REMOVE_WKT':
            tmp = ModelUtils.deepCopy(state.wktArray);

            delete tmp[action.data];

            return{
                ...state,
                wktArray: tmp
            };

        case 'REMOVE_ALL_WKTS':
            tmp = {};

            return{
                ...state,
                wktArray: tmp
            };

        case 'CHANGE_ACTIVE_TAB':
            return{
                ...state,
                activeTab: action.data
            };

        case 'SELECT_WKT_BY_ID':
            let wkt: string = "";
            let wktId: string | null = action.data;
            if(wktId !== null && (wktId in state.wktArray))
                wkt = state.wktArray[wktId];

            return{
                ...state,
                selectedWkt: wkt,
                selectedWktId: wktId
            };

        case 'SELECT_WKT':
            return{
                ...state,
                selectedWkt: action.data
            };

        case 'RESET_MAP_STORE':
            return{
                ...state,
                wktArray: {},
                showWktSpinner: false
            };

        default:
            return state;
    }
};

export default mapReducer