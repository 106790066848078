import {Ontology} from "../../model/Ontology";
import {TypeUtils} from "../../utils/TypeUtils";
import {OntologyTypesWrapper} from "./OntologyTypesWrapper";
import {entityFields} from "../OntologyFieldNames";

export class OntologyEntityTypesWrapper extends OntologyTypesWrapper{
    private entityTypes: Array<string> = [];

    public constructor(ontology: Ontology | null){
        super(ontology, entityFields);

        if(ontology) {
            this.initEntityTypes(ontology);
        }
    }

    public getEntityTypes(): Array<string>{
        return this.entityTypes;
    }

    private initEntityTypes(ontology: Ontology){
        let ret: Set<string> = new Set<string>();

        ontology.EntityType.forEach(type => {
            ret.add(type["@KeyName"]);
        });

        if(this.isContained(ret, TypeUtils.RoadTypes))
            ret.add("All roads");

        this.entityTypes = Array.from(ret).sort((lhs: string, rhs: string) => lhs > rhs ? 1 : -1);
    }

    private isContained(container: Set<string>, contained: Array<string>){
        let ret: boolean = true;

        contained.forEach(roadType => {
            if (!container.has(roadType))
                ret = false;
        });

        return ret;
    }
}