import {PropertyType} from "../entityInformation/entityDetails/properties/PropertyType";
import {Ontology} from "../model/Ontology";
import {OntologyUtils} from "./OntologyUtils";
import {
    entityFields,
    entityNameFields,
    entityRelateFields,
    OntologyFieldNames,
    primitiveFields,
    primitiveRelateFields
} from "./OntologyFieldNames";

export class OntologyPropertiesWrapper{
    private propertiesTypes: {[propertyName: string]: PropertyType} = {};
    private subproperties: {[propertyName: string]: Array<string>} = {};
    private enumValues: {[propertyName: string]: Array<string>} = {};

    public constructor(ontology: Ontology | null){
        if(ontology) {
            this.initProperties(entityNameFields, ontology.NameType);
            this.initProperties(entityFields, ontology.EntityType);
            this.initProperties(primitiveFields, ontology.PrimitiveType);
            this.initRelateProperties(entityRelateFields, ontology.EntityType);
            this.initRelateProperties(primitiveRelateFields, ontology.PrimitiveType);
        }
    }

    public getPropertyType(propertyName: string | null): PropertyType{
        if(propertyName && propertyName in this.propertiesTypes) {
            return this.propertiesTypes[propertyName];
        }
        else
            return PropertyType.Empty;
    }

    public getSubproperties(propertyName: string | null){
        if(propertyName && propertyName in this.subproperties)
            return this.subproperties[propertyName].sort();
        else
            return [];
    }

    public getEnumValues(propertyName: string | null){
        if(propertyName && propertyName in this.enumValues)
            return this.enumValues[propertyName];
        else
            return [];
    }

    private initProperties(ontologyFields: OntologyFieldNames, inheritanceObjects: any){
        let objects: Array<any> = OntologyUtils.ensureIsArray(inheritanceObjects);
        objects.forEach(
            object => {
                if(ontologyFields.properties in object){
                    let properties: Array<any> = OntologyUtils.ensureIsArray(object[ontologyFields.properties]);

                    properties.forEach(property => {
                        this.processProperty(property, ontologyFields.enumValues);
                    });
                }
            }
        );
    }

    private initRelateProperties(ontologyFields: OntologyFieldNames, inheritanceObjects: any){
        let types: Array<any> = OntologyUtils.ensureIsArray(inheritanceObjects);
        let relates: Array<any> = [];

        types.forEach(
            type => {
                if(ontologyFields.relateProp in type){
                    relates = relates.concat(OntologyUtils.ensureIsArray(type[ontologyFields.relateProp]));
                }
            }
        );

        this.initProperties(ontologyFields, relates);
    }

    private processProperty(property: {}, enumProperty: string){
        this.classifyProperty(property);
        this.extractSubproperties(property, enumProperty);
        this.extractEnumValues(property, enumProperty);
    }

    private classifyProperty(property: {}){
        let propertyName: string = property['@KeyName'];

        this.propertiesTypes[propertyName] = OntologyPropertiesWrapper.getType(property);
    }

    private extractSubproperties(property: {}, enumProperty: string){
        let propertyName: string = property['@KeyName'];

        if(this.propertiesTypes[propertyName] === PropertyType.Subproperties){
            let childProperties: Array<any> = OntologyUtils.ensureIsArray(property['ChildProperty']);

            let subproperties: Array<string> = [];
            childProperties.forEach(subproperty => {
                subproperties.push(subproperty['@KeyName']);
                this.processProperty(subproperty, enumProperty);
            });

            this.subproperties[propertyName] = subproperties;
        }
    }

    private extractEnumValues(property: {}, enumProperty: string){
        let propertyName: string = property['@KeyName'];

        if(enumProperty in property){
            let enumValues: Array<any> = OntologyUtils.ensureIsArray(property[enumProperty]);

            this.enumValues[propertyName] = enumValues.map(value => value['@KeyName']);
        }
    }

    private static getType(property): PropertyType{
        if(property['@IsMultiValued'] === "true"){
            if('ChildProperty' in property){
                return PropertyType.Subproperties;
            }
            else{
                return PropertyType.Values;
            }
        }
        else {
            return PropertyType.Value;
        }
    }
}