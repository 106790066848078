type initialStateType = {
    entityToUpdate: string | null,
    enforceUpdate: boolean
}

const initState : initialStateType = {
    entityToUpdate: null,
    enforceUpdate: false
};

const editingReducer = (state=initState, action) => {
    switch(action.type){
        case 'SET_ENTITY_TO_UPDATE':
            return{
                ...state,
                entityToUpdate: action.data,
                enforceUpdate: !state.enforceUpdate
            };

        case 'RESET_EDITING_STORE':
            return{
                ...state
            };

        default:
            return state;
    }
};

export default editingReducer