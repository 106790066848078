import {Ontology} from "../model/Ontology";
import {OntologyFieldNames} from "./OntologyFieldNames";
import {OntologyUtils} from "./OntologyUtils";

export class OntologyRelatePropertiesWrapper{
    private properties: {[keyName: string]: Set<string>} = {};

    public constructor(ontology: Ontology | null, ontologyFields: OntologyFieldNames){
        if(ontology){
            this.initRelateProperties(ontologyFields, ontology[ontologyFields.type]);
        }
    }

    public getPropertiesOfType(type: string | null){
        if(type && type in this.properties)
            return Array.from(this.properties[type]).sort();
        else
            return [];
    }

    private initRelateProperties(ontologyFields: OntologyFieldNames, typeObjects: any){
        let types: Array<any> = OntologyUtils.ensureIsArray(typeObjects);
        let relates: Array<any> = [];

        types.forEach(
            type => {
                if(ontologyFields.relateProp in type){
                    relates = relates.concat(OntologyUtils.ensureIsArray(type[ontologyFields.relateProp]));
                }
            }
        );

        this.initProperties(ontologyFields, relates);
    }

    private initProperties(ontologyFields: OntologyFieldNames, inheritanceObjects: any){
        let objects: Array<any> = OntologyUtils.ensureIsArray(inheritanceObjects);

        let descendantsMap: {[ancestorId: string]: Array<string>} = OntologyUtils.getDescendantsMap(ontologyFields.idField, ontologyFields.parentIdField, objects);
        let idToNameMap: {[objectId: string]: string} = {};

        objects.forEach(
            object => {
                let keyName: string = object['@KeyName'];
                let objectId: string = object[ontologyFields.idField];

                idToNameMap[objectId] = keyName;

                this.properties[keyName] = new Set<string>();

                if(ontologyFields.properties in object){
                    let properties: Array<any> = OntologyUtils.ensureIsArray(object[ontologyFields.properties]);

                    properties.forEach(property => {
                        let propertyName: string = property['@KeyName'];
                        this.properties[keyName].add(propertyName);
                    });
                }
            }
        );

        let nameDescendantsMap: {[keyName: string]: Array<string>} = OntologyUtils.changeIdsToNames(descendantsMap, idToNameMap);
        OntologyUtils.completeNameToPropertiesMap(nameDescendantsMap, this.properties);
    }
}