import * as React from 'react'
import {Primitive} from "../../model/Primitive";
import Shape from '../views/Shape';
import { GeometryUtils } from '../../utils/GeometryUtils';
import {Direction} from "../../entityInformation/entityDetails/primitives/Direction";

type PrimitiveComponentProps = {
    primitive: Primitive,
    onClick: any,
    highlightedEntity: boolean
}

type PrimitiveComponentState = {
    highlightedPrimitive: boolean
}

class PrimitiveComponent extends React.Component<PrimitiveComponentProps, PrimitiveComponentState>{
    private editable: boolean = false;
    private shape: any;

    public constructor(props){
        super(props);

        this.state = {
            highlightedPrimitive: false
        }
    }

    public setHighlight(){
        this.setState({
            highlightedPrimitive: true
        })
    }

    public resetHighlight(){
        this.setState({
            highlightedPrimitive: false
        })
    }

    public setEditable(editable: boolean){
        if(this.editable !== editable)
            this.shape.setEditable(editable);

        this.editable = editable;
    }

    public moveLayer(direction: Direction){
        this.shape.moveLayer(direction);
    }

    public render(){
        let color: string;

        if(this.state.highlightedPrimitive){
            color = GeometryUtils.HighlightedPrimitiveColor;
        }
        else if(this.props.highlightedEntity){
            color = GeometryUtils.HighlightedEntityColor;
        }
        else {
            color = GeometryUtils.RegularColor;
        }

        return(
            <div>
                <Shape ref={s => { this.shape = s; }} geometry={this.props.primitive.ShapeWKT} onClick={() => this.props.onClick(this.props.primitive.PrimitiveID, this.props.primitive.ShapeWKT)} color={color}/>
            </div>
        )
    }
}

export { PrimitiveComponent }