import * as React from 'react';
import {Button, TextField} from "@material-ui/core";
import StreetName from "./StreetName";
import ShieldName from "./ShieldName";

type EntityNameFormProps = {
    name: string,
    nameType: string,
    onChange: (newName: string) => void
}

type EntityNameFormState = {
    isOpen: boolean
};

class EntityNameForm extends React.Component<EntityNameFormProps, EntityNameFormState>{
    public constructor(props){
        super(props);

        this.state = {
            isOpen: false
        }
    }

    public openDialog = () => {
        console.log("EntityNameForm openDialog");
        this.setState({
            isOpen: true
        })
    };

    private getDialogFromType(nameType: string): JSX.Element{
        if(nameType === "StreetName")
            return (<StreetName initialName={this.props.name} onChange={this.updateName}/>);
        else
            return <ShieldName initialName={this.props.name} onChange={this.updateName}/>;
    }

    private updateName = (name: string) => {
        this.props.onChange(name);
        this.setState({
            isOpen: false
        })
    };

    private onNameChange = () => {
        let input : string = (document.getElementById("entityNameId") as HTMLInputElement).value;
        this.props.onChange(input);
    };

    private getNameDisplay(): JSX.Element{
        if(this.props.nameType === "EntityName" || this.props.nameType === "" || !this.props.nameType)
            return <TextField id="entityNameId" type="text" size="small" onChange={this.onNameChange} value={this.props.name}/>;
        else
            return <Button onClick={this.openDialog} variant="outlined" size="small" style={{textTransform: 'none'}}>{this.props.name}</Button>
    }

    public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log(this.props);
        return(
            <div>
                {
                    this.getNameDisplay()
                }
                {
                    this.state.isOpen &&
                    this.getDialogFromType(this.props.nameType)
                }
            </div>
        )
    }
}

export default EntityNameForm;