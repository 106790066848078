import {Primitive} from "../model/Primitive";
import {Direction} from "../entityInformation/entityDetails/primitives/Direction";

class GlobalCallbacks {
    private static displaySnackbarMessageField: ((message: string) => void) | null = null;
    private static centerMapField: ((lat: number, long: number) => void) | null = null;
    private static addPrimitiveToListField: ((primitive: Primitive) => void) | null;
    private static dropPrimitiveFromListField: ((primitive: Primitive) => void) | null;
    private static moveLayerField: ((entityId: string, primitiveId: string, direction: Direction) => void) | null;

    public static setDisplaySnackbarMessage(displaySnackbarMessage: ((message: string) => void) | null): void {
        GlobalCallbacks.displaySnackbarMessageField = displaySnackbarMessage;
    }

    public static resetDisplaySnackbarMessage(): void {
        GlobalCallbacks.setDisplaySnackbarMessage(null);
    }

    public static displaySnackbarMessage(message: string): void {
        if (GlobalCallbacks.displaySnackbarMessageField !== null) {
            GlobalCallbacks.displaySnackbarMessageField(message);
        }
    }

    public static setCenterMap(centerMap: ((lat: number, long: number) => void) | null): void{
        GlobalCallbacks.centerMapField = centerMap;
    }

    public static resetCenterMap(): void{
        GlobalCallbacks.setCenterMap(null);
    }

    public static centerMap(lat: number, long: number){
        if(GlobalCallbacks.centerMapField !== null){
            GlobalCallbacks.centerMapField(lat, long);
        }
    }

    public static setAddPrimitiveToList(addPrimitiveToList: ((primitive: Primitive) => void) | null): void{
        GlobalCallbacks.addPrimitiveToListField = addPrimitiveToList;
    }

    public static resetAddPrimitiveToList(): void{
        GlobalCallbacks.setAddPrimitiveToList(null);
    }

    public static addPrimitiveToList(primitive: Primitive){
        if(GlobalCallbacks.addPrimitiveToListField !== null){
            GlobalCallbacks.addPrimitiveToListField(primitive);
        }
    }

    public static setDropPrimitiveFromList(dropPrimitiveFromList: ((primitive: Primitive) => void) | null): void{
        GlobalCallbacks.dropPrimitiveFromListField = dropPrimitiveFromList;
    }

    public static resetDropPrimitiveFromList(): void{
        GlobalCallbacks.setDropPrimitiveFromList(null);
    }

    public static dropPrimitiveFromList(primitive: Primitive){
        if(GlobalCallbacks.dropPrimitiveFromListField !== null){
            GlobalCallbacks.dropPrimitiveFromListField(primitive);
        }
    }

    public static setMoveLayer(moveLayer: (((entityId: string, primitiveId: string, direction: Direction) => void) | null)): void {
        GlobalCallbacks.moveLayerField = moveLayer;
    }

    public static resetMoveLayer(): void {
        GlobalCallbacks.setMoveLayer(null);
    }

    public static moveLayer(entityId: string, primitiveId: string, direction: Direction): void {
        if (GlobalCallbacks.moveLayerField !== null) {
            GlobalCallbacks.moveLayerField(entityId, primitiveId, direction);
        }
    }
}

export default GlobalCallbacks;